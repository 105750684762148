<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="col-12">
            <!--<span><b>Trade - {{setting.code}}</b></span>-->
            <div class="row" id="tmSummary">
                <!-- COIN MARKETS APPEAR HERE SEE getTradeSummary()-->
                <div class="summary-holder clickable col align-self-center" (click)="goToMarket(option)" *ngFor="let option of coinMarket">
                    <div class="market-head">
                        <b>{{option.coinCode}} / {{option.exchangeCode}}</b>
                    </div>
                    <div>
                        <span class="small-font">{{option.lastPrice | decimalNumber: option.exchangeDecimals}} {{option.exchangeCode}}</span>
                        <span class="{{option.changeColor}} trade_change"><b>{{option.change}}</b></span>
                    </div>
                    <div class="small-font">{{option.volume}} <ng-container i18n>VOL</ng-container></div>
                </div>
            </div>
        </div>
    </div>
</div>
