import { Component, ViewChild, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';

import { SideMarketComponent } from './side-market/side-market.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'markets-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  // @ViewChild('chat') chat: ChatComponent; // chat disabled
  @ViewChild('market') market: SideMarketComponent;
  @Output() marketIdChange: EventEmitter<any> = new EventEmitter();
  parent: any;
  showChat: boolean = true;
  @ViewChild('tradeHistory') tradeHistory: StatisticsComponent;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() showHistory: boolean = false;

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  constructor() {}

  onMarketIDChange(marketID: string) {
    this.marketIdChange.emit(marketID);
  }

  onValueChange(value: any) {
    this.valueChange.emit(value);
  }

  /**
   * Chat is disabled
   *

    ngAfterViewInit() {
      if (!!this.chat) {
        this.chat.SetParent(this);
        this.cdRef.detectChanges();
      }
    }

    setChat(chat: ChatComponent) {
      if (!!this.parent && !!this.parent.setchat) {
        this.parent.setchat(chat);
      }
    }
  */
}
