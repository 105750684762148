<div id="supportOsticket" class="d-none chat mat-elevation-z3" [class.expanded]="expanded" *ngIf="!!parent && !hidden && showSupportForm">
    <div [ngClass]="{'d-none': expanded && !drag}" class="float-right noselect">
        <img id="popup" (click)="expand()" class="d-none chat-icon" src="/assets/chainex/images/icons/lets_chat.svg" alt="connect with support.">
    </div>
    <div class="noselect" [ngClass]="{'d-none': !expanded}">
        <div id="formWidget" #formWidget>
            <span class="title" i18n>
                CONTACT SUPPORT
            </span>
            <span class="toggle">
                <a id="popout" href="{{supportURL}}/open.php" target="_blank">
                  <mat-icon class="popout" i18n-matTooltip matTooltip="Pop Out">call_made</mat-icon>
                </a>
                <mat-icon class="d-none" id="popOut" (click)="popOut()" i18n-matTooltip matTooltip="Pop Out">call_made</mat-icon>
                <mat-icon (click)="expand()" i18n-matTooltip matTooltip="Collapse">minimize</mat-icon>
                <mat-icon (click)="onClose()" i18n-matTooltip matTooltip="Close">close</mat-icon>
            </span>
        </div>
    </div>
    <div class="osticketform w-100" #osticketForm>
        <iframe class="w-100 h-100" [src]="supporUrlIframe"></iframe>
    </div>
</div>

<!-- full screen pop out -->
<div class="full-screen osticketform back" #osticketForm *ngIf="!parent">
    <iframe class="w-100 h-100" [src]="supporUrlIframe"></iframe>
</div>
