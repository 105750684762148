<mat-icon *ngIf="dialogRef" class="dialog-close" (click)="dialogRef.close()">close</mat-icon>
<div class="row clearfix m-2" [ngClass]="{'mat-elevation-z2 px-3' : !dialogRef}">
  <div class="col-12" *ngIf="dialogHeader; else pageHeader">
    <h4 class="mb-0" i18n>2FA Deactivation</h4>
  </div>
  <ng-template #pageHeader>
    <div class="col-12 d-flex align-items-center justify-content-center heading py-2 my-2">
      <span class="icon">
        <mat-icon svgIcon="twofa" i18n-matTooltip matTooltip="Google Authentication"></mat-icon>
      </span>
      <span class="orange-text">
        <h2 class="font-weight-bold mb-0" i18n>Disable 2FA</h2>
      </span>
    </div>
  </ng-template>


  <!-- disable confirmed -->
  <form novalidate class="col-12 px-3" *ngIf="emailSent">
    <div class="grey-text my-3">
      An email with instructions on how to disable 2FA on your account has been sent to your registered email
      address. Please check your email and follow the instructions.
    </div>

    <div class="col-12 col-lg-9 col-xl-8 mx-auto my-4 heading">
      <a href="" (click)="continueClick()">
        <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE</button>
      </a>
    </div>
  </form>

  <!-- confirmation prompt -->
  <form novalidate class="col-12 px-3" (ngSubmit)="confirmDisable()" *ngIf="!emailSent">
    <div class="grey-text mt-2 mb-4">
      <div class="my-3">
        Are you sure that you would like to disable 2FA on your account? Disabling 2FA could compromise your
        account's security and is only suggested if you have lost access to your 2FA token.
      </div>
      <div class="my-3">
        Once you confirm this action from the button below, an email with instructions on how to proceed will be
        sent to you.
      </div>
      <div class="my-3">
        <b>Please note: Disabling 2FA will result in the suspension of all withdrawals from your account for 24
          hours</b>
      </div>
    </div>
    <!-- Start of Alert -->
    <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0 mt-2 mb-4 heading" *ngIf="submitFailed">
      <div class="alert alert-danger">
        <p class="mb-0">{{resultMessage|i18n}}</p>
      </div>
    </div>
    <!-- End of Alert -->
    <!-- Captcha -->
    <div class="col-12 heading mt-2 mb-2" *ngIf="enableRecaptch">
      <div class="recaptcha-overlay"></div>
      <re-captcha id="recaptcha" theme="{{darkTheme ? 'dark' : 'light'}}" (resolved)="resolved($event)" required></re-captcha>
      <mat-error *ngIf="missingRecaptcha">
        <small i18n>Recaptcha is a required field</small>
      </mat-error>
    </div>

    <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0 mt-2 mb-4 heading">
      <div class="row">
        <div class="col-12 heading my-2">
          <button type="submit" class="w-100" mat-raised-button color="primary" i18n>
            DISABLE 2FA
          </button>
        </div>
        <div class="col-12 heading my-2">
          <button (click)="cancelClick()" class="w-100" mat-raised-button color="accent" i18n>
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
