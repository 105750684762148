import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MarketsService, IMarket } from 'src/app/markets/markets.service';
import { BalancesService } from '../../balances.service';


@Component({
  selector: 'app-trade-summary',
  templateUrl: './trade-summary.component.html',
  styleUrls: ['./trade-summary.component.scss']
})
export class TradeSummaryComponent implements OnInit, OnDestroy {
  coinMarket: IMarket[] = [];
  marketInfo: any;
  parent: any;
  currencyInfo: any;

  storeSubscriptions: any = [];
  private subs: SubscriptionLike[] = [];

  // tslint:disable-next-line:no-input-rename
  @Input('coin') coinCode: string = 'ZAR';
  // tslint:disable-next-line:no-input-rename
  @Input('type') coinType: string = 'fiat';

  constructor(
    public dialog: MatDialog,
    public marketService: MarketsService,
    private router: Router,
    public balancesService: BalancesService
  ) { }

  ngOnInit() {
    this.getPairs(); // set up handler for updates
    this.setupPairs(); // setup the pairs
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  getPairs() {
    this.subs.push(this.marketService.marketUpdateSubject.subscribe(response => {
      this.setupPairs();
    }));
  }

  goToMarket(market: IMarket) {
    this.router.navigate([`markets/${market.coinCode}/${market.exchangeCode}`]);
    this.closeModal();
  }

  closeModal() {
    if (!!this.parent) {
      this.parent.dialogRef.close();
    }
  }

  SetParent(parent: any) {
    this.parent = parent;
  }

  setupPairs() {
    const data: IMarket[] = [];
    this.subs.push(this.marketService.getMarketData().subscribe(response => {
      for (let i = 0; i < response.length; i++) {
        if (this.coinCode === response[i].coinCode) {
          data.push(response[i]);
        }
      }
      this.coinMarket = data;
    }));
  }
}
