import { Injectable } from '@angular/core';
import { State, Action, StateContext, createSelector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Product } from 'src/app/core/interfaces/balances';
import { InternalStateType } from 'src/app/core/services/session-storage.service';
import { BalancesService } from 'src/app/balances/balances.service';

export class EarnProducts {
  coinProducts: Product[];
  userProducts: Product[];
}

export class GetEarnProducts {
  static readonly type: string = '[Earn Products] Get earn products';

  constructor() {}
}

@State<InternalStateType>({
  name: 'earnProducts',
  defaults: {}
})
@Injectable()
export class EarnProductsState {
  static getEarnProducts() {
    return createSelector(
      [EarnProductsState],
      (state: EarnProducts): EarnProducts => state);
  }

  static getCoinEarnProduct(coin: string) {
    return createSelector(
      [EarnProductsState],
      (state: EarnProducts): EarnProducts => {
        return {
          coinProducts: state.coinProducts.filter(coinProduct => coinProduct.coin_code === coin),
          userProducts: state.userProducts.filter(userProduct => userProduct.coin_code === coin)
        };
      });
  }

  constructor(private balanceService: BalancesService) {}

  @Action(GetEarnProducts)
  GetEarnProducts(ctx: StateContext<EarnProducts>) {
    const getProducts = this.balanceService
      .getCoinProducts()
        .pipe(tap(data => {
          if (data && data.response === 'success') {
            ctx.setState(data.data);
          } else {
            ctx.setState({
              coinProducts: [],
              userProducts: []
            });
          }
        }));

    return getProducts;
  }

}
