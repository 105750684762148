<div class="warning-box" *ngIf="!loggedIn">
  <div>
    <span i18n>
      Please sign up or in to trade.
    </span>

    <br><br>

    <button mat-raised-button routerLink="/register" class="mat-raised-button" color="accent" i18n>
      SIGN UP
    </button>
    &nbsp;&nbsp;
    <button mat-raised-button [routerLink]="[getLoginUrl(router, null)]" class="mat-raised-button" color="primary"
            i18n>
      SIGN IN
    </button>
  </div>
</div>
