import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[appHoverPopUp]'
}) export class HoverPopUpDirective {

    @Input('appHoverPopUp') list: string[];
    // tslint:disable-next-line:no-input-rename
    @Input('appHoverPopUp-title') title: string;

    popUp: HTMLDivElement;

    constructor(private element: ElementRef) {
        this.popUp = document.getElementById('appHoverPopUp') as HTMLDivElement;
        if (this.popUp === null) {
            this.popUp = document.createElement('div');
            this.popUp.id = 'appHoverPopUp';
            this.popUp.classList.add('mat-elevation-z2');
            this.popUp.classList.add('hover-pop-up');
            this.popUp.style.display = 'none';
            this.popUp.style.position = 'absolute';
            this.popUp.style.padding = '10px';
        }

        const temp: any = document.body;
        temp.append(this.popUp);
    }

    updateContent () {
        let html = '';
        if (!!this.title && this.title !== '') {
            html = `<span class="pop-up-title"><small>${this.title}</small></span><br>`;
        }
        if (!!this.list) {
            for (let i = 0; i < this.list.length; i++) {
                if (i !== 0) {
                    html += '<br>';
                }
                html += this.list[i];
            }
        } else {
            html += 'NONE';
        }
        this.popUp.innerHTML = html;
    }

    @HostListener('mouseenter') onmouseenter() {
        this.updateContent ();
        this.popUp.style.display = 'block';
        const bounds = this.element.nativeElement.getBoundingClientRect();
        this.popUp.style.top = (bounds.top + this.element.nativeElement.clientHeight) + 'px';
        this.popUp.style.left = bounds.left + 'px';
    }

    @HostListener('mouseleave') onmouseleave() {
        this.popUp.style.display = 'none';
    }
}
