import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventHubService {
  private _listners: Subject<any> = new Subject<any>();

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  emit(data: any) {
    this._listners.next(data);
  }
}
