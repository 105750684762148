// Angular
import { Injectable } from '@angular/core';
import { mergeMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';

// Crypto Interfaces
import { AddressBook, UnsavedAddresses, AddressStatus,
  ModifyAddressBook
} from 'src/app/core/interfaces/addressbook';
// Fiat Interfaces
import { AddBankAddressBook, BankAddressBook, BankAddressBooks, BankAddressStatus, BankAddressVerified } from 'src/app/core/interfaces/bankAddressbook';
// Services
import { AddressBookService } from '../address-book.service';
// Store
import { State, Action, StateContext, createSelector, Store } from '@ngxs/store';

/** ------------ Interfaces ------------ */
// Model Interfaces
interface CryptoAddressBookModel {
  [coin: string]: AddressBook[];
}

interface UnsavedCryptoAddressesModel {
  [coin: string]: UnsavedAddresses[];
}

interface FiatAddressBookModel {
  [coin: string]: BankAddressBooks;
}

// State Interface
interface AddressBookState {
  cryptoAddressBook: CryptoAddressBookModel;
  unsavedAddresses: UnsavedCryptoAddressesModel;
  fiatAddressBook: FiatAddressBookModel;
}

/** ------------ State Classes ------------ */
// Crypto Address Book Actions
export class GetCryptoAddressBook {
  static readonly type: string = '[AddressBook] Get crypto address book';

  constructor(public coinCode: string) {}
}

export class GetUserUnsavedCryptoAddresses {
  static readonly type: string = '[AddressBook] Get crypto Unsaved address book';

  constructor(public coinCode: string) {}
}

export class UpdateCryptoAddressBookItem {
  static readonly type: string = '[AddressBook] Update crypto Unsaved address book';

  constructor(public data: ModifyAddressBook) {}
}

export class RemoveCryptoAddressBookItem {
  static readonly type: string = '[AddressBook] Remove crypto address book item';

  constructor(public coinCode: string, public address: string) {}
}

export class RemoveUnsavedCryptoAddressBookItem {
  static readonly type: string = '[AddressBook] Remove unsaved crypto address book item';

  constructor(public coinCode: string, public address: string) {}
}

// Fiat Address Book Actions
export class GetFiatAddressBook {
  static readonly type: string = '[AddressBook] Get fiat address book';

  constructor(public coinCode: string) {}
}

export class AddFiatAddressBookItem {
  static readonly type: string = '[AddressBook] Add fiat address book item';

  constructor(public data: AddBankAddressBook) {}
}

export class RemoveFiatAddressBookItem {
  static readonly type: string = '[AddressBook] Remove fiat address book item';

  constructor(public coinCode: string, public accountNumber: string) {}
}

@State<AddressBookState>({
  name: 'AddressBooks',
  defaults: {
    cryptoAddressBook: {},
    unsavedAddresses: {},
    fiatAddressBook: {}
  }
})

@Injectable()
export class AddressBookStore {

  constructor(
    private _addressBookService: AddressBookService,
    private _store: Store
  ) {}

  /** ------------ Crypto Address Book Static Methods ------------ */
  static GetCryptoAddressBook(coinCode: string) {
    return createSelector(
      [AddressBookStore],
      (state: AddressBookState): AddressBook[] => state.cryptoAddressBook[coinCode]
    );
  }

  static GetUserUnsavedCryptoAddresses(coinCode: string) {
    return createSelector(
      [AddressBookStore],
      (state: AddressBookState): UnsavedAddresses[] => state.unsavedAddresses[coinCode]
    );
  }

  static UpdateCryptoAddressBookItem(data: ModifyAddressBook) {
    return createSelector(
      [AddressBookStore],
      (state: AddressBookState): AddressBook[] => state.cryptoAddressBook[data.code]
    );
  }

  static RemoveCryptoAddressBookItem(coinCode: string, address: string) {
    return createSelector(
      [AddressBookStore],
      (state: AddressBookState): AddressBook[] => state.cryptoAddressBook[coinCode]
    );
  }

  static RemoveUnsavedCryptoAddressBookItem(coinCode: string, address: string) {
    return createSelector(
      [AddressBookStore],
      (state: AddressBookState): UnsavedAddresses[] => state.unsavedAddresses[coinCode]
    );
  }

  /** ------------ Fiat Address Book Static Methods ------------ */

  static GetFiatAddressBook(coinCode: string) {
    return createSelector(
      [AddressBookStore],
      (state: AddressBookState): BankAddressBooks => state.fiatAddressBook[coinCode]
    );
  }

  static AddFiatAddressBookItem(coinCode: string) {
    return createSelector(
      [AddressBookStore],
      (state: AddressBookState): BankAddressBooks => state.fiatAddressBook[coinCode]
    );
  }

  static RemoveFiatAddressBookItem(coinCode: string, address: string) {
    return createSelector(
      [AddressBookStore],
      (state: AddressBookState): BankAddressBooks => state.fiatAddressBook[coinCode]
    );
  }

  /** ------------ Crypto Address Book Actions ------------ */

  @Action(GetCryptoAddressBook)
  getCryptoAddressBook(ctx: StateContext<AddressBookState>, action: GetCryptoAddressBook) {
    // Obtain current state object
    const state = ctx.getState();
    // Append state object data

    const newAddressBook = this._addressBookService.getAddressBook(action.coinCode).pipe(
      tap(coinAddresses => {
        ctx.patchState({
          cryptoAddressBook: {
            ...state.cryptoAddressBook,
            [action.coinCode]: coinAddresses
          }
        });
      })
    );

    if (newAddressBook[action.coinCode]) {
      newAddressBook.toPromise().then(coinAddresses => state.cryptoAddressBook[action.coinCode] = coinAddresses);

      return newAddressBook[action.coinCode];
    }

    return newAddressBook;
  }

  @Action(GetUserUnsavedCryptoAddresses)
  getUserUnsavedCryptoAddresses(ctx: StateContext<AddressBookState>, action: GetUserUnsavedCryptoAddresses) {
    // Obtain current state object
    const state = ctx.getState();
    // Append state object data
    const newUnsavedAddresses = this._addressBookService.getUserUnsavedAddresses(action.coinCode).pipe(
      tap(unsavedCoinAddresses => {
        ctx.patchState({
          unsavedAddresses: {
            ...state.unsavedAddresses,
            [action.coinCode]: unsavedCoinAddresses
          }
        });
      })
    );

    if (newUnsavedAddresses[action.coinCode]) {
      newUnsavedAddresses.toPromise().then(unsavedCoinAddresses =>
        state.unsavedAddresses[action.coinCode] = unsavedCoinAddresses
      );

      return newUnsavedAddresses[action.coinCode];
    }

    return newUnsavedAddresses;
  }

  @Action(UpdateCryptoAddressBookItem)
  updateCryptoAddressBookItem(ctx: StateContext<AddressBookState>, action: UpdateCryptoAddressBookItem) {
    // Obtain current state object
    const state = ctx.getState();
    let updatedBook: AddressBook[];
    let idx;
    // Obtain item array index
    if (!!action.data.paymentId && action.data.paymentId?.toUpperCase() !== 'NONE') {
      idx = state.cryptoAddressBook[action.data.code].findIndex(
        (a: AddressBook) => a.address === action.data.address && a.paymentId === action.data.paymentId
      );
    } else {
      idx = state.cryptoAddressBook[action.data.code].findIndex(
        (a: AddressBook) => a.address === action.data.address
      );
    }

    // Update item if it exists in the current store
    if (idx > -1) {
      updatedBook = JSON.parse(JSON.stringify(state.cryptoAddressBook[action.data.code]));
      updatedBook[idx].name = action.data.name;
      updatedBook[idx].network_name = action.data.network_name;
      if (action.data.coin_network) {
        updatedBook[idx].coin_network = action.data.coin_network;
      }
      ctx.patchState({
        cryptoAddressBook: {
          ...state.cryptoAddressBook,
          [action.data.code]: updatedBook
        }
      });
    } else {
      // Add item to store if it doesnt exist
      const addAddress: AddressBook = {
        address: action.data.address,
        paymentId: action.data.paymentId ? action.data.paymentId : null,
        name: action.data.name,
        coin_network: action.data.coin_network ? action.data.coin_network : null,
        network_name: action.data.network_name ? action.data.network_name : null,
        status: AddressStatus.Untrusted,
        statusLabel: 'Untrusted Address',
        trusted: action.data.trusted,
      };

      if (addAddress.trusted) {
        addAddress.status = AddressStatus.Pending;
        addAddress.statusLabel = 'Pending Confirmation';
      }

      updatedBook = JSON.parse(JSON.stringify(state.cryptoAddressBook[action.data.code]));
      updatedBook.push(addAddress);
      updatedBook.sort((a: AddressBook, b: AddressBook) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        } else {
          return 0;
        }
      });

      ctx.patchState({
        cryptoAddressBook: {
          ...state.cryptoAddressBook,
          [action.data.code]: updatedBook
        }
      });
      // Remove address from unsaved address state
      this._store.dispatch(new RemoveUnsavedCryptoAddressBookItem(action.data.code, action.data.address));
    }

    if (updatedBook[action.data.code]) {
      return updatedBook[action.data.code];
    }

    return updatedBook;
  }

  @Action(RemoveCryptoAddressBookItem)
  removeCryptoAddressBookItem(ctx: StateContext<AddressBookState>, action: RemoveCryptoAddressBookItem) {
    // Obtain current state object
    const state = ctx.getState();
    let updatedBook: AddressBook[];
    // Obtain item array index
    const idx = state.cryptoAddressBook[action.coinCode].findIndex((a: AddressBook) => a.address === action.address);
    if (idx > -1) {
      updatedBook = JSON.parse(JSON.stringify(state.cryptoAddressBook[action.coinCode]));
      updatedBook.splice(idx, 1);
      ctx.patchState({
        cryptoAddressBook: {
          ...state.cryptoAddressBook,
          [action.coinCode]: updatedBook
        }
      });
      this._store.dispatch(new GetUserUnsavedCryptoAddresses(action.coinCode));
    }
  }

  @Action(RemoveUnsavedCryptoAddressBookItem)
  removeUnsavedCryptoAddressBookItem(ctx: StateContext<AddressBookState>, action: RemoveUnsavedCryptoAddressBookItem) {
    // Obtain current state object
    const state = ctx.getState();
    let updatedUnsavedAddresses: UnsavedAddresses[];
    // Obtain item array index
    const idx = state.unsavedAddresses[action.coinCode].findIndex(
      (a: UnsavedAddresses) => a.address === action.address
    );
    if (idx > -1) {
      updatedUnsavedAddresses = JSON.parse(JSON.stringify(state.cryptoAddressBook[action.coinCode]));
      updatedUnsavedAddresses.splice(idx, 1);
      ctx.patchState({
        unsavedAddresses: {
          ...state.unsavedAddresses,
          [action.coinCode]: updatedUnsavedAddresses
        }
      });
    }
  }

  /** ------------ Fiat Address Book Actions ------------ */

  @Action(GetFiatAddressBook)
  getFiatAddressBook(ctx: StateContext<AddressBookState>, action: GetFiatAddressBook) {
    // Obtain current state object
    const state = ctx.getState();
    // Append state object data
    const newAddressBook = this._addressBookService.getBankAddressBook(action.coinCode).pipe(
      tap(fiatAddresses => {
        ctx.patchState({
          fiatAddressBook: {
            ...state.fiatAddressBook,
            [action.coinCode]: fiatAddresses
          }
        });
      })
    );

    if (newAddressBook[action.coinCode]) {
      newAddressBook.toPromise().then(fiatAddresses => state.fiatAddressBook[action.coinCode] = fiatAddresses);

      return newAddressBook[action.coinCode];
    }

    return newAddressBook;
  }

  @Action(AddFiatAddressBookItem)
  addFiatAddressBookItem(ctx: StateContext<AddressBookState>, action: AddFiatAddressBookItem) {
    return this._addressBookService.bankAccountLink(action.data).pipe(take(1), tap(response => {
      if (response.response === 'success') {
        // Obtain current state object
        const state = ctx.getState();
        // Append state object data
        const addBankAddress: BankAddressBook = {
          name: action.data.name,
          bank: action.data.bankName,
          accountNumber: action.data.accountNumber,
          verified: BankAddressVerified.Pending,
          trusted: action.data.trusted,
          status: BankAddressStatus.Untrusted,
          statusLabel: 'Untrusted bank account',
          verifiedLabel: 'Bank verify Pending',
          fastClearingAvailable: action.data.fastClearingAvailable
        };

        if (addBankAddress.trusted) {
          addBankAddress.status = BankAddressStatus.Pending;
          addBankAddress.statusLabel = 'Pending bank account';
        }

        const updatedBook: BankAddressBooks = JSON.parse(JSON.stringify(state.fiatAddressBook[action.data.coin]));
        updatedBook.addresses.push(addBankAddress);
        updatedBook.addresses.sort((a: BankAddressBook, b: BankAddressBook) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        });

        ctx.patchState({
          fiatAddressBook: {
            ...state.fiatAddressBook,
            [action.data.coin]: updatedBook
          }
        });
      } else {
        throw response;
      }
    }));
  }

  @Action(RemoveFiatAddressBookItem)
  removeFiatAddressBookItem(ctx: StateContext<AddressBookState>, action: RemoveFiatAddressBookItem) {
    // Obtain current state object
    const state = ctx.getState();
    let updatedBook: BankAddressBooks;
    // Obtain item array index
    const idx = state.fiatAddressBook[action.coinCode].addresses.findIndex(
      (a: BankAddressBook) => a.accountNumber === action.accountNumber
    );
    if (idx > -1) {
      updatedBook = JSON.parse(JSON.stringify(state.fiatAddressBook[action.coinCode]));
      updatedBook.addresses.splice(idx, 1);
      ctx.patchState({
        fiatAddressBook: {
          ...state.fiatAddressBook,
          [action.coinCode]: updatedBook
        }
      });
    }
  }
}
