<input type="tel"
  #tokenInput
  [formControl]="formControlInput"
  class="col-12 col-sm-auto py-1 text-center mx-auto d-block token themed-input"
  autocomplete="off"
  maxlength="6"
  placeholder="000000"
  (input)="onInput($event)"
  (keyup)="valueChange($event)"
  (blur)="valueChange($event)"
  (paste)="valueChange($event)">

<mat-error class="d-block" *ngIf="formControlInput && formControlInput.invalid && (formControlInput.dirty || formControlInput.touched)">
  <small i18n>This field is required.</small>
</mat-error>