import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { ProfileService } from 'src/app/profile/profile.service';
import { FaceTecController } from './facetec-controller';
import { FaceTecSessionResult } from 'src/assets/core-sdk/FaceTecSDK.js/FaceTecPublicApi';
import { FaceTecSDK } from 'src/assets/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { FaceTecAppUtilities } from './utilities/FaceTecAppUtilities';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Component({
  selector: 'app-liveness-check',
  templateUrl: './liveness-check.component.html',
  styleUrls: ['./liveness-check.component.css']
})
export class LivenessCheckComponent implements OnInit {
  @Output() livenessChecked: EventEmitter<any> = new EventEmitter();
  @Output() manualSelfieSwitch: EventEmitter<any> = new EventEmitter();

  assetsPath: string = '/assets/chainex/images/pages/verification/';
  fileUrl: string;

  auditFile: File;
  auditTrailUploaded: boolean = false;
  initFailed: boolean = false;
  initialized: boolean = false;
  resultUploading: boolean = false;
  submitSuccess: boolean = false;
  testComplete: boolean = false;
  testInProgress: boolean = false;

  constructor(
    private profileService: ProfileService, private sessionStorage: SessionStorageService) {
  }

  ngOnInit(): void {
    // First get keys from PBVerify
    this.profileService.get3DLivenessKeys().pipe(take(1)).subscribe((response: any) => {
      const keys = response.keys;
      if (keys) {
        FaceTecController.loadComplete(this, keys.productionKey, keys.deviceKey, keys.publicEncryptionKey);
      } else {
        FaceTecAppUtilities.displayStatus('Liveness test not available. Please try again later or switch to photo upload.');
      }
    });
  }

  // Perform Liveness Check.
  onLivenessCheckPressed() {
    this.testInProgress = true;
    const userAgentString = FaceTecSDK.createFaceTecAPIUserAgentString('');
    const data = {
      'X-USER-AGENT': userAgentString
    };
    this.profileService.get3DSessionToken(data).pipe(take(1)).subscribe((response: any) => {
      if (response.sessionToken) {
        const token = this.sessionStorage.get('TOKEN');
        FaceTecController.onLivenessCheckPressed(response.sessionToken, token);
      } else {
        this.testInProgress = false;
        FaceTecAppUtilities.displayStatus('Could not create session. Please click "Start" again or switch to photo uploads.');
      }
    });
  }

  onComplete(success: boolean, scanResult: FaceTecSessionResult) {
    this.testInProgress = false;
    if (success) {
      this.testComplete = true;
      this.uploadResults(scanResult);
    }
    // failure and retry prompt handled by FaceTec SDK
  }

  onTestEnd() {
    this.testInProgress = false;
  }

  onTestInit() {
    this.initialized = true;
  }

  resubmit() {
    const scanResult: FaceTecSessionResult = FaceTecController.getLatestSessionResult();
    this.uploadResults(scanResult);
  }

  uploadResults(scanResult: FaceTecSessionResult) {
    this.resultUploading = true;
    if (this.auditTrailUploaded) {
      // no need to upload again
      this.submitSuccess = true;
      this.resultUploading = false;
      this.livenessChecked.emit();
    } else {
      // Upload audit trail image (photo from test session) after successful test
      const blob = this.base64toBlob(scanResult.auditTrail[0]);
      this.auditFile = new File([blob], 'LivenessAudit.jpg', { type: 'image/jpeg' });

      const formData: FormData = new FormData();
      formData.append('Liveness Audit', this.auditFile, this.auditFile.name);

      this.auditTrailUploaded = true;
      this.profileService.uploadKYC(formData).pipe(take(1)).subscribe((response: any) => {
        if (response.response === 'success') {
          this.submitSuccess = true;
          this.resultUploading = false;
          this.livenessChecked.emit();
        } else {
          this.resultUploading = false;
        }
      });
    }
  }

  switchToManual() {
    this.manualSelfieSwitch.emit();
  }

  base64toBlob(base64String: string) {
    const byteString = atob(base64String);

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: 'image/jpeg'});
  }
}
