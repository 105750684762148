import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { BalancesService } from '../../balances.service';
import { SessionStorageService } from './../../../core/services/session-storage.service';
import { StoreService } from 'src/app/core/services/store.service';

import { environment } from '../../../../environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { take, tap, distinctUntilChanged, skipWhile } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  IConfirmDialogData,
  ConfirmDialogComponent,
  DialogTypes,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { SettingsService } from './../../../settings/settings.service';
import { IResultDialogData, ResultDialogComponent } from 'src/app/shared/result-dialog/result-dialog.component';
import { IMarket, MarketsService } from 'src/app/markets/markets.service';
import { TradeType } from 'src/app/core/interfaces/trade-type';
import { AutoTrade } from 'src/app/core/interfaces/autotrade';
import { CoinNetwork } from 'src/app/core/interfaces/coin-network';
import { CurrencyManagementComponent } from '../currency.management.component';
import { DomainDialogComponent } from 'src/app/shared/domain-dialog/domain-dialog.component';
import { SnackbarMessageComponent } from 'src/app/shared/snackbar-message/snackbar-message';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit, OnDestroy {
  @Input('selectedCoinNetwork')
  set setDropdown(value: CoinNetwork) {
    if (value) {
      this.selectedCoinNetwork = value;
      if (this.coinNetworkControl) {
        this.coinNetworkControl.setValue(this.selectedCoinNetwork);

        this.minAmount = this.selectedCoinNetwork && this.selectedCoinNetwork.min_withdraw ?
        Number(this.selectedCoinNetwork.min_withdraw) : Number(this.currencyInfo.withdraw.withdrawLimits.min_withdraw);

        this.refreshAddress();
        this.loadUI();
      }
    }
  }
  selectedCoinNetwork: CoinNetwork;

  @Input() coinNetworks: any = [];
  // tslint:disable-next-line:no-input-rename
  @Input('coin') coinCode: string = '';
  // tslint:disable-next-line:no-input-rename
  @Input('type') coinType: string = '';
  @Input() dialogRef: MatDialogRef<CurrencyManagementComponent>;
  @Output() networkChange: EventEmitter<any> = new EventEmitter();
  @Output() showHistoryChange: EventEmitter<boolean> = new EventEmitter();

  currencyInfo: any = {
    deposit: {
      depositAddress: '',
      coin_network: ''
    },
    has_coin_networks: false,
  };

  private subs: any = [];
  private lightningInvoiceSub: any;

  showInvoiceInfo: boolean = false;
  invoiceCreated: boolean = false;
  invoicePaid: boolean = false;
  generatingAddress: boolean;

  addressResult: boolean;

  qrCode: string = '';

  bankSTD: any = {
    name: environment.config.BANK_NAME_STD,
    holder: environment.config.BANK_HOLDER_STD,
    account: environment.config.BANK_ACCOUNT_STD,
    branch: environment.config.BANK_BRANCH_STD,
    type: environment.config.BANK_TYPE_STD
  };

  showDomainPrompt: boolean = true;
  showDetails: boolean = true;

  coinNetworkForm: FormGroup;
  coinNetworkControl: FormControl;
  coinNetworkOptions: CoinNetwork[] = [];

  depositAddressSelected: any;
  depositID: string;
  depositRef: string;
  depositAmount: number;

  zarEstimate: number = 0;
  verificationLevel: number;
  minAmount: number = 0;

  showAutoTrade: boolean = false;
  interUserLevel1: boolean = false;
  showNotification: boolean = false;

  invoiceForm: FormGroup;
  autoTradeForm: FormGroup;
  autoTradeTypeControl: FormControl;
  autoTradeMarketControl: FormControl;
  autoTradeMarketDisabled: boolean = true;
  autoTradeTypes: TradeType[] = [
    { value: '0', name: 'Buy' },
    { value: '1', name: 'Sell' }
  ];
  allAutoTradeMarkets: IMarket[] = [];
  autoTradeMarkets: IMarket[] = [];

  invoice: {
    id?: string;
    memo?: string;
    value?: string;
    address?: string;
  };

  ngOnDestroy() {
    this.subs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
    if (this.lightningInvoiceSub) {
      this.lightningInvoiceSub.unsubscribe();
    }
  }

  constructor(
    public balancesService: BalancesService,
    public marketsService: MarketsService,
    private sessionStorage: SessionStorageService,
    private store: StoreService,
    private clipboard: ClipboardService,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.autoTradeTypeControl = new FormControl('', Validators.required);
    this.autoTradeMarketControl = new FormControl('', Validators.required);
    this.coinNetworkControl = new FormControl('', Validators.required);

    this.autoTradeForm = this.formBuilder.group({
      auto_trade_type: this.autoTradeTypeControl,
      auto_trade_market: this.autoTradeMarketControl
    });
    this.coinNetworkForm = this.formBuilder.group({
      coin_network: this.coinNetworkControl
    });

    const lightningAmountValidator = () => (control) => {
      if (this.invoiceForm && this.invoiceForm.controls) {
        if (this.isLightningNetwork() && +control.value < +this.minAmount) {
          return { min: true };
        }
      }
      return null;
    };

    this.invoiceForm = this.formBuilder.group({
      amount: this.formBuilder.control('', [Validators.required, lightningAmountValidator()]),
      description: this.formBuilder.control('')
    });

    this.invoiceForm.controls.amount.valueChanges.pipe(
      skipWhile(value => value === ''),
      distinctUntilChanged(),
      tap((amount: string) => {
        // convert BTC val to ZAR
        if (Number(amount) >= 0) {
          this.convertToZar(amount);
        }
      })
    ).subscribe();

    if (this.coinNetworks.length > 0 && !this.selectedCoinNetwork) {
      this.snackbar.open(
        this.translateService.translateResponse('The network for this coin is currently unavailable.'), this.i18n('Close'), {duration: 2000}
      );
      this.dialogRef.close();
      return;
    }

    this.fetchAutoTradeMarkets();

    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showNotification = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
      if (response.data.show_domain_prompt === 0 || response.data.domains_available === false) {
        this.showDomainPrompt = false;
        this.sessionStorage.store('SHOW_DOMAIN_PROMPT', 0);
      }
    }));

    let level: any = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL');
    if (level === '') {
      level = 0;
    } else {
      level = Number(level);
    }

    this.subs.push(this.store.getBalance(this.coinCode)
      .subscribe((data: any) => {
        this.currencyInfo = data.data;

        this.minAmount = this.selectedCoinNetwork && this.selectedCoinNetwork.min_withdraw ?
        Number(this.selectedCoinNetwork.min_withdraw) : Number(this.currencyInfo.withdraw.withdrawLimits.min_withdraw);

        this.refreshAddress();
        this.loadUI();
      }, () => { }));

    this.verificationLevel = level;
    if (this.verificationLevel === 1) {
      this.interUserLevel1 = (this.sessionStorage.get('PROFILE_COUNTRY') !== 'South Africa');
    }

    this.coinNetworkOptions = this.coinNetworks; // create independant display list
    this.coinNetworkControl.setValue(this.selectedCoinNetwork);

    if (this.sessionStorage.get('SHOW_DOMAIN_PROMPT') === 0) {
      this.showDomainPrompt = false;
    }
  }

  loadUI() {
    if (!!this.depositAddressSelected
      && this.depositAddressSelected.address_address !== '...' && this.coinType !== 'Fiat') {
      this.generateQRImage();
    } else {
      // if no address found, reset QR code
      this.qrCode = '';
    }
  }

  generateAddressClick() {
    if (!this.generatingAddress) {
      this.generatingAddress = true;
      this.balancesService.requestNewAddress(this.coinCode,
        this.selectedCoinNetwork ? this.selectedCoinNetwork.id : null).pipe(take(1))
        .subscribe((data: any) => {
          if (data.response === 'success') {
            this.store.getBalance(this.coinCode).subscribe((response: any) => {
              this.generatingAddress = false;
              this.currencyInfo = response.data;
              this.refreshAddress();
              this.loadUI();
            });
          } else {
            this.generatingAddress = false;
            this.addressResult = true;
            // this.addressMessage = data.reason;
            this.snackbar.open(this.translateService.translateResponse(data.reason),
              this.i18n('Close'), { duration: 2000 });
          }
        });
    }
  }

  generateQRImage() {
    // TODO: We should create a coin_scheme field, as bitcoin uses 'bitcoin:' and
    // other coins such as monero use 'monero:' and particl uses 'particl:', where XRP uses 'xrp:'..
    let schema = '';

    if (!!this.currencyInfo.cryptonote_address) {
      let addressField;
      switch (this.coinCode) {
        case 'XMR':
          addressField = 'tx_payment_id';
          schema = 'monero:';
          break;
        case 'XRP':
          schema = 'xrp:';
          addressField = 'dt';
          break;
        case 'EOS':
        default:
          addressField = 'memo';
      }
      if (this.coinType === 'EOS') {
        addressField = 'memo';
      }
      this.qrCode =
        `${schema}${this.currencyInfo.cryptonote_address}?\
${addressField}=${this.depositAddressSelected.address_address}`; // This is intentionally badly indented
    } else {
      switch (this.coinCode) {
        case 'BTC':
          schema = 'bitcoin:';
          break;
        case 'PART':
          schema = 'particl:';
          break;
      }
      this.qrCode = `${schema}${this.depositAddressSelected.address_address}`;
    }
  }

  copyToClipboard(str: string) {
    this.clipboard.copyFromContent(str);
    this.snackbar.open(this.i18n('Copied') + ': ' + str, this.i18n('Close'), { duration: 2000 });
  }

  copyToClipboardFiat(str: string, display: string) {
    this.clipboard.copyFromContent(str);
    this.snackbar.open(display + ' ' + this.i18n('Copied'), this.i18n('Close'), { duration: 2000 });
  }

  resetAutoTrade() {
    this.showAutoTrade = false;
    this.autoTradeMarketDisabled = true;
    this.autoTradeForm.reset();
  }

  enableAutoTrade() {
    this.autoTradeForm.markAsTouched();
    this.autoTradeForm.controls.auto_trade_type.markAsTouched();
    this.autoTradeForm.controls.auto_trade_market.markAsTouched();
    if (this.autoTradeForm.valid) {
      if (this.showNotification) {
        const dialogData: IConfirmDialogData = {
          no: 'CANCEL',
          yes: 'ENABLE',
          cancelType: DialogTypes.enableAutotrade,
          pair: this.autoTradeMarket.marketPair,
          coinCode: this.autoTradeMarket.coinCode,
          type: this.autoTradeType
        };
        const dialogRef = ConfirmDialogComponent.openDialog(this.dialog, dialogData, '400px');
        dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
          if (!!result && result.result === 'accept') {
            this.submitEnableAutoTrade();
          }
        });
      } else {
        this.submitEnableAutoTrade();
      }
    }
  }

  submitEnableAutoTrade() {
    const data: AutoTrade = {
      coin: this.coinCode,
      type: this.autoTradeTypeControl.value,
      market: this.autoTradeMarketControl.value
    };
    this.settingsService.addUserAutoTrade(data)
      .subscribe((response: any) => {
        if (response.response === 'success') {
          const updatedAutotradeMarket = this.autoTradeMarket;
          this.currencyInfo.autotrade = {
            id: updatedAutotradeMarket.id, // actual autotrade id not available
            type: this.autoTradeTypeControl.value,
            market: updatedAutotradeMarket.marketPair,
            marketId: updatedAutotradeMarket.id,
            coin: updatedAutotradeMarket.coinCode,
            exchange: updatedAutotradeMarket.exchangeCode
          };
          this.resetAutoTrade();
        } else {
          const resultDialogData: IResultDialogData = {
            title: 'Failed',
            result: 'failed',
            reason: this.translateService.translateResponse(response.reason)
          };
          ResultDialogComponent.openDialog(this.dialog, resultDialogData, '200px');
        }
      });
  }

  disableAutoTrade() {
    if (this.showNotification) {
      const dialogData: IConfirmDialogData = {
        no: 'CANCEL',
        yes: 'DISABLE',
        cancelType: DialogTypes.disableAutotrade,
        pair: this.autoTradeMarket.marketPair,
        type: this.autoTradeType
      };
      const dialogRef = ConfirmDialogComponent.openDialog(this.dialog, dialogData, '400px');
      dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
        if (!!result && result.result === 'accept') {
          this.submitDisableAutoTrade();
        }
      });
    } else {
      this.submitDisableAutoTrade();
    }
  }

  submitDisableAutoTrade() {
    const data: AutoTrade = {
      coin: this.autoTradeMarket.coinCode,
      type: this.currencyInfo.autotrade.type ? this.currencyInfo.autotrade.type :
        this.autoTradeTypes.find(x => x.value === this.autoTradeTypeControl.value),
      market: this.autoTradeMarket.id
    };
    this.settingsService.deleteUserAutoTrade(data)
      .subscribe((response: any) => {
        if (response.response === 'success') {
          this.currencyInfo.autotrade = null;
          this.resetAutoTrade();
        } else {
          const resultDialogData: IResultDialogData = {
            title: 'Failed',
            result: 'failed',
            reason: this.translateService.translateResponse(response.reason)
          };
          ResultDialogComponent.openDialog(this.dialog, resultDialogData, '200px');
        }
      });
  }

  // update the displayed deposit address and deposit reference, based on the selected coin_network
  refreshAddress() {
    let depositAddressFound;
    // if deposit addresses available
    if (this.currencyInfo.deposit.depositAddress) {
      if (this.coinNetworks.length > 0 && this.selectedCoinNetwork) {
        // find the address linked to the selected coin_network
        depositAddressFound = this.currencyInfo.deposit.depositAddress.find(
          depositAddress => depositAddress.coin_network === this.selectedCoinNetwork.id
        );

        if (depositAddressFound) {
          // display the address for this coin_network
          this.depositAddressSelected = depositAddressFound;
          this.depositRef = 'CX' + depositAddressFound.address_address.toUpperCase();
        } else {
          this.depositAddressSelected = null;
          this.depositRef = null;
        }
      } else {
        // display default address if available
        if (this.currencyInfo.deposit.depositAddress[0]) {
          this.depositAddressSelected = this.currencyInfo.deposit.depositAddress[0];
          this.depositRef = 'CX' + this.currencyInfo.deposit.depositAddress[0].address_address.toUpperCase();
        }
      }
    }
  }

  fetchAutoTradeMarkets() {
    const data: IMarket[] = [];
    this.subs.push(this.marketsService.getMarketData().subscribe(markets => {
      if (markets && markets.length > 0) {
        for (let i = 0; i < markets.length; i++) {
          if (this.coinCode === markets[i].coinCode || this.coinCode === markets[i].exchangeCode) {
            data.push(markets[i]);
          }
        }
        this.allAutoTradeMarkets = data;
        this.setAutoTradeMarkets();
      }
    }));
  }

  changeAutoTradeType() {
    this.autoTradeMarketDisabled = false;
    this.setAutoTradeMarkets();
  }

  setAutoTradeMarkets() {
    if (this.allAutoTradeMarkets) {
      const data: IMarket[] = [];
      for (let i = 0; i < this.allAutoTradeMarkets.length; i++) {
        if ((this.autoTradeTypeControl.value === '1' && this.coinCode === this.allAutoTradeMarkets[i].coinCode) ||
          (this.autoTradeTypeControl.value === '0' && this.coinCode === this.allAutoTradeMarkets[i].exchangeCode)) {
          data.push(this.allAutoTradeMarkets[i]);
        }
      }
      this.autoTradeMarkets = data;
    }
  }

  dismissDomain() {
    this.showDomainPrompt = false;

    const data: any = {
      show_domain_prompt: 0
    };

    this.settingsService.updateSettings(data)
        .subscribe((response: any) => {
          if (response.response === 'success') {
            this.sessionStorage.store('SHOW_DOMAIN_PROMPT', 0);
          } else if (response.response === 'failure') {
            this.snackbar.open(
              this.translateService.translateResponse(response.reason), this.i18n('Close'), {duration: 2000}
            );
          }
        });
  }

  openDomainDialog() {
    let searchTerm;

    let username: string = this.sessionStorage.get('PROFILE_EMAIL');
    if (username && username.indexOf('@') > 0) {
      // user email set, get searchterm from email
      searchTerm = username.split('@')[0];
    } else {
      // try to get searchterm from user's first name
      username = this.sessionStorage.get('PROFILE_FIRST_NAME');
      if (username) {
        searchTerm = username;
      }
    }

    this.dialog.open(DomainDialogComponent,
      { width: '500px', data: { searchTerm: searchTerm } });
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }


  get autoTradeType() {
    const type = this.currencyInfo.autotrade == null ?
      this.autoTradeTypeControl.value :
      this.currencyInfo.autotrade.type;
    const autoTradeType = this.autoTradeTypes.find(x => x.value === type);
    return autoTradeType ? autoTradeType.name : '';
  }

  get autoTradeMarket(): IMarket {
    return (this.currencyInfo.autotrade ?
      {
        coinCode: this.currencyInfo.autotrade.coin,
        exchangeCode: this.currencyInfo.autotrade.exchange,
        id: this.currencyInfo.autotrade.marketId,
        marketPair: this.currencyInfo.autotrade.market,
        icon: '',
        exchangeId: ''
      } :
      this.autoTradeMarkets.find(x => x.id === this.autoTradeMarketControl.value)
    );
  }

  changeNetwork(event: any) {
    this.selectedCoinNetwork = event.value;
    if (event.value.id) { // skip invalid networks
      this.networkChange.emit(event.value);
      this.refreshAddress();
      this.loadUI();
    }
  }

  convertToZar(amountInBtc: string) {
    const market = this.marketsService.getMarketbyCoinPair('BTC', 'ZAR');
    if (market) {
      // convert btc to ZAR
      this.zarEstimate = Number(amountInBtc) * market.spreadPrice;
    } else {
      this.zarEstimate = 0;
    }
  }

  isLightningNetwork() {
    return this.selectedCoinNetwork?.network_name.toLowerCase() === 'lightning network';
  }

  createInvoice() {
    this.invoiceForm.markAllAsTouched();

    if (this.invoiceForm.valid) {
      if (!this.lightningInvoiceSub) {
        this.lightningInvoiceSub =  this.store.subscribe('lightning-deposit').subscribe((response) => {
          // If payment is confirmed by the coin API, display success screen
          if (this.invoiceCreated && response.payment_request === this.invoice.address ) {
            this.invoicePaid = true;
          } else if (this.invoice && response.id && (+response.id === +this.invoice.id)) {
            this.invoice.address = response.payment_request;
            this.invoiceCreated = true;
          }
        });
      }

      const data = {
        memo: this.invoiceForm.controls.description.value,
        amount: this.invoiceForm.controls.amount.value
      };

      this.invoiceCreated = false;
      this.invoice = null;
      this.balancesService.requestInvoice(data).pipe(
        tap((result) => {
          if (result.response) {
            this.invoice = result.data;
            this.showInvoiceInfo = true;
            this.invoiceCreated = false;
            this.invoicePaid = false;
            this.showHistoryChange.emit(false);
          } else {
            SnackbarMessageComponent.openSnackBar(
              this.snackbar,
              this.translateService.translateResponse(result.reason),
              3000
            );
          }
        })
      ).subscribe();
    }
  }

  hideLightInvoice() {
    this.invoice = null;
    this.invoiceForm.reset();
    this.showInvoiceInfo = false;
    this.invoiceCreated = false;
    this.invoicePaid = false;
    this.showHistoryChange.emit(true);
  }

}
