import { Component, OnInit, Input } from '@angular/core';
import { UtilHelper } from '../../core/helpers/util-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'home-market-card',
  templateUrl: './market-card.component.html',
  styleUrls: ['./market-card.component.scss']
})
export class MarketCardComponent implements OnInit {
  public strToNumber: (str: string) => number = UtilHelper.strToNumber;

  @Input('market')
  set setMarketValue(values: any) {
    if (values) {
      this.market = values;
    }
  }
   market: any = [];
  @Input() darkTheme: boolean = false;
  @Input() showChartOnly: boolean = false;

  BASE_URL: string = environment.config.BACKEND_URL;
  imageUrl: string;

  // Setting Chart Options
  lineChartLabels: string[] = ['', '', '', '', '', '', ''];
  lineChartOptions: any = {
    responsive: true,
    elements: {
      point: { radius: 0 }
    },
    scales: {
      xAxes: [{
        display: false,
        gridLines: {
          display: false,
        }
      }],
      yAxes: [{
        display: false,
        gridLines: {
          display: false,
        }
      }]
    }
  };

  lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(239,108,0,0.0)',
      borderColor: 'rgba(239,108,0,0.2)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#ef6c00',
      pointHoverBorderColor: '#ef6c00'
    }
  ];
  lineChartLegend: boolean = false;
  lineChartType: string = 'line';

  constructor() {
  }

  ngOnInit() {
    if (this.market.coinCode) {
      this.imageUrl = '../../../assets/chainex/images/icons/coins/' + this.market.coinCode.toLowerCase() + '.svg';
    }
  }

}
