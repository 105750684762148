<ng-template #chatTemplate>
    <div class="message" id="messageContainer" #messageContainer [scrollTop]="messageContainer.scrollHeight">
        <mat-card class="message-card" *ngFor="let message of chatMessages" [ngClass]="[message.user_type === '0' ? 'user' : message.user_type === '1' && !message.system_message ?  'admin' : !!message.system_message && message.system_message === '1' ? 'system' : '']">
            <mat-card-header>
                <mat-card-title [ngClass]="[message.user_type === '0' ? 'user' : message.user_type === '1' && !message.system_message ?  'admin' : !!message.system_message && message.system_message === '1' ? 'system' : '']">
                        <mat-icon *ngIf="!!message.chat_rank || message.user_type === '1'" [matTooltip]="getTitle(message)" class="rank-icon" [svgIcon]="getIcon(message)"></mat-icon>
                    <span class="username">{{ message.user_type === '0' ? message.chat_username : !!message.system_message && message.system_message === '1' ? 'System Message' : message.useralias}}</span>
                    <div class="action-container">
                        {{message.time | relativeTime}}
                        <mat-icon *ngIf="chatDetails.status === '1' && message.user_type === '0' && !!message.chat_username && message.chat_username !== chatDetails.username" (click)="reportMessage(message)" color="warn" i18n-matTooltip matTooltip="Report">report</mat-icon>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="message-container" [innerHTML]="message.message | emoji">
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="chat-input" *ngIf="chatDetails.username !== '' && chatDetails.status === '1'">
        <mat-form-field>
            <input autofocus id="longInput" type="text" maxlength="140" name="message" matInput i18n-placeholder placeholder="Message" (keyup)="writeMessage($event)" [formControl]="messageControl" [(ngModel)]="chatMessage">
            <button mat-button *ngIf="chatMessage" matSuffix mat-icon-button aria-label="Clear" (click)="chatMessage=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-icon class="message-icon" (click)="send()">send</mat-icon>
        <mat-icon id="emojiIcon" class="emoticon-icon" (click)="toggleEmojiDrawer()" #emoticon>insert_emoticon</mat-icon>
        <div class="char-count">
            {{chatMessage.length}}/140
        </div>
        <div class="emoji-panel" *ngIf="emojiMenuOpen">
            <div class="emoji-button" (click)="emoticonSelected(emoji)" *ngFor="let emoji of emojis" [innerHTML]="emoji | emoji"></div>
        </div>
    </div>

    <div class="chat-username" *ngIf="chatDetails.username === '' && chatDetails.status === '1'">
        <mat-form-field>
            <input [autofocus]="!parent" id="usernameInput" type="text" maxlength="40" name="username" matInput i18n-placeholder placeholder="Username" (keyup)="checkUsername($event)" [formControl]="usernameControl" [(ngModel)]="username">
            <button mat-button *ngIf="username" matSuffix mat-icon-button aria-label="Clear" (click)="username=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-icon class="chat-username-status-icon" (click)="setUsername()" [color]="usernameAvailable ? 'success' : 'warn'" [matTooltip]="usernameAvailable ? translatedWords['Set Username'] : translatedWords['Username Unavailable']">{{usernameAvailable ? 'check_circle_outline' : 'error_outline'}}</mat-icon>
    </div>

    <div class="chat-display" *ngIf="chatDetails.status === '0'" i18n>
        <div class="row pt-2 pl-2 pr-2">
            <div class="col-2 pt-1 blocked-icon" [ngClass]="parent ? 'pt-1' : 'pt-2 pl-4'"><mat-icon>warning</mat-icon></div>
            <div class="col-10 sml-text">Your account has been restricted from chat use due to abuse!</div>
        </div>
    </div>

    <div class="chat-display" *ngIf="!chatDetails.status">
        <div class="pb-2 pt-2 pl-2 pr-2">
            <div class="col-12 text-center">
                <button mat-raised-button routerLink="/register" class="mat-raised-button" color="accent" i18n>
                    SIGN UP
                </button>
                &nbsp;&nbsp;
                <button mat-raised-button routerLink="/login" class="mat-raised-button" color="primary" i18n>
                    SIGN IN
                </button>
            </div>
        </div>
    </div>
</ng-template>

<div class="chat mat-elevation-z3" [class.expanded]="expanded" [ngStyle]="GetLocation()" *ngIf="!!parent && !hidden && showTrollBox">
    <div [class.drag]="drag" id="chatWidget" #chatWidget>
        <span class="title" i18n>
            TROLLBOX
        </span>
        <span class="toggle">
            <mat-icon (click)="popOut()" i18n-matTooltip matTooltip="Pop Out">call_made</mat-icon>
            <mat-icon (click)="expand()" i18n-matTooltip [matTooltip]="expanded ? 'Collapse' : 'Expand'">{{ expanded ? 'minimize' : 'add'}}</mat-icon>
            <mat-icon (click)="onClose()" i18n-matTooltip matTooltip="Close">close</mat-icon>
        </span>
    </div>

    <ng-template [ngTemplateOutlet]="chatTemplate"></ng-template>

</div>

<div class="full-screen back chatcomponent" *ngIf="!parent">
    <ng-template [ngTemplateOutlet]="chatTemplate"></ng-template>
</div>
