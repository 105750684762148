import { SessionStorageService } from './../../core/services/session-storage.service';
import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';

import { BalancesService } from '../balances.service';
import { WithdrawalComponent } from './withdrawal/withdrawal.component';
import { DepositComponent } from './deposit/deposit.component';
import { Coin, TransactionHistory } from '../../core/interfaces/balances';
import { TradeSummaryComponent } from './trade-summary-component/trade-summary.component';
import {environment} from '../../../environments/environment';
import { SubscriptionLike } from 'src/app/core/services/store.service';
import { StoreService } from '../../core/services/store.service';
import { EarnPortalComponent } from './earn-portal/earn-portal.component';
import { CoinNetwork } from 'src/app/core/interfaces/coin-network';

export interface DialogSetting {
    code: string;
    type: string;
    mode: number;
    icon: string;
    has_product: boolean;
    has_coin_networks: boolean;
}

@Component({
  selector: 'app-currency-management',
  templateUrl: './currency.management.component.html',
  styleUrls: ['./currency.management.component.scss']
})
export class CurrencyManagementComponent implements OnInit, OnDestroy {
  coinNetworks: CoinNetwork[] = [];
  showHistory: boolean = true;
  history_index: number = 0;

  setting: DialogSetting = {
      code: '',
      type: 'crypto',
      mode: 0,
      icon: '',
      has_product: false,
      has_coin_networks: false
  };

  balance: any = {
    total: 0,
    available: 0,
    held: 0,
    earn: 0,
    selectedCoinNetwork: {},
    default_network: '',
    default_contract_digits: '',
    default_contract_link: '',
    name: ''
  };

  balanceSubs: SubscriptionLike;

  BASE_URL: string = environment.config.BACKEND_URL;

  @ViewChild('withdrawal') withdrawal: WithdrawalComponent;
  @ViewChild('deposit') deposit: DepositComponent;
  @ViewChild('depositHistory') withdrawalHistory: TransactionHistory;
  @ViewChild('withdrawalHistory') depositHistory: TransactionHistory;

  @ViewChild('actionTab', { static: true }) actionTab: MatTabGroup;
  @ViewChild('historyTab', { static: true }) historyTab: MatTabGroup;
  @ViewChild('trade') trade: TradeSummaryComponent;
  @ViewChild('earnPortal') earnPortal: EarnPortalComponent;

  result_failed: boolean;
  result_message: string;

  constructor(
    public dialogRef: MatDialogRef<CurrencyManagementComponent>,
    public balanceService: BalancesService,
    public sessionStorage: SessionStorageService,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: DialogSetting
  ) {
    if (data) {
      this.history_index = data.mode;
      if (data.mode === 4) {
        this.showHistory = false;
        data.mode = 3;
      } else if (data.mode === 3) {
        this.history_index = 3;
        data.mode = 2;
      }
      this.setting = data;
    }
  }

  ngOnInit() {
    this.actionTab.selectedIndex = this.setting.mode;
    if (this.historyTab) {
      this.historyTab.selectedIndex = this.history_index;
    }

    if (!!this.withdrawal && !!this.withdrawal.SetParent) {
      this.withdrawal.SetParent(this);
    }

    if (!!this.trade && !!this.trade.SetParent) {
      this.trade.SetParent(this);
    }
    this.balanceSubs = this.store.subscribe('balances').subscribe(response => {
      const filteredResponse = response.data.filter(responseBalance =>
        responseBalance.code === this.setting.code);
      this.balance.held = filteredResponse[0].balance_held;
      this.balance.available = filteredResponse[0].balance_available;
      this.balance.name = filteredResponse[0].name;
      this.balance.total = filteredResponse[0].balance_total;
      this.balance.earn = filteredResponse[0].balance_earn;
      this.balance.default_network = filteredResponse[0].default_network ? filteredResponse[0].default_network : '';
      this.balance.default_contract_digits = filteredResponse[0].default_contract_digits ?
                                              filteredResponse[0].default_contract_digits : '';
      this.balance.default_contract_link = filteredResponse[0].default_contract_link ?
                                              filteredResponse[0].default_contract_link : '';
      this.setting.has_coin_networks = ((typeof filteredResponse[0].has_coin_networks === 'boolean'
        && filteredResponse[0].has_coin_networks) || filteredResponse[0].has_coin_networks === '1');
      this.setting.has_product = (typeof filteredResponse[0].has_product === 'boolean'
      && filteredResponse[0].has_product || filteredResponse[0].has_product === 'true');

      if (this.setting.has_coin_networks && filteredResponse[0].coin_networks) {
        // set coin networks and default to the first one
        this.coinNetworks = filteredResponse[0].coin_networks;
        // get the first active network
        this.balance.selectedCoinNetwork = this.coinNetworks.find((coinNetwork =>
          coinNetwork.active === '1'
        ));
      } else {
        // set default name and token information only
        this.balance.selectedCoinNetwork = {
          network_name: this.balance.default_network,
          contract_digits: this.balance.default_contract_digits,
          contract_link: this.balance.default_contract_link
        };
      }
    });
  }

  ngOnDestroy() {
    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }
  }

  switchTab(e: any) {
    if (e.tab.textLabel !== 'Earn Portal') {
      this.showHistory = true;
      this.historyTab.selectedIndex = e.index;
    } else {
      this.showHistory = false;
    }
  }

  buildImageString(coinName: string) {
    const imageurl = this.BASE_URL + '/action/getCoin?coinIcon=' + coinName;
    return imageurl;
  }

  setNetwork(newNetwork: any) {
    this.balance.selectedCoinNetwork = newNetwork;
    this.deposit.selectedCoinNetwork = newNetwork;
  }

  toggleHistory(value: boolean) {
    this.showHistory = value;
  }

}
