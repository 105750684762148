// Angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Interfaces
import { AddressBook, AddressStatus, UnsavedAddresses } from 'src/app/core/interfaces/addressbook';
import { AddBankAddressBook, BankAddressBook, BankAddressBooks,
  BankAddressStatus, BankAddressVerified, BankLimits, RemoveBankAddress
} from 'src/app/core/interfaces/bankAddressbook';
// Services
import { ApiService } from '../../core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AddressBookService {

  constructor(
    private _apiService: ApiService,
  ) { }

  private _setGetParams(data: any) {
    let prams = '?';
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
      if (prams !== '?' && entries[i][1] !== '') {
        prams += '&';
      }
      if (entries[i][1] !== '') {
        prams += `${entries[i][0]}=${entries[i][1]}`;
      }
    }
    return prams;
  }

  /**
   * Handy utility function to return a value with specific type
   * or if a value isnt in the type list, it will return a default value
   * works well with ensuring that values passed from php are the correct tpye as well
   * e.g "1" (string) would be converted to 1 (number)
   */
  private _getValueOrDefault<T>(value: T, type: 'string' | 'number' | 'boolean', defaultValue: T): T {
    return typeof value === type ? value : defaultValue;
  }

  /** ------------ Crypto Methods ------------ */

  getAddressBook(coinCode: string): Observable <AddressBook[]> {
    return this._apiService.call<any>(`getAddressBook${this._setGetParams({code: coinCode})}`).pipe(
      map(response => {
        if (response.response === 'success') {
          const addresses: AddressBook[] = response.data.map((dataItem: AddressBook) => {
            const newItem: AddressBook = {
              statusLabel: +dataItem.status === AddressStatus.Trusted ? 'Trusted Address'
                : (+dataItem.status === AddressStatus.Pending ? 'Pending Confirmation' : 'Untrusted Address'),
              address: this._getValueOrDefault(dataItem.address, 'string', ''),
              paymentId: this._getValueOrDefault(dataItem.paymentId, 'string', ''),
              name: this._getValueOrDefault(dataItem.name, 'string', ''),
              status: this._getValueOrDefault(+dataItem.status, 'number', 0),
              trusted: this._getValueOrDefault(!!+dataItem.trusted, 'boolean', false),
              coin_network: this._getValueOrDefault(dataItem.coin_network, 'string', null),
              network_name: this._getValueOrDefault(dataItem.network_name, 'string', null),
            };
            return newItem;
          });

          return addresses;
        }

        return ([] as AddressBook[]);
      })
    );
  }

  getUserUnsavedAddresses(coinCode: string): Observable<UnsavedAddresses[]> {
    return this._apiService.call<any>(`getUserUnsavedAddresses${this._setGetParams({code: coinCode})}`).pipe(
      map(response => {
        if (response.response === 'success') {
          const addresses: UnsavedAddresses[] = response.data.map((dataItem: UnsavedAddresses) => {
            const newItem: UnsavedAddresses = {
              address: this._getValueOrDefault(dataItem.address, 'string', ''),
              code: this._getValueOrDefault(dataItem.code, 'string', ''),
              lastWithdrawal: this._getValueOrDefault(dataItem.lastWithdrawal, 'string', ''),
              paymentId: this._getValueOrDefault(dataItem.paymentId, 'string', ''),
              coin_network: this._getValueOrDefault(dataItem.coin_network, 'string', null),
              network_name: this._getValueOrDefault(dataItem.network_name, 'string', null),
            };
            return newItem;
          });

          return addresses;
        }

        return ([] as UnsavedAddresses[]);
      })
    );
  }

  modifyAddressBookEntry(data: any) {
    return this._apiService.call<any>('modifyAddressBookEntry', data);
  }

  /** ------------ Fiat Methods ------------ */

  getBankAddressBook(coinCode: string): Observable <BankAddressBooks> {
    return this._apiService.call<any>(`getAddressBook${this._setGetParams({code: coinCode})}`).pipe(
      map(response => {
        if (response.response === 'success') {
          const addresses: BankAddressBook[] = response.data.addresses.map((dataItem: BankAddressBook) => {
            const newItem: BankAddressBook = {
              statusLabel: +dataItem.status === BankAddressStatus.Trusted ? 'Trusted Address'
                : (+dataItem.status === BankAddressStatus.Pending ? 'Pending Confirmation' : 'Untrusted Address'),
              verifiedLabel: +dataItem.verified === BankAddressVerified.Success ? 'Verification Passed'
                : (+dataItem.verified === BankAddressVerified.Pending ? 'Verification Pending' : 'Verification Failed'),
              name: this._getValueOrDefault(dataItem.name, 'string', ''),
              bank: this._getValueOrDefault(dataItem.bank, 'string', ''),
              accountNumber: this._getValueOrDefault(dataItem.accountNumber, 'string', ''),
              verified: this._getValueOrDefault(+dataItem.verified, 'number', 0),
              status: this._getValueOrDefault(+dataItem.status, 'number', 0),
              trusted: this._getValueOrDefault(!!+dataItem.trusted, 'boolean', false),
              fastClearingAvailable: this._getValueOrDefault(!!+dataItem.fastClearingAvailable, 'boolean', false)
            };
            return newItem;
          });

          const limits: BankLimits = response.data.limits;
          limits.unverifiedLimit = this._getValueOrDefault(+limits.unverifiedLimit, 'number', 0);
          limits.unverifiedRemainingLimit = this._getValueOrDefault(+limits.unverifiedRemainingLimit, 'number', 0);
          limits.verifiedLimit = this._getValueOrDefault(+limits.verifiedLimit, 'number', 0);
          limits.verifiedRemainingLimit = this._getValueOrDefault(+limits.verifiedRemainingLimit, 'number', 0);

          const bankAddresses: BankAddressBooks = {
            addresses,
            limits
          };
          return bankAddresses;
        }

        const bankAddressesDefault: BankAddressBooks = {
          addresses: [],
          limits: { unverifiedLimit: 0, verifiedLimit: 0, unverifiedRemainingLimit: 0, verifiedRemainingLimit: 0 }
        };
        return bankAddressesDefault;
      })
    );
  }

  bankAccountLink(data: AddBankAddressBook) {
    return this._apiService.call<any>('bankAccountLink', data);
  }

  bankAccountUnlink(data: RemoveBankAddress) {
    return this._apiService.call<any>('bankAccountUnlink', data);
  }
}
