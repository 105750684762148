import { SessionStorageService } from './../services/session-storage.service';
import {
    HttpInterceptor, HttpRequest, HttpHandler,
    HttpEvent, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RouterHelper } from '../router-helper';

/**
 * @export
 * @class InterceptorService
 * @implements {HttpInterceptor}
 */
@Injectable()
export class InterceptorService implements HttpInterceptor {
    public token: String = '';
    constructor(private sessionStorage: SessionStorageService,
        private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('/action/') === -1 && req.url.indexOf('/chart/') === -1) {
            // do not process if this is a not an action or a chart call
            return next.handle(req);
        }
        let requestToForward = req;
        this.token = this.sessionStorage.get('TOKEN');

        // making use of tempToken for logout calls
        const tempToken = this.sessionStorage.get('TEMP_TOKEN');
        this.sessionStorage.remove('TEMP_TOKEN');

        this.token = (!!this.token && this.token !== '') ? this.token : tempToken;
        if (!!this.token && this.token !== '') {
            requestToForward = req.clone({
                setHeaders:
                {
                    'X-XSRF-TOKEN': this.token.toString()
                }
            });
        }
        // return next.handle(requestToForward);

        return next.handle(requestToForward).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
                const tokenHeaders = event.headers.getAll('xsrf-token');
                if (!!tokenHeaders) {
                    // Only save token on login
                    this.token = tokenHeaders.shift();
                    this.sessionStorage.store('TOKEN', this.token.toString());
                }

                if (this.sessionStorage.get('LOGGED_IN') === 'logged_in' &&
                    (event.body as any).response === 'failure' && (
                    (event.body as any).reason === 'GENERAL_NOTLOGGEDIN' ||
                    (event.body as any).reason === 'GENERAL_NOTLOGGEDIN_UA' ||
                    (event.body as any).reason === 'GENERAL_NOTLOGGEDIN_SESSION_EXPIRED' ||
                    (event.body as any).reason === 'GENERAL_NOTLOGGEDIN_SESSION_DEACTIVATED' ||
                    (event.body as any).reason === 'GENERAL_NOTLOGGEDIN_IP_CHANGE'
                    )) {
                    // console.error('not logged in');
                    this.sessionStorage.purge();
                    this.sessionStorage.store('LOGGED_IN', false);
                    this.sessionStorage.store('API_ERROR_LOGOUT', true);
                    this.sessionStorage.store('LOGGED_OUT_REASON', (event.body as any).reason);
                    RouterHelper.goToLogin(this.router, null);
                }
            }
        }));
    }
}
