
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';

/*XSRF Token Imports*/
import { InterceptorService } from './interceptor/interceptor.service';
import { SessionStorageService } from './services/session-storage.service';
import { SplashService } from './services/splash.service';
import { StoreService } from './services/store.service';
import { NotFoundComponent } from './responses/not-found/not-found.component';
import { GenericComponent } from './responses/generic/generic.component';
import { RedirectComponent } from './responses/redirect/redirect.component';

import { SplashComponent } from './responses/splash/splash.component';
import { UnavailableComponent } from './responses/unavailable/unavailable.component';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule,
    SharedModule,
    MatIconModule
  ],
  declarations: [NotFoundComponent, GenericComponent, RedirectComponent, UnavailableComponent, SplashComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, // XSRF Token,
    SessionStorageService,
    StoreService,
    SplashService
  ],
  exports: [SplashComponent]
})
export class CoreModule { }
