import { Pipe, PipeTransform } from '@angular/core';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';

@Pipe({
  name: 'i18n'
})
export class I18nPipe implements PipeTransform {
  translationService: I18nTranslationService;
  constructor(translationService: I18nTranslationService) {
    this.translationService = translationService;
  }

  transform(value: any, args?: any): any {
    return this.translationService.translateResponse(value, args);
  }

}
