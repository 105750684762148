import { Deactivate } from '../core/interfaces/deactivate';
import { Observable } from 'rxjs';
import { Profilesettings } from '../core/interfaces/profilesettings';
import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { ResetPassword } from '../core/interfaces/reset-password';
import { Enable2FA } from '../core/interfaces/enable-2fa';
import { Disable2FA } from '../core/interfaces/disable-2fa';
import { MarketingSettings } from '../core/interfaces/marketing-settings';
import { WithdrawSettings } from '../core/interfaces/withdrawsettings';
import { ConfirmWithdrawSettings } from '../core/interfaces/confirm-withdraw-settings';
import { AutoTrade } from '../core/interfaces/autotrade';
import { FundPin } from '../core/interfaces/fund-pin';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private apiService: ApiService,
  ) { }

  resetPassword(data: ResetPassword): Observable<ResetPassword> {
    return this.apiService.call<ResetPassword>('updatePassword', data);
  }

  getTimezoneList() {
    return this.apiService.call('getTimezoneList');
  }

  getAutoLogoutTimes() {
    return this.apiService.call('getAutoLogoutTimes');
  }

  getPopupStyles() {
    return this.apiService.call('getPopupStyles');
  }

  getPopupLocations() {
    return this.apiService.call('getPopupLocations');
  }

  getMarketingSettings(data: {hash: string}) {
    return this.apiService.call('getMarketingSettings', data);
  }

  updateMarketingSettings(data: MarketingSettings): Observable<MarketingSettings> {
    return this.apiService.call<MarketingSettings>('updateMarketingSettings', data);
  }

  confirmWithdrawalSettings(data: ConfirmWithdrawSettings): Observable<ConfirmWithdrawSettings> {
    return this.apiService.call<ConfirmWithdrawSettings>('withdrawSettingsConfirm', data);
  }

  confirmFundPin(data: ConfirmWithdrawSettings): Observable<ConfirmWithdrawSettings> {
    return this.apiService.call<ConfirmWithdrawSettings>('fundPinConfirm', data);
  }

  deactivateAccount(data: Deactivate) {
    return this.apiService.call<any>('deactivateRequest', data);
  }

  updateSettings(data: Profilesettings): Observable<Profilesettings> {
    return this.apiService.call<Profilesettings>('updateSetting', data);
  }

  updateWithdrawSettings(data: WithdrawSettings): Observable<Profilesettings> {
    return this.apiService.call<Profilesettings>('updateSetting', data);
  }

  getUserAPIKeys() {
    return this.apiService.call<any>('getUserAPIKeys');
  }

  getAPIAccess() {
    return this.apiService.call<any>('getAPIAccess');
  }

  generateAPIKey(data: any) {
    return this.apiService.call<any>('generateAPIKey', data);
  }

  editAPIKey(data: any) {
    return this.apiService.call<any>('editAPIKey', data);
  }

  deleteAPIKey(api_id: any) {
    return this.apiService.call<any>('deleteAPIKey', {api_id: api_id});
  }

  activateAPIKey(data: any) {
    return this.apiService.call<any>('activateAPIKey', data);
  }

  deactivateAPIKey(data: any) {
    return this.apiService.call<any>('deactivateAPIKey', data);
  }

  regenerateAPIKey(data: any) {
    return this.apiService.call<any>('regenerateAPIKey', data);
  }

  fetch2FAStatus() {
    return this.apiService.call<any>('enable2fa');
  }

  enable2FA(data: Enable2FA): Observable<Enable2FA> {
    return this.apiService.call<any>('verify2fa', data);
  }

  disable2FA(data: Disable2FA): Observable<Disable2FA> {
    return this.apiService.call<any>('disable2fa', data);
  }

  addUserAutoTrade(data: AutoTrade) {
    return this.apiService.call<any>('addUserAutoTrade', data);
  }

  deleteUserAutoTrade(data: any) {
    return this.apiService.call<any>('deleteUserAutoTrade', data);
  }

  enableFundPin(data: FundPin): Observable<FundPin> {
    return this.apiService.call<FundPin>('enableFundPin', data);
  }

  disableFundPin(data: FundPin): Observable<FundPin> {
    return this.apiService.call('disableFundPin', data);
  }

  disableSettingFallback(data: any): Observable<any> {
    return this.apiService.call('disableSettingFallback', data);
  }

  disableSettingConfirm(data: any): Observable<any> {
    return this.apiService.call('disableSettingConfirm', data);
  }

  resendFundPin() {
    return this.apiService.call<any>('resendFundPin');
  }
}
