import { Injectable, OnDestroy } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

export { SubscriptionLike } from 'rxjs';

export interface InternalStateType {
  [key: string]: any;
}

interface InternalStateCache {
  [key: string]: {
    observer: Observer<InternalStateType>,
    observable: Observable<any>
  };
}

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService implements OnDestroy {

  private observerPairs: InternalStateCache = {};

  constructor() {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  private createGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
    window.onunload = () => {
      this.removeTab();
    };
    if (sessionStorage.getItem('localStorage') !== null || sessionStorage.getItem('localStorage') !== '') {
      const data = JSON.parse(sessionStorage.getItem('localStorage'));
      let loggedIn: any = null;
      for (const key in data) {
        if (key === 'LOGGED_IN') {
          loggedIn = data[key];
        } else {
          this.store(key, data[key]);
        }
      }
      if (loggedIn !== null) { this.store('LOGGED_IN', loggedIn); }
      sessionStorage.removeItem('localStorage');
    }
  }

  private getTabs() {
    const tabs = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substr(0, 'TAB_'.length) === 'TAB_') {
        tabs.push(localStorage.key(i));
      }
    }
    return tabs;
  }

  public resetTabs() {
    localStorage.removeItem('RESET_TABS');
    localStorage.removeItem('TABS');
    this.removeTabs();
    this.setGuid();
    localStorage.setItem('RESET_TABS', 'true');
    setTimeout(() => { localStorage.removeItem('RESET_TABS'); }, 100);
  }

  private removeTab() {
    const tab = 'TAB_' + sessionStorage.getItem('GUID');
    const tabs = this.getTabs();
    tabs.forEach(item => {
      if (item === tab) {
        localStorage.removeItem(tab);
      }
    });
  }

  private removeTabs() {
    const tabs = this.getTabs();
    tabs.forEach(item => {
      localStorage.removeItem(item);
    });
  }

  private setGuid() {
    const guid = sessionStorage.getItem('GUID') ? sessionStorage.getItem('GUID') : this.createGuid();
    sessionStorage.setItem('GUID', guid);
    localStorage.setItem('TAB_' + guid, 'active');
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      if (event.key === 'RESET_TABS' && event.newValue === 'true') {
        this.setGuid();
      } else {
        if (event.key && !(event.key === 'tvxwevents.settings' ||
          event.key.startsWith('tradingview.chartproperties'))) {
          let v: any;
          try { v = JSON.parse(event.newValue); } catch (e) { v = event.newValue; }
          if (v !== null) {
            this.store(event.key, v);
          }
        }
      }
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  store(key: string, value: any) {
    const state = value;
    if (localStorage.getItem(key) !== value) {
      localStorage.setItem(key, JSON.stringify(value));

      if (this._getObservablePair(key).observer) {
        this._getObservablePair(key)
          .observer.next(value);
      }
    }

    return state;
  }

  get(key: string) {
    let item = localStorage.getItem(key);
    if ((item != null) && (item !== 'undefined')) {
      return JSON.parse(item);
    } else {
      item = sessionStorage.getItem(key);
      if ((item != null) && (item !== 'undefined')) {
        return JSON.parse(item);
      } else {
        return '';
      }
    }
  }

  observe(prop: string) {
    const observable = this._getObservablePair(prop).observable;
    return observable.pipe(distinctUntilChanged());
  }

  _getObservablePair(prop: string) {
    if (!this.observerPairs[prop]) {
      this.observerPairs[prop] = {
        observable: null,
        observer: null
      };

      this.observerPairs[prop].observable = new Observable(
        (_observer: Observer<InternalStateType>) => {
          const item = this.get(prop);
          if (item) {
            _observer.next(item);
          }
          this.observerPairs[prop].observer = _observer;
        }
      ).pipe(shareReplay(1));
    }

    return this.observerPairs[prop];
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  purge() {
    const theme = this.get('THEME');
    localStorage.clear();
    this.store('THEME', theme);
  }

}
