//
// Welcome to the annotated FaceTec Device SDK core code for performing secure Liveness Checks!
//

import { Config } from 'src/assets/Config';
import { FaceTecSDK } from 'src/assets/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { FaceTecSessionResult, FaceTecFaceScanResultCallback, FaceTecFaceScanProcessor } from 'src/assets/core-sdk/FaceTecSDK.js/FaceTecPublicApi';
import { environment } from 'src/environments/environment';

//
// This is an example self-contained class to perform Liveness Checks with the FaceTec SDK.
// You may choose to further componentize parts of this in your own Apps based on your specific requirements.
//
export class LivenessCheckProcessor implements FaceTecFaceScanProcessor {
  latestNetworkRequest: XMLHttpRequest = new XMLHttpRequest();
  deviceIdentifier: string;
  XSRFToken: string;
  public latestSessionResult: FaceTecSessionResult | null;

  success: boolean;
  FaceTecControllerReference: any;

  constructor(sessionToken: string, deviceIdentifier: string, XSRFToken: string, FaceTecControllerReference: any) {
    this.success = false;
    this.FaceTecControllerReference = FaceTecControllerReference;
    this.latestSessionResult = null;
    this.deviceIdentifier = deviceIdentifier;
    this.XSRFToken = XSRFToken;

    //
    // Part 1:  Starting the FaceTec Session
    //
    // Required parameters:
    // - FaceTecFaceScanProcessor:  A class that implements FaceTecFaceScanProcessor,
    // which handles the FaceScan when the User completes a Session.
    // In this example, "this" implements the class.
    // - sessionToken:  A valid Session Token you just created by calling your API
    // to get a Session Token from the Server SDK.
    //
    /* tslint:disable*/
    new FaceTecSDK.FaceTecSession(
      this,
      sessionToken
    );
    /* tslint:enable */
  }

  //
  // Part 2:  Handling the Result of a FaceScan
  //
  public processSessionResultWhileFaceTecSDKWaits(
    sessionResult: FaceTecSessionResult,
    faceScanResultCallback: FaceTecFaceScanResultCallback) {

    // Save the current sessionResult
    this.latestSessionResult = sessionResult;

    //
    // Part 3:  Handles early exit scenarios where there is no FaceScan to handle
    // -- i.e. User Cancellation, Timeouts, etc.
    //
    if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
      console.log('Session was not completed successfully, cancelling.  Session Status: ' +
        FaceTecSDK.FaceTecSessionStatus[sessionResult.status]);
      this.latestNetworkRequest.abort();
      faceScanResultCallback.cancel();
      return;
    }

    // IMPORTANT:  FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
    // DOES NOT mean the Liveness Check was Successful.
    // It simply means the User completed the Session and a 3D FaceScan was created.
    // You still need to perform the Liveness Check on your Servers.

    //
    // Part 4:  Get essential data off the FaceTecSessionResult
    //
    const parameters = {
      faceScan: sessionResult.faceScan,
      auditTrailImage: sessionResult.auditTrail[0], // base64 string here
      lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
      sessionId: sessionResult.sessionId,
      'X-DEVICE-KEY': this.deviceIdentifier,
      'X-USER-AGENT':
      FaceTecSDK.createFaceTecAPIUserAgentString(sessionResult.sessionId as string)
    };

    //
    // Part 5:  Make the Networking Call to Your Servers.
    //
    this.latestNetworkRequest = new XMLHttpRequest();
    this.latestNetworkRequest.open('POST', environment.config.BACKEND_URL + '/action/sendLivenessWithAuth');
    this.latestNetworkRequest.setRequestHeader('Content-Type', 'application/json');
    this.latestNetworkRequest.setRequestHeader('X-XSRF-TOKEN', this.XSRFToken);

    this.latestNetworkRequest.onreadystatechange = () => {
      //
      // Part 6:  In our App, we evaluate a boolean response and treat true as
      // was successfully processed and should proceed to next step,
      // and handle all other responses by cancelling out.
      //

      if (this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
        try {
          const responseJSON = JSON.parse(this.latestNetworkRequest.responseText);
          const scanResultBlob = responseJSON.scanResultBlob;

          if (responseJSON.wasProcessed && responseJSON.success) {

            // Demonstrates dynamically setting the Success Screen Message.
            FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage('Liveness\nConfirmed');

            // In v9.2.0+, simply pass in scanResultBlob to the proceedToNextStep function to advance the User flow.
            // scanResultBlob is a proprietary, encrypted blob
            // that controls the logic for what happens next for the User.
            faceScanResultCallback.proceedToNextStep(scanResultBlob);
          } else {
            // CASE:  UNEXPECTED response from API.  Our App Code keys off a wasProcessed boolean on
            // the root of the JSON object --> You define your own API contracts with yourself and may
            // choose to do something different here based on the error.
            console.log('Unexpected API response or scan failed, cancelling out.');
            faceScanResultCallback.cancel();
          }
        } catch {
          // CASE:  Parsing the response into JSON failed --> You define your own API contracts with yourself
          // and may choose to do something different here based on the error.
          // Solid server-side code should ensure you don't get to this case.
          console.log('Exception while handling API response, cancelling out.');
          faceScanResultCallback.cancel();
        }
      }
    };

    this.latestNetworkRequest.onerror = () => {

      // CASE:  Network Request itself is erroring --> You define your own API contracts with yourself
      //  and may choose to do something different here based on the error.
      console.log('XHR error, cancelling.');
      faceScanResultCallback.cancel();
    };

    //
    // Part 7:  Demonstrates updating the Progress Bar based on the progress event.
    //
    this.latestNetworkRequest.upload.onprogress = (event) => {

      const progress = event.loaded / event.total;
      faceScanResultCallback.uploadProgress(progress);
    };

    //
    // Part 8:  Actually send the request.
    //
    const jsonStringToUpload = JSON.stringify(parameters);
    this.latestNetworkRequest.send(jsonStringToUpload);

    //
    // Part 9:  For better UX, update the User if the upload is taking a while.
    // You are free to customize and enhance this behavior to your liking.
    //
    window.setTimeout( () => {

      if (this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
        return;
      }
      faceScanResultCallback.uploadMessageOverride('Still Uploading...');
    }, 6000);
  }

  //
  // Part 10:  This function gets called after the FaceTec SDK is completely done.
  // There are no parameters because you have already been passed all data in the
  // processSessionWhileFaceTecSDKWaits function and have already handled all of your own results.
  //
  public onFaceTecSDKCompletelyDone = () => {
    //
    // DEVELOPER NOTE:  onFaceTecSDKCompletelyDone() is called after you signal
    // the FaceTec SDK with success() or cancel().
    //
    this.success = this.latestSessionResult.isCompletelyDone;
    this.FaceTecControllerReference.onComplete(this.latestSessionResult, null, this.latestNetworkRequest.status);
  }

  //
  // DEVELOPER NOTE:  This public convenience method is for demonstration purposes only
  // so the App can get information about what is happening in the processor.
  // In your code, you may not even want or need to do this.
  //
  public isSuccess = () => {
    return this.success;
  }
}
