export interface FundPin {
  password?: string;
  fundpin: string;
}

export enum FundPinStatus {
  Disabled = '0',
  Enabled = '1',
  Pending = '2'
}
