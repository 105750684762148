<footer>
  <div class="transparent-wave col-12 px-0 d-none d-lg-block">
    <img class="wavey-footer"
          src="../../../assets/chainex/images/pages/auth/signup/light-mode/wavey_footer.svg" loading="lazy">
  </div>
  <div>
    <div class="container-fluid px-0">
      <div class="col-12 mx-auto px-0">
      <div class="row mx-0 align-items-center">

        <div class="orange-text col-12 col-lg-auto text-center text-lg-left foot-text pt-2 pt-lg-1 pl-3 pr-3 pr-lg-0">
          {{'COPYRIGHT_STRING' | i18n}}
        </div>

        <div class="col-12 col-lg pt-2 pt-lg-1 px-0">
            <div class="col-12 px-0 ml-0 text-center">
              <div class="row mx-0 justify-content-center">
                <a class="d-block pr-1 pl-1 foot-text" routerLink="about" i18n>ABOUT</a>
                <a class="d-block pr-1 pl-1 foot-text" routerLink="terms" i18n>TERMS&nbsp;OF&nbsp;SERVICE</a>
                <a class="d-block pr-1 pl-1 foot-text" routerLink="fees" i18n>FEES</a>
                <a class="d-block pr-1 pl-1 foot-text" routerLink="api" i18n>API</a>
                <a class="d-block pr-1 pl-1 foot-text" routerLink="security" i18n>SECURITY</a>
                <a class="d-block pr-1 pl-1 foot-text" routerLink="legal" i18n>LEGAL</a>
              </div>
            </div>
        </div>

        <div class="col-12 col-lg-auto px-0">
          <div class="row mx-0">
            <div class="col-12 text-center text-lg-right py-2 py-lg-1 pl-3 pl-lg-0 pr-3">
              <a class="mx-1" *ngIf="environment.INSTAGRAM_LINK" href="{{environment.INSTAGRAM_LINK}}" target="_blank">
                <mat-icon svgIcon="instagram"></mat-icon>
              </a>
              <a class="mx-1" *ngIf="environment.FACEBOOK_LINK" href="{{environment.FACEBOOK_LINK}}" target="_blank">
                <mat-icon svgIcon="facebook" ></mat-icon>
              </a>
              <a class="mx-1" *ngIf="environment.TWITTER_LINK" href="{{environment.TWITTER_LINK}}" target="_blank">
                <mat-icon svgIcon="twitter"></mat-icon>
              </a>
              <a class="mx-1" *ngIf="environment.LINKEDIN_LINK" href="{{environment.LINKEDIN_LINK}}" target="_blank">
                <mat-icon svgIcon="linkedin"></mat-icon>
              </a>
              <a class="mx-1" *ngIf="environment.GHOST_LINK" href="{{environment.GHOST_LINK}}" target="_blank">
                <mat-icon svgIcon="ghost"></mat-icon>
              </a>
              <a class="mx-1" *ngIf="environment.TELEGRAM_LINK" href="{{environment.TELEGRAM_LINK}}" target="_blank">
                <mat-icon svgIcon="telegram_orange"></mat-icon>
              </a>
              <a class="mx-1" *ngIf="environment.YOUTUBE_LINK" href="{{environment.YOUTUBE_LINK}}" target="_blank">
                <mat-icon svgIcon="youtube"></mat-icon>
              </a>


            </div>
          </div>
        </div>
        <div class="col-12 px-0 d-block d-lg-none">
          <img class="bottom-wavey-footer"
                src="../../../assets/chainex/images/pages/auth/signup/light-mode/wavey_footer.svg" loading="lazy">
        </div>
      </div>
    </div>
  </div>
  </div>
</footer>
