const orderType = {
    'buy': 0,
    0: 'buy',
    'sell': 1,
    1: 'sell'
};

const executionType = {
    '2': 'market',
    'market': '2',
    '3': 'limit',
    'limit': '3',
    '4': 'stop',
    'stop': '4',
    '5': 'stop-limit',
    'stop-limit': '5'
};

export { executionType, orderType };
