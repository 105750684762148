import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface ILinkAccountDialogData {
    accountNumber: string;
    bankName: string;
    typeName: string;
    link: boolean;
  }

  @Component({
    selector: 'app-link-account-dialog-dialog',
    templateUrl: './link-account-dialog.component.html',
    styleUrls: ['./link-account-dialog.component.scss']
  })
  export class LinkAccountDialogComponent {

    public static openDialog(
        dialog: MatDialog,
        dialogData: ILinkAccountDialogData,
        width?: string): MatDialogRef<LinkAccountDialogComponent> {

      return dialog.open(LinkAccountDialogComponent, {
        width: !width ? width : '650px',
        data: dialogData
      });
    }

    constructor(
      public dialogRef: MatDialogRef<LinkAccountDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ILinkAccountDialogData) {}

      ok_click(): void {
        this.dialogRef.close({result: 'accept'});
      }

      close_click(): void {
        this.dialogRef.close({result: 'reject'});
      }
  }
