import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { SubscriptionLike } from 'src/app/core/services/store.service';
import { CurrencyManagementComponent } from 'src/app/balances/currency-management/currency.management.component';

@Component({
  selector: 'app-balance-viewer',
  templateUrl: './balance-viewer.component.html',
  styleUrls: ['./balance-viewer.component.scss']
})
export class BalanceViewerComponent implements OnInit, OnDestroy {
  @Input() darkTheme: boolean = false;
  @Input() available: number;
  @Input() held: number;
  @Input() earn: number;
  @Input() code: string;
  @Input() showButtons: boolean = true;

  private subs: SubscriptionLike;

  constructor(
    public dialog: MatDialog,
    private sessionStorage: SessionStorageService,
  ) { }

  ngOnInit() {
    this.subs = this.sessionStorage.observe('THEME').subscribe( data => {
      this.darkTheme = (data === 'dark-theme');
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  openCurrencyManagement(mode: number) {
    this.dialog.open(CurrencyManagementComponent, {
      width: '1200px',
      data: {
        code: this.code,
        type: this.code === 'ZAR' ? 'Fiat' : '',
        icon: '',
        mode
      }
    });
  }

}
