import { Injectable } from '@angular/core';
import { State, Action, StateContext, createSelector } from '@ngxs/store';
import { tap } from 'rxjs/operators';


import { InternalStateType } from 'src/app/core/services/session-storage.service';

import { LeaderBoardService } from '../leader-board.service';

export class Competition {
  rank?: number;
  entries: any[];
}

/* TODO: Add GetCompetitions and API for all Competitions...
export class GetCompetitions {
  static readonly type = '[Competitions] Get competitions';
}
*/
export class GetCompetition {
  static readonly type: string = '[Competitions] Get competition by ID';

  constructor(public id: number) {}
}

@State<InternalStateType>({
  name: 'competitions',
  defaults: {}
})
@Injectable()
export class CompetitionsState {
  static getCompetition(id: number) {
    return createSelector(
      [CompetitionsState],
      (state: InternalStateType): Competition => state[id]);
  }

  constructor(private leaderBoardService: LeaderBoardService) {}

  @Action(GetCompetition)
  getCompetition(ctx: StateContext<Competition>, action: GetCompetition) {
    const competitions = ctx.getState();
    const id = action.id;

    const getLeaderBoard = this.leaderBoardService
      .getLeaderBoard(id).pipe(tap(competition => ctx.patchState({[id]: competition})));

    if (competitions[id]) {
      // Update them datas...
      getLeaderBoard.toPromise().then(competition => competition[id] = competition);

      return competitions[id];
    }

    return getLeaderBoard;
  }
}
