import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export enum DialogTypes {
  cancelOrder,
  cancelWithdrawal,
  createWithdrawal,
  reportMessage,
  enableAutotrade,
  disableAutotrade,
  general
}

export interface IConfirmDialogData {
  // cancelOrder
  pair?: string;
  type?: string;

  // cancelWithdrawal
  // createWithdrawal
  coinCode?: string;
  address?: string;
  networkName?: string;
  internal?: boolean;

  // createWithdrawal
  bank?: string;

  // reportMessage
  username?: string;
  transformedMessage?: string;

  // cancelOrder
  // cancelWithdrawal
  // createWithdrawal
  // reportMessage
  cancelType: Number;
  amount?: string;
  fee?: Number;

  // general
  title?: string;
  message?: string;

  messagealign?: string;

  no?: string;
  yes?: string;

  swopbuttons?: boolean;
}

@Component({
  selector: 'app-confirm-dialog-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  public dialogTypes: any = DialogTypes;

  public static openDialog(
    dialog: MatDialog,
    dialogData: IConfirmDialogData,
    width?: string): MatDialogRef<ConfirmDialogComponent> {

      if (!dialogData.messagealign) {
        dialogData.messagealign = 'center';
      }
      if (!dialogData.no) {
        dialogData.no = 'NO';
      }
      if (!dialogData.yes) {
        dialogData.yes = 'YES';
      }
      if (!dialogData.swopbuttons) {
        dialogData.swopbuttons = false;
      }

      return dialog.open(ConfirmDialogComponent, {
        width: !width ? width : '650px',
        data: dialogData
      });
  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData) {
  }

  ok_click(): void {
    this.dialogRef.close({result: 'accept'});
  }

  close_click(): void {
    this.dialogRef.close({result: 'reject'});
  }
}
