<div class="container-fluid mt-2">
    <div class="row justify-content-center">
        <ng-container *ngIf="showProducts; else subscribe">
            <!-- User products -->
            <div class="my-2 col-12 px-0">
                <div class="col-12 mb-1 pt-5 pb-3" *ngIf="!dashboard">
                    <span i18n>
                        <h2><span class="orange-text"><strong>EARN</strong></span> PORTAL</h2>
                    </span>
                </div>
                <div class="col-12 border-box warning" *ngIf="(!userProducts || userProducts.length === 0) && !dashboard">
                    <span class="d-block text-left" i18n>
                        Our Earn Products allow users to manage their digital assets more effectively. Instead of just holding your assets,
                        you are able to subscribe to products by transferring portions of your holdings into the Earn Products, which will
                        generate interest. For more information about our Earn Portal contained in our knowledge base
                        <a href="{{supportLink}}/kb/functionality/what-is-the-earn-portal-and-how-to-use-it" target="_bank">click here.</a>
                    </span>
                </div>

                <!-- User products -->
                <div class="table-container col-12 full-table" [ngClass]="(!!userProducts && userProducts.length > 0) ? 'd-block' : 'd-none'">
                    <table mat-table [dataSource]="user_products" class="w-100" matSort #user_products_table>

                        <ng-container matColumnDef="coin_code">
                            <th mat-header-cell *matHeaderCellDef class="col-1" i18n>Coin</th>
                            <td mat-cell *matCellDef="let element" class="pl-1">
                                <img loading="lazy" [src]="imageUrl + element.coin_code.toLowerCase() + '.svg'" class="coin-icon"
                                    (error)="$event.target.src = imageUrl + 'fallback_coin_icon.svg'">
                                <strong>{{ element.coin_code }}</strong>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Product</th>
                            <td mat-cell *matCellDef="let element" class="text-center"><strong>{{ element.name }}</strong></td>
                        </ng-container>

                        <ng-container matColumnDef="estimated_apy">
                            <th mat-header-cell *matHeaderCellDef class="col-1" mat-sort-header i18n>Est. APY</th>
                            <td mat-cell *matCellDef="let element" class="color_green">
                                <strong>{{ element.estimated_apy | decimalNumber: 2 }}%</strong>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="period">
                            <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Duration</th>
                            <td mat-cell *matCellDef="let element" class="text-center"><strong>{{ element.period }}</strong></td>
                        </ng-container>

                        <ng-container matColumnDef="pending_product_balance">
                            <th mat-header-cell *matHeaderCellDef class="col-2" mat-sort-header i18n>Pending Balance</th>
                            <td mat-cell *matCellDef="let element" class="text-left"> {{ element.pending_product_balance | decimalNumber: 8 }} </td>
                        </ng-container>

                        <ng-container matColumnDef="balance">
                            <th mat-header-cell *matHeaderCellDef class="col-2" mat-sort-header i18n>Balance</th>
                            <td mat-cell *matCellDef="let element" class="text-left"> {{ element.balance | decimalNumber: 8 }} </td>
                        </ng-container>

                        <ng-container matColumnDef="compound_earning">
                            <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Compound Earning</th>
                            <td mat-cell *matCellDef="let element" class="text-center">
                                <mat-slide-toggle color="primary" [checked]="element.compound_earning" (change)="changeCompound(element, $event)" [disabled]="element.no_compound"></mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef class="col" i18n></th>
                            <td mat-cell *matCellDef="let element">
                                <!-- unsubscribe -->
                                <button (click)="toggleProduct(element, false)" mat-button color="primary" class="mat-raised-button d-block w-100" i18n>
                                    UNSUBSCRIBE
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="userProducts_definition"></tr>
                        <tr mat-row *matRowDef="let row; columns: userProducts_definition;"></tr>
                    </table>
                </div>
                <mat-paginator #paginator2 [ngClass]="{'d-none': userProducts.length <= 10}" i18n-itemsPerPageLabel itemsPerPageLabel="Items per page:" [pageSize]="10" [length]="userProducts.length" class=""></mat-paginator>
            </div>

            <!-- Available products -->
            <div class="col-12 mt-3 px-0 pt-3 border-top mb-4">
                <div class="col-12 mb-1">
                    <span i18n>
                        <strong>Available Earn Products</strong>
                    </span>
                </div>

                <!-- Available products table -->
                <div class="table-container col-12 full-table">
                    <table #coin_products_table mat-table [dataSource]="coin_products" class="w-100" matSort>

                        <ng-container matColumnDef="coin_code">
                            <th mat-header-cell *matHeaderCellDef class="col-1" i18n>Coin</th>
                            <td mat-cell *matCellDef="let element" class="pl-1">
                                <img [src]="imageUrl + element.coin_code.toLowerCase() + '.svg'" class="coin-icon"
                                    (error)="$event.target.src = imageUrl + 'fallback_coin_icon.svg'">

                                <strong>{{ element.coin_code }}</strong>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Product</th>
                            <td mat-cell *matCellDef="let element" class="text-center"><strong>{{ element.name }}</strong></td>
                        </ng-container>

                        <ng-container matColumnDef="estimated_apy">
                            <th mat-header-cell *matHeaderCellDef class="col-1" mat-sort-header i18n>Est. APY</th>
                            <td mat-cell *matCellDef="let element" class="color_green">
                                <strong>{{ element.estimated_apy | decimalNumber: 2 }}%</strong>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="period">
                            <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Duration</th>
                            <td mat-cell *matCellDef="let element" class="text-center"><strong>{{ element.period }}</strong></td>
                        </ng-container>

                        <ng-container matColumnDef="min_amount">
                            <th mat-header-cell *matHeaderCellDef class="col-1" mat-sort-header i18n>Min Amount</th>
                            <td mat-cell *matCellDef="let element" class="text-left"> {{ element.min_amount | decimalNumber: 8 }} </td>
                        </ng-container>

                        <ng-container matColumnDef="compound_earning">
                            <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Compound Earning</th>
                            <td mat-cell *matCellDef="let element" class="text-center">{{ element.compound_earning ? 'Allowed' : 'NA' }}</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef class="col" i18n></th>
                            <td mat-cell *matCellDef="let element" class="mw-125">
                                <!-- subscribe -->
                                <ng-container *ngIf="this.loggedIn$ | async; else signIn">
                                    <button (click)="toggleProduct(element, true)" mat-button color="primary" class="mat-raised-button d-block ml-auto mr-0 mw-125">
                                        SUBSCRIBE
                                    </button>

                                </ng-container>

                                <ng-template #signIn>
                                    <button routerLink="/login/earn" mat-button color="primary" class="mat-raised-button d-block ml-auto mr-0 mw-125">
                                        SIGN IN
                                    </button>
                                </ng-template>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="products_definition"></tr>
                        <tr mat-row *matRowDef="let row; columns: products_definition;"></tr>
                    </table>
                </div>
                <mat-paginator #paginator1  [ngClass]="{'d-none': products.length <= 10}" i18n-itemsPerPageLabel itemsPerPageLabel="Items per page:" [pageSize]="10" [length]="products.length" (page)="pageChange($event)" class=""></mat-paginator>
                <div class="w-100 text-center mt-1" *ngIf="isLoading" i18n>Loading Products...</div>
                <div class="w-100 text-center mt-1" *ngIf="!isLoading && (!coin_products || coin_products.data.length === 0)" i18n>No Products Available...</div>
            </div>
        </ng-container>

        <!-- Subscribe to a product -->
        <ng-template #subscribe>
            <div [ngClass]="{'container-warning': warning || canAccessProduct()}">
                <div [ngClass]="{'subscribe': warning || canAccessProduct()}">
                    <div class="my-2 col-12 px-3" [ngClass]="{'mw-60': earnPage}">
                        <div class="col-12 mb-1 size-50">
                            <div class="row justify-end mr-2">
                                <div [ngClass]="{'m-l-5': earnPage}">
                                    <a (click)="toggleProduct()"><mat-icon class="h-50 align-sub">arrow_back_ios</mat-icon></a>
                                    <img loading="lazy" [src]="imageUrl + selectedProduct.coin_code.toLowerCase() + '.svg'" class="coin-icon-bg mb-3 mb-sm"
                                        (error)="$event.target.src = imageUrl + 'fallback_coin_icon.svg'">
                                    <strong class="earn-header">Earn {{ selectedProduct.token }}</strong>
                                </div>
                                <ng-container *ngIf="earnPage">
                                    <div class="h-50 px-0 mx-0">
                                        <div class="row justify-end m-l-2 border-box">
                                            <span>
                                                <strong>
                                                    <ng-container i18n>Available Balance:</ng-container> {{ userBalance | decimalNumber: 8 }} {{ selectedProduct.coin_code }}
                                                </strong>
                                            </span>
                                            <app-balance-viewer
                                                [available]="coinBalance.balance_total"
                                                [held]="coinBalance.balance_held"
                                                [code]="selectedProduct.coin_code"
                                                [earn]="coinBalance.balance_earn"
                                                [showButtons]="false">
                                            </app-balance-viewer>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <!-- Single product table -->
                    <div class="col-12 mw-60 px-5">
                        <div class="table-container full-table col-12 px-0 pb-3">
                            <table mat-table [dataSource]="product" class="w-100 single-product">

                                <ng-container matColumnDef="coin_code">
                                    <th mat-header-cell *matHeaderCellDef class="col-1" i18n>Coin</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.coin_code }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Product</th>
                                    <td mat-cell *matCellDef="let element" class="text-center"><strong>{{ element.name }}</strong></td>
                                </ng-container>

                                <ng-container matColumnDef="estimated_apy">
                                    <th mat-header-cell *matHeaderCellDef class="col-1 text-center" i18n>Est. APY</th>
                                    <td mat-cell *matCellDef="let element" class="color_green text-center">
                                        <strong>{{ element.estimated_apy | decimalNumber: 2 }}%</strong>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="period">
                                    <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Duration</th>
                                    <td mat-cell *matCellDef="let element" class="text-center"><strong>{{ element.period }}</strong></td>
                                </ng-container>

                                <ng-container matColumnDef="estimated_daily_earning">
                                    <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Est. Daily Earning</th>
                                    <td mat-cell *matCellDef="let element" class="text-center">{{ estimated_daily | decimalNumber: 8 }}</td>
                                </ng-container>

                                <ng-container matColumnDef="estimated_annual_earning">
                                    <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Est. Annual Earning</th>
                                    <td mat-cell *matCellDef="let element" class="text-center">{{ estimated_anual | decimalNumber: 8 }}</td>
                                </ng-container>

                                <ng-container matColumnDef="min_amount">
                                    <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Min Amount</th>
                                    <td mat-cell *matCellDef="let element" class="text-center"> {{ element.min_amount | decimalNumber: 8 }} </td>
                                </ng-container>

                                <ng-container matColumnDef="balance">
                                    <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Balance</th>
                                    <td mat-cell *matCellDef="let element" class="text-center"> {{ element.balance | decimalNumber: 8 }} </td>
                                </ng-container>

                                <ng-container matColumnDef="pending_product_balance">
                                    <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Pending Balance</th>
                                    <td mat-cell *matCellDef="let element" class="text-center"> {{ element.pending_product_balance | decimalNumber: 8 }} </td>
                                </ng-container>

                                <ng-container matColumnDef="compound_earning">
                                    <th mat-header-cell *matHeaderCellDef class="col-2 text-center" i18n>Compound Earning</th>
                                    <td mat-cell *matCellDef="let element" class="text-center">
                                        <mat-slide-toggle color="primary" [checked]="element.compound_earning" disabled></mat-slide-toggle>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="getProductDefinition()"></tr>
                                <tr mat-row *matRowDef="let row; columns: getProductDefinition()"></tr>
                            </table>
                        </div>

                        <div class="col-12 border-box warning subscribe-warning">
                            <span class="d-block text-left">
                                <ul class="pb-0 mb-0" *ngIf="isSubscribing; else unsubscribeWarning">
                                    <li i18n>
                                        No interest is accumulated on products subscribed to on the day of subscription. Interest is calculated the following day.
                                    </li>
                                    <li i18n>
                                        If funds are withdrawn from the earn product within 7 days of subscription, a {{ !!selectedProduct.withdrawal_fee ? (selectedProduct.withdrawal_fee | decimalNumber: 2) + '% fee will be charged.' : 'withdrawal fee may be charged.' }}
                                    </li>
                                    <li i18n>
                                        Subscriptions could take up to 24 hours to reflect and up to 48 hours on weekends and public holidays.
                                    </li>
                                </ul>

                                <ng-template #unsubscribeWarning>
                                    Please note, when you unsubscribe from a fixed-term product before the end of the term, you will forfeit the interest generated and
                                    you will only receive your subscription amount back. Further, when un-subscribing from products the funds will only appear in your wallet the following day.
                                </ng-template>
                            </span>
                        </div>

                        <div class="col-12 my-3 px-0">
                            <div [formGroup]="subscriptionForm" class="row px-0 mx-0">
                                <div class="col-12 col-lg-7 mt-auto px-0 h-50 d-flex amount-input justify-content-lg-start">
                                    <mat-form-field class="row m-0 p-0 col-8">
                                        <input [readonly]="!isSubscribing && selectedProduct.period.toLowerCase() != 'flexible'" title="" type="number" required matInput formControlName="amount" name="amount" i18n-placeholder placeholder="Amount">
                                        <mat-error class="mb-2" *ngIf="subscriptionForm.controls.amount.hasError('required')" i18n>
                                            Amount required.
                                        </mat-error>
                                        <mat-error class="mb-2" *ngIf="subscriptionForm.controls.amount.hasError('min')" i18n>
                                            Amount must be greater or equal to {{ min_amount }} {{ coinCode }}.
                                        </mat-error>
                                        <mat-error class="mb-2" *ngIf="subscriptionForm.controls.amount.hasError('max')" i18n>
                                            Amount must be less or equal to {{ max_amount }} {{ coinCode }}.
                                        </mat-error>
                                    </mat-form-field>

                                    <button [ngClass]="{'d-none': !isSubscribing}" mat-button color="primary" class="max col ml-2 h-35 text-center my-auto" (click)="maxAmount()">MAX</button>
                                </div>

                                <div [ngClass]="!isSubscribing ? 'd-none' : 'd-flex'" class="col-12 col-lg-5 mt-auto px-0 row h-50 terms mx-0 justify-content-center">
                                    <div class="col-12 col-sm-6 col-lg-12 d-flex px-0 justify-content-center justify-content-lg-end">
                                        <mat-checkbox formControlName="acceptTerms"></mat-checkbox>
                                        <a href="/terms#earn" class="mx-2" target="_blank" i18n>Accept Terms &amp; Conditions</a>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-12 d-flex px-0 justify-content-center justify-content-lg-end" *ngIf="selectedProduct.compound_earning">
                                        <span i18n>
                                            Compound Earnings
                                            <mat-slide-toggle color="primary" formControlName="compound"></mat-slide-toggle>
                                        </span>
                                        <mat-icon class="ml-2 info" svgIcon="fee-info" color="primary" i18n-matTooltip
                                            matTooltip="Compounding is the process of earning interest upon interest. If compounding is switched on,
                                            your daily reward will be added to your subscribed balance and interest is calculated on your new total subscribed balance."
                                            triggers="hover click">
                                        </mat-icon>
                                    </div>
                                </div>

                                <!-- Buttons -->
                                <div class="col-12 col-md-7 mt-3 mx-auto"  *ngIf="isSubscribing; else unsubscribing">
                                    <button mat-button color="primary" class="w-95 mx-auto mat-raised-button d-block"
                                    (click)="validateForm(true)" [disabled]="!subscriptionForm.controls.acceptTerms.value || formIsDirty" i18n>SUBSCRIBE</button>
                                </div>

                                <ng-template #unsubscribing>
                                    <div class="col mx-auto mt-auto h-50">
                                        <button mat-button color="primary" class="w-95 mx-auto mat-raised-button d-block"
                                        (click)="validateForm(false)" [disabled]="formIsDirty || subscriptionForm.controls.amount.value <= 0" i18n>UNSUBSCRIBE</button>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="warning || canAccessProduct()" [ngClass]="warning ? 'risk-warning' : 'verify-warning'">
                    <div class="text-center">
                        <h5 i18n>
                            <strong *ngIf="warning; else verifyWarning">NOTE: HIGH RISK PRODUCT</strong>
                            <ng-template #verifyWarning>
                                <strong>Please verify your account to use this Product</strong>
                            </ng-template>
                        </h5>
                        <span *ngIf="warning" i18n>
                            <strong>
                                ChainEX endeavors to offer its users only the best products. However, ChainEX only acts as a platform and provide users with related services,
                                such as accessing funds on behalf of the user and distributing earnings, etc. ChainEX does not assume liability for any losses incurred due to
                                project on-chain contract security issues.
                            </strong>
                        </span>
                        <div class="mt-5 p-0 col-12 d-flex justify-content-around row">
                            <ng-container *ngIf="warning; else verifyNow">
                                <button (click)="toggleWarning(true)" mat-button color="primary" class="mat-raised-button col-8 col-sm-6 col-lg-4 d-block mt-2" i18n>
                                    ACCEPT
                                </button>
                            </ng-container>
                            <ng-template #verifyNow>
                                <button [routerLink]="[navlink()]" mat-button color="primary" class="mat-raised-button col-8 col-sm-6 col-lg-4 d-block mt-2" i18n>
                                    VERIFY NOW
                                </button>
                            </ng-template>
                            <button (click)="warning ? toggleWarning(false) : toggleProduct()" mat-button color="accent" class="mat-raised-button col-8 col-sm-6 col-lg-4 d-block mt-2" i18n>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
