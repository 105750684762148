import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';

export class RouterHelper {

  static goToLogin(router: Router, state: RouterStateSnapshot) {
    router.navigate([RouterHelper.getLoginUrl(router, state, null)]);
  }

  static getLoginUrl(router: Router, state: RouterStateSnapshot, routeList: string[] = ['/dashboard']) {
    const url = !!state ? state.url
      : (!!router ? router.url : '');
      const t = RouterHelper.checkRoutes(routeList, url);
    if (!t) {
      return '/login';
    }
    return '/login/' + encodeURI(url);
  }

  private static checkRoutes(routeList: string[], url: string): boolean {
    if (routeList === null) { return true; }
    for (let i = 0; i < routeList.length; i++) {
      if (url.startsWith(routeList[i])) {
        return true;
      }
    }
    return false;
  }

  static goToRedirect(router: Router, route: ActivatedRoute, verificationLevel: any) {
    let returnurl = decodeURI(route.snapshot.paramMap.get('returnroute'));
    if (returnurl !== null && returnurl !== '' && returnurl !== 'null') {
      let subroute = route.snapshot.paramMap.get('subroute1');
      if (subroute !== null && subroute !== '') {
        returnurl = returnurl + '/' + decodeURI(subroute);
        subroute = route.snapshot.paramMap.get('subroute2');
        if (subroute !== null && subroute !== '') {
          returnurl = returnurl + '/' + decodeURI(subroute);
        }
      }
      router.navigate([returnurl]);
    } else if (verificationLevel >= 1) {
      router.navigate(['/dashboard']);
    } else {
      router.navigate(['/profile']);
    }
  }
}
