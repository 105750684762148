<mat-icon class="dialog-close" (click)="dialogRef.close()">close</mat-icon>

  <!-- enable Fund PIN -->
  <div *ngIf="defaultDialog; else disableFallback">
    <div class="col-12 px-0" *ngIf="enableFundPin; else disable">
      <form novalidate class="w-100" (ngSubmit)="onSubmit()" [formGroup]="enableData">
        <div class="row clearfix m-2">
          <div class="col-12">
            <h4 class="text-center" i18n>Enable Fund PIN</h4>
          </div>
          <div class="col-12 mb-3">
            <p class="mb-0" i18n>
              Please enter your account password and desired Fund PIN below.<br>Please note:
            </p>
          </div>

          <div class="col-12">
            <ul>
              <li i18n>
                Your Fund PIN should be between four and eight numbers
              </li>
              <li i18n>
                Ensure that you do not lose your Fund PIN since you will need to complete a verification procedure in order to reset it
              </li>
            </ul>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100 mb-3">
              <input matInput type="password" autocomplete="off" i18n-placeholder placeholder="Account Password" required
                formControlName="accountPassword" value="">
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100 mb-3">
              <input #newFundsPin matInput class="newpass" type="password" autocomplete="off" i18n-placeholder placeholder="New Fund PIN"
                required formControlName="newFundsPin" id="newFundsPin" value="">
              <mat-error
                *ngIf="enableData.controls.newFundsPin.hasError('minlength')||
                enableData.controls.newFundsPin.hasError('maxlength')||
                enableData.controls.newFundsPin.hasError('pattern')"
                i18n>
                Fund PIN must be between 4 and 8 digits and consist of numeric characters
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100 mb-3">
              <input matInput type="password" autocomplete="off" i18n-placeholder placeholder="Confirm Fund PIN" required
                formControlName="confirmFundsPin" value="">
              <mat-error *ngIf="enableData.controls.confirmFundsPin.hasError('MatchPin')" i18n>
                Confirmation Fund PIN has to match the new Fund PIN.
              </mat-error>
              <mat-error *ngIf="enableData.controls.confirmFundsPin.hasError('minlength')||
                enableData.controls.confirmFundsPin.hasError('maxlength')" i18n>
                Fund PIN must be between 4 and 8 digits and consist of numeric characters
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Start of Alert -->
          <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0 mt-2 mb-4 heading" *ngIf="submitFailed">
            <div class="alert alert-danger">
              <p class="mb-0">{{resultMessage|i18n}}</p>
            </div>
          </div>
          <!-- End of Alert -->

          <div class="col-12 col-lg-6 mb-2">
            <button class="w-100" mat-raised-button color="accent" (click)="dialogRef.close()" i18n>Cancel</button>
          </div>

          <div class="col-12 col-lg-6 text-right mb-2">
            <button class="w-100" mat-raised-button color="primary" i18n>Confirm</button>
          </div>

        </div>
      </form>

    </div>

    <ng-template #disable>
    <!-- disable Fund PIN -->
    <div class="col-12 px-0">
      <form novalidate class="w-100" (ngSubmit)="onSubmit()" [formGroup]="disableData">
        <div class="row clearfix m-2">
          <div class="col-12">
          <h4 class="text-center" i18n>Disable Fund PIN</h4>
          </div>
          <div class="col-12">
          <p i18n>To disable your Fund PIN, enter your Account Password and Fund PIN below.</p>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100 mb-3">
              <input matInput type="password" autocomplete="off" i18n-placeholder placeholder="Account Password" required
                formControlName="accountPassword" value="">
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100 mb-3">
              <input matInput type="password" autocomplete="off" i18n-placeholder placeholder="Fund PIN" required
                formControlName="fundsPin"
                value="">
            </mat-form-field>
          </div>

          <div class="col-12 py-3">
            <p class="disable-text" i18n>
              Forgot your Fund PIN? <a class="clickable" href="" (click)="defaultDialog = false">Click here</a> to request disabling of your Fund PIN or
              <a target="_blank" href="{{getSupportUrl()}}">contact support here</a>.
            </p>
          </div>

          <!-- Start of Alert -->
          <div class="mt-2 col-12" *ngIf="submitFailed">
            <div class="alert alert-danger">
              <p class="mb-0">{{resultMessage|i18n}}</p>
            </div>
          </div>
          <!-- End of Alert -->

          <div class="col-12 col-lg-6 mb-2">
            <button class="w-100" mat-raised-button color="accent" (click)="dialogRef.close()" i18n>Cancel</button>
          </div>

          <div class="col-12 col-lg-6 text-right mb-2">
            <button class="w-100" mat-raised-button color="primary" i18n>Confirm</button>
          </div>
        </div>
      </form>
    </div>
    </ng-template>
  </div>

  <ng-template #disableFallback>
    <!-- disable Fund PIN if forgotten-->

    <div *ngIf="disableFallbackUnconfirmed; else emailSent">

      <div class="col-12 px-0">
        <form novalidate class="w-100" (ngSubmit)="onDisableFallback()" [formGroup]="disableData">
          <div class="row clearfix m-2">
            <div class="col-12">
              <h4 class="text-center mb-0" i18n>Disable Fund PIN</h4>
            </div>
            <div class="col-12 pb-3 grey-text">
              <div class="my-3" i18n>Are you sure that you would like to disable Fund PIN? Disabling your Fund PIN could compromise
                your
                account's security.</div>
              <div class="my-3" i18n>Once you confirm this action from the button below, an email with instructions on
                how to proceed
                will be sent to you.</div>
              <div class="my-3 font-weight-bold" i18n>Please note: Disabling your Fund PIN will result in the suspension
                of all
                withdrawals from your
                account for 24 hours</div>
            </div>

            <!-- Start of Alert -->
            <div class="mt-2 col-12" *ngIf="submitFailed">
              <div class="alert alert-danger">
                <p class="mb-0">{{resultMessage|i18n}}</p>
              </div>
            </div>
            <!-- End of Alert -->
            <!-- Captcha -->
            <div class="col-12 col-lg-9 col-xl-8 mx-auto px-0 mt-2 mb-4 heading">
              <div class="recaptcha-overlay"></div>
              <re-captcha id="recaptcha" theme="{{darkTheme ? 'dark' : 'light'}}" (resolved)="resolved($event)" required></re-captcha>
              <mat-error *ngIf="missingRecaptcha">
                <small i18n>Recaptcha is a required field</small>
              </mat-error>
            </div>

            <div class="col-12 col-lg-6 mb-2">
              <button class="w-100" mat-raised-button color="accent" (click)="dialogRef.close()" i18n>Cancel</button>
            </div>

            <div class="col-12 col-lg-6 text-right mb-2">
              <button class="w-100" mat-raised-button color="primary" i18n>Confirm</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <ng-template #emailSent>
      <div class="col-12 px-0">
        <div class="row clearfix m-2">
          <div class="col-12">
            <h4 class="text-center mb-0" i18n>Disable Fund PIN</h4>
          </div>
          <div class="col-12 my-3 grey-text" i18n>
              An email with instructions on how to disable your Fund PIN been sent to your registered email
              address.
              Please check your email and follow the instructions.
          </div>

          <div class="col-12 col-lg-6 mb-2 mt-3 mx-auto">
            <button class="w-100" mat-raised-button color="primary" (click)="dialogRef.close()" i18n>Continue</button>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
