<div class="container-fluid px-0">
  <div class="row back ml-1 mr-1">

    <div class="col-12 col-lg-3 col-xlg-2 mt-1 p-0 mat-elevation-z2 order-0 order-lg-0">
      <markets-sidebar #sidebar></markets-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-xlg-10 order-1 order-lg-1">
      <div class="row mobile-margin-unavailable">
        <div class="col-12 mb-2 ">
          <div class="row mat-elevation-z2 mt-1 balance-table p-0 mtmobile">
            <div class="col-12" >
              <mat-icon color="primary" class="big-icon">error</mat-icon>
              <div class="float-left-top">
              <h3 i18n>MARKET NOT AVAILABLE</h3>
                <h6 i18n>The market you are trying to reach is not available.  This could be because:</h6>
                <ul>
                  <li i18n>The market has been disabled</li>
                  <li i18n>The market does not exist</li>
                </ul>
                <h6 i18n>A full list of markets available to you can be found and loaded from the markets list to the left on desktop devices or top on mobile devices.</h6>
                <h6 i18n>If you have any questions on how to reach a market you are looking for, consult our knowledge base or contact our support team <a href="{{ getSupportUrl() }}">here</a></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
