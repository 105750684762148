import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ClipboardModule } from 'ngx-clipboard';
import { QRCodeModule } from 'angularx-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { SidebarComponent } from '../markets/sidebar/sidebar.component';
import { SideMarketComponent } from '../markets/sidebar/side-market/side-market.component';
import { StatisticsComponent } from '../markets/sidebar/statistics/statistics.component';

import { MenuStatsComponent } from './header/menu-stats/menu-stats.component';

import { ChatComponent } from '../chat/chat.component';
import { ContactSupportComponent } from '../contact-support/contact-support.component';

import { EmojiPipe } from '../core//pipe/emoji.pipe';
import { RelativeTimePipe } from '../core/pipe/relativeTime.pipe';
import { I18nPipe } from '../core/pipe/i18n.pipe';
import { HoverPopUpDirective } from '../core/directives/hover-pop-up.directive';
import { DecimalNumberPipe } from '../core/pipe/decimalNumber.pipe';
import { ResultDialogComponent } from './result-dialog/result-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SnackbarMessageComponent } from './snackbar-message/snackbar-message';
import { QuickWizardComponent } from '../markets/order-types/quick-wizard/quick-wizard.component';
import { QuickOrderDialogComponent } from '../markets/order-types/quick-order-dialog/quick-order-dialog.component';
import { WarningBoxComponent } from './warning-box/warning-box.component';
import { CurrencyManagementComponent } from '../balances/currency-management/currency.management.component';
import { TransactionHistoryComponent } from '../balances/transaction-history/transaction-history.component';
import { TradeHistoryComponent } from '../balances/trade-history/trade-history.component';
import { DepositComponent } from '../balances/currency-management/deposit/deposit.component';
import { WithdrawalComponent } from '../balances/currency-management/withdrawal/withdrawal.component';
import {
  ConfirmWithdrawalComponent
} from '../balances/currency-management/withdrawal/confirm-withdrawal/confirm-withdrawal.component';
import { EarnPortalComponent } from '../balances/currency-management/earn-portal/earn-portal.component';
import {
  ProductConfirmationComponent
} from '../balances/currency-management/earn-portal/product-confirmation/product-confirmation.component';
import { TradeSummaryComponent } from '../balances/currency-management/trade-summary-component/trade-summary.component';
import { LinkAccountDialogComponent } from '../balances/link-account-dialog/link-account-dialog.component';
import { MarketCoinsComponent } from '../home/market-coins/market-coins.component';
import { MarketCardComponent } from '../home/market-card/market-card.component';
import { ChartsModule } from 'ng2-charts';
import { BalanceViewerComponent } from './balance-viewer/balance-viewer.component';
import { ExplorerUrlPipe } from '../core/pipe/explorerUrl.pipe';
import { CoinAnalyticsComponent } from '../markets/coin-analytics/coin-analytics.component';
import { TopStatsComponent } from '../markets/chart/top-stats/top-stats.component';
import { CommentCardComponent } from './comment-card/comment-card.component';
import { DisableTwofaFallbackComponent } from './disable-twofa-fallback/disable-twofa-fallback.component';
import { TokenInputComponent } from './token-input/token-input.component';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { AuthGuard, NoAuthGuard } from '../core/services/auth-guard.service';
import { environment } from 'src/environments/environment';
import { DomainDialogComponent } from './domain-dialog/domain-dialog.component';
import { LivenessCheckComponent } from './liveness-check/liveness-check.component';
import { BalanceCardsComponent } from '../balances/balance-cards/balance-cards.component';
import { BalanceCardComponent } from '../balances/balance-cards/balance-card/balance-card.component';

@NgModule({
  imports: [
    CommonModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatSelectModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatInputModule,
    MatPaginatorModule,
    MatCardModule,
    MatSortModule,
    MatExpansionModule,
    MatGridListModule,
    ChartsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      timeOut: 10000,
      preventDuplicates: true,
      maxOpened: 5,
      autoDismiss: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      enableHtml: true
    }), // ToastrModule added,
    QRCodeModule,
    ZXingScannerModule
  ],
  declarations: [
    EmojiPipe,
    CommentCardComponent,
    RelativeTimePipe,
    I18nPipe,
    ExplorerUrlPipe,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    SideMarketComponent,
    StatisticsComponent,
    ChatComponent,
    ContactSupportComponent,
    HoverPopUpDirective,
    DecimalNumberPipe,
    ResultDialogComponent,
    ConfirmDialogComponent,
    QuickOrderDialogComponent,
    QuickWizardComponent,
    SnackbarMessageComponent,
    WarningBoxComponent,
    MenuStatsComponent,
    CurrencyManagementComponent,
    TransactionHistoryComponent,
    TradeHistoryComponent,
    DepositComponent,
    WithdrawalComponent,
    EarnPortalComponent,
    BalanceCardsComponent,
    BalanceCardComponent,
    ProductConfirmationComponent,
    ConfirmWithdrawalComponent,
    TradeSummaryComponent,
    LinkAccountDialogComponent,
    MarketCoinsComponent,
    MarketCardComponent,
    BalanceViewerComponent,
    CoinAnalyticsComponent,
    TopStatsComponent,
    TokenInputComponent,
    DisableTwofaFallbackComponent,
    DomainDialogComponent,
    LivenessCheckComponent
  ],
  entryComponents: [
    MatIcon,
    ResultDialogComponent,
    ConfirmDialogComponent,
    SnackbarMessageComponent,
    QuickWizardComponent,
    QuickOrderDialogComponent,
    CurrencyManagementComponent,
    LivenessCheckComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    I18nPipe,
    ExplorerUrlPipe,
    EmojiPipe,
    RelativeTimePipe,
    SidebarComponent,
    SideMarketComponent,
    StatisticsComponent,
    ChatComponent,
    ContactSupportComponent,
    CoinAnalyticsComponent,
    HoverPopUpDirective,
    DecimalNumberPipe,
    MenuStatsComponent,
    QuickOrderDialogComponent,
    QuickWizardComponent,
    WarningBoxComponent,
    DecimalNumberPipe,
    MarketCoinsComponent,
    MarketCardComponent,
    BalanceViewerComponent,
    MatTooltipModule,
    MatProgressSpinnerModule,
    TopStatsComponent,
    MatSlideToggleModule,
    CommentCardComponent,
    EarnPortalComponent,
    BalanceCardsComponent,
    BalanceCardComponent,
    TokenInputComponent,
    DisableTwofaFallbackComponent,
    ReactiveFormsModule,
    RecaptchaModule,
    LivenessCheckComponent
  ],
  providers: [
      AuthGuard,
      NoAuthGuard,
      {
        provide: RECAPTCHA_SETTINGS,
        useValue: { siteKey: environment.config.RECAPTCHA_SITE_KEY } as RecaptchaSettings,
      }
    ],
})
export class SharedModule { }
