import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Observable , BehaviorSubject, ReplaySubject} from 'rxjs';
import * as Big from 'big-js';

import { ApiService } from '../core/api/api.service';
import { StoreService } from '../core/services/store.service';
import { Coin } from '../core/interfaces/balances';
import { UtilHelper } from '../core/helpers/util-helper';
import { SessionStorageService } from '../core/services/session-storage.service';
import { FormGroup, Validators } from '@angular/forms';


export interface IMarket {
  exchangeBalance?: Coin;
  icon: string;
  coinBalance?: Coin;
  takerFee?: string;
  change?: string;
  changeColor?: string;
  coinCode: string;
  coinId?: string;
  coinName?: string;
  coinDecimals?: number;
  ieoCoin?: string;
  exchangeCode: string;
  exchangeId: string;
  exchangeName?: string;
  exchangeDecimals?: number;
  ieoExchange?: string;
  high?: string;
  id: string;
  lastPrice?: string;
  low?: string;
  marketPair: string;
  makerFee?: string;
  spreadPrice?: number;
  topAsk?: string;
  topBid?: string;
  volume?: string;
  volumeAmount?: string;
  yesterdayPrice?: string;
  btcEstimate?: string;
  zarEstimate?: string;
  marketVolumeInBTC?: number;
}

export interface IMarketStats {
  total: any; // Cannot define this because it can change based on needs. {btcVolume: string, trades: integer}
  volume: Array<string>; // Cannot be defined because it is coin codes with volume. [coinCode] = volume: string
}

export interface IMarketResponse {
  data: IMarket[];
  stats: IMarketStats;
}

@Injectable({
  providedIn: 'root'
})

export class MarketsService implements OnDestroy {

  private daStatus: BehaviorSubject<number>;
  public fixMarketValueDecimals: Function = UtilHelper.bigValueToFixed;

  readonly supportedCoinsITB: string[] = [ 'BTC', 'ETH', 'USDT', 'LTC', 'DOGE', 'BAT', 'ZRX', 'SNT', 'BNT', 'CVC', 'STMX', 'TIX'];

  public activeMarket: IMarket = {
    exchangeBalance: {
      id: -1,
      coin_type: '',
      code:  '',
      name:  '',
      icon:  '',
      sort_order:  0,
      balance_available: 0,
      balance_pending_deposit: 0,
      balance_pending_withdraw: 0,
      balance_held: 0,
      estimated_value: 0,
      account_available: false,
      balance_total: 0,
      btc_estimated_value: 0,
      change: '',
    },
    coinBalance: {
      id: -1,
      coin_type: '',
      icon:  '',
      code:  '',
      name:  '',
      sort_order:  0,
      balance_available: 0,
      balance_pending_deposit: 0,
      balance_pending_withdraw: 0,
      balance_held: 0,
      estimated_value: 0,
      account_available: false,
      balance_total: 0,
      btc_estimated_value: 0,
      change: '',
    },
    takerFee: '0.25000000',
    coinCode: '',
    coinName: '',
    coinId: '-1',
    ieoCoin: '',
    icon:  '',
    exchangeCode: '',
    exchangeName: '',
    exchangeId: '-1',
    ieoExchange: '',
    id: '-1',
    marketPair: '',
    makerFee: '0.25000000'
  };

  public marketsLoaded: boolean = false;
  public marketUpdateSubject: ReplaySubject<IMarket[]> = new ReplaySubject(1);
  public activeMarketSubject: BehaviorSubject<IMarket> = new BehaviorSubject(this.activeMarket);
  private marketsReceived: any;
  private markets: IMarket[] = [];
  private marketsStats: IMarketStats = { total: null, volume: null };

  private balances: any[] = [];
  public marketIdChange: EventEmitter<any> = new EventEmitter();

  private subs: any[] = [];

  prevWindowSize: any = 992;
  expanded: boolean = true;
  expandable: boolean = false;

  balancesInit: boolean = false;

  takerFee: number = -1;
  makerFee: number = -1;

  subAllMarkets: boolean = true;

  constructor(
    private apiService: ApiService,
    private session: SessionStorageService,
    public store: StoreService) {
    this.initMarket();
    this.daStatus = new BehaviorSubject<number>(-1);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public getDaStatus(): Observable<number> {
    return this.daStatus.asObservable();
  }

  public setDaStatus(val: number): void {
    this.daStatus.next(val);
  }

  private initBalances() {
    if (!this.balancesInit && this.session.get('LOGGED_IN') === 'logged_in') {
      this.subs.push(this.store.subscribe('balances').subscribe(response => {
        if (!response.refresh) {
          if (!this.balancesInit) {
            // Initialize user fees...
            this.subs.push(this.getUserFee().subscribe(resp => {
              if (resp.response !== 'failure') {
                this.makerFee = +(resp.data.maker_fee / 100).toFixed(8);
                this.takerFee = +(resp.data.taker_fee / 100).toFixed(8);
                this.balancesInit = true;
              }
            }));
          }
          this.balances = JSON.parse(JSON.stringify(response.data)); // Don't mutate their data
          this.addBalances();
        }
      }));
    }
  }

  private initMarket() {
    this.subs.push(this.store.subscribe('marketData').subscribe((response) => {
      if (!response.refresh) {
        this.setMarket(response);
      }
    }));
  }

  getMarketByID(marketId: string) {
    const loadMarket = this.getMarketPair(marketId);
    return loadMarket;
  }

  setActiveMarket(newMarket: IMarket) {
    if (this.markets.indexOf(newMarket) === -1 || newMarket.id === this.activeMarket.id) {
      // console.warn('WARNING: The active market is not in the current list of markets.');
      // console.warn('Active market received: ', newMarket);
      return;
    }
    this.activeMarket = newMarket;
    this.applyDecimals();

    this.activeMarketSubject.next(this.activeMarket);
    this.marketIdChange.emit(newMarket.id);
  }

  setMarket(response: any) {
    if (response != null) {
      this.marketsReceived = response;
      this.transformMarkets();
      this.initBalances();
    }
  }

  getMarketPair(id: any) {
    for (let i = 0; i < this.markets.length; i++) {
      if (this.markets[i].id === id) {
        return this.markets[i];
      }
    }
  }

  getMarketbyCoinPair(coin1: string, coin2: string, market?: string) {
    let marketParamFound: IMarket;
    for (let i = 0; i < this.markets.length; i++) {
      if (this.markets[i].coinCode === coin1 && this.markets[i].exchangeCode === coin2) {
        return this.markets[i];
      } else if (this.markets[i].id === market) {
        marketParamFound = this.markets[i];
      }
    }
    if (!!marketParamFound) { return marketParamFound; }
    if (this.activeMarket && this.activeMarket.id !== '-1') {
      return this.activeMarket;
    } else
    if (this.markets.length > 0) {
      return this.markets[0];
    } else {
      return undefined;
    }
  }

  getMarketLength(): number {
    return this.markets.length;
  }

  transformMarkets() { // Combine information and change names
    let marketId: string;
    let newMarket: IMarket;
    this.markets = [];

    // If received market is a JSON object instead of an array
    if (this.marketsReceived.data != null) {
      this.marketsReceived = this.marketsReceived.data; // then work with the data received
    }

    this.marketsStats.total = this.marketsReceived.total;
    this.marketsStats.volume = this.marketsReceived.volume;

    // Combine each marketData with its matching marketPair data, temporary code
    this.marketsReceived.forEach(baseMarket => {
      if (baseMarket.market_id) {
        marketId = baseMarket.market_id;
      } else {
        marketId = baseMarket.id;
      }

      // Setup the new names. When API updated, stop using chosenpairs.
      // Also if API names updated, this should be removed
      newMarket = {
        icon: baseMarket['icon'],
        change: baseMarket['change'],
        changeColor: baseMarket['changeColor'],
        coinCode: baseMarket['coin_code'],
        coinId: baseMarket['coin_id'],
        coinName: baseMarket['coin_name'],
        coinDecimals: baseMarket['coin_decimals'],
        ieoCoin: baseMarket['ieo_coin'],
        exchangeCode: baseMarket['exchange_code'],
        exchangeId: baseMarket['exchange_id'],
        exchangeName: baseMarket['exchange_name'],
        exchangeDecimals: baseMarket['exchange_decimals'],
        ieoExchange: baseMarket['ieo_exchange'],
        high: baseMarket['high'],
        id: marketId,
        lastPrice: baseMarket['last_price'],
        low: baseMarket['low'],
        marketPair: baseMarket['pair'],
        makerFee: baseMarket['maker_fee'],
        takerFee: baseMarket['taker_fee'],
        topAsk: baseMarket['top_ask'],
        topBid: baseMarket['top_bid'],
        volume: baseMarket['volume'],
        volumeAmount: baseMarket['volume_amount'],
        yesterdayPrice: baseMarket['yesterday_price'],
        btcEstimate: baseMarket['btcEstimate'],
      };
      if (baseMarket['spread_price']) {
        newMarket.spreadPrice = baseMarket['spread_price'];
      }

      if (this.subAllMarkets) {
        this.store.connectMarket(`marketUpdates-${newMarket.id}`);
      }

      if (this.activeMarket.id === marketId) {
        this.activeMarket = newMarket;
        this.applyDecimals();
      }

      this.addBalance(newMarket);

      this.markets.push(newMarket);
    });
    this.marketsLoaded = true;
    this.subAllMarkets = false;

    this.markets = this.calculateVolumeInBTC(this.markets);
    this.marketUpdateSubject.next(this.markets);
  }

  private calculateVolumeInBTC(markets: IMarket[]): IMarket[] {
    if (markets) {
      const marketsWithBTC = markets.filter(market => market.coinCode === 'BTC');
      const convertVolumeToBTC = (exchangeCode: string, amount: number) => {
        const coinMarket = marketsWithBTC.find(
          marketWithBTC => marketWithBTC.exchangeCode === exchangeCode
        );

        return (!!coinMarket && coinMarket.spreadPrice > 0 ? (amount / coinMarket.spreadPrice) : 0);
      };

      for (const [index, market] of markets.entries()) {
        markets[index].marketVolumeInBTC = convertVolumeToBTC(market.exchangeCode, Number(market.volume));
      }
    }

    return markets;
  }

  applyDecimals() {
    this.activeMarket.exchangeDecimals  = this.activeMarket.exchangeDecimals ? this.activeMarket.exchangeDecimals : 8;
    this.activeMarket.coinDecimals  = this.activeMarket.coinDecimals ? this.activeMarket.coinDecimals : 8;

    this.activeMarket.lastPrice = Number(this.activeMarket.lastPrice).toFixed(this.activeMarket.exchangeDecimals);
    this.activeMarket.high = Number(this.activeMarket.high).toFixed(this.activeMarket.exchangeDecimals);
    this.activeMarket.low = Number(this.activeMarket.low).toFixed(this.activeMarket.exchangeDecimals);

    if (this.activeMarket.coinBalance) {
      this.activeMarket.coinBalance.balance_available = +(Number(this.activeMarket.coinBalance.balance_available)
        .toFixed(this.activeMarket.coinDecimals));
    }

    if (this.activeMarket.exchangeBalance) {
      this.activeMarket.exchangeBalance.balance_available = +(
        Number(this.activeMarket.exchangeBalance.balance_available)
          .toFixed(this.activeMarket.exchangeDecimals));
    }
  }

  addBalance(market: IMarket) {
    if (!this.balancesInit) {
      market.exchangeBalance = {
        id: -1,
        coin_type: '',
        code:  '',
        name:  '',
        sort_order:  0,
        balance_available: 0,
        balance_pending_deposit: 0,
        icon: '',
        balance_pending_withdraw: 0,
        balance_held: 0,
        estimated_value: 0,
        account_available: false,
        balance_total: 0,
        btc_estimated_value: 0,
        change: '',
      };
      market.coinBalance = {
        id: -1,
        coin_type: '',
        code:  '',
        name:  '',
        sort_order:  0,
        icon: '',
        balance_available: 0,
        balance_pending_deposit: 0,
        balance_pending_withdraw: 0,
        balance_held: 0,
        estimated_value: 0,
        account_available: false,
        balance_total: 0,
        btc_estimated_value: 0,
        change: '',
      };
      return;
    }
    market.exchangeBalance = this.balances.filter(responseBalance => responseBalance.code === market.exchangeCode)[0];
    market.coinBalance = this.balances.filter(responseBalance => responseBalance.code === market.coinCode)[0];
  }

  private addBalances() {
    for (let i = 0; i < this.markets.length; i++) {
      this.addBalance(this.markets[i]);
    }
  }

  addFavoritePairs(data: any): Observable<any> {
    return this.apiService.call<any>('addFavoritePairs', data);
  }

  cancelOrder(request: any): Observable<any> {
    return this.apiService.call<any>('cancelOrder', request);
  }

  checkUsernameAvailable(data: any) {
    return this.apiService.call<any>('checkUsernameAvailable', data);
  }

  createOrder(request: any): Observable<any> {
    return this.apiService.call<any>('createOrder', request);
  }

  createScalingOrders(request: any): Observable<any> {
    return this.apiService.call<any>('createScalingOrders', request);
  }

  createDAOrder(request: any): Observable<any> {
    return this.apiService.call<any>('createDAOrder', request);
  }

  fetchDAOrders(request: any): Observable<any> {
    return this.apiService.call<any>('getDaOrders', request);
  }

  getMarketFromExchangeCode(exchangeCode: string): IMarket {
    const filteredMarkets = this.markets.filter((market) => market.exchangeCode === exchangeCode);
    if (filteredMarkets.length > 0) {
      return filteredMarkets[0];
    } else {
    return null;
    }
  }

  getMyActiveOrders(request: any): Observable<any> {
    return this.apiService.call<any>(`getMyActiveOrders${this.setGetParams(request)}`);
  }

  getActiveOrders(request: any): Observable<any> {
    return this.apiService.call<any>(`getActiveOrders${this.setGetParams(request)}`);
  }

  getBalance(coin: string) {
    return this.apiService.call<any>(`getBalance?coin=${coin}`);
  }

  getBuyOrders(request: any): Observable<any> {
    return this.apiService.call<any>(`getOrderDepth${this.setGetParams(request)}`);
  }

  getMarketData(): Observable<IMarket[]> {
    return new Observable((observer) => {
      observer.next(this.markets);
      observer.complete();
    });
  }

  getMarketPairsWithStats(): Observable<IMarketResponse> {
    return new Observable((observer) => {
      observer.next({ data: this.markets, stats: this.marketsStats});
      observer.complete();
    });
  }

  getMyTradeHistory(request: any): Observable<any> {
    return this.apiService.call<any>(`getMyTradeHistory${this.setGetParams(request)}`);
  }

  getOrderDepth(request: any) {
    return this.apiService.call<any>(`getOrderDepth${this.setGetParams(request)}`);
  }

  getOrderHistory(request: any): Observable<any> {
    return this.apiService.call<any>(`getMyOrderHistory${this.setGetParams(request)}`);
  }

  getSellOrders(request: any): Observable<any> {
    return this.apiService.call<any>(`getOrderDepth${this.setGetParams(request)}`);
  }

  getTradeHistory(request: any): Observable<any> {
    return this.apiService.call<any>(`getTradeHistory${this.setGetParams(request)}`);
  }

  reportMessage(data: any) {
    return this.apiService.call<any>('reportChat', data);
  }

  sendChat(data: any) {
    return this.apiService.call<any>('createChat', data);
  }

  setGetParams(data: any) {
    let prams = '?';
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
      if (i > 0) {
        prams += '&';
      }
      prams += `${entries[i][0]}=${entries[i][1]}`;
    }
    return prams;
  }

  coinPairExist(coin1: string, coin2: string) {
    for (let i = 0; i < this.markets.length; i++) {
      if (this.markets[i].coinCode === coin1 && this.markets[i].exchangeCode === coin2) {
        return true;
      }
    }
    return false;
  }

  setExpandable() {
    const currWindowSize = window.innerWidth;
    this.expanded = currWindowSize >= 992;
    if (this.prevWindowSize > 992 && currWindowSize < 992) {
      this.expanded = false;
    }
    this.prevWindowSize = currWindowSize;
    this.expandable = currWindowSize < 992;
  }

  getUserFee(): Observable<any> {
    return this.apiService.call<any>('getUserFee');
  }

  getMinAmount(prevMinAmount: string, maxAmount: string | boolean,
    orderForm: FormGroup | null, price: number, takerFee: number, market?: IMarket) {
    const marketToUse = market ? market : this.activeMarket;
    const isZarMarket: boolean = (marketToUse.exchangeCode === 'ZAR');
    const decimals: number = (marketToUse.exchangeCode === 'ZAR' ?
      marketToUse.coinDecimals : marketToUse.exchangeDecimals);
    let minAmount = new Big(0).toFixed(8);
    if (takerFee > 0 && (price > 0 || isZarMarket)) {
      const minFee = new Big(1).div(Math.pow(10, decimals));

      minAmount = minFee.div(takerFee);
      minAmount = isZarMarket ? minAmount.toFixed(8) : minAmount.div(price).toFixed(8);

      if (!!orderForm && orderForm !== null) {
        orderForm.controls.amount.setValidators(
          maxAmount === false ? [Validators.min(minAmount)] : [Validators.min(minAmount), Validators.max(+maxAmount)]);
        orderForm.controls.amount.updateValueAndValidity();
      }
    } else {
      minAmount = prevMinAmount;
    }

    return minAmount;
  }
}
