<mat-table matSort [dataSource]="history_data" (matSortChange)="loadTableData()" class="w-100">

  <ng-container matColumnDef="market">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Market</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Market" > {{element.market}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Action</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Action"> {{displayTypeNiceName(element)}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Type</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Type"> {{element.action}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Amount</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Amount"> {{element.amount | decimalNumber: element.coin_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="price">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Price</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Price"> {{element.price | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Total</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Total"> {{element.total | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="fee">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fee</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Fee"> {{setFee(element) | decimalNumber: (element.exchange === 'ZAR' ? element.coin_decimals: element.exchange_decimals)}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="net_total">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Net Total</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Net Total"> {{setNetTotal(element) | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Status</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Status"> {{element.status}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="time">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Placed</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Placed"> {{element.time_formatted}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="options">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element" align="right">
      <mat-icon color="primary" (click)="cancelOrder(element)">close</mat-icon>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="history_definition; sticky:true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: history_definition;"></mat-row>

</mat-table>

<div class="w-100 text-center" *ngIf="!history_data || history_data.filteredData.length === 0" i18n><br>No records found</div>
<mat-paginator i18n-itemsPerPageLabel itemsPerPageLabel="Items per page:" [length]="total_records" [pageSize]="list_number"
[pageSizeOptions]="page_sizes" (page)="paging_change($event)" [ngClass]="[page_loading ? 'disable-control' : '']">
</mat-paginator>
