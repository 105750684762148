import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-token-input',
  templateUrl: './token-input.component.html',
  styleUrls: ['./token-input.component.scss']
})
export class TokenInputComponent implements OnInit {
  @Input() formControlInput: AbstractControl;

  @Output() changeOrPaste: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('tokenInput') tokenInput: ElementRef;

  ngOnInit() {
    if (this.formControlInput) {
      this.formControlInput.setValidators([
        Validators.pattern('([0-9]{6,6})$'),
        Validators.required,
        Validators.maxLength(6)
      ]);
    }
  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    input.value = input.value.replace(/[^0-9]/g, '');
  }

  valueChange(event: Event) {
    let value;
    if (event instanceof ClipboardEvent) {
      value = event.clipboardData.getData('text').trim();
      this.formControlInput.setValue(value);
    } else {
      value = (event.target as HTMLInputElement).value;
    }
    if (value) {
      this.formControlInput.markAsTouched();

      if (this.formControlInput.valid) {
        // replace unicode U+2000 (white space) and regular spaces
        this.changeOrPaste.emit(value.replace(/ | /g, ''));
      }
    }
  }

  focus() {
    if (!!this.tokenInput) {
      this.tokenInput.nativeElement.focus();
    }
  }

}
