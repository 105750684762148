<div [ngClass]="darkTheme ? 'dark-box':'light-box'" class="d-flex justify-content-start justify-content-lg-end">
  <mat-icon class="clickable px-1 col-auto py-0 d-flex orange t-24"
    data-toggle="dropdown" data-boundary="window" data-display="static" aria-haspopup="true" aria-expanded="false">
    arrow_drop_down_circle
  </mat-icon>

  <div class="dropdown-menu balance-viewer-dropdown">
    <div class="row space-around m-t-5">
      <span class="ml-2 display-amount-size text-bold" il8n>Total Balance:</span>
      <span class="ml-2 mr-2 display-amount-size text-normal">{{ code === 'ZAR' ? (available | decimalNumber: 2) : (available | decimalNumber: 8)}}</span>
    </div>
    <div class="row space-around">
      <span class="ml-2 display-amount-size text-bold" il8n>Held For Orders:</span>
      <span class="ml-2 mr-2 display-amount-size text-normal">{{ code === 'ZAR' ? (held | decimalNumber: 2) : (held | decimalNumber: 8)}}</span>
    </div>
    <div class="row space-around">
      <span class="ml-2 display-amount-size text-bold" il8n>Earn Balance:</span>
      <span class="ml-2 mr-2 display-amount-size text-normal">{{ code === 'ZAR' ? (earn | decimalNumber: 2) : (earn | decimalNumber: 8)}}</span>
    </div>
    <div class="row space-evenly m-t-20" *ngIf="showButtons">
      <button mat-raised-button class="mat-raised-button m-t-5" color="primary" (click)="openCurrencyManagement(0)" i18n>DEPOSIT</button>
      <button mat-raised-button class="mat-raised-button m-t-5" color="primary" (click)="openCurrencyManagement(1)" i18n>WITHDRAW</button>
    </div>
  </div>
</div>
