// Angular
import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild } from '@angular/core';
// Components
import { SnackbarMessageComponent } from 'src/app/shared/snackbar-message/snackbar-message';
// Libraries
import { merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map,
  skipWhile, switchMap, take, takeUntil, tap, mergeMap
} from 'rxjs/operators';
// Services
import { AddressBookService } from '../address-book.service';
import { BalancesService } from 'src/app/balances/balances.service';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
// Store
import { AddressBookStore, GetCryptoAddressBook, GetUserUnsavedCryptoAddresses,
  RemoveCryptoAddressBookItem, UpdateCryptoAddressBookItem
} from '../state/address-book.state';
import { Store } from '@ngxs/store';
// Interfaces
import { ModifyAddressBook, AddressBook, AddressStatus, UnsavedAddresses
} from 'src/app/core/interfaces/addressbook';
import { environment } from 'src/environments/environment';
import { FundPinStatus } from 'src/app/core/interfaces/fund-pin';
import { CoinNetwork } from 'src/app/core/interfaces/coin-network';

export interface AddressBookSettings {
  code: string;
  address?: string;
  cryptoNoteAddress: {
    address: string;
    label?: string;
    validation?: string;
    paymentId?: string;
    paymentIdNotRequired?: boolean;
  };
  is2faenabled?: boolean;
  isFundpinEnabled?: string;
  coinNetworks?: CoinNetwork[];
  selectedCoinNetwork: string;
  selectedNetworkName: string;
}

export interface DialogReturnData {
  name: string;
  paymentId?: string;
  coin_network?: string;
}

export enum PaymentIdLabel {
  Internal = 'INTERNAL',
  None = 'NONE'
}

enum AddressView {
  Normal = 0,
  Add = 1,
  Edit = 2,
  Confirmation = 3,
  ConfirmationTrusted = 4,
  ConfirmRemove = 5,
  NewAddressIdentified = 6
}

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit, OnDestroy, AfterViewInit {
  private _timeout$: Subject<void> = new Subject();

  // Allows for the paginators, sorters to be destroyed then rebinded after being created again
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.savedAddresses.paginator = paginator;
    }
  }

  @ViewChild('unsavedPaginatior') set unSavedPaginator(paginator: MatPaginator) {
    if (paginator) {
      this.unsavedAddresses.paginator = paginator;
    }
  }

  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (sort) {
      this.savedAddresses.sort = sort;
    }
  }

  @ViewChild('unsavedSort') set unSavedSort(sort: MatSort) {
    if (sort) {
      this.unsavedAddresses.sort = sort;
    }
  }

  addressForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    address: new FormControl('', [Validators.required,
      Validators.pattern('^[a-zA-HJ-NP-Z0-9@\+\-\.\:]+$')]
    ),
    coinNetwork: new FormControl('0'),
    paymentId: new FormControl(''),
    paymentIdRequired: new FormControl(''),
    trusted: new FormControl(''),
    password: new FormControl(''),
    tfa: new FormControl(''),
    fundPin: new FormControl('')
  });

  setting: AddressBookSettings;
  // table definitions
  addressDisplayedColumns: string[] = ['name', 'address', 'trusted', 'actions'];
  savedAddresses: MatTableDataSource<AddressBook> = new MatTableDataSource([]);
  unsavedDisplayedColumns: string[] = ['lastWithdrawal', 'address', 'actions'];
  unsavedAddresses: MatTableDataSource<UnsavedAddresses> = new MatTableDataSource([]);

  viewDisplay: number = AddressView.Normal;
  // Used to reference enum on html
  AddressView: typeof AddressView = AddressView;
  AddressStatus: typeof AddressStatus = AddressStatus;
  FundPinStatus: typeof FundPinStatus = FundPinStatus;

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  internalAddressIdentified: boolean = false;

  constructor(
    private _balancesService: BalancesService,
    @Inject(MAT_DIALOG_DATA) private _data: AddressBookSettings,
    private _dialogRef: MatDialogRef<AddressBookComponent>,
    private _snackbar: MatSnackBar,
    private _addressStore: Store,
    private _addressBookService: AddressBookService,
    private _translateService: I18nTranslationService,
  ) {
    if (_data) {
      this.setting = _data;
      // Add payment id column to coins that require a payment id
      // Order of array items determine order of display on the table
      if (!!this.setting.cryptoNoteAddress?.address) {
        this.addressDisplayedColumns.splice(2, 0, 'paymentId');
        this.unsavedDisplayedColumns.splice(2, 0, 'paymentId');
      }
      if (this.setting.coinNetworks?.length > 0) {
        this.addressDisplayedColumns.splice(2, 0, 'network_name');
        this.unsavedDisplayedColumns.splice(2, 0, 'network_name');
      }
    }
  }

  ngOnInit(): void {
    // Display add address book form
    if (this.setting.address) {
      this.form.address.setValue(this.setting.address);
      this.form.address.disable();

      if (!!this.setting.cryptoNoteAddress?.address) {
        this.form.paymentId.setValue(this.setting.cryptoNoteAddress.paymentId);
        this.form.paymentId.disable();
      }

      if (typeof this.setting.cryptoNoteAddress.paymentIdNotRequired === 'boolean') {
        this.form.paymentIdRequired.setValue(this.setting.cryptoNoteAddress.paymentIdNotRequired);
        this.form.paymentIdRequired.disable();
      }

      this.viewDisplay = AddressView.Add;
    }

    if (this.setting.coinNetworks?.length > 0) {
      if (!this.setting.selectedCoinNetwork) {
        this.setting.selectedCoinNetwork = this.setting.coinNetworks[0].id;
      }
      this.addressForm.controls.coinNetwork.setValue(this.setting.selectedCoinNetwork);
    }

    // Setup payment id validators, function checks if we need to set validators or not
    this.setPaymentIdValidators();

    // Subscriptions to observables
    merge(
      // Obtain address book
      this._addressStore.dispatch(new GetCryptoAddressBook(this.setting.code)).pipe(
        mergeMap(() => this._addressStore.select(AddressBookStore.GetCryptoAddressBook(this.setting.code))),
        distinctUntilChanged(),
        tap((value) => {
          this.savedAddresses.data = value;
        }),
        takeUntil(this._timeout$)
      ),
      // Obtain unsaved addresses
      this._addressStore.dispatch(new GetUserUnsavedCryptoAddresses(this.setting.code)).pipe(
        mergeMap(() => this._addressStore.select(AddressBookStore.GetUserUnsavedCryptoAddresses(this.setting.code))),
        distinctUntilChanged(),
        tap((value) => {
          this.unsavedAddresses.data = value;
        }),
        takeUntil(this._timeout$)
      ),

      this.form.name.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        skipWhile(value => value === ''),
        tap((value) => {
          if (!(this.viewDisplay === AddressView.Edit)) { // Allow edits that change only the network
            const idx = this.savedAddresses.data
            .findIndex((a: AddressBook) => a.name.toLowerCase() === value.toLowerCase());
            if (idx > -1) {
              this.form.name.setErrors({'nameExists': true});
            }
          }
        }),
        takeUntil(this._timeout$)
      ),

      this.form.address.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        skipWhile(value => value === ''),
        tap((value) => {
          const idx = this.savedAddresses.data
          .findIndex((a: AddressBook) => a.address.toLowerCase() === value.toLowerCase());
          if (idx > -1) {
            if (!!this.setting.cryptoNoteAddress?.address && value.toLowerCase()
              !== this.setting.cryptoNoteAddress.address.toLowerCase()) {
              this.form.address.setErrors({'addressExists': true});
            } else if (!this.setting.cryptoNoteAddress?.address) {
              this.form.address.setErrors({'addressExists': true});
            }
          }
        }),
        switchMap((value) => this._balancesService.checkTransferAddress({address: value, coin: this.setting.code})
          .pipe(take(1), map((response) => {
            return {
              ownAddress: response.response === 'failure',
              internalAddress: response.data === true
            };
          }))
        ),
        tap((isValid) => {
          // Determine if address is internal or own address
          if (isValid.internalAddress) {
            this.internalAddressIdentified = true;
            if (!!this.setting.cryptoNoteAddress?.address) {
              this.form.paymentId.setValue('');
              this.form.paymentId.clearValidators();
              this.form.paymentId.updateValueAndValidity();
            }
          } else if (isValid.ownAddress) {
            this.form.address.setErrors({'ownAddress': true});
            this.form.address.markAsTouched();
            this.internalAddressIdentified = false;
          } else {
            this.internalAddressIdentified = false;
            this.setPaymentIdValidators();
          }
        }),
        takeUntil(this._timeout$)
      ),

      this.form.paymentId.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        skipWhile(value => (value === '' || value === null)),
        tap((value) => {
          const idx = this.savedAddresses.data
          .findIndex((a: AddressBook) => a.paymentId.toLowerCase() === value.toLowerCase());
          if (idx > -1) {
            this.form.paymentId.setErrors({'addressExists': true});
          }
        }),
        switchMap((value) => this._balancesService.checkTransferAddress({address: value, coin: this.setting.code})
          .pipe(take(1), map((response) => {
            return {
              ownAddress: response.response === 'failure'
            };
          }))
        ),
        tap((isValid) => {
          if (isValid.ownAddress) {
            this.form.paymentId.setErrors({'ownAddress': true});
            this.form.paymentId.markAsTouched();
            this.internalAddressIdentified = false;
          }
        }),
        takeUntil(this._timeout$)
      ),

      this.form.paymentIdRequired.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((value) => {
          if (this.viewDisplay === AddressView.Edit) {
            // this.form.paymentId.setValue('');
            this.form.paymentId.clearValidators();
          } else if (value) {
            this.form.paymentId.disable();
            this.form.paymentId.setValue('');
            this.form.paymentId.clearValidators();
          } else {
            this.form.paymentId.enable();
            this.setPaymentIdValidators();
          }
          this.form.paymentId.updateValueAndValidity();
        }),
        takeUntil(this._timeout$)
      ),

      this.form.trusted.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((value) => {
          if (this.viewDisplay === AddressView.Edit) {
            this.form.password.clearValidators();
            this.form.tfa.clearValidators();
            this.form.fundPin.clearValidators();
          } else if (value) {
            this.form.password.setValidators([Validators.required]);
            if (this.setting.is2faenabled) {
              this.form.tfa.setValidators([Validators.required]);
            }
            if (this.setting.isFundpinEnabled === FundPinStatus.Enabled) {
              this.form.fundPin.setValidators([Validators.required]);
            }
          } else {
            this.form.password.setValue('');
            this.form.tfa.setValue('');
            this.form.fundPin.setValue('');
            this.form.password.clearValidators();
            this.form.tfa.clearValidators();
            this.form.fundPin.clearValidators();
          }
          this.form.password.updateValueAndValidity();
          this.form.tfa.updateValueAndValidity();
          this.form.fundPin.updateValueAndValidity();
        }),
        takeUntil(this._timeout$)
      )
    ).subscribe();
  }

  ngAfterViewInit() {
    this.savedAddresses.sort = this.sort;
    this.unsavedAddresses.sort = this.unSavedSort;
  }

  ngOnDestroy() {
    this._timeout$.next();
    this._timeout$.complete();
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  get form() {
    return this.addressForm.controls;
  }

  applySavedFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.savedAddresses.filter = filterValue.trim().toLowerCase();
  }

  applyUnsavedFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.unsavedAddresses.filter = filterValue.trim().toLowerCase();
  }

  closeBook(): void {
    this._dialogRef.close();
  }

  useAddress(name: string, paymentId: string, coin_network: string): void {
    const data: DialogReturnData = {
      name: name,
    };
    if (paymentId) {
      data.paymentId = paymentId;
    }
    if (paymentId === PaymentIdLabel.Internal || paymentId === PaymentIdLabel.None) {
      data.paymentId = '';
    }
    if (coin_network) {
      data.coin_network = coin_network;
    }
    this._dialogRef.close(data);
  }

  setPaymentIdValidators() {
    if (!!this.setting.cryptoNoteAddress?.address) {
      if (!!this.setting.cryptoNoteAddress.validation) {
        this.form.paymentId.setValidators([
          Validators.required,
          Validators.pattern(this.setting.cryptoNoteAddress.validation)
        ]);
      } else {
        this.form.paymentId.setValidators([
          Validators.required,
        ]);
      }
      this.form.paymentId.updateValueAndValidity();
    }
  }

  addAddressForm(address?: string): void {
    if (address) {
      this.form.address.setValue(address);
      this.form.address.disable();
    }
    if (this.setting.selectedCoinNetwork) {
      this.addressForm.controls.coinNetwork.setValue(this.setting.selectedCoinNetwork);
    }
    this.viewDisplay = AddressView.Add;
  }

  handleAddressModification(): void {
    if (this.addressForm.status === 'VALID') {
      if (this.viewDisplay === AddressView.Edit) {
        this.editAddress();
      } else {
        this.addAddress();
      }
    }
  }

  cancelConfirm(): void {
    this.viewDisplay = AddressView.Add;
  }

  addAddress(): void {
    const data: ModifyAddressBook = {
      code: this.setting.code,
      name: this.form.name.value.trim(),
      address: this.form.address.value.trim(),
      coin_network: this.setting.selectedCoinNetwork,
      network_name: this.setting.selectedNetworkName,
      trusted: this.form.trusted.value,
      action: 'add'
    };

    if (!!this.setting.cryptoNoteAddress?.address) {
      if (this.internalAddressIdentified) {
        data.paymentId = PaymentIdLabel.Internal;
      } else if (this.form.paymentId.value && !this.form.paymentIdRequired.value) {
        data.paymentId = this.form.paymentId.value.trim();
      } else {
        data.paymentId = PaymentIdLabel.None;
      }
    }

    if (this.form.trusted.value) {
      data.password = this.form.password.value;
      if (this.form.fundPin.value !== '') {
        data.fundPin = this.form.fundPin.value;
      }
      if (this.form.tfa.value !== '') {
        data.tfa = this.form.tfa.value;
      }
    }
    this._addressBookService.modifyAddressBookEntry(data).pipe(take(1)).subscribe((response) => {
      if (response.response === 'success') {
        this._addressStore.dispatch(new UpdateCryptoAddressBookItem(data));
        if (this.form.trusted.value === true) {
          this.viewDisplay = AddressView.ConfirmationTrusted;
        } else {
          this.viewDisplay = AddressView.Confirmation;
        }
      } else {
        this.form.password.setValue('');
        this.form.tfa.setValue('');
        this.form.fundPin.setValue('');
        this.showSnackBar(response.reason);
      }
    });
  }

  changeNetwork(event: any) {
    const networkFound = this.setting.coinNetworks?.find(network => network.id === event.value);
    if (networkFound) {
      this.setting.selectedCoinNetwork = networkFound.id;
      this.setting.selectedNetworkName = networkFound.network_name;
    }
  }

  editAddressForm(address: AddressBook): void {

    // Change network if disabled
    let editCoinNetwork = this.setting.coinNetworks?.find(network => network.id === address.coin_network);
    if (editCoinNetwork && editCoinNetwork.active === '0') {
      const replacementNetwork = this.setting.coinNetworks?.find(coinNetwork => coinNetwork.active === '1');
      if (replacementNetwork) {
        editCoinNetwork = replacementNetwork;
        this.addressForm.controls.coinNetwork.setValue(editCoinNetwork.id);
      } else {
        editCoinNetwork = null; // unset if no replacement network found
      }
    }

    this.addressForm.setValue({
      name: address.name,
      address: address.address,
      coinNetwork: editCoinNetwork ? editCoinNetwork.id : null,
      paymentId: address.paymentId ? address.paymentId : null,
      paymentIdRequired: address.paymentId ? false : true,
      trusted: address.trusted,
      password: '',
      tfa: '',
      fundPin: ''
    });

    this.form.address.disable();
    this.form.trusted.disable();
    this.form.paymentId.disable();
    this.form.paymentIdRequired.disable();
    this.viewDisplay = AddressView.Edit;
  }

  editAddress(): void {
    const data: ModifyAddressBook = {
      code: this.setting.code.trim(),
      name: this.form.name.value,
      address: this.form.address.value,
      coin_network: this.setting.selectedCoinNetwork,
      network_name: this.setting.selectedNetworkName,
      action: 'edit'
    };

    if (!!this.setting.cryptoNoteAddress?.address) {
      data.paymentId = this.form.paymentId.value;
    }

    this._addressBookService.modifyAddressBookEntry(data).pipe(take(1)).subscribe((response) => {
      this.showSnackBar(response.reason);
      if (response.response === 'success') {
        this._addressStore.dispatch(new UpdateCryptoAddressBookItem(data));
        this.resetView();
      }
    });
  }

  requestRemoveAddress(address: AddressBook): void {
    this.form.name.setValue(address.name);
    this.form.address.setValue(address.address);
    this.form.trusted.setValue(address.status === AddressStatus.Trusted ? 'Trusted'
      : (address.status === AddressStatus.Pending ? 'Pending' : 'Untrusted'));

    this.viewDisplay = AddressView.ConfirmRemove;
  }

  removeAddress(): void {
    const data: ModifyAddressBook = {
      code: this.setting.code,
      name: this.form.name.value,
      address: this.form.address.value,
      trusted: this.form.trusted.value,
      action: 'remove'
    };

    this._addressBookService.modifyAddressBookEntry(data).pipe(take(1)).subscribe((response) => {
      this.showSnackBar(response.reason);
      if (response.response === 'success') {
        this._addressStore.dispatch(new RemoveCryptoAddressBookItem(data.code, data.address));
        this.resetView();
      }
    });
  }

  resetView(): void {
    this.addressForm.reset({
      name: '',
      address: '',
      paymentId: '',
      trusted: false,
      paymentIdRequired: false,
      password: '',
      tfa: ''
    });
    this.viewDisplay = AddressView.Normal;
    this.form.address.enable();
    this.form.trusted.enable();
    this.form.paymentIdRequired.enable();
    this.form.password.clearValidators();
    this.form.tfa.clearValidators();
    this.form.password.updateValueAndValidity();
    this.form.tfa.updateValueAndValidity();

    if (!!this.setting.cryptoNoteAddress?.address) {
      this.setPaymentIdValidators();
    }
  }

  showSnackBar(message: string): void {
    SnackbarMessageComponent.openSnackBar(this._snackbar,
      this._translateService.translateResponse(message),
      2000
    );
  }

}
