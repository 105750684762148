import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';


import { SessionStorageService } from './../../core/services/session-storage.service';
import { ProfileService } from '../../profile/profile.service';

interface ProfileDetails {
  first_name: string;
  last_name: string;
  country: boolean;
}

@Component({
  selector: 'app-verification-level-zero-update-dialog',
  templateUrl: './verification-level-zero-update-dialog.component.html',
  styleUrls: ['./verification-level-zero-update-dialog.component.scss']
})
export class VerificationLevelZeroUpdateDialogComponent implements OnInit {

  form_data: FormGroup;

  profile: ProfileDetails = {
    first_name: '',
    last_name: '',
    country: false
  };

  result_fail: boolean;
  result_message: string;

  constructor(
    private formBuilder: FormBuilder,
    private profile_Service: ProfileService,
    private sessionStorage: SessionStorageService,
    private dialogRef: MatDialogRef<VerificationLevelZeroUpdateDialogComponent>) {
    this.form_data = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      country: ['', Validators.required]
    });
  }
  cancel: boolean = false;

  ngOnInit() {
  }

  validateForm() {
    let valid = true;
    const controls = this.form_data.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.form_data.controls[name].markAsDirty();
        valid = false;
      }
    }
    return valid;
  }

  save_click(): void {
    if (this.validateForm()) {
      const data = {
        first_name: this.profile.first_name,
        last_name: this.profile.last_name,
      };
      this.profile_Service.saveUsernames(data).subscribe((resp: any) => {
        if (resp.response === 'success') {
          this.sessionStorage.store('PROFILE_FIRST_NAME', this.profile.first_name);
          this.sessionStorage.store('PROFILE_LAST_NAME', this.profile.last_name);
          this.dialogRef.close();
        } else {
          this.result_fail = true;
          this.result_message = resp.reason;
        }
      });
    }
  }

  cancel_click(): void {
    this.cancel = true;
    this.dialogRef.close();
  }

}
