<div class="container-fluid">
    <div class="row" *ngIf="cellphone_attempt_count < 3">

        <div class="col-12">
            <h2 class="text-center" i18n>
                <span class="orange-text">
                    <b>Cellphone Verification</b>
                </span>
            </h2>
        </div>

        <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step [stepControl]="user_profile_form">
                <form [formGroup]="user_profile_form">
                    <div class="border-2 my-3 p-3">
                        <small class="d-block text-center" i18n>
                            Please select your Country Code and enter your Mobile Number below.
                            An OTP will be sent to the entered Mobile Number once the "Send OTP" button has been selected
                        </small>
                    </div>
                    <div class="row">
                        <mat-form-field class="col-12" *ngIf="!hideFirstName">
                            <input required matInput name="First Name" i18n-placeholder placeholder="First Name" formControlName="first_name" [(ngModel)]="profile.first_name">
                        </mat-form-field>

                        <mat-form-field class="col-12" *ngIf="!hideLastName">
                            <input required matInput name="Last Name" i18n-placeholder placeholder="Last Name" formControlName="last_name" [(ngModel)]="profile.last_name">
                        </mat-form-field>

                        <mat-form-field class="col-5 mb-0">
                            <mat-select [disableOptionCentering]="true" (openedChange)="opened($event)" required i18n-placeholder placeholder="Code" formControlName="country_code" [(ngModel)]="profile.country_code" (selectionChange)="country_code_change()" #countrySelect>
                                <mat-option>
                                    <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No countries found.'"  [formControl]="countryFilterCtrl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filteredCountries | async" [value]="country.id">
                                    {{country.nicename}} (+{{country.phonecode}})
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="user_profile_form.controls.country_code.hasError('blocked')" i18n>Country Blocked</mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-7 mb-0">
                            <input required matInput  name="Mobile Number" i18n-placeholder placeholder="Mobile Number" formControlName="mobile_number" [(ngModel)]="profile.mobile">
                            <mat-error *ngIf="user_profile_form.controls.mobile_number.hasError('required')" i18n>Number is required.</mat-error>
                            <mat-error *ngIf="user_profile_form.controls.mobile_number.hasError('pattern')" i18n>Minimum {{ minimumRequiredDigits }} digits required.</mat-error>
                        </mat-form-field>

                        <div class="col-12" *ngIf="result_success === false">
                            <div class="alert alert-danger">
                            <p class="mb-0" [innerHTML]="result_message|i18n"></p>
                            </div>
                        </div>

                        <div class="col-12 mt-3 mt-lg-4 pt-3">
                            <div class="row justify-content-around">
                                    <button mat-raised-button class="col-12 col-lg-3 mb-3" color="accent" type="button" (click)="cancel_click()" i18n>CANCEL</button>
                                    <button mat-raised-button class="col-12 col-lg-3 mb-3" type="submit" *ngIf="!attemptLimit" (click)="next_click($event)" color="primary" i18n>SEND OTP</button>
                            </div>
                        </div>

                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="verify_code_form">
                <form [formGroup]="verify_code_form">
                    <div class="border-2 my-3 p-3">
                        <small class="d-block text-center" i18n>
                            Please enter the OTP which has been sent to the Mobile Number entered.
                            If you did not receive the OTP, you can resend it after one minute.
                        </small>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field class="col-12 col-lg-6 mx-auto mb-0 verificationCodeHolder">
                                <input matInput name="Verification Code" autocomplete="false" i18n-placeholder placeholder="OTP" formControlName="verify_code" [(ngModel)]="verification_code">
                            </mat-form-field>
                            <p class="smsNotifier text-center col-12" *ngIf="smsSent" color="primary">Resend SMS in: {{smsTimer}}</p>
                            <button class="resendBtn col-12 col-lg-6 d-block mx-auto mb-3" mat-raised-button color="accent" *ngIf="!attemptLimit && !smsSent" (click)="resend_click()" i18n>RESEND SMS</button>
                        </div>

                        <div class="col-12" *ngIf="result_success || result_success === false || optExpired">
                            <div class="alert" [ngClass]="{'alert-success': result_success,
                                'alert-danger': !result_success && !optExpired,
                                'alert-warning': !result_success && optExpired}">
                                <p class="mb-0" [innerHTML]="result_message|i18n"></p>
                            </div>
                        </div>

                        <div class="col-12 mt-3 mt-lg-4 pt-3">
                            <div class="row justify-content-around">
                                <button mat-raised-button class="col-12 col-lg-3 mb-3" matStepperPrevious type="button" color="accent" i18n>BACK</button>
                                <button mat-raised-button class="col-12 col-lg-3 mb-3" color="primary" type="submit" (click)="verify_click()" i18n>VERIFY</button>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>

    </div>

    <div class="row" *ngIf="cellphone_attempt_count >= 3">
        <div class="col-12 text-center">
            <img class="py-4" src="assets\{{exchangeName}}\images\pages\common\cx_failed.svg" width="90">

            <div class="my-4 text-center">
                <div *ngIf="verificationData.max_cellphone_attempt_notification && verificationData.max_cellphone_attempt_notification !== ''; else defaultMessage">
                    <p [innerHTML]="verificationData.max_cellphone_attempt_notification"></p>
                </div>
                <ng-template #defaultMessage>
                    <p i18n>Cellphone verification is not available at this time. Please contact support or try again later.</p>
                </ng-template>
            </div>

            <div class="col-12 mt-3 mt-lg-4 pt-3">
                <button mat-raised-button class="col-12 col-lg-3 mb-3" color="accent" (click)="cancel_click()" i18n>CLOSE</button>
            </div>
        </div>
    </div>
</div>