import {
  Component,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { takeUntil, tap, distinctUntilChanged, skipWhile, take } from 'rxjs/operators';
import { Subject, ReplaySubject, merge } from 'rxjs';

import { ProfileService } from '../profile.service';
import { environment } from '../../../environments/environment';

import { SessionStorageService, SubscriptionLike } from './../../core/services/session-storage.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// interfaces
import {
  LocationInfo,
  ComplianceOption,
  ComplianceFormControl,
  ComplianceQuestionData
} from '../../core/interfaces/verification-location';
import { VerificationRequestType } from 'src/app/core/interfaces/profilesettings';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-verification-location',
  templateUrl: './verification-location.component.html',
  styleUrls: ['./verification-location.component.scss']
})
export class VerificationLocationComponent implements OnInit, OnDestroy {
  @ViewChild('idStepper') idStepper: MatStepper;
  @ViewChild('residenceStepper') residenceStepper: MatStepper;
  @Output() verificationSet: EventEmitter<VerificationRequestType> = new EventEmitter();
  @Output() verificationCancel: EventEmitter<any> = new EventEmitter();
  @Output() showToastNotification: EventEmitter<any> = new EventEmitter();
  @Output() complianceAnswered: EventEmitter<any> = new EventEmitter();
  @Input() darkTheme: boolean = false;
  @Input() requestType: VerificationRequestType = VerificationRequestType.Identity;
  @Input() verificationData: any = {
    complianceOnly: false,
    kyc_attempts: 0,
    max_kyc_attempt_notification: '',
    verifyResidence: false,
    livenessChecked: false,
    profile: {}
  };

  @ViewChild('imageView') imageViewRef: TemplateRef<any>;
  @ViewChild('selfieExample') selfieDialogRef: TemplateRef<any>;

  assetsPath: string = '/assets/chainex/images/pages/verification/';

  frontIdUrl: any;
  backIdUrl: any;
  selfieUrl: any;
  porUrl: any;
  today: Date;

  // Used to reference enum on html
  VerificationRequestType: typeof VerificationRequestType = VerificationRequestType;

  identity_form: FormGroup;
  location_form: FormGroup;
  residence_form: FormGroup;
  identity_doc_form: FormGroup;
  selfie_form: FormGroup;
  por_upload_form: FormGroup;
  accountPurpose_form: FormGroup;
  sourceOfFunds_form: FormGroup;
  income_form: FormGroup;
  compliance_form: FormGroup;

  complianceOnly: boolean = false;
  manualSelfie: boolean = false;
  verificationPage: number = 0;

  location: LocationInfo = {
    country: { idrequired: '0' },
    residenceCountry: null,
    selectidtype: 'ID',
    blankIdBack: false,
    id_number: '',
    dateOfBirth: new Date(),
    passport_number: '',
    state: '',
    street_no: '',
    suburb: '',
    zipcode: '',
    city: ''
  };

  fileFrontID: File = null;
  fileBackID: File = null;
  fileSelfiePhoto: File = null;
  filePORDocument: File = null;
  imagesValid: any = {
    frontId: null,
    backId: null,
    selfie: null,
    POR: null
  };
  fileUploading: any = false;
  fileLoading: boolean[] = [true, true, true, true];

  jumio_settings: any = {
    srcUrl: '',
    clientWidth: 'responsive',
    clientHeight: 'responsive'
  };
  jumio_iframe: any;

  iframe_loading: boolean = true;

  verification_token: string;

  verification_level: number;

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  countries: any = [
    { id: '1', nicename: 'test', idrequired: '1' },
    { id: '27', nicename: 'ZA', idrequired: '1' }
  ];

  result_fail: boolean;
  result_message: string;

  provinces: string[] = [
    'Eastern Cape'
  ];
  cities: string[] = [
    'Port Elizabeth'
  ];

  jumioRedirectUrl: SafeResourceUrl;
  jumioLoaded: boolean;

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('countrySelect') countrySelect: MatSelect;

  issuingCountryFilterCtrl: FormControl = new FormControl();
  residenceCountryFilterCtrl: FormControl = new FormControl();
  filteredCountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  residenceCountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  sub: SubscriptionLike;

  _onDestroy: Subject<void> = new Subject<void>();

  verifyResidence: boolean = false;
  user_address: any = {
    address_state: '',
    address_street_no: '',
    address_suburb: '',
    address_city: '',
    address_zipcode: '',
  };

  sourceOfFunds: any = {};
  accountPurpose: any = {};
  otherPositionId: number;
  otherSourceOfFundId: number;
  politicallyExposedId: number;
  sourceOfFundsGroup: FormArray;
  accountPurposeGroup: FormArray;
  filteredPositions: ComplianceOption[];
  hidePositionSelection: boolean = false;
  complianceAnswersRequired: boolean = true;
  fieldsFailed: boolean = false;
  filteredNetMonthlyIncome: ComplianceOption[];
  questionsAndOptions: ComplianceQuestionData = {};
  complianceAnswers: any = {
    other_position: '',
    nature_of_business: '',
    politically_exposed: '',
    other_source_of_funds: ''
  };

  constructor(
    private profileService: ProfileService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private sessionStorage: SessionStorageService,
    public sanitizer: DomSanitizer,
  ) {

    this.identity_form = this.formBuilder.group({
      country: ['', Validators.required],
      id_type: [''],
      id_number: [''],
      document_id_number: [''],
      passport_number: [''],
      document_passport_number: [''],
      date_of_birth: ['', Validators.required]
    });

    this.location_form = this.formBuilder.group({
      residenceCountry: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      suburb: ['', Validators.required],
      zip: ['', Validators.required],
      street: ['', Validators.required]
    });

    this.identity_doc_form = this.formBuilder.group({
      fileFront: ['', Validators.required],
      fileBack: [''],
      id_back_blank: ['']
    });

    this.selfie_form = this.formBuilder.group({
      fileSelfie: ['', Validators.required]
    });

    this.por_upload_form = this.formBuilder.group({
      filePOR: ['', Validators.required]
    });
    this.por_upload_form.markAsUntouched();

    this.profileService.getKYCUploads().pipe(take(1)).subscribe((response: any) => {
      if (response.fileOne) {
        this.profileService.getKYCUpload('?fileName=' + response.fileOne['s3Link'])
          .pipe(take(1)).subscribe((fileResponse: any) => {
            this.fileFrontID = response.fileOne;
            if (fileResponse.type && fileResponse.type === 'application/octet-stream') {
              // file retrieved from API
              this.frontIdUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fileResponse));
              this.identity_doc_form.controls.fileFront.setValue(this.fileFrontID);
              this.identity_doc_form.controls.fileFront.markAsUntouched();
              this.imagesValid.frontId = true;
            } else {
              this.fileFrontID = null;
            }
          });
      }
      if (response.fileTwo) {
        this.profileService.getKYCUpload('?fileName=' + response.fileTwo['s3Link'])
          .pipe(take(1)).subscribe((fileResponse: any) => {
            if (fileResponse.type && fileResponse.type === 'application/octet-stream') {
              this.fileSelfiePhoto = response.fileTwo;
              this.selfieUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fileResponse));
              this.selfie_form.controls.fileSelfie.setValue(this.fileSelfiePhoto);
              this.selfie_form.controls.fileSelfie.markAsUntouched();
              this.imagesValid.selfie = true;
            } else {
              this.fileSelfiePhoto = null;
            }
          });
      }
      if (response.fileThree) {
        this.profileService.getKYCUpload('?fileName=' + response.fileThree['s3Link'])
          .pipe(take(1)).subscribe((fileResponse: any) => {
            if (fileResponse.type && fileResponse.type === 'application/octet-stream') {
              this.filePORDocument = response.fileThree;
              this.porUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fileResponse));
              this.por_upload_form.controls.filePOR.setValue(this.filePORDocument);
              this.por_upload_form.controls.filePOR.markAsUntouched();
              this.imagesValid.POR = true;
            } else {
              this.filePORDocument = null;
            }
          });
      }
      if (response.fileFour) {
        this.profileService.getKYCUpload('?fileName=' + response.fileFour['s3Link'])
          .pipe(take(1)).subscribe((fileResponse: any) => {
            if (fileResponse.type && fileResponse.type === 'application/octet-stream') {
              this.fileBackID = response.fileFour;
              this.backIdUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fileResponse));
              this.identity_doc_form.controls.fileBack.setValue(this.fileBackID);
              this.identity_doc_form.controls.fileBack.markAsUntouched();
              this.imagesValid.backId = true;
            } else {
              this.fileBackID = null;
            }
          });
      }
    });

    this.profileService.getCountries({ orderBy: 'nicename' }).subscribe((response: any) => {
      if (response.response === 'success') {
        this.countries = response.data;
        this.filter_country(this.issuingCountryFilterCtrl, this.filteredCountries);
        this.filter_country(this.residenceCountryFilterCtrl, this.residenceCountries);

        if (this.user_address.residential_country) {
          this.location.residenceCountry = this.countries.find(country =>
            country.nicename.toLowerCase() === this.user_address.residential_country.toLowerCase()
          );
        }
        if (this.user_address.country) {
          this.location.country = this.countries.find(country =>
            country.nicename.toLowerCase() === this.user_address.country.toLowerCase()
          );
          this.country_code_change();
        }
      }
    });

    let level: any = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL');
    if (level === '') {
      level = 0;
    } else {
      level = Number(level);
    }
    this.verification_level = level || 0;

    this.issuingCountryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filter_country(this.issuingCountryFilterCtrl, this.filteredCountries);
    });
    this.residenceCountryFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filter_country(this.residenceCountryFilterCtrl, this.residenceCountries);
    });
  }

  ngOnInit(): void {
    this.today = new Date();
    if (!this.verificationData) {
      this.verificationData.max_cellphone_attempt_notification = '';
      this.verificationData.cellphone_attempt_count = 0;
      this.verificationData.kyc_attempts = 0;
      this.verificationData.max_kyc_attempt_notification = '';
    } else {
      this.verificationData.cellphone_attempt_count = !this.verificationData.cellphone_attempt_count ? '' :
        this.verificationData.max_cellphone_attempt_notification = '';
      this.verificationData.kyc_attempts =
        !this.verificationData.kyc_attempts ? 0 : Number(this.verificationData.kyc_attempts);
      this.verificationData.max_kyc_attempt_notification = !this.verificationData.max_kyc_attempt_notification ? '' :
        this.verificationData.max_kyc_attempt_notification;
      this.fieldsFailed = (this.verificationData.failedFields &&
        Object.keys(this.verificationData.failedFields).length > 0) && +(this.verificationData.profile.kyc_status) >= 3;
    }

    if (this.verificationData.profile) {
      this.verifyResidence = true;
      this.user_address = this.verificationData.profile;

      this.location.dateOfBirth = this.user_address.date_of_birth ?
        new Date(+this.user_address.date_of_birth * 1000).toISOString().slice(0, 10) : null;
      this.location.state = this.user_address.address_state ? this.user_address.address_state : '';
      this.location.city = this.user_address.address_city ? this.user_address.address_city : '';
      this.location.suburb = this.user_address.address_suburb ? this.user_address.address_suburb : '';
      this.location.street_no = this.user_address.address_street_no ? this.user_address.address_street_no : '';
      this.location.zipcode = this.user_address.address_zipcode ? this.user_address.address_zipcode : '';
    }

    const subscribeToCheckboxChanges = () => {
      if (this.accountPurposeGroup && this.sourceOfFundsGroup) {
        merge(
          this.compliance_form.controls.question_6.valueChanges.pipe(
            skipWhile(value => [undefined, null, ''].indexOf(value) >= 0),
            distinctUntilChanged(),
            tap(value => {
              this.compliance_form.controls.politically_exposed
                .setValidators(Number(value.option_id) === this.politicallyExposedId ? Validators.required : null);
              this.compliance_form.controls.politically_exposed.updateValueAndValidity();
            }),
            takeUntil(this._onDestroy)
          ),

          this.compliance_form.controls.question_4.valueChanges.pipe(
            skipWhile(value => [undefined, null, ''].indexOf(value) >= 0),
            distinctUntilChanged(),
            tap((industry) => {
              // Filter positions based on the industry selected
              this.filteredPositions = this.questionsAndOptions.question_5.options
                .filter(option =>
                  option.parent_option === industry.option_id ||
                  Number(option.option_id) === this.otherPositionId
                );

              this.hidePositionSelection = (industry &&
                industry.compliance_option.toLocaleLowerCase() === 'unemployed');

              // Only validate position if it is not hidden
              if (this.hidePositionSelection) {
                this.compliance_form.controls.question_5.setValidators(null);
                this.compliance_form.controls.other_position.setValidators(null);
              } else {
                this.compliance_form.controls.question_5.setValidators(Validators.required);

                this.compliance_form.controls.other_position
                  .setValidators(
                    this.otherPositionId === +this.complianceAnswers.question_5.option_id ? Validators.required : null);
              }

              this.compliance_form.controls.question_5.updateValueAndValidity();
              this.compliance_form.controls.other_position.updateValueAndValidity();
            }),
            takeUntil(this._onDestroy)
          ),

          this.compliance_form.controls.question_5.valueChanges.pipe(
            skipWhile(value => [undefined, null, ''].indexOf(value) >= 0),
            distinctUntilChanged(),
            tap((position) => {
              // Select the industry automatically unless `other` is selected as position.
              if (this.otherPositionId !== +position.option_id &&
                position.parent_option !== this.complianceAnswers.question_4.option_id
              ) {
                const industry = this.questionsAndOptions.question_4.options
                  .find(option => option.option_id === position.parent_option);

                this.complianceAnswers.question_4 = (industry || '');
              }

              // Validate other input, if `other` is selected
              this.compliance_form.controls.other_position
                .setValidators(this.otherPositionId === +position.option_id ? Validators.required : null);
              this.compliance_form.controls.other_position.updateValueAndValidity();
            }),
            takeUntil(this._onDestroy)
          ),

          this.accountPurposeGroup.valueChanges.pipe(
            distinctUntilChanged(),
            tap((_options: any[]) => {
              this.accountPurpose_form.controls.account_purpose_required
                .setValue(this.mapCheckboxes(_options));
            }),
            takeUntil(this._onDestroy)
          ),

          this.sourceOfFundsGroup.valueChanges.pipe(
            distinctUntilChanged(),
            tap((_options: any[]) => {
              this.sourceOfFunds_form.controls.source_of_funds_required
                .setValue(this.mapCheckboxes(_options, true));
            }),
            takeUntil(this._onDestroy)
          )
        ).subscribe();
      }
    };

    this.profileService.getComplianceQuestions().pipe(
      tap(data => {
        if (data.response === 'success') {
          if (data.answered) {
            this.complianceAnswersRequired = false;
            this.complianceAnswered.emit();
            if (this.requestType === VerificationRequestType.Compliance) {
              // If already answered, skip compliance questions
              this.requestType = VerificationRequestType.Bank;
              this.verificationSet.emit(this.requestType);
            }
          } else {
            this.questionsAndOptions = data.data;

            // set question 1 & 2 checkboxes to false
            const complianceFormGroup: ComplianceFormControl = {
              other_position: this.formBuilder.control(false),
              nature_of_business: this.formBuilder.control(''),
              politically_exposed: this.formBuilder.control(''),
              other_source_of_funds: this.formBuilder.control(false)
            };
            const questionOneOptions: FormGroup[] = [];
            const questionTwoOptions: FormGroup[] = [];

            this.questionsAndOptions.question_1.options.map(option => {
              this.accountPurpose[this.getKeyName(option.option_id)] = false;
              questionOneOptions.push(this.formBuilder.group({
                id: this.formBuilder.control(option.option_id),
                textValue: this.formBuilder.control(option.compliance_option),
                checked: this.formBuilder.control(false)
              }));
            });

            this.questionsAndOptions.question_2.options.map(option => {
              this.sourceOfFunds[this.getKeyName(option.option_id)] = false;
              questionTwoOptions.push(this.formBuilder.group({
                id: this.formBuilder.control(option.option_id),
                textValue: this.formBuilder.control(option.compliance_option),
                checked: this.formBuilder.control(false)
              }));

              if (option.compliance_option.toLowerCase() === 'other') {
                // Get `Other` option Id for source of funds option
                this.otherSourceOfFundId = +option.option_id;
              }
            });

            Object.keys(this.questionsAndOptions)
              .filter(question => !(['question_1', 'question_2'].includes(question)))
              .map(questionNo => {
                const question = this.questionsAndOptions[questionNo];

                if (questionNo === 'question_3') {
                  if (this.user_address) {
                    this.filterNetMonthlyIncome(this.user_address.residential_country);
                  } else {
                    this.filteredNetMonthlyIncome = question.options
                      .filter(option => option.in_zar);
                  }
                } else if (questionNo === 'question_5') {
                  this.filteredPositions = question.options
                    .sort((a, b) => {
                      return (a.compliance_option < b.compliance_option ? -1 :
                        (a.compliance_option > b.compliance_option ? 1 : 0));
                    });

                  // Get `Other` option Id for position
                  question.options.filter(option => option.compliance_option.toLowerCase() === 'other')
                    .map(option => {
                      this.otherPositionId = +option.option_id;
                    });
                } else if (questionNo === 'question_6') {
                  // Get `yes` option id for polliticaly exposed
                  question.options.filter(option => option.compliance_option.toLowerCase() === 'yes')
                    .map(option => {
                      this.politicallyExposedId = +option.option_id;
                    });
                }

                if (questionNo === 'question_8') {
                  // Auto select other option for nature of business
                  this.complianceAnswers[questionNo] = question.options[0];
                } else {
                  this.complianceAnswers[questionNo] = '';
                  complianceFormGroup[questionNo] = this.formBuilder.control('', Validators.required);
                }
              });

            // create question controls and options
            this.sourceOfFundsGroup = this.formBuilder.array(questionTwoOptions);
            this.accountPurposeGroup = this.formBuilder.array(questionOneOptions);

            // Hidden controls used to validate the chackboxes for question 1 & 2
            this.accountPurposeGroup['account_purpose_required'] = this.formBuilder.control(null, Validators.required);
            this.sourceOfFundsGroup['source_of_funds_required'] = this.formBuilder.control(null, Validators.required);

            // create forms
            this.accountPurpose_form = this.formBuilder.group(this.accountPurposeGroup);
            this.sourceOfFunds_form = this.formBuilder.group(this.sourceOfFundsGroup);
            this.compliance_form = this.formBuilder.group(complianceFormGroup);

            // Add checkbox form arrays
            this.accountPurpose_form.addControl('account_purpose', this.accountPurposeGroup);
            this.sourceOfFunds_form.addControl('source_of_funds', this.sourceOfFundsGroup);
            this.sourceOfFunds_form.addControl('other_source_of_funds', this.formBuilder.control(''));

            subscribeToCheckboxChanges();
          }
        }
      }),
      takeUntil(this._onDestroy)
    ).subscribe();
  }

  getKeyName(id: string | number, isQuestion: boolean = false) {
    return (isQuestion ? `question_${id}` : `option_${id}`);
  }

  mapCheckboxes(checkboxes: any[], isSourceOfFunds: boolean = false) {
    const selectedItems = checkboxes.filter(option => option.checked)
      .map(option => {
        if (isSourceOfFunds) {
          this.sourceOfFunds_form.controls.other_source_of_funds
            .setValidators(this.otherSourceOfFundId === +option.id ? Validators.required : null);
            this.sourceOfFunds_form.controls.other_source_of_funds.updateValueAndValidity();
        }

        return option.id;
      });
    return (selectedItems.length ? selectedItems : null);
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  fileDrop(event: any, type: number) {
    event.preventDefault();
    this.onFileChange(event, type);
  }

  fileView(type: number) {
    switch (type) {
      case 1: // ID front
      if (this.frontIdUrl) {
        this.dialog.open(this.imageViewRef, { maxWidth: '100%', maxHeight: '100%', data: {imageUrl: this.frontIdUrl} });
      }
      break;
      case 2: // ID back
      if (this.backIdUrl) {
        this.dialog.open(this.imageViewRef, { maxWidth: '100%', maxHeight: '100%', data: {imageUrl: this.backIdUrl} });
      }
      break;
      case 3: // selfie
      if (this.selfieUrl) {
        this.dialog.open(this.imageViewRef, { maxWidth: '100%', maxHeight: '100%', data: {imageUrl: this.selfieUrl} });
      }
      break;
      case 4: // Proof of Residence
      if (this.porUrl) {
        this.dialog.open(this.imageViewRef, { maxWidth: '100%', maxHeight: '100%', data: {imageUrl: this.porUrl} });
      }
      break;
    }
  }

  async onFileChange(event: any, type: number) {
    const filesReceived = event.type === 'drop' ? event.dataTransfer.files : event.target.files;

    if (filesReceived.length > 0) {
      this.result_fail = false;
      const reader = new FileReader();
      const restrictedSize = 5000000; // 5mb
      let image: File;
      if (filesReceived[0].type === 'application/pdf') {
        image = filesReceived.item(0);
      } else if (filesReceived[0].type.startsWith('image/')) {
        image = await this.resizeImage(filesReceived.item(0), type);
      } else {
        this.showToastNotification.emit('Invalid file type selected. Please select an image or .pdf document.');
        return;
      }
      if (+image.size <= restrictedSize) {
        switch (type) {
          case 1: // ID front
          this.frontIdUrl = null;
          this.fileFrontID = image;
          this.identity_doc_form.controls.fileFront.setValue(this.fileFrontID);
          this.identity_doc_form.controls.fileFront.markAsTouched();
          if (this.fileFrontID.type.startsWith('image/')) {
            reader.readAsDataURL(this.fileFrontID);
            reader.onload = (_event) => {
              this.frontIdUrl = reader.result;
            };
          } else {
            this.fileLoaded(1);
            this.frontIdUrl = '/';
          }
          this.imagesValid.frontId = true;
          break;
          case 2: // ID back
          if (!this.location.blankIdBack) {
            this.backIdUrl = null;
            this.fileBackID = image;
            this.identity_doc_form.controls.fileBack.setValue(this.fileBackID);
            this.identity_doc_form.controls.fileBack.markAsTouched();
            if (this.fileBackID.type.startsWith('image/')) {
              reader.readAsDataURL(this.fileBackID);
              reader.onload = (_event) => {
                this.backIdUrl = reader.result;
              };
            } else {
              this.fileLoaded(1);
              this.backIdUrl = '/';
            }
            this.imagesValid.backId = true;
          }
          break;
          case 3: // selfie
          this.selfieUrl = null;
          this.fileSelfiePhoto = image;
          this.selfie_form.controls.fileSelfie.setValue(this.fileSelfiePhoto);
          this.selfie_form.controls.fileSelfie.markAsTouched();
          if (this.fileSelfiePhoto.type.startsWith('image/')) {
            reader.readAsDataURL(this.fileSelfiePhoto);
            reader.onload = (_event) => {
              this.selfieUrl = reader.result;
            };
          } else {
            this.fileLoaded(2);
            this.selfieUrl = '/';
          }
          this.imagesValid.selfie = true;
          break;
          case 4: // Proof of Residence
          this.porUrl = null;
          this.filePORDocument = image;
          this.por_upload_form.controls.filePOR.setValue(this.filePORDocument);
          this.por_upload_form.controls.filePOR.markAsTouched();
          if (this.filePORDocument.type.startsWith('image/')) {
            reader.readAsDataURL(this.filePORDocument);
            reader.onload = (_event) => {
              this.porUrl = reader.result;
            };
          } else {
            this.fileLoaded(3);
            this.porUrl = '/';
          }
          this.imagesValid.POR = true;
          break;
        }
      } else {
        switch (type) {
          case 1: // ID front
          this.imagesValid.frontId = false;
          break;
          case 2: // ID back
          this.imagesValid.backId = false;
          break;
          case 3: // selfie
          this.imagesValid.selfie = false;
          break;
          case 4: // Proof of Residence
          this.imagesValid.POR = false;
          break;
        }
      }
    } // Take no action if cancelled
  }

  async resizeImage(img: File, type: Number): Promise<File> {
    const fileReader = new FileReader();

    let resolver;
    const promise = new Promise<File>(resolve => resolver = resolve);
    const extension = img.name.split('.')[1];

    fileReader.onload = event => {
      // helper Image object
      const image = new Image();
      image.onload = function() {
        // have to wait till it's loaded
        const canvas = document.createElement('canvas');

        const max_height = 1920;
        const max_width = 1920;
        let width = image.width;
        let height = image.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > max_width) {
            height = Math.round(height *= max_width / width);
            width = max_width;
          }
        } else {
          if (height > max_height) {
            width = Math.round(width *= max_height / height);
            height = max_height;
          }
        }

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        const blobBin = atob(canvas.toDataURL('image/jpeg', 0.8).split(',')[1]);

        const dataArray = [];
        for (let i = 0; i < blobBin.length; i++) {
          dataArray.push(blobBin.charCodeAt(i));
        }

        let fileName = 'default';
        switch (type) {
          case 1: // ID front
          fileName = 'fileFrontId';
          break;
          case 2: // ID back
          fileName = 'fileBackId';
          break;
          case 3: // selfie
          fileName = 'fileSelfie';
          break;
          case 4: // Proof of Residence
          fileName = 'filePOR';
          break;
        }
        if (extension) {
          fileName += '.' + extension;
        }

        resolver(new File(
          [new Uint8Array(dataArray)],
          fileName,
          {type: 'image/jpeg'}
        ));
      };

      image.src = event.target.result.toString();
    };
    fileReader.readAsDataURL(img);
    return promise;
  }

  back_click() {
    if (this.verificationPage > 0) {
      this.verificationPage--;
    } else {
      this.requestType--;
      this.verificationSet.emit(this.requestType);
    }
  }

  cancel_click() {
    this.verificationCancel.emit();
  }

  filter_country(filterControl: FormControl, countryList: ReplaySubject<any[]>) {
    if (!this.countries) {
      return;
    }
    let search = filterControl.value;

    if (!search) {
      countryList.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    countryList.next(
      this.countries.filter(country => country.nicename.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  opened(e: any) {
    const element: ElementRef<HTMLElement> = this.countrySelect.panel;
    const host: ElementRef<HTMLElement> = this.countrySelect._elementRef;

    if (!!host.nativeElement && !!element && !!element.nativeElement) {
      let top: number = host.nativeElement.parentElement.getBoundingClientRect().top;

      top += 100;
      element.nativeElement.parentElement.style.top = top + 'px';
      element.nativeElement.parentElement.style.display = 'inline-block';
    }
  }

  openSelfieExample() {
    this.dialog.open(this.selfieDialogRef, { width: '500px' });
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  submit_form() {
    // Upload proof of residence
    if (this.por_upload_form.valid && this.por_upload_form.untouched) {
      // skip submit if no new changes since the last submit
      this.fileUploading = true;
      let data;
      if (+this.verificationData.profile.kyc_status >= 3 || +this.verificationData.profile.kyc_status === 1) {
        // verification attempt complete, request verification pending status
        data = {setPending: true};
      } else {
        // set verification started
        data = {};
      }
      this.profileService.requestVerification(data).subscribe((verificationResp: any) => {
        this.fileUploading = false;
        if (verificationResp.response === 'success') {
          if (this.complianceAnswersRequired) {
            this.requestType++;
          } else {
            // If already answered, skip compliance questions
            if (this.fieldsFailed || +this.verificationData.profile.kyc_status >= 3) {
              // If verification was completed previously, skip bank verification
              this.requestType = VerificationRequestType.Level2Pending;
            } else {
              // proceed to bank verification
              this.requestType = VerificationRequestType.Bank;
            }
            this.result_fail = false;
            this.result_message = '';
          }
          this.verificationSet.emit(this.requestType);
          this.showToastNotification.emit('Verification request has been submitted');
        } else {
          this.result_fail = true;
          this.result_message = verificationResp.reason;
        }
      });
    } else {
      // proceed with upload
      this.por_upload_form.markAllAsTouched();
      if (this.por_upload_form.valid) {
        const formData: FormData = new FormData();
        if (!this.filePORDocument.type.startsWith('image/') && this.filePORDocument.type !== 'application/pdf') {
          this.result_fail = true;
          this.result_message = 'Only image files or .pdf documents may be submitted here';
          return;
        }
        this.fileUploading = true;
        formData.append('fileThree', this.filePORDocument, this.filePORDocument.name);

        this.profileService.uploadKYC(formData).subscribe((resp: any) => {
          if (resp.response === 'success') {
            let data;
            if (this.verificationData.profile.kyc_status >= 3 || this.verificationData.profile.kyc_status === 1) {
              // verification attempt complete, request verification pending status
              data = {setPending: true};
            } else {
              data = {};
            }
            this.profileService.requestVerification(data).subscribe((verificationResp: any) => {
              this.fileUploading = false;
              if (verificationResp.response === 'success') {
                this.result_fail = false;
                this.result_message = '';

                if (this.complianceAnswersRequired) {
                  this.requestType++;
                } else {
                  // If already answered, skip compliance questions
                  if (this.fieldsFailed || +this.verificationData.profile.kyc_status >= 3) {
                    // If verification was completed previously, skip bank verification
                    this.requestType = VerificationRequestType.Level2Pending;
                  } else {
                    // proceed to bank verification
                    this.requestType = VerificationRequestType.Bank;
                  }
                }
                this.verificationSet.emit(this.requestType);
                this.showToastNotification.emit('Upload successful');
              } else {
                this.result_fail = true;
                this.result_message = verificationResp.reason;
              }
            });
          } else {
            this.fileUploading = false;
            this.result_fail = true;
            this.result_message = resp.reason;
          }
        });
      }
    }
  }

  submit_idDocuments() {
    // Upload identification documents
    if (this.identity_doc_form.valid && this.identity_doc_form.untouched) {
      // skip submit if no new changes since the last submit
      this.verificationPage++;
      if (this.verificationData.livenessChecked) {
        this.requestType++; // skip liveness check, proceed to POR
        this.verificationSet.emit(this.requestType);
      }
    } else {
      const fileFrontChanged = this.identity_doc_form.controls.fileFront.touched;
      const fileBackChanged = this.identity_doc_form.controls.fileBack.touched;
      this.identity_doc_form.markAllAsTouched();
      if (this.identity_doc_form.valid && (this.location.blankIdBack || this.fileBackID)) {
        const formData: FormData = new FormData();
        if (fileFrontChanged) {
          if (!this.fileFrontID.type.startsWith('image/')) {
            this.result_fail = true;
            this.result_message = 'Only image files may be submitted here';
            return;
          }
          formData.append('fileOne', this.fileFrontID, this.fileFrontID.name);
        }
        if (!this.location.blankIdBack && fileBackChanged) {
          if (!this.fileBackID.type.startsWith('image/')) {
            this.result_fail = true;
            this.result_message = 'Only image files may be submitted here';
            return;
          }
          formData.append('fileFour', this.fileBackID, this.fileBackID.name);
        }
        if (fileFrontChanged || fileBackChanged) {
          this.fileUploading = true;
          this.profileService.uploadKYC(formData).subscribe((resp: any) => {
            if (resp.response === 'success') {
              this.fileUploading = false;
              this.result_fail = false;
              this.result_message = '';

              this.verificationPage++;
              this.showToastNotification.emit('Upload successful');
              if (this.verificationData.livenessChecked) {
                this.requestType++; // skip liveness check, proceed to POR
                this.verificationSet.emit(this.requestType);
              }
            } else {
              this.fileUploading = false;
              this.result_fail = true;
              this.result_message = resp.reason;
              this.fileUploading = false;
            }
          });
        } else {
          // user didn't make actual changes, skip submit
          this.fileUploading = false;
          this.verificationPage++;
        }
      }
    }
  }

  submit_selfie() {
    // Upload selfie photo
    if (this.selfie_form.valid && this.selfie_form.untouched) {
      // skip submit if no new changes since the last submit
      this.requestType++;
      this.verificationSet.emit(this.requestType);
    } else {
      this.selfie_form.markAllAsTouched();
      if (this.fileSelfiePhoto && this.selfie_form.valid) {
        if (!this.fileSelfiePhoto.type.startsWith('image/')) {
          this.result_fail = true;
          this.result_message = 'Only image files may be submitted here';
          return;
        }
        this.fileUploading = true;
        const formData: FormData = new FormData();
        formData.append('fileTwo', this.fileSelfiePhoto, this.fileSelfiePhoto.name);

        this.profileService.uploadKYC(formData).subscribe((resp: any) => {
          if (resp.response === 'success') {
            this.fileUploading = false;
            this.result_fail = false;
            this.result_message = '';

            this.requestType++;
            this.verificationSet.emit(this.requestType);
            this.showToastNotification.emit('Upload successful');
          } else {
            this.fileUploading = false;
            this.result_fail = true;
            this.result_message = resp.reason;
            this.fileUploading = false;
          }
        });
      }
    }
  }

  submit_liveness() {
    if (this.verificationData.livenessChecked) {
      this.fileUploading = false;
      this.result_fail = false;
      this.result_message = '';

      this.requestType++;
      this.verificationSet.emit(this.requestType);

    } else {
      this.showToastNotification.emit('Please complete the liveness test. Alternatively, click the "UPLOAD PHOTOS" button for manual verification.');
    }
  }

  filterNetMonthlyIncome(country: string) {
    const isInSouthAfrica = (!country || country.toLowerCase() === 'south africa');
    this.filteredNetMonthlyIncome = this.questionsAndOptions.question_3.options
      .filter(option => option?.in_zar === isInSouthAfrica);
  }

  next_click() {
    this.stepper.next();
  }

  previous_click(previousType: boolean = false) {
    if (previousType) {
      this.requestType--;
      this.verificationSet.emit(this.requestType);
    } else {
      this.stepper.previous();
    }
  }

  init_Jumio() {
    this.profileService.requestVerification().subscribe((resp: any) => {
      if (resp.response === 'success' && !this.jumioLoaded) {
        this.result_fail = false;
        if (!!this.stepper) {
          this.stepper.next();
        }
        if (resp.redirectUrl !== undefined) {
          resp.redirectUrl = resp.redirectUrl.concat(resp.redirectUrl.indexOf('?') === -1 ? '?' : '&')
            .concat('theme=').concat(this.darkTheme ? 'dark' : 'light');
        }
        this.jumioLoaded = true;
        this.iframe_loading = true;
        this.jumioRedirectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(resp.redirectUrl);
        this.add_iframe_event();
      } else {
        this.result_fail = true;
        this.result_message = resp.reason;
      }
    });
  }

  add_iframe_event() {
    const iframes = document.getElementsByTagName('iframe');
    if (iframes.length > 0) {
      this.jumio_iframe = iframes[0];
      const iframe = iframes[0];
      iframe.onload = () => {
        const prams = iframe.src.split('?')[0].split('&');
        for (let i = 0; i < prams.length; i++) {
          const pram = prams[i].split('=');
          if (pram[0].toLowerCase() === 'idscanstatus') {
            if (pram[1].toLowerCase() === 'success') {
              return false;
            }
          }
        }
        if (iframe.src.split('/').indexOf('done') > -1) {
          return false;
        } else {
          this.iframe_loading = false;
        }
      };
    } else {
      window.setTimeout(this.add_iframe_event, 1000);
    }
  }

  validate_ID() {
    let d: number = -1;
    if (this.location.id_number.length === 13) {
      try {
        let a: number = 0;
        for (let i = 0; i < 6; i++) {
          a += Number(this.location.id_number[2 * i].toString());
        }
        let b: number = 0;
        for (let i = 0; i < 6; i++) {
          b = b * 10 + Number(this.location.id_number[2 * i + 1].toString());
        }
        b *= 2;
        let c: number = 0;
        do {
          c += b % 10;
          b = Math.floor(b / 10);
        } while (b > 0);
        c += a;
        d = 10 - (c % 10);
        if ((d % 10).toString() === this.location.id_number[12]) {
          d = 0;
        } else {
          d = -1;
        }
      } catch (e) {
        d = -1;
      }
    }

    if (d !== 0) {
      this.identity_form.controls.document_id_number.setErrors({ 'Invalid ID': true });
      this.identity_form.controls.id_number.setErrors({ 'Invalid ID': true });
    } else {
      this.identity_form.controls.document_id_number.setErrors(null);
      this.identity_form.controls.id_number.setErrors(null);
    }
  }

  country_code_change() {
      if (this.exchangeName === 'chainex') {
        this.identity_form.controls.id_type.setValidators(Validators.required);

        if (this.location.country.idrequired === '1') {
          this.identity_form.controls.id_number.setValidators(Validators.required);
        } else {
          this.identity_form.controls.id_number.setValidators(null);
        }
        this.identity_form.controls.id_number.updateValueAndValidity();

        if (this.location.selectidtype === 'ID' && !(this.location.country.idrequired === '1')) {
          this.identity_form.controls.document_id_number.setValidators(Validators.required);
        } else {
          this.identity_form.controls.document_id_number.setValidators(null);
        }
        this.identity_form.controls.document_id_number.updateValueAndValidity();

        this.location_form.controls.state.setValidators(Validators.required);
        this.location_form.controls.suburb.setValidators(Validators.required);
        this.location_form.controls.street.setValidators(Validators.required);

        if (this.location.selectidtype === 'Passport' && this.location.country.idrequired === '1') {
          this.identity_form.controls.passport_number.setValidators(Validators.required);
        } else {
          this.identity_form.controls.passport_number.setValidators(null);
        }
        this.identity_form.controls.passport_number.updateValueAndValidity();

        if (this.location.selectidtype === 'Passport' && !(this.location.country.idrequired === '1')) {
          this.identity_form.controls.document_passport_number.setValidators(Validators.required);
        } else {
          this.identity_form.controls.document_passport_number.setValidators(null);
        }
        this.identity_form.controls.document_passport_number.updateValueAndValidity();

      } else {
        this.identity_form.controls.id_type.setValidators(null);
        this.identity_form.controls.id_number.setValidators(null);
        this.identity_form.controls.passport_number.setValidators(null);
        this.location_form.controls.state.setValidators(null);
        this.location_form.controls.suburb.setValidators(null);
        this.identity_form.controls.id_type.updateValueAndValidity();
        this.identity_form.controls.id_number.updateValueAndValidity();
        this.identity_form.controls.passport_number.updateValueAndValidity();
        this.location_form.controls.state.updateValueAndValidity();
        this.location_form.controls.suburb.updateValueAndValidity();
      }
  }

  getCheckedOptions(data: any) {
    const checkedOptions = [];
    Object.keys(data).filter(key => {
      if (data[key]) {
        checkedOptions.push(key.split('_')[1]);
      }
    });
    return checkedOptions;
  }

  submitIdInformation() {
    if (this.identity_form.valid && this.identity_form.untouched) {
      // skip submit if no new changes since the last submit
      if (!!this.idStepper) {
        this.idStepper.next();
      }
    } else {
      if ((this.location.country.idrequired === '1') && (this.exchangeName === 'chainex')) {
        this.validate_ID();
      }
      if (this.identity_form.valid) {
        const data = {
          country_id: this.location.country.id,
          selectidtype: this.location.country.idrequired,
          id_number: ((this.location.country.idrequired === '1') || (this.location.selectidtype === 'ID')) ?
            this.location.id_number : '',
          passport_number: this.location.selectidtype === 'Passport' ? this.location.passport_number : '',
          // convert to unix timestamp in seconds
          date_of_birth: Date.parse(this.location.dateOfBirth.toString()) / 1000,
          proofOfResidence: false
        };

        // submit ID information only, update address in the next step
        this.profileService.verifyAddress(data).subscribe((resp: any) => {
          if (resp.response === 'success') {
            this.showToastNotification.emit('Identification information successfully saved');
            this.result_fail = false;
            this.result_message = '';
            if (!!this.idStepper) {
              this.idStepper.next();
            }
            this.identity_form.markAsUntouched();
          } else {
            this.result_fail = true;
            this.result_message = resp.reason;
          }
        });
      }
    }
  }

  submitResidenceInformation() {
    if (this.location_form.valid && this.location_form.untouched) {
      if (!!this.residenceStepper) {
        // skip submit if no new changes since the last submit
        this.residenceStepper.next();
      }
    } else {
      this.location_form.markAllAsTouched();
      if (this.location_form.valid) {
        const data = {
          state: this.location.state,
          street_no: this.location.street_no,
          suburb: this.location.suburb,
          zipcode: this.location.zipcode,
          city: this.location.city,
          residence_country_id: this.location.residenceCountry.id,
          proof_of_residence: false
        };

        // submit residence information only, then check if verified
        this.profileService.verifyAddress(data).subscribe((resp: any) => {
          if (resp.response === 'success') {
            this.result_fail = false;
            this.result_message = '';

            this.profileService.requestVerification().subscribe((verificationResp: any) => {
              if (verificationResp.response === 'success') {
                this.result_fail = false;
                if (!!this.residenceStepper) {
                  this.residenceStepper.next();
                }
                this.showToastNotification.emit('Residence information successfully saved');
              } else {
                this.result_fail = true;
                this.result_message = verificationResp.reason;
              }
            });
          } else {
            this.result_fail = true;
            this.result_message = resp.reason;
          }
        });
      }
    }
  }

  submitSourceOfFundsForm() {
    this.sourceOfFunds_form.markAllAsTouched();
    if (this.sourceOfFunds_form.valid || !this.complianceAnswersRequired) {
      this.next_click();
    }
  }

  submitAccountPurposeForm() {
    this.accountPurpose_form.markAllAsTouched();
    if (this.accountPurpose_form.valid || !this.complianceAnswersRequired) {
      this.next_click();
    }
  }

  submitComplianceForm() {
    if (!this.complianceAnswersRequired && this.compliance_form.untouched) {
      // If the user did not make new changes, skip saving
      this.requestType++;
      this.verificationSet.emit(this.requestType);
    } else {
      this.result_fail = false;
      this.result_message = '';
      this.compliance_form.markAllAsTouched();
      if (this.compliance_form.valid) {
        const data = {};
        data['compliance_only'] = this.complianceOnly;
        data['question_1'] = this.getCheckedOptions(this.accountPurpose);
        data['question_2'] = this.getCheckedOptions(this.sourceOfFunds);

        Object.keys(this.complianceAnswers).forEach(key => {
          data[key] = (typeof this.complianceAnswers[key] === 'string' ?
            this.complianceAnswers[key] : this.complianceAnswers[key].option_id
          );
        });

        this.profileService.saveComplianceAnswers(data)
          .subscribe((resp: any) => {
            if (resp.response === 'success') {
              this.result_fail = false;
              this.requestType++;
              this.verificationSet.emit(this.requestType);
              this.showToastNotification.emit('Compliance response successfully saved');
            } else {
              this.result_fail = true;
              this.result_message = resp.reason;
            }
          });
      }
    }
  }

  fileLoaded(index: number) {
    this.fileLoading[index] = false;
  }

  onLivenessChecked() {
    this.verificationData.livenessChecked = true;
    this.showToastNotification.emit('Liveness test completed');
  }

  onManualSelfieSwitch() {
    this.manualSelfie = true;
  }

}
