<div class="sidebar-trades">
      <mat-expansion-panel [disabled]="!marketService.expandable" [expanded]="marketService.expanded" class="min-height-50">
        <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
          <div class="table-heading pl-2 markets-heading font-avenir-medium" i18n>TRADE HISTORY</div>
        </mat-expansion-panel-header>

        <mat-tab-group class="sidebar-history">
          <mat-tab i18n-label label="MARKET">
            <div class="trade-height-lim">
            <mat-table id="market" (scroll)="onScroll($event, true)" [dataSource]="marketData" class="height-limit scrollable" [trackBy]="trackByMarketData">
              <ng-container matColumnDef="price">
                <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Price</mat-header-cell>
                <mat-cell class="clickable text-right mr-1" *matCellDef="let element" [ngClass]="[!element.type ? '' : element.type === '0' ? 'buy' : 'sell']">
                  {{element.price | decimalNumber:marketService.activeMarket.exchangeDecimals}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="volume">
                <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Amount</mat-header-cell>
                <mat-cell class="clickable mcw-45px text-right mr-1" *matCellDef="let element"> {{element.amount | decimalNumber: marketService.activeMarket.coinDecimals}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="time">
                <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Date</mat-header-cell>
                <mat-cell class="clickable time-right text-right mr-1" *matCellDef="let element" matTooltip="{{element.time_formatted}}">
                  {{ element.time_display }}
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="tableDefinition; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: tableDefinition;" (click)="loadValue(row)"></mat-row>
            </mat-table>
            </div>
          </mat-tab>
          <mat-tab i18n-label label="YOURS" *ngIf="loggedIn">
            <mat-table id="yours" (scroll)="onScroll($event, false)" [dataSource]="yourData" class="height-limit scrollable" [trackBy]="trackByMarketData">
              <ng-container matColumnDef="price">
                <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Price</mat-header-cell>
                <mat-cell class="clickable text-right mr-1" *matCellDef="let element" [ngClass]="[!element.type ? '' : element.type === '0' ? 'buy' : 'sell']">
                  {{element.price | decimalNumber:marketService.activeMarket.exchangeDecimals}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="volume">
                <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Amount</mat-header-cell>
                <mat-cell class="clickable text-right mr-1" *matCellDef="let element">
                  {{element.amount | decimalNumber: marketService.activeMarket.coinDecimals}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="executionType">
                <mat-header-cell class="text-right mr-1" *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell class="clickable text-right mr-1" *matCellDef="let element"> {{element.executionType}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="time">
                <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Date</mat-header-cell>
                <mat-cell class="clickable time-right text-right mr-1" *matCellDef="let element">
                  {{ element.time_display }}
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="tableDefinition; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: tableDefinition;" (click)="loadValue(row)"></mat-row>
            </mat-table>
          </mat-tab>
        </mat-tab-group>
      </mat-expansion-panel>
</div>
