<div [ngClass]="darkTheme ? 'dark-theme-background' : 'light-theme-background'" class="container-fluid px-0">
  <div class="blueprint-background"> </div>
    <div class="container mt-0 mt-lg-4 mb-2 mb-lg-5 py-2 py-lg-4 px-0 px-lg-auto mx-auto">

  <ng-container *ngIf="!verificationStarted">
    <ng-container *ngIf="requestType === VerificationRequestType.CompliancePending">
      <div class="col-12 col-lg-10 mx-auto p-4" [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 my-2">
            <h2 class="text-center" i18n>
              <span class="orange-text">
                <b>Verification Submitted</b>
              </span>
            </h2>
            <img class="py-4 d-block mx-auto" width="100" src="assets\{{exchangeName}}\images\pages\verification\verified.svg">

            <div class="col-12 my-4 text-center px-3">
              <span i18n>
                Thank you for responding to the Income and Intended Use of Account Questionnaire. Your responses have been
                captured and are currently being reviewed by our team and you shall be notified of the outcome within 48 hours
                of the submission.<br><br>

                Unfortunately your account shall remain blocked from all actions until this review process has been completed.
                We will notify you via email once the process has been completed and your account is ready for use
                again.<br><br>

                Thank you for your patience in this regard.
              </span><br />
              </div>

            <div class="col-12 col-lg-5 mx-auto my-3 pt-3">
              <a class="btn-w d-block col mx-auto my-3 col-12 col-md-9" mat-raised-button color="accent" (click)="logout()" i18n>
                LOGOUT
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="requestType === VerificationRequestType.Level2Pending">
      <div class="col-12 col-lg-10 mx-auto p-4" [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 my-2">
            <h2 class="text-center" i18n>
              <span class="orange-text">
                <b>Verification Submitted</b>
              </span>
            </h2>
            <img class="py-4 d-block mx-auto" width="100" src="assets\{{exchangeName}}\images\pages\verification\verified.svg">
            <div class="col-12 my-4 text-center px-3">
              <span i18n>
                Thank you for submitting your verification attempt.
                Your submission has been captured and is currently being reviewed by our team and you shall be notified of the outcome within 48 hours of the submission.
                <br><br>

                Unfortunately your account shall remain blocked from all actions until this review process has been completed.
                We will notify you via email once the process has been completed and your account is ready for use
                again.<br><br>

                Thank you for your patience in this regard.
              </span><br />
            </div>

            <div class="col-12 col-lg-5 mx-auto my-3 pt-3">
              <a class="btn-w d-block col mx-auto my-3 col-12 col-md-9" mat-raised-button color="accent" (click)="logout()" i18n>
                LOGOUT
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="requestType === VerificationRequestType.Level2Blocked">
      <div class="col-12 col-lg-10 mx-auto p-4" [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 my-2">
            <h2 class="text-center" i18n>
              <span class="orange-text">
                <b>Verification Blocked</b>
              </span>
            </h2>
            <img class="my-4 mt-2 d-block mx-auto" width="100" src="assets\{{exchangeName}}\images\pages\verification\failed.svg">
          </div>

          <div class="col-12 col-lg-10 my-4 text-center px-3">
            <span i18n>
              To remain compliant with new regulations and legislative requirements, we require all users to complete Level
              2 Verification in order to continue using their accounts.<br /><br />
              Unfortunately you have submitted and failed three Verification attempts and in order to protect your account,
              you are currently blocked from submitting another attempt. Please contact support for assistance using the
              button below:
            </span><br />
          </div>

          <div class="col-12 col-lg-10 mx-auto my-3">
            <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7"  mat-raised-button color="primary" href="{{getSupportUrl()}}" i18n>CONTACT SUPPORT</a>
            <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="accent" (click)="logout()" i18n>LOGOUT</a>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="requestType !== VerificationRequestType.CompliancePending && requestType !== VerificationRequestType.Level2Pending  && requestType !== VerificationRequestType.Level2Blocked">
      <div class="col-12 col-lg-10 mx-auto p-4" [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'">
        <ng-container *ngIf="verificationData; else loadingSpinner">
          <div *ngIf="requestType === VerificationRequestType.Compliance; else fullVerification">
            <!-- compliance questions required -->
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10 mt-2 mb-2">
                <h2 class="text-center" i18n>
                  <span class="orange-text">
                    <b>Verification Required</b>
                  </span>
                </h2>
                <img class="my-4 mt-2 d-block mx-auto" width="100" src="assets\{{exchangeName}}\images\pages\verification\verification_yellow.svg">
              </div>

              <div class="col-12 col-lg-10 my-4 text-center px-3">
                <span i18n>
                  To remain compliant with new regulations and legislative requirements, we require all users to answer the questions that follow below.<br />
                  Please take a moment to answer them as you will not be allowed to perform any action on your account until this has been completed.
                </span><br /><br>
              </div>

              <div class="col-12 col-lg-10 mx-auto my-3">
                <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="primary" (click)="openForm()" i18n>
                  CONTINUE TO VERIFICATION
                </a>
                <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="accent" (click)="logout()" i18n>LOGOUT</a>
              </div>
            </div>
          </div>
          <ng-template #fullVerification>
            <div class="row justify-content-center" *ngIf="+profile.kyc_status < 1">
              <!-- start verification -->
              <div class="col-12 col-lg-10 mt-2 mb-2">
                <h2 class="text-center" i18n>
                  <span class="orange-text">
                    <b>Verification Required</b>
                  </span>
                </h2>
                <img class="my-4 mt-2 d-block mx-auto" width="100" src="assets\{{exchangeName}}\images\pages\verification\verification_yellow.svg">
              </div>

              <div class="col-12 col-lg-10 my-4 text-center px-3">
                <span i18n>
                  To remain compliant with new regulations and legislative requirements, we require all users to complete our
                  verification process in order to continue using their accounts.<br><br>
                  Please take a moment to complete the process using the button below, as you will not be allowed to perform any
                  action on your account until this has been completed.
                </span><br /><br>
              </div>

              <div class="col-12 col-lg-10 mx-auto my-3">
                <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="primary" (click)="openForm()" i18n>
                  START VERIFICATION
                </a>
                <!-- <a class="btn-w col m-2 col-11 col-md-8" mat-raised-button color="primary" href="https://support.chainex.io/open.php?topicId=70" i18n>
                  COMPLETE CORPORATE VERIFICATION
                </a> -->
                <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="accent" (click)="logout()" i18n>LOGOUT</a>
              </div>
            </div>

            <div class="row justify-content-center" *ngIf="+profile.kyc_status >= 1 && +profile.kyc_status  < 3">
              <!-- resume verification -->
              <div class="col-12 col-lg-10 mt-2 mb-2">
                <h2 class="text-center" i18n>
                  <span class="orange-text">
                    <b>Resume Verification</b>
                  </span>
                </h2>
                <img class="my-4 mt-2 d-block mx-auto" width="100" src="assets\{{exchangeName}}\images\pages\verification\verification_yellow.svg">
              </div>

              <div class="col-12 col-lg-10 my-4 text-center px-3">
                <span i18n>
                  You previously started the Verification Process but did not complete it. To resume where you left off, please select the resume button below.
                  This will load the step you were busy with last with all previously entered information loaded.<br><br>
                  Please note that you will not be allowed to perform any action on your account this this has been completed.
                </span><br /><br>
              </div>

              <div class="col-12 col-lg-10 mx-auto my-3">
                <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="primary" (click)="openForm()" i18n>
                  RESUME VERIFICATION
                </a>
                <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="accent" (click)="logout()" i18n>LOGOUT</a>
              </div>
            </div>

            <div class="row justify-content-center" *ngIf="+profile.kyc_status >= 3">
              <div class="col-12 col-lg-10 mt-2 mb-2">
                <h2 class="text-center" i18n>
                  <span class="orange-text">
                    <b>Retry Verification</b>
                  </span>
                </h2>
                <img class="my-4 mt-2 d-block mx-auto" width="100" src="assets\{{exchangeName}}\images\pages\verification\verification_yellow.svg">
              </div>

              <div class="col-12 col-lg-10 my-4 text-center px-3">
                <span i18n>
                  Unfortunately your previously submitted Verification attempt was unsuccessful.
                  An email was sent to you outlining the reasons for it being unsuccessful and the changes required.<br><br>
                  Please retry the Verification process below. To assist you, information which failed the check will be indicated.
                  All previously submitted information will also be reloaded, allowing you to update all relevant information instead of entering it again.
                </span><br /><br>
              </div>

              <div class="col-12 col-lg-10 mx-auto my-3">
                <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="primary" (click)="openForm()" i18n>
                  RETRY VERIFICATION
                </a>
                <a class="btn-w d-block col mx-auto my-3 col-12 col-md-7" mat-raised-button color="accent" (click)="logout()" i18n>LOGOUT</a>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>



  <ng-container *ngIf="verificationStarted"> <!-- start verification wizard -->
    <div class="container mt-0 mt-lg-4 mb-2 mb-lg-4 py-2 py-lg-3 mx-auto">
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-lg-2 px-0 mb-2 mb-lg-0">
          <div [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'"
            class="mx-2 px-2 px-lg-4 py-0 py-lg-2 mb-1 mb-0 mb-lg-3 d-flex flex-column justify-content-around">
            <div class="row d-flex flex-grow-1 justify-content-center">
              <div class="col-12 d-flex flex-row flex-lg-column align-items-center">
                  <img *ngIf="requestType === VerificationRequestType.Mobile; else mobileInactive" class="col col-lg-auto px-0 column-icon" src="{{assetsPath}}mobile_orange.svg">
                  <ng-template #mobileInactive>
                    <img class="col col-lg-auto px-0 column-icon"
                    src="{{requestType > VerificationRequestType.Mobile ? assetsPath + 'mobile_green.svg' : assetsPath + 'mobile_grey.svg'}}">
                  </ng-template>

                  <img *ngIf="requestType === VerificationRequestType.Identity; else identityInactive" class="col col-lg-auto px-0 column-icon" src="{{assetsPath}}identity_orange.svg">
                  <ng-template #identityInactive>
                    <img class="col col-lg-auto px-0 column-icon"
                    src="{{requestType > VerificationRequestType.Identity ? assetsPath + 'identity_green.svg' : assetsPath + 'identity_grey.svg'}}">
                  </ng-template>

                  <img *ngIf="requestType === VerificationRequestType.Residence; else residenceInactive" class="col col-lg-auto px-0 column-icon" src="{{assetsPath}}residence_orange.svg">
                  <ng-template #residenceInactive>
                    <img class="col col-lg-auto px-0 column-icon"
                    src="{{requestType > VerificationRequestType.Residence ? assetsPath + 'residence_green.svg' : assetsPath + 'residence_grey.svg'}}">
                  </ng-template>

                  <img *ngIf="requestType === VerificationRequestType.Compliance; else complianceInactive" class="col col-lg-auto px-0 column-icon" src="{{assetsPath}}income_orange.svg">
                  <ng-template #complianceInactive>
                    <img class="col col-lg-auto px-0 column-icon"
                    src="{{(requestType > VerificationRequestType.Compliance || complianceAnswered) ? assetsPath + 'income_green.svg' : assetsPath + 'income_grey.svg'}}">
                  </ng-template>

                  <img *ngIf="requestType === VerificationRequestType.Bank; else bankInactive" class="col col-lg-auto px-0 column-icon" src="{{assetsPath}}bank_orange.svg">
                  <ng-template #bankInactive>
                    <img class="col col-lg-auto px-0 column-icon"
                    src="{{(requestType > VerificationRequestType.Bank || +profile.kyc_status >= 3) ? assetsPath + 'bank_green.svg' : assetsPath + 'bank_grey.svg'}}">
                  </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-9 col-xl-8 px-0"> <!-- verification steps box -->
          <div [ngClass]="darkTheme ? 'dark-signup-box':'signup-box'" class="mx-2 px-2 px-lg-4 py-4">
            <ng-container *ngIf="verificationData; else loadingSpinner">
              <ng-container *ngIf="requestType === VerificationRequestType.Mobile">
                  <app-verification-phone [verificationData]="verificationData" [darkTheme]="darkTheme"
                    (verificationNext)="onVerificationNext()" (verificationCancel)="onVerificationCancel()" (showToastNotification)="onShowToastNotification($event)">
                  </app-verification-phone>
              </ng-container>
              <ng-container *ngIf="(requestType >= VerificationRequestType.Identity) && (requestType < VerificationRequestType.Bank)">
                  <app-verification-location [verificationData]="verificationData" [requestType]="requestType" [darkTheme]="darkTheme"
                    (verificationSet)="onVerificationSet($event)" (verificationCancel)="onVerificationCancel()" (showToastNotification)="onShowToastNotification($event)" (complianceAnswered)="onComplianceAnswered()">
                  </app-verification-location>
              </ng-container>
              <ng-container *ngIf="requestType == VerificationRequestType.Bank">
                <h2 class="text-center" i18n>
                  <span class="orange-text">
                      <b>Bank Account Verification</b>
                  </span>
                </h2>
                <div class="border-2 my-3 p-3">
                  <small class="d-block text-center" i18n>
                    In order to withdraw South African Rands, a Bank Account needs to be linked and verified against your entered ID Number.
                    To link an account now, please enter your bank account details below.<br>
                    Please note that we only support South African bank accounts and the withdrawal of South African Rands at this stage.
                    This step my be skipped if you do not wish to link a Bank Account or do not own a South African Bank Account
                  </small>
                </div>
                <div class="row mx-0 justify-content-center">
                  <button class="col-12 col-lg-3 mt-1 text-white yellow-button" mat-raised-button (click)="onVerificationNext()">SKIP THIS STEP</button>
                </div>
                <app-bank-address-book [viewDisplay]="AddressView.Add" [setting]="verificationData" (verificationNext)="onVerificationNext()"></app-bank-address-book>
              </ng-container>

            </ng-container>
            <ng-template #loadingSpinner>
              <div class="row h-100">
              <mat-progress-spinner class="m-auto" mode="indeterminate" color="primary"></mat-progress-spinner>
              </div>
            </ng-template>
          </div>
        </div> <!-- end verification steps box -->
      </div>
    </div>
  </ng-container> <!-- end verification wizard -->
  </div>
</div>
