<mat-icon class="dialog-close" (click)="dialogRef.close()">close</mat-icon>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 pb-2 text-center">
            <h3>{{data.title|i18n}}</h3>

            <img *ngIf="data.result==='success'" src="assets\{{exchangeName}}\images\pages\common\cx_confirmation.svg" width="90" class="pb-4 pt-4">
            <img *ngIf="data.result==='failed'" src="assets\{{exchangeName}}\images\pages\common\cx_failed.svg" width="90" class="pb-4 pt-4">

            <div class="mt-2 mb-2 text-left" innerHTML="{{data.isTranslated ? data.reason : data.reason|i18n}}">
            </div>
        </div>

        <div class="col-6 text-left pt-2">
            <button mat-raised-button *ngIf="data.okButton" color="accent" (click)="close_click()" i18n>NO</button>
        </div>

        <div class="col-6 text-right pt-2">
            <button mat-raised-button *ngIf="!data.okButton" color="accent" (click)="close_click()" i18n>CLOSE</button>
            <button *ngIf="data.okButton" mat-raised-button color="primary" class="mr-2" (click)="ok_click()" i18n>YES</button>
        </div>
    </div>
</div>
