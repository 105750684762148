import {
  Component, OnInit, OnDestroy, Output, EventEmitter,
  ChangeDetectorRef, ChangeDetectionStrategy
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionLike } from 'rxjs';
import { DatePipe } from '@angular/common';

import { SessionStorageService } from './../../../core/services/session-storage.service';
import { MarketsService } from '../../markets.service';
import { StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'markets-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticsComponent implements OnInit, OnDestroy {
  dp: DatePipe = new DatePipe('en-US');
  tableDefinition: string[] = [
    'price',
    'volume',
    'time'
  ];
  today: string = new Date().toDateString();
  loggedIn: boolean;
  marketData: MatTableDataSource<any> = new MatTableDataSource([]);
  marketTimeout: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  yourData: MatTableDataSource<any> = new MatTableDataSource([]);
  yourTimeout: any;

  marketDataRequest: any = {
    'perPage': 25,
    'pageNo': 0,
    'orderBy': 'time',
    'order': 'DESC',
    'market': '',
  };

  yourDataRequest: any = {
    'perPage': 25,
    'pageNo': 0,
    'orderBy': 'time',
    'order': 'DESC',
    'market': '',
  };

  scrollEnd: boolean = false;


  private subs: SubscriptionLike[] = [];

  constructor(
    public marketService: MarketsService,
    private sessionStorage: SessionStorageService,
    private store: StoreService,
    private cdr: ChangeDetectorRef
  ) {
    if (this.sessionStorage.get('LOGGED_IN') !== '' &&
      this.sessionStorage.get('TOKEN') !== '') {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }

  ngOnInit() {
    this.subs.push(this.marketService.activeMarketSubject.subscribe(() => {
      this.getMarketData();
      if (this.loggedIn) {
        this.getYourData();
      }
    }));

    const spliceData = (response: any, mine: boolean = false) => {
      const data = mine ? this.yourData.data : this.marketData.data;

      response.type = response.order === 'buy' ? '0' : '1';
      response.time_formatted = this.dp.transform(new Date(
        response.time.replace(/-/g, '/') + ' UTC'), 'yyy-MM-dd HH:MM:SS');
      response.time_display = this.formatDate(response.time + ' UTC');

      data.splice(0, 0, response);
      this[mine ? 'yourData' : 'marketData'].data = data;
    };

    this.subs.push(this.store.subscribe('tradeHistory').subscribe(response => {
      if (this.loggedIn && response.isUserSpecific && response.messageType === 'specific-user-trade' &&
        response.market === this.marketService.activeMarket.marketPair) {
        spliceData(response, true);
      }

      if (response.messageType === 'trade' && response.feed === `marketUpdates-${this.marketService.activeMarket.id}`) {
        spliceData(response);
      }

      this.cdr.markForCheck();
    }));

    window.addEventListener('resize', () => { this.marketService.setExpandable(); });
    this.marketService.setExpandable();
  }

  ngOnDestroy() {
    for (let i = 0; i < this.subs.length; i++) {
      this.subs[i].unsubscribe();
    }
    window.removeEventListener('resize', () => { this.marketService.setExpandable(); });
  }

  private formatDate(dateString: string) {
    if (!dateString) {
      dateString = '';
    }
    const date = new Date(dateString.replace(/-/g, '/'));
    return this.today === date.toDateString() ?
      date.toLocaleTimeString() :
      (dateString ? this.dp.transform(date, 'yyyy-MM-dd') : new Date().toLocaleTimeString());
  }

  getMarketData(append?: boolean) {
    if (this.marketDataRequest.market !== this.marketService.activeMarket.id) {
      this.resetScroll();
    }

    this.marketDataRequest.market = this.marketService.activeMarket.id;
    if (append) {
      this.marketDataRequest.pageNo = ++this.marketDataRequest.pageNo;
    }
    if (!!this.marketTimeout) {
      clearTimeout(this.marketTimeout);
    }
    this.marketTimeout = setTimeout(() => {
      this.marketService.getTradeHistory(this.marketDataRequest).subscribe((response) => {
        if (response.response === 'success') {
          if (!this.marketTimeout) {
            clearTimeout(this.marketTimeout);
          }

          response.data.forEach(row => row.time_display = this.formatDate(row.time_formatted));

          if (append) {
            this.marketData.data = [ ...this.marketData.data, ...response.data];
          } else {
            this.marketData.data = response.data;
          }
          this.cdr.markForCheck();
        }
      });
    }, 500);
  }

  getYourData(append?: boolean) {
    this.yourDataRequest.market = this.marketService.activeMarket.id;
    if (append) {
      this.yourDataRequest.pageNo = ++this.yourDataRequest.pageNo;
    }
    if (!!this.yourTimeout) {
      clearTimeout(this.yourTimeout);
    }
    this.yourTimeout = setTimeout(() => {
      this.marketService.getMyTradeHistory(this.yourDataRequest).subscribe((response) => {
        if (response.response === 'success') {
          response.data.forEach(row => row.time_display = this.formatDate(row.time_formatted));
          if (append) {
            this.yourData.data = [ ...this.yourData.data, ...response.data];
          } else {
            this.yourData.data = response.data;
          }
        }
      });
      this.cdr.markForCheck();
    }, 500);
  }

  loadValue(row: any) {
    this.valueChange.emit({ price: +row.price, amount: +row.amount, userSpecified: true, marketChanged: false });
    this.cdr.markForCheck();
  }

  isToday(date: Date) {
    return this.today === new Date(date).toDateString();
  }

  trackByMarketData(_index: number, market: any) {
    return market.time + market.type;
  }

  resetScroll() {
    const marketTable = document.getElementById('market');
    if (marketTable) {
      marketTable.scrollTop = 0;
    }
    const yourTable = document.getElementById('yours');
    if (yourTable) {
      yourTable.scrollTop = 0;
    }
    this.yourDataRequest.pageNo = 0;
    this.marketDataRequest.pageNo = 0;
  }

  onScroll(event: any, market: boolean) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.scrollEnd) {
        return;
      }
      this.scrollEnd = true;
      if (market) {
        this.getMarketData(true);
      } else {
        this.getYourData(true);
      }
    } else {
      this.scrollEnd = false;
    }
  }
}
