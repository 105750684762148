import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SubscriptionLike } from 'rxjs';

import { MarketsService } from '../../markets.service';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'markets-top-stats',
  templateUrl: './top-stats.component.html',
  styleUrls: ['./top-stats.component.scss']
})
export class TopStatsComponent implements OnInit, OnDestroy {
  @Input() showPairName: boolean = true;
  @Input() setPageTitle: boolean = true;
  activeMarketSub: SubscriptionLike;

  showMarket: boolean = environment.config.MARKET_PAIR;

  siteName: string = environment.config.EXCHANGE_NAME_L;

  constructor(
    private readonly meta: MetaService,
    private i18n: I18n,
    public marketsService: MarketsService) { }

  ngOnInit() {
    this.activeMarketSub = this.marketsService.activeMarketSubject.subscribe((response) => {
      this.activeMarketUpdated();
    });
    if (this.marketsService.activeMarket) {
      this.activeMarketUpdated();
    }
  }

  ngOnDestroy() {
    this.activeMarketSub.unsubscribe();
  }

  buildImageString() {
    const imageurl = environment.config.BACKEND_URL + '/action/getCoin?coinIcon=' +
      this.marketsService.activeMarket.coinBalance.icon;
    return imageurl;

  }

  activeMarketUpdated() {
    this.setMarketMetaTitle();
  }

  public setMarketMetaTitle() {
    let exchangeName = '';
    if (environment.config.EXCHANGE_NAME_L !== 'chainex') {
      exchangeName = 'BurnX';
    } else {
      exchangeName = 'ChainEX';
    }
    if (!!this.marketsService.activeMarket.marketPair &&
        this.marketsService.activeMarket.lastPrice !== undefined &&
        this.setPageTitle) {
      this.meta.setTitle(
        `(${this.marketsService.activeMarket.lastPrice} ${this.marketsService.activeMarket.marketPair}) - ` +
        exchangeName  + ' - ' + this.i18n('Market'));
    } else if (this.setPageTitle) {
      this.meta.setTitle(environment.config.EXCHANGE_NAME + ' - ' + this.i18n('Market'));
    }
  }

}
