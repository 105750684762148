<mat-icon class="dialog-close" (click)="dialogRef.close()">close</mat-icon>
<div class="container-fluid dialog-over-flow">
    <div class="row">
        <div class="col-12 mb-2 mt-2">
            <div class="row justify-end mr-2">
                <div class="wallet-name m-t-10 ml-3">
                  <span class="t-16">Your {{balance.name}} wallet</span>
                </div>
                <div class="row m-l-2 justify-end border-box">
                    <span><b>
                        <ng-container i18n>Available Balance:</ng-container> {{setting.code === 'ZAR' ? (balance.available | decimalNumber: 2)
                        : (balance.available | decimalNumber: 8)}} {{setting.code}}
                    </b></span>
                    <app-balance-viewer
                        [available]="balance.total"
                        [held]="balance.held"
                        [code]="setting.code"
                        [earn]="balance.earn"
                        [showButtons]="false">
                    </app-balance-viewer>
                </div>
            </div>
        </div>
        <div class="col-12">
            <mat-tab-group class="w-100 main-label" (selectedTabChange)="switchTab($event)" #actionTab>
                <mat-tab i18n-label label="Deposit">
                    <app-deposit
                      [coin]="setting.code"
                      [type]="setting.type"
                      [selectedCoinNetwork]="balance.selectedCoinNetwork"
                      [coinNetworks]="coinNetworks"
                      [dialogRef]="dialogRef"
                      (networkChange)="setNetwork($event)"
                      (showHistoryChange)="toggleHistory($event)"
                      #deposit
                    ></app-deposit>
                </mat-tab>
                <mat-tab i18n-label label="Withdraw">
                    <app-withdrawal
                    [coin]="setting.code"
                    [type]="setting.type"
                    [selectedCoinNetwork]="balance.selectedCoinNetwork"
                    [coinNetworks]="coinNetworks"
                    [dialogRef]="dialogRef"
                    (networkChange)="setNetwork($event)" #withdrawal></app-withdrawal>
                </mat-tab>
                <mat-tab i18n-label label="Trade">
                    <app-trade-summary [coin]="setting.code" [type]="setting.type" #trade></app-trade-summary>
                </mat-tab>
                <mat-tab *ngIf="setting.has_product && setting.code !== 'ZAR'" i18n-label label="Earn Portal">
                    <app-earn-portal [coin]="setting.code" [type]="setting.type" [earnPage]="false" #earnPortal></app-earn-portal>
                </mat-tab>
            </mat-tab-group>
        </div>

        <mat-tab-group [ngClass]="showHistory ? null : 'd-none'" class="w-100 main-label" #historyTab>
            <mat-tab i18n-label label="Deposits">
              <ng-template matTabContent>
                <app-transaction-history [coin]="setting.code" [type]="setting.type" [mode]="0" #depositHistory></app-transaction-history>
              </ng-template>
            </mat-tab>
            <mat-tab i18n-label label="Withdrawals">
              <ng-template matTabContent>
                <app-transaction-history [coin]="setting.code" [type]="setting.type" [mode]="1" #withdrawalHistory></app-transaction-history>
              </ng-template>
            </mat-tab>
            <mat-tab i18n-label label="Open Orders">
              <ng-template matTabContent>
                <app-transaction-history [coin]="setting.code" [type]="setting.type" [mode]="2" #activeOrders></app-transaction-history>
              </ng-template>
            </mat-tab>
            <mat-tab i18n-label label="Trade History">
              <ng-template matTabContent>
                <app-trade-history [coin]="setting.code" [type]="setting.type" #tradeHistory></app-trade-history>
              </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
