import * as Big from 'big-js';

export class UtilHelper {
    static strToNumber(str: string): number {
            if (!str) {
                return 0;
            } else {
                return Number(str.replace('<', '').replace('>', ''));
            }
    }

    static bigValueToFixed(value: Number | Big, decimals: number): string {
      let amount: number = 0;
      try {
        if (value) {
          const ten = new Big(10);
          const multiplier = ten.pow(+decimals < 8 ? +decimals : 8);
          if (UtilHelper.checkDecimalSpacesLimit(value, decimals)) {
            amount = new Big(Math.floor(+(new Big(+value).times(multiplier)))).div(multiplier);
            value = new Big(amount);
          }
        } else {
          value = 0;
        }
        return (+value).toFixed(decimals);
      } catch (ex) {
        console.log(ex);
        return '0';
      }
    }

    static checkDecimalSpacesLimit(value: any, decimalSpaces: number) {
      const decimals = String(value).split('.');
      if (decimals[1] != null) {
        if (decimals[1].length > decimalSpaces) {
          return true;
        }
      }
      return false;
    }

    // check if provided string is a wallet or transaction address
    static isAddress(value: string) {
      const regexTelephone: RegExp = /^\d{10}$/;
      const regexEmail: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      const noSpaces = value.indexOf(' ') === -1; // to check for phrases like 'REFFERAL REWARD'
      const isTelephone = value.match(regexTelephone);
      const isEmail = value.match(regexEmail);
      const isInternalReferral = value.indexOf('REFERRAL') === 0;
      const isInternalXFER = value.indexOf('XFER') === 0;
      return (noSpaces && !isTelephone && !isEmail && !isInternalReferral && !isInternalXFER);
  }

}

export const CUSTOM_DATEPICKER_FORMAT = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'YYYY/MM/DD',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY',
    },
};
