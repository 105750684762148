import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UtilHelper } from 'src/app/core/helpers/util-helper';
import { MarketsService, IMarketResponse } from 'src/app/markets/markets.service';
import { SubscriptionLike } from 'rxjs';
import { IMarketData } from 'src/app/core/interfaces/home-interface';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-menu-stats',
  templateUrl: './menu-stats.component.html',
  styleUrls: ['./menu-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuStatsComponent implements OnInit, OnDestroy {
  @Input() collapsedView: boolean = false;
  @Output() menuClose: EventEmitter<any> = new EventEmitter();

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  coinPrices: any[];
  coinVolumes: any[];
  dynamicStats: any[];

  totalVolume: number = 0;

  marketUpdateSubscription: SubscriptionLike;
  marketPairsSubscription: SubscriptionLike;

  rawStats: IMarketData[] = [];

  constructor(
    public router: Router,
    public marketsService: MarketsService,
    private i18n: I18n,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.marketUpdateSubscription = this.marketsService.marketUpdateSubject.subscribe(() => {
      // TODO: Use promise???
      this.marketPairsSubscription = this.marketsService.getMarketPairsWithStats().pipe(take(1)).subscribe(data => {
        this.onMarketChange(data);
        this.cdr.detectChanges();
      });
    });
  }

  onMarketChange(markets: IMarketResponse) {
    this.generateTopBarStats(markets);
  }

  ngOnDestroy() {
    this.marketUpdateSubscription.unsubscribe();
    if (this.marketPairsSubscription) {
      this.marketPairsSubscription.unsubscribe();
    }
  }

  generateTopBarStats(response: IMarketResponse) {
    this.rawStats = response.data;
    if (!response.stats.volume) {
      return;
    }

    const stats: IMarketData [] = [];
    const dStats: IMarketData [] = [];
    let maxVol: IMarketData = null;
    let maxChange: IMarketData = null;
    let minChange: IMarketData = null;
    let statDataObj: IMarketData = null;
    this.totalVolume = 0;

    for (let i = 0; i < response.data.length; i++) {
      if (
        response.data[i].marketPair === 'BTC/USDT' ||
        (response.data[i].marketPair === 'BTC/ZAR' && this.exchangeName === 'chainex')
      ) {
        statDataObj = {...response.data[i]};
        stats.push(statDataObj);
      }

      this.totalVolume += UtilHelper.strToNumber(response.data[i].btcEstimate);
      if (i > 0) {
        if (response.data[i].marketVolumeInBTC > maxVol.marketVolumeInBTC) {
          maxVol = {...response.data[i]};
          maxVol.type = 'maxVol';
          maxVol.text = this.i18n('HIGHEST VOL');
        }

        if (UtilHelper.strToNumber(response.data[i].change) > UtilHelper.strToNumber(maxChange.change) ) {
          maxChange = {...response.data[i]};
          maxChange.type = 'maxChange';
          maxChange.text = this.i18n('HIGHEST CHANGE');
        }

        if (UtilHelper.strToNumber(response.data[i].change) < UtilHelper.strToNumber(minChange.change) ) {
          minChange = {...response.data[i]};
          minChange.type = 'minChange';
          minChange.text = this.i18n('LOWEST CHANGE');
        }
      } else {
        maxVol = {...response.data[i]};
        maxVol.type = 'maxVol';
        maxVol.text = this.i18n('HIGHEST VOL');

        maxChange = {...response.data[i]};
        maxChange.type = 'maxChange';
        maxChange.text = this.i18n('HIGHEST CHANGE');

        minChange = {...response.data[i]};
        minChange.type = 'minChange';
        minChange.text = this.i18n('LOWEST CHANGE');
      }

      if (response.data.length - 1 === i) {
        dStats.push(maxVol);
        dStats.push(maxChange);
        dStats.push(minChange);
        this.dynamicStats = dStats;
        this.coinPrices = stats;
        this.coinVolumes = [
          { coinCode: 'USDT', volume: response.stats.volume['USDT'] }
        ];
      }
    }
  }

  openMarket(stat: IMarketData) {
    if (!!stat.coinCode && !!stat.exchangeCode) {
      this.menuClose.emit();
      this.router.navigate([`markets/${stat.coinCode}/${stat.exchangeCode}`]);
      const market = this.marketsService.getMarketPair(stat.id);
      if (!!market) {
        this.marketsService.setActiveMarket(market);
      }
    }
  }

}
