import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit, TemplateRef, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd, Event, ActivatedRoute, RouterEvent, NavigationStart } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { distinctUntilChanged, filter, take, takeUntil, tap } from 'rxjs/operators';
import { isScullyRunning } from '@scullyio/ng-lib';
import { Subject } from 'rxjs';

import { SessionStorageService } from './core/services/session-storage.service';
import { AutoLogoutService } from './core/services/auto-logout.service';
import { AutoLogoutDialogComponent } from './auto-logout-dialog/auto-logout-dialog.component';
import { EventHubService } from './core/services/event-hub.service';
import { SplashService } from './core/services/splash.service';
import {
  VerificationLevelZeroUpdateDialogComponent
} from './profile/verification-level-zero-update-dialog/verification-level-zero-update-dialog.component';
import { AuthService } from './auth/auth.service';
import { environment } from '../environments/environment';
import { StoreService } from './core/services/store.service';
import { RouterHelper } from './core/router-helper';
import { FireworkAnimationComponent } from './shared/firework-animation/firework-animation.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { RequestProofOfResidenceComponent } from '../app/request-proof-of-residence/request-proof-of-residence.component';
import { RequestVerificationComponent } from './request-verification/request-verification.component';
import { VerificationRequestType } from './core/interfaces/profilesettings';
import { VerificationLocationComponent } from './profile/verification-location/verification-location.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('logoutmodel') logoutmodel: TemplateRef<any>;
  @ViewChild('chat') chat: ContactSupportComponent;
  modal_open: Boolean = false;
  firstlogin: boolean = true;
  noTrade: boolean = false;
  parent: any;
  exchangeName: string = environment.config.EXCHANGE_NAME_L;
  showForm: boolean = true;

  hideHeader: boolean = false;
  hideFooter: boolean = false;

  loggedIn: boolean = false;

  loginStateChecker: any;
  currentTheme: string = 'dark-theme';

  private dialogRef: MatDialogRef<AutoLogoutDialogComponent>;
  private dialogRef_POR: MatDialogRef<RequestProofOfResidenceComponent>;
  private dialogRef_RV: MatDialogRef<RequestVerificationComponent>;
  private dialogRef_VL: MatDialogRef<VerificationLocationComponent>;
  private _timeout$: Subject <void> = new Subject;

  constructor(
    public router: Router,
    private autoLogout: AutoLogoutService,
    private eventhub: EventHubService,
    private overlayContainer: OverlayContainer,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    private sessionStorage: SessionStorageService,
    private service: AuthService,
    private store: StoreService,
    private route: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private splash: SplashService,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private cdRef: ChangeDetectorRef,
  ) {
    this.eventhub.listen().subscribe((m: any) => {
      this.onEvent(m);
    });
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.startsWith('/404')) {
          this.splash.stop();
        }
        const scroll = document.getElementsByClassName('bottom-container')[0];
        if (scroll && !document.location.hash) {
          scroll.scrollTop = 0;
        } else if (document.location.hash) {
          const element = document.querySelector(document.location.hash);
          if (element) {
            element.scrollIntoView();
          }
        }

        if (this.firstlogin === true &&
          this.sessionStorage.get('LOGGED_IN') === 'logged_in' &&
          this.sessionStorage.get('PROFILE_FIRST_NAME') === '' &&
          !['/terms', '/about', '/fees', '/api', '/security', '/bounty',
          '/legal', '/privacy', '/amlkyc', '/risk', '/profile']
            .find(url => event.urlAfterRedirects.startsWith(url))) {

          this.firstlogin = false;
          if (!this.modal_open) {
            this.modal_open = true;
            const dialogRef = this.dialog.open(VerificationLevelZeroUpdateDialogComponent,
              { width: '500px', disableClose: true });

            dialogRef.afterClosed().subscribe(
              () => {
                this.modal_open = false;
                if (dialogRef.componentInstance.cancel) {
                  dialogRef.componentInstance.cancel = false;
                  this.router.navigate(['/profile']);
                }
              }
            );
          }
          this.sessionStorage.store('FORCED_LOGOUT', false);
        }

        if (this.sessionStorage.get('FIRST_LOGIN') &&
            this.sessionStorage.get('LOGGED_IN') === 'logged_in') {
            // Welcome first login!
            this.store.pushGreetingMessage('LOGIN');
            this.showFireworks();
            this.sessionStorage.remove('FIRST_LOGIN');
        }

        // check if verification still required
        const verificationTypeRequired = this.sessionStorage.get('verificationTypeRequired');
        if (verificationTypeRequired && router.url !== '/verificationwizard') {
          this.eventhub.emit(verificationTypeRequired);
        }
      }
    });
    angulartics2GoogleTagManager.startTracking();

    // Close any opened dialog when route changes
    router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this.dialog.closeAll())
    ).subscribe();
    this.registerIcons();
  }

  ngOnInit() {
    let theme: string = this.sessionStorage.get('THEME');
    theme = theme === 'light-theme' ? 'light-theme' : 'dark-theme';
    this.onSetTheme(theme);
    // make the initial call to the backend to setup the token for future requests
    // not sure if this is the best place to put it?
    /*const locationParts = this.router.url.toLowerCase().split('/');
    if (locationParts.length === 0 || ['chat', '/', ''].indexOf(locationParts[1])) {
      this.hideHeader = true;
      this.hideFooter = true;
    }*/
    this.sessionStorage.observe('LOGGED_IN').subscribe(
      (data) => {
        this.sessionStorage.resetTabs();
        this.loggedIn = data === 'logged_in';
        if (this.loggedIn) {
          this.autoLogout.logoff = false;
          this.autoLogout.startIfNotStarted();
          const url = window.location.pathname;
          if (url.startsWith('/login/')) {
            this.router.navigate([
              (url.startsWith('/login/verify/') || url.startsWith('/login/unblock/')) ?
                this.router.navigate(['/profile']) : url.replace('/login', '')]);
          } else if (url.startsWith('/login')
            || url.startsWith('/signup')
            || url.startsWith('/register')
            || url === '/') {
            const level = !!this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL')
              ? Number(this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL'))
              : 0;
            RouterHelper.goToRedirect(this.router, this.route, level);
          }
          // initialize on log in only
          this.store.hasFirstTrade(false);
        } else {
          this.autoLogout.destroyInterval();
          const token = this.sessionStorage.get('TOKEN');
          const forced = this.sessionStorage.get('FORCED_LOGOUT');
          const apiError = this.sessionStorage.get('API_ERROR_LOGOUT');
          const loggedOutReason = this.sessionStorage.get('LOGGED_OUT_REASON');
          this.sessionStorage.purge();
          this.sessionStorage.store('API_ERROR_LOGOUT', apiError);
          this.sessionStorage.store('FORCED_LOGOUT', forced);
          this.sessionStorage.store('LOGGED_OUT_REASON', loggedOutReason);
          this.sessionStorage.store('TEMP_TOKEN', token);
          if (apiError) {
            this.router.navigate(['/login']);
            this.service.autoLogout(window.location.pathname)
              .subscribe(() => {
            });
            RouterHelper.goToLogin(this.router, null);
          } else if (forced === true) {
            this.service.autoLogout(window.location.pathname)
              .subscribe(() => {
            });
            RouterHelper.goToLogin(this.router, null);
          } else {
            this.service.logout()
              .subscribe(() => {
            });
            if (data !== 'SOFT_LOGOUT') {
              this.router.navigate([`/`]);
            }
          }
        }
      });
    this.sessionStorage.observe('THEME').subscribe(
      (data) => {
        if (this.currentTheme !== data && (data === 'light-theme' || data === 'dark-theme')) {
          const el = <HTMLIFrameElement>document.querySelector('#recaptcha iframe');
          const o = <HTMLElement>document.querySelector('.recaptcha-overlay');
          if (el !== null && typeof el !== 'undefined') {
            if (o !== null && typeof o !== 'undefined') { o.style.display = 'block'; }
            el.src = el.src.replace(`&theme=${this.currentTheme.replace('-theme', '')}`,
              `&theme=${data.replace('-theme', '')}`);
            el.onload = () => {
              this.updateTheme(data);
              if (o !== null && typeof o !== 'undefined') { o.style.display = 'none'; }
            };
          } else { this.updateTheme(data); }
        }
      });

    if (this.sessionStorage.get('LOGGED_IN') === 'logged_in') {
      this.store.subscribe('firstTrade').pipe(
        distinctUntilChanged(),
        tap((value) => {
          if (value?.firstTrade) {
            this.store.pushGreetingMessage('TRADE');
            this.showFireworks();
          }
        }),
      takeUntil(this._timeout$)).subscribe();
    }
  }

  private updateTheme(newTheme: string) {
    const currentTheme = this.currentTheme;
    this.onSetTheme(newTheme);
    const elements: any = document.getElementsByClassName(currentTheme);
    while (currentTheme !== newTheme && elements.length > 0) {
      const element = elements[0];
      element.classList.remove(currentTheme);
      element.classList.add(newTheme);
    }
  }

  ngOnDestroy() {
    this._timeout$.next();
    this._timeout$.complete();
    if (!!this.loginStateChecker) {
      clearInterval(this.loginStateChecker);
    }
  }

  ngAfterViewInit() {
    if (!!this.chat) {
      this.chat.SetParent(this);
      this.cdRef.detectChanges();
    }
    if (!isScullyRunning()) {
      setTimeout(() => this.splash.stop(), 1500);
    }
  }

  setForm(chat: ContactSupportComponent) {
    if (!!this.parent && !!this.parent.setForm) {
      this.parent.setForm(chat);
    }
  }

  onEvent(event: any) {
    switch (event) {
      case 'show-autologout-modal':
        if (!this.modal_open) {
          this.modal_open = true;
          this.dialogRef = this.dialog.open(AutoLogoutDialogComponent, { width: '450px', disableClose: true });

          this.dialogRef.afterClosed().subscribe(
            () => {
              this.modal_open = false;
              if (this.dialogRef.componentInstance.logout) {
                this.dialogRef.componentInstance.logout = false;
                this.logout(this.dialogRef.componentInstance.auto);
              } else {
                this.eventhub.emit('reset-logout-time');
              }
            }
          );
        }
        break;
      case 'force-autologout':
        if (this.modal_open) {
          this.dialogRef.componentInstance.auto = true;
          this.dialogRef.componentInstance.confirmModalLogout();
        } else {
          this.logout(false);
        }
        break;
      case 'proof-of-residence-requred':
        this.sessionStorage.store('requestType', VerificationRequestType.Residence);
        this.router.navigate(['/verificationwizard']);
        break;
      case 'compliance-responses-required':
        this.sessionStorage.store('requestType', VerificationRequestType.Compliance);
        this.router.navigate(['/verificationwizard']);
        break;
      case 'compliance-responses-pending':
        this.sessionStorage.store('requestType', VerificationRequestType.CompliancePending);
        this.router.navigate(['/verificationwizard']);
        break;
      case 'level2-verification-blocked' :
        this.sessionStorage.store('requestType', VerificationRequestType.Level2Blocked);
        this.router.navigate(['/verificationwizard']);
        break;
      case 'level2-verification-required':
        this.sessionStorage.store('requestType', VerificationRequestType.Identity);
        this.router.navigate(['/verificationwizard']);
        break;
      case 'level2-verification-pending':
        this.sessionStorage.store('requestType', VerificationRequestType.Level2Pending);
        this.router.navigate(['/verificationwizard']);
        break;
      case 'level1-verification-required':
        this.sessionStorage.store('requestType', VerificationRequestType.Mobile);
        this.router.navigate(['/verificationwizard']);
        break;
      case 'close-autologout-modal':
          if (this.modal_open) {
            if (this.dialogRef) {
              this.dialogRef.close();
            }
            this.modal_open = false;
          }
      break;
    }
  }

  logout(forced: boolean) {

    if (forced && !this.autoLogout.logoff) {
      return;
    }

    this.dialog.closeAll();
    this.autoLogout.destroyInterval();

    this.store.logOff();
    this.sessionStorage.store('FORCED_LOGOUT', forced);
    this.sessionStorage.store('LOGGED_IN', '');
  }

  onSetTheme(theme: string) {
    document.body.classList.remove(this.currentTheme);
    this.elementRef.nativeElement.classList.remove(this.currentTheme);
    this.overlayContainer.getContainerElement().classList.remove(this.currentTheme);
    this.currentTheme = theme;
    this.sessionStorage.store('THEME', theme);
    this.elementRef.nativeElement.classList.add(theme);
    this.overlayContainer.getContainerElement().classList.add(theme);
    document.body.classList.add(theme);
  }

  changeOfRoutes() {
    this.autoLogout.startIfNotStarted();
  }

  addClassFromRoute() {
    if (this.router.navigated) {
      const urlParts = this.router.url.split('/');
      if (urlParts.length > 1 && (urlParts[1] === 'signup' || urlParts[1] === 'register' || urlParts[1] === 'deactivatefallback')) {
        if (urlParts[1] === 'signup' || urlParts[1] === 'register') {
          return '_signup';
        } else {
          return '_deactivatefallback';
        }
      } else {
        return `${urlParts.join('_')}`;
      }
    }
  }

  showFireworks() {
    const dialogRef = this.dialog.open(FireworkAnimationComponent, {
      panelClass: ['transparent-background']
    });

    setTimeout(() => {
      if (dialogRef) {
        dialogRef.close();
      }
    }, 3000);
  }

  registerIcons() {
    const assetPath = 'assets/' + this.exchangeName + '/';

    const icons = {
      'light-bulb-on':     'images/icons/small/light-bulb-on.svg',
      'light-bulb-off':    'images/icons/small/light-bulb-off.svg',
      'bell':              'images/icons/small/bell.svg',
      'bellactive':        'images/icons/small/bellactive.svg',

      'deposit':           'images/pages/balances/deposit.svg',
      'withdraw':          'images/pages/balances/withdraw.svg',
      'history':           'images/pages/balances/history.svg',
      'market':            'images/pages/balances/market.svg',
      'piggy-banking':     'images/pages/balances/piggy-bank-outline.svg',
      'verification-info': 'images/icons/fee-info.svg',

      'verified':          'images/icons/small/verified.svg',
      'warning':           'images/icons/small/warning.svg',

      'twofa':             'images/icons/small/twofa.svg',
      'estimatedvalue':    'images/icons/estimatedvalue2.svg',
      'password':          'images/icons/small/password.svg',

      'rank_0':            'images/chat/rank_0.svg',
      'rank_1':            'images/chat/rank_1.svg',
      'rank_2':            'images/chat/rank_2.svg',
      'rank_3':            'images/chat/rank_3.svg',
      'rank_99':           'images/chat/rank_99.svg',

      'laugh':             'images/chat/emoticon_laugh.svg',
      'love':              'images/chat/emoticon_love.svg',
      'sad':               'images/chat/emoticon_sad.svg',
      'smile':             'images/chat/emoticon_smiley.svg',
      'thumbsup':          'images/chat/emoticon_thumbsup.svg',
      'wink':              'images/chat/emoticon_wink.svg',

      'bitcoin':          'images/icons/bitcoin.svg',
      'Volume':          'images/icons/volume_sml.svg',
      'Change':           'images/icons/change_sml.svg',
      'Arrow':            'images/icons/arrow_sml.svg',
      'Price':            'images/icons/price_sml.svg',
      'Telegram':         'images/icons/telegram.svg',
      'Medium':           'images/icons/medium.svg',
      'Ghost':            'images/icons/ghost.svg',


      'handokay':         'images/icons/small/handokay.svg',
      'accountstar':      'images/icons/small/accountstar.svg',
      'shieldcheck':      'images/icons/small/shieldcheck.svg',
      'headset':          'images/icons/small/headset.svg',
      'chartareaspline':  'images/icons/small/chartareaspline.svg',
      'wallet':           'images/icons/small/wallet.svg',
      'coinranking':      'images/pages/home/links/coinranking.svg',

      'map-marker':       'images/icons/small/map-marker.svg',

      'arrowdown':        'images/icons/arrowdown.svg',
      'arrowup':          'images/icons/arrowup.svg',
      'change':           'images/icons/change.svg',

      'volume':           'images/icons/volume.svg',
      'facebook':         'images/icons/facebook.svg',
      'twitter':          'images/icons/twitter.svg',
      'linkedin':         'images/icons/linkedin.svg',
      'instagram':        'images/icons/instagram.svg',
      'medium':           'images/icons/medium.svg',
      'ghost':            'images/icons/ghost.svg',
      'reddit':           'images/icons/reddit.svg',
      'telegram':         'images/icons/telegram.svg',
      'telegram_orange':  'images/icons/telegram_orange.svg',
      'youtube':          'images/icons/youtube.svg',

      'order-indicator':  'images/icons/order-indicator.svg',
      'fee-info':         'images/icons/fee-info.svg',
      'stop-info':        'images/icons/fee-info.svg',

      'personaldetails':  'images/icons/small/personaldetails.svg',
      'verification':     'images/icons/small/verification.svg',
      'arrowright':       'images/icons/small/arrowright.svg',

      'twitter-blue':     'images/icons/twitter.svg',
      'facebook-blue':    'images/icons/facebook.svg',

      'info':             'images/icons/info.svg',
      'apikeysettings':   'images/icons/small/apikeysettings.svg',
      'noapikeysettings': 'images/icons/noapikeysettings.svg',
      'shield':           'images/icons/small/shield.svg',
      'systemsettings':   'images/icons/small/systemsettings.svg',

      'address-book': 'images/pages/address-book/address-book.svg',
    };

    for (const icon in icons) {
      if (icons[icon]) {
        this.iconRegistry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(assetPath + icons[icon]));
      }
    }

  }
}
