import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ProductConfirmDialogData {
  title: string;
  compound: boolean;
}

@Component({
  selector: 'app-product-confirmation',
  templateUrl: './product-confirmation.component.html',
  styleUrls: ['./product-confirmation.component.scss']
})
export class ProductConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<ProductConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProductConfirmDialogData
  ) {}

  ok_click(): void {
    this.dialogRef.close({result: 'accept'});
  }

  close_click(): void {
    this.dialogRef.close({result: 'reject'});
  }
}
