export class DateHelper {
    static pad(num: number) {
        if (num < 10) {
          return '0' + num;
        }
        return num;
    }

    static toLocalISOString(date: Date): string {
        return date.getUTCFullYear() +
            '-' + this.pad(date.getUTCMonth() + 1) +
            '-' + this.pad(date.getUTCDate()) +
            ' ' + this.pad(date.getHours()) +
            ':' + this.pad(date.getMinutes()) +
            ':' + this.pad(date.getUTCSeconds());
    }

    static dateFromISOString(date: any): Date {
        const tempDate = new Date(date);
        if (tempDate.toString() === 'Invalid Date') {
            date = date.replace(/-/g, '/');
            return new Date(date);
        }
        return tempDate;
    }

    static updateTimeFormatted(data: any): any {
        const updatedTimes: any = data.map(obj => {
            const temp: any = {...obj};
            const noZone: Date = this.dateFromISOString(temp.time_formatted);
            const offSet: number = (-1) * (new Date()).getTimezoneOffset();
            const minutes: number = noZone.getMinutes();
            noZone.setMinutes(minutes + offSet);
            temp.time_formatted = this.toLocalISOString(noZone);
            return temp;
        });
        return updatedTimes;
    }
}
