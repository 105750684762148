<mat-icon class="dialog-close" (click)="close_click()">close</mat-icon>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center pb-2">
            <h3>
                <ng-container [ngSwitch]="data.cancelType">
                    <ng-container *ngSwitchCase="dialogTypes.cancelOrder" i18n>Cancel Order</ng-container>
                    <ng-container *ngSwitchCase="dialogTypes.cancelWithdrawal" i18n>Cancel Withdrawal</ng-container>
                    <ng-container *ngSwitchCase="dialogTypes.createWithdrawal">
                        <ng-container  #internalHeading *ngIf="data.internal; else externalHeading" i18n>
                            Internal withdrawal identified
                        </ng-container>

                        <ng-template #externalHeading>
                            <ng-container i18n>Withdrawal</ng-container> - {{data.coinCode}}
                        </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="dialogTypes.general">{{data.title | i18n}}</ng-container>
                    <ng-container *ngSwitchCase="dialogTypes.reportMessage" i18n> Report Message</ng-container>
                    <ng-container *ngSwitchCase="dialogTypes.enableAutotrade" i18n>Enable Instant Trade Deposits</ng-container>
                    <ng-container *ngSwitchCase="dialogTypes.disableAutotrade" i18n>Disable Instant Trade Deposits</ng-container>
                </ng-container>
            </h3>

            <div class="mt-2 mb-2 text-left overflowWrap" id="reasonContainer">
                <ng-container [ngSwitch]="data.cancelType">

                    <ng-container *ngSwitchCase="dialogTypes.cancelOrder">
                        <ng-container i18n> Are you sure you would like to cancel the following Order?</ng-container><br>
                        <br><b i18n>Pair</b>: {{data.pair}}
                        <br><b i18n>Type</b>: {{data.type}}
                        <br><b i18n>Amount</b>: {{data.amount}}
                    </ng-container>

                    <ng-container *ngSwitchCase="dialogTypes.cancelWithdrawal">
                        <ng-container i18n>Are you sure you would like to cancel the following withdrawal?</ng-container><br>
                        <br><b i18n>Amount</b>: {{data.amount}}
                        <br><b i18n>Address</b>: {{data.address}}
                    </ng-container>

                    <ng-container *ngSwitchCase="dialogTypes.createWithdrawal" >
                        <ng-container  #internal *ngIf="data.internal; else external" i18n>
                            <br>This withdrawal has been identified as an internal withdrawal to another ChainEX user and will be processed free of charge, Continue? <br>
                        </ng-container>

                        <ng-template #external>
                            <ng-container i18n>You are about to Withdraw the following:</ng-container><br>
                        </ng-template>

                        <ng-container *ngIf="!!data.bank">
                            <br><b i18n>Bank: </b>{{data.bank}}
                        </ng-container>
                        <ng-container *ngIf="!!data.address">
                            <br><b i18n>Address: </b>{{data.address}}
                        </ng-container>
                        <ng-container *ngIf="!!data.networkName">
                          <br><b i18n>Network: </b>{{data.networkName}}
                        </ng-container>
                        <br><b i18n>Amount: </b>{{data.amount}} {{data.coinCode}}
                        <br><b i18n>Fee: </b>{{data.fee}} {{data.coinCode}}
                    </ng-container>

                    <ng-container *ngSwitchCase="dialogTypes.general">
                      <br/>
                      <div class="col-12 text-{{data.messagealign}}" [innerHtml]="data.message"></div>
                    </ng-container>

                    <ng-container *ngSwitchCase="dialogTypes.reportMessage">
                        <ng-container i18n>Are you sure you would like to report the following message?</ng-container><br>
                        <br><b>{{data.username}} <ng-container i18n>wrote</ng-container></b>:<br>
                        <div [innerHtml]="data.transformedMessage"></div>
                    </ng-container>

                    <ng-container *ngSwitchCase="dialogTypes.enableAutotrade">
                      <ng-container i18n>Instant Trading on Deposit settings will be set to:</ng-container><br />
                      <span class="d-block my-3 text-bold" i18n>Automatically
                        <span class="orange-text">{{data.type}} {{data.coinCode}}</span>
                        on the
                        <span class="orange-text">{{data.pair}}</span>
                        market<br>
                      </span>
                      <ng-container i18n>Please note:</ng-container><br>
                      <ul>
                        <li i18n>This will only be enabled on this wallet, i.e. deposits on other wallets will not trade automatically unless set up</li>
                        <li i18n>Once the deposit clears, it will trade automatically against available market prices</li>
                        <li i18n>Your deposit amount will therefore be spent on these trades automatically</li>
                        <li i18n>Automated trading will repeat for all received deposits until this setting is disabled</li>
                        <li i18n>ChainEX cannot be held responsible for losses as a result of market fluctuations at the time of trades</li>
                        <li i18n>Trades will only be executed once a deposit has been received and cleared successfully</li>
                        <li i18n>Your full deposit amount will be traded based on your defined setting</li>
                        <li i18n>The outcome of trades will be sent to you via email</li>
                      </ul>
                      <ng-container i18n>By enabling this setting below, you agree with the risks and warnings presented above.</ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="dialogTypes.disableAutotrade">
                      <ng-container i18n>This wallet is currently set up to:</ng-container><br />
                      <span class="d-block my-3 text-bold" i18n>Automatically
                        <span class="orange-text">{{data.type}} {{data.coinCode}}</span>
                        on the
                        <span class="orange-text">{{data.pair}}</span>
                        market<br>
                      </span>
                      <ng-container i18n>Please select the button below to disable this setting:</ng-container><br>
                    </ng-container>

                    <br><br>
                </ng-container>

            </div>
        </div>

        <div class="col-6 text-left pt-2">
            <button mat-raised-button color="accent" (click)="close_click()" i18n *ngIf="!data.swopbuttons">{{data.no|i18n}}</button>
            <button mat-raised-button color="primary" class="mr-2" (click)="ok_click()" i18n *ngIf="data.swopbuttons">{{data.yes|i18n}}</button>
        </div>

        <div class="col-6 text-right pt-2">
            <button mat-raised-button color="primary" class="mr-2" (click)="ok_click()" i18n *ngIf="!data.swopbuttons">{{data.yes|i18n}}</button>
            <button mat-raised-button color="accent" (click)="close_click()" i18n *ngIf="data.swopbuttons">{{data.no|i18n}}</button>
        </div>
    </div>
</div>
