
<!-- Standard address book display -->
<ng-container *ngIf="viewDisplay === AddressView.Normal">
  <!-- Close dialog icon -->
  <mat-icon class="dialog-close" (click)="closeBook()">
    close
  </mat-icon>
  <!-- Main view container -->
  <div class="container-fluid dialog-over-flow mb-4">
    <!-- Header -->
    <div class="row mb-4 col-12">
      <span i18n>
        {{setting.code}} Address Book
      </span>
    </div>
    <!-- Tabs -->
    <mat-tab-group class="w-100 main-label">
      <mat-tab label="Address Book">
        <ng-template matTabContent>
          <div class="row space-between mb-1">
            <!-- Filter -->
            <mat-form-field class="ml-3">
              <input matInput (keyup)="applySavedFilter($event)" i18n-placeholder placeholder="Search addresses" #input>
            </mat-form-field>
            <!-- Add new addy -->
            <button mat-raised-button class="w-30 mr-3" color="primary" (click)="addAddressForm()" i18n>ADD NEW</button>
          </div>

          <table mat-table class="table-responsive" [dataSource]="savedAddresses" matSort>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Network Name Column -->
            <ng-container matColumnDef="network_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Network</th>
              <td mat-cell *matCellDef="let element"> {{element.network_name}} </td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Address </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container>

            <!-- Payment Id Column -->
            <ng-container matColumnDef="paymentId" *ngIf="!!setting.cryptoNoteAddress.address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> {{setting.cryptoNoteAddress.label}} </th>
              <td mat-cell *matCellDef="let element"> {{element.paymentId}} </td>
            </ng-container>

            <!-- Trusted Column -->
            <ng-container matColumnDef="trusted">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Trusted </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon color="primary" class="ml-2 mt-2" matTooltipPosition="above" i18n-matTooltip
                  matTooltip="{{element.statusLabel}}">
                  {{element.status === AddressStatus.Untrusted ? 'cancel'
                  : (element.status === AddressStatus.Trusted ? 'check_circle' : 'hourglass_empty' )}}
                </mat-icon>
              </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions" stickyEnd >
              <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
              <td mat-cell *matCellDef="let element">
                <div class="button-container">
                  <!-- Use icon -->
                  <mat-icon color="primary" class="ml-2" matTooltipPosition="above" i18n-matTooltip matTooltip="Use address"
                    (click)="useAddress(element.name, element?.paymentId ? element?.paymentId : '', element?.coin_network ? element?.coin_network : '')">
                    send
                  </mat-icon>
                  <!-- Edit icon -->
                  <mat-icon color="primary" class="ml-2" matTooltipPosition="above" i18n-matTooltip matTooltip="Edit address"
                    (click)="editAddressForm(element)">
                    edit
                  </mat-icon>
                  <!-- Delete icon -->
                  <mat-icon color="primary" class="ml-2" matTooltipPosition="above" i18n-matTooltip matTooltip="Remove address"
                   (click)="requestRemoveAddress(element)">
                    delete
                  </mat-icon>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="addressDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: addressDisplayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <ng-container *ngIf="input.value; else noAddresses">
                <td class="mat-cell" colspan="4" i18n>No address matching the filter "{{input.value}}"</td>
              </ng-container>
              <ng-template #noAddresses>
                <td class="mat-cell" colspan="4" i18n>No address book entries found</td>
              </ng-template>
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" ></mat-paginator>
        </ng-template>
      </mat-tab>

      <mat-tab label="Recent Unsaved Addresses">
        <ng-template matTabContent>
          <div class="row space-between">
            <!-- Filter -->
            <mat-form-field class="ml-3">
              <input matInput (keyup)="applyUnsavedFilter($event)" i18n-placeholder placeholder="Search addresses" #input>
            </mat-form-field>
          </div>

          <table mat-table class="table-responsive" [dataSource]="unsavedAddresses" #unsavedSort="matSort" matSort>
            <!--  Last Withdrawal Column -->
            <ng-container matColumnDef="lastWithdrawal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Last Withdrawal </th>
              <td mat-cell *matCellDef="let element"> {{element.lastWithdrawal}} </td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Address </th>
              <td mat-cell *matCellDef="let element"> {{element.address}} </td>
            </ng-container>

            <!-- Network Name Column -->
            <ng-container matColumnDef="network_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Network</th>
              <td mat-cell *matCellDef="let element"> {{element.network_name}} </td>
            </ng-container>

            <!-- Payment Id Column -->
            <ng-container matColumnDef="paymentId" *ngIf="!!setting.cryptoNoteAddress.address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> {{setting.cryptoNoteAddress.label}} </th>
              <td mat-cell *matCellDef="let element"> {{element.paymentId}} </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions" stickyEnd >
              <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
              <td mat-cell *matCellDef="let element">
                <div class="button-container">
                  <!-- Use icon -->
                  <mat-icon color="primary" class="ml-2" matTooltipPosition="above" i18n-matTooltip matTooltip="Use address"
                    (click)="useAddress(element.address)">
                    send
                  </mat-icon>
                  <!-- Add icon -->
                  <mat-icon color="primary" class="ml-2" matTooltipPosition="above" i18n-matTooltip matTooltip="Add address"
                    (click)="addAddressForm(element.address, element.coin_network)">
                    add
                  </mat-icon>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="unsavedDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: unsavedDisplayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <ng-container *ngIf="input.value; else noRecent">
                <td class="mat-cell" colspan="4" i18n>No address matching the filter "{{input.value}}"</td>
              </ng-container>
              <ng-template #noRecent>
                <td class="mat-cell" colspan="4" i18n>No recently used addresses found</td>
              </ng-template>
            </tr>
          </table>
          <mat-paginator #unsavedPaginatior [pageSizeOptions]="[5, 10]"></mat-paginator>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>

<!-- Add display -->
<ng-container *ngIf="viewDisplay === AddressView.Add || viewDisplay === AddressView.Edit">
  <div class="container-fluid dialog-over-flow">
    <!-- Header -->
    <div class="row mr-2 ml-2 flex-center bold t-20">
      <ng-container i18n>
        {{viewDisplay === AddressView.Add ? 'ADD' : 'EDIT'}} ADDRESS BOOK ENTRY
      </ng-container>
    </div>
    <!-- Form -->
    <div class="row mt-4 mr-3 ml-3 column">
      <span i18n>
        Coin:
        <ng-container class="bold">{{setting.code}}</ng-container>
      </span>
      <!-- Inputs -->
      <form class="modify-form" [formGroup]="addressForm" (ngSubmit)="handleAddressModification()">
        <!-- Name -->
        <mat-form-field class="w-100">
          <input type="text" matInput formControlName="name" placeholder="Name *">
          <mat-error class="mb-2" *ngIf="form.name.hasError('required')">
            <ng-container i18n>Name is required</ng-container>
          </mat-error>
          <mat-error class="mb-2" *ngIf="form.name.hasError('nameExists')">
            <ng-container i18n>This Name has already been used</ng-container>
          </mat-error>
        </mat-form-field>
        <!-- Address -->
        <mat-form-field class="w-100">
          <input type="text" matInput formControlName="address" placeholder="Address *">
          <mat-error class="mb-2" *ngIf="form.address.hasError('required')">
            <ng-container i18n>Address is required</ng-container>
          </mat-error>
          <mat-error class="mb-2" *ngIf="form.address.hasError('ownAddress')">
            <ng-container i18n>You cannot withdraw to your own wallet.</ng-container>
          </mat-error>
          <mat-error class="mb-2" *ngIf="form.address.hasError('pattern')">
            <ng-container i18n>Invalid wallet address, email address or mobile number</ng-container>
          </mat-error>
          <mat-error class="mb-2" *ngIf="form.address.hasError('addressExists')">
            <ng-container i18n>This address has already been added</ng-container>
          </mat-error>
        </mat-form-field>
        <!-- Coin Network -->
        <mat-form-field class="w-100" *ngIf="setting.coinNetworks.length > 0">
          <mat-select [disabled]="setting.coinNetworks.length <= 1" i18n-placeholder placeholder="Network"
            formControlName="coinNetwork" value="setting.selectedCoinNetwork" (selectionChange)="changeNetwork($event)">
            <ng-container *ngIf="setting.coinNetworks.length > 0; else defaultNetwork">
              <mat-option *ngFor="let coin_network of setting.coinNetworks" [value]="coin_network.id"
                [disabled]="coin_network.active === '0'">
                {{ coin_network.network_name }}
                <ng-container *ngIf="coin_network.active === '0'">
                  <span class="suspended-text ml-3 px-2">
                    SUSPENDED
                  </span>
                </ng-container>
              </mat-option>
            </ng-container>
            <ng-template #defaultNetwork>
              <mat-option [value]="this.setting.selectedCoinNetwork">
                {{ setting.selectedNetworkName }}
              </mat-option>
            </ng-template>
          </mat-select>
        </mat-form-field>
        <!-- PaymentId -->
        <ng-container *ngIf="(!!setting.cryptoNoteAddress.address && !internalAddressIdentified)
          || (!!setting.cryptoNoteAddress.address && viewDisplay === AddressView.Edit)">
          <mat-form-field class="w-100">
            <input type="text" matInput formControlName="paymentId" placeholder="{{setting.cryptoNoteAddress.label}} *">
            <mat-error class="mb-2" *ngIf="form.paymentId.hasError('required')">
              <ng-container i18n>{{setting.cryptoNoteAddress.label}} is required</ng-container>
            </mat-error>
            <mat-error class="mb-2" *ngIf="form.paymentId.hasError('ownAddress')">
              <ng-container i18n>You cannot withdraw to your own wallet.</ng-container>
            </mat-error>
            <mat-error class="mb-2" *ngIf="form.paymentId.hasError('pattern')">
              <ng-container i18n>Invalid {{setting.cryptoNoteAddress.label}}</ng-container>
            </mat-error>
            <mat-error class="mb-2" *ngIf="form.paymentId.hasError('addressExists')">
              <ng-container i18n>This {{setting.cryptoNoteAddress.label}} has already been added</ng-container>
            </mat-error>
          </mat-form-field>
          <!-- No payment id required -->
          <div class="w-100 mt-4">
            <mat-checkbox color="primary" formControlName="paymentIdRequired" i18n>
              No {{setting.cryptoNoteAddress.label}}
            </mat-checkbox>
          </div>
        </ng-container>
        <!-- Trusted -->
        <div [ngClass]="{'mt-4': !setting.cryptoNoteAddress.address || (!!setting.cryptoNoteAddress.address && internalAddressIdentified)}" class="w-100 flex-container">
          <mat-checkbox color="primary" formControlName="trusted" i18n>
            Trusted
          </mat-checkbox>
          <!-- Trusted info icon -->
          <mat-icon color="primary" class="ml-2" i18n-matTooltip matTooltipPosition="above"
            matTooltip="2FA, Fund PIN or email confirmation will not be required when withdrawing from Trusted Accounts">
            info
          </mat-icon>
        </div>
        <ng-container *ngIf="form.trusted.value && viewDisplay === AddressView.Add">
          <!-- Password -->
          <mat-form-field class="w-100">
            <input type="password" matInput formControlName="password" placeholder="Password *">
            <mat-error *ngIf="form.password.hasError('required')">
              <ng-container i18n>Password is required</ng-container>
            </mat-error>
          </mat-form-field>
          <!-- Fund PIN -->
          <mat-form-field class="w-100" *ngIf="setting.isFundpinEnabled === FundPinStatus.Enabled">
            <input type="password" matInput formControlName="fundPin" placeholder="Fund PIN *">
            <mat-error *ngIf="form.fundPin.hasError('required')">
              <ng-container i18n>Fund PIN is required</ng-container>
            </mat-error>
          </mat-form-field>
          <!-- TFA Code -->
          <mat-form-field class="w-100" *ngIf="setting.is2faenabled">
            <input type="text" matInput formControlName="tfa" placeholder="2FA Code *">
            <mat-error *ngIf="form.tfa.hasError('required')">
              <ng-container i18n>2FA code is required</ng-container>
            </mat-error>
          </mat-form-field>
        </ng-container>
        <!-- Buttons -->
        <div class="row space-evenly mt-4">
          <button mat-raised-button class="w-30 mr-3" color="accent" (click)="resetView()" i18n>CANCEL</button>
          <button mat-raised-button class="w-30 mr-3" color="primary" type="submit" i18n>SAVE</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<!-- Confirmation display -->
<ng-container *ngIf="viewDisplay === AddressView.Confirmation || viewDisplay === AddressView.ConfirmationTrusted">
  <div class="container-fluid dialog-over-flow col-12 flex-center">
    <!-- Header -->
    <div class="row bold t-20 column">
      <img class="mb-4 mt-2" src="assets\{{exchangeName}}\images\pages\common\cx_confirmation.svg" width="90">
      <span i18n>{{viewDisplay === AddressView.ConfirmationTrusted ? 'TRUSTED' : ''}} ADDRESS ADDED</span>
    </div>
    <!-- Body -->
    <div class="row mt-5 mr-4 ml-4 column flex-center" *ngIf="viewDisplay === AddressView.Confirmation">
      <span i18n>
        You have successfully added a new address to your Address Book.
      </span>
    </div>
    <!-- Trusted flavor text -->
    <div class="row mt-5 mr-4 ml-4 column flex-center" *ngIf="viewDisplay === AddressView.ConfirmationTrusted">
      <span i18n>
        You have successfully added a new Trusted address to your Address Book.
      </span>
      <span class="mt-4" i18n>
        Since trusted addresses do not require 2FA, Fund PIN or email confirmation when performing withdrawals,
        the account needs to be confirmed per email as an added security measure.
      </span>
      <span class="mt-4" i18n>
        An email has been sent to you from which you can confirm the trusted addresses.
        Please check your email inbox and confirm it.
        Please note that trusted addresses will remain untrusted for 24 hours after confirmation as an added security measure.
      </span>
      <span class="mt-4" i18n>
        If you have any queries, please contact support
        <a target="_blank" href="{{getSupportUrl()}}">
          here.
        </a>
      </span>
    </div>
    <!-- Buttons -->
    <div class="row space-evenly mt-5 mb-4">
      <button mat-raised-button class="w-30 mr-3" color="primary" (click)="resetView()" i18n>CONTINUE</button>
    </div>
  </div>
</ng-container>

<!-- Confirm remove display -->
<ng-container *ngIf="viewDisplay === AddressView.ConfirmRemove">
  <div class="container-fluid dialog-over-flow col-12 flex-center">
    <!-- Header -->
    <div class="row bold flex-center t-20">
      <span i18n>Remove Address</span>
    </div>
    <!-- Body -->
    <div class="row mt-4 column">
      <!-- Flavor text -->
      <div class="row mr-4 ml-4 column">
        <span i18n>
          Are you sure you would like to delete the following Address Book entry?
        </span>
      </div>

      <!-- Address Details -->
      <div class="row mr-5 ml-5 column">
        <!-- Name -->
        <div class="row mt-4 w-80 space-between mobile">
          <span class="w-40 bold" i18n>Name:</span>
          <span class="w-40" i18n>{{form.name.value}}</span>
        </div>
        <!-- Address -->
        <div class="row mt-2 w-80 space-between mobile">
          <span class="w-40 bold" i18n>Address:</span>
          <span class="w-40" i18n>{{form.address.value}}</span>
        </div>
        <!-- Address -->
        <div class="row mt-2 w-80 space-between mobile" *ngIf="form.trusted.value">
          <span class="w-40 bold" i18n>Trusted:</span>
          <span class="w-40" i18n>{{form.trusted.value}}</span>
        </div>
      </div>

      <!-- Buttons -->
      <div class="row space-evenly mt-3 mb-4">
        <button mat-raised-button class="w-30 mr-3" color="accent" (click)="resetView()" i18n>CANCEL</button>
        <button mat-raised-button class="w-30 mr-3" color="primary" (click)="removeAddress()" i18n>CONFIRM</button>
      </div>
    </div>
  </div>
</ng-container>
