
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CommentCard } from 'src/app/core/interfaces/commentCard';

@Component({
  selector: 'shared-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent implements OnInit, OnDestroy {
  @Input() userComment: CommentCard;

  constructor(
    private sessionStorage: SessionStorageService,
  ) {}

  private _timeout$: Subject<void> = new Subject();
  darkTheme: boolean = false;

  ngOnInit() {
    this.sessionStorage.observe('THEME').pipe(
      tap((data) => {
        this.darkTheme = data === 'dark-theme';
      }),
      takeUntil(this._timeout$)
    ).subscribe();
  }

  ngOnDestroy() {
    this._timeout$.next();
    this._timeout$.complete();
  }
}
