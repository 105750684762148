<mat-icon class="dialog-close" (click)="dialogRef.close()">close</mat-icon>

<!-- Custom informational prompt before navigating to tfa steps -->
<ng-container *ngIf="showTfaSteps === false; else tfaSteps">
  <!-- Api informational prompt -->
  <ng-container *ngIf="dialogData.informationScreen === InformationScreen.apiKeyGeneration">
    <!-- do designs in here -->
    <div class="col-12 heading mt-2 mb-2 center">
      <span class="title">
        <h2 i18n>Define API Setting</h2>
        <label i18n>
          For your safety, you are required to enable 2FA security in order to add or edit an API setting. 2FA security can be activated below:
        </label>
      </span>
    </div>
    <div class="col-12 center mt-4">
    <button mat-raised-button color="primary" (click)="showTfaSteps = true" class="w-75" i18n>ENABLE 2FA</button>
    </div>
    <div class="col-12 center mt-4">
    <button mat-raised-button color="accent" (click)="cancelClick()" class="w-75" i18n>CANCEL</button>
    </div>
  </ng-container>

</ng-container>

<ng-template #tfaSteps>
<div class="container-fluid">
  <div class="row clearfix">

    <div class="col-12" *ngIf="!complete && !disable2fa">
      <h4 i18n>2FA Activation</h4>
      <br/>
      <p i18n>
        Further enhance your account's security by activating Two Factor Authentication (2FA) on your account
        using the Google 2FA mobile application. 2FA will further reduce the risk of unauthorised access to your
        account by requiring you to verify each login to your {{ siteNameN }} account using your mobile phone linked
        to your account.
      </p>

      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step>
            <small i18n>
              Download the google authenticator app on to your mobile phone below.
            </small>
            <div class="row">

              <div class="col-12 col-md-6 mt-2 mb-2 mt-md-4 mb-md-4 px-2 center right">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"><img src="assets/{{ siteName }}/images/pages/home/light-mode/google_play.svg" class="img w-100" /></a>
              </div>

              <div class="col-12 col-md-6 mt-2 mb-2 mt-md-4 mb-md-4 px-2 center left">
                <a target="_blank" href="https://itunes.apple.com/in/app/google-authenticator/id388497605?mt=8"><img src="assets/{{ siteName }}/images/pages/home/light-mode/app_store.svg" class="img w-100" /></a>
              </div>

              <div class="col-12 col-md-3 mb-2">
                <button mat-raised-button color="accent" (click)="cancelClick()" class="w-100" i18n>CANCEL</button>
              </div>

              <div class="col-12 col-md-3 offset-md-6 mb-2">
                <button mat-raised-button matStepperNext color="primary" class="w-100" i18n>NEXT</button>
              </div>
            </div>
        </mat-step>
        <mat-step>
            <small i18n>
              Scan the following QR Code using the app and enter the six-digit code generated for the account.
            </small>
            <div class="media row pt-4">
              <qrcode class="col-12 col-sm-auto d-flex justify-content-center" *ngIf="qrCode" [qrdata]="qrCode" width="140" errorCorrectionLevel="M"></qrcode>
              <div class="media-body col-12 col-sm-auto pl-3 text-center text-sm-left">
                  <div class="border border-orange border-2">
                    <p class="p-1 m-0 note">
                      <strong>
                        PLEASE NOTE: It is important for you to keep your setup key in a safe place. It will not be displayed again.
                        Your setup key can be used to recover your two-factor authentication should you lose access to your device.<br/><br/>
                        If you need to recover your 2FA and are unable to provide this code, you will lose access to your
                        ChainEX account and will have to go through a reset process that requires identity verification to regain access to your account.
                      </strong>
                    </p>
                  </div>
                <p class="mt-2 mb-0">
                  <strong>
                    2FA Setup Key: {{ twoFactorSecret }}
                  </strong>
                </p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-6">
                <button mat-raised-button matStepperPrevious color="accent" i18n>BACK</button>
              </div>

              <div class="col-6 text-right">
                <button mat-raised-button matStepperNext color="primary" (click)="nextClick()" i18n>NEXT</button>
              </div>
            </div>
        </mat-step>

        <mat-step [stepControl]="enableData">
            <small class="token-text d-block text-center" i18n>
              Enter verification code
            </small>
            <div class="w-100">
              <ng-container *ngTemplateOutlet="input2fa"></ng-container>
            </div>
            <mat-error class="d-block text-center" *ngIf="submitFailed">
              {{resultMessage|i18n}}
            </mat-error>

            <div class="row mt-5">
              <div class="col-6">
                <button mat-raised-button matStepperPrevious color="accent" i18n>BACK</button>
              </div>

              <div class="col-6 text-right">
                <button mat-raised-button color="primary" (click)="verifyClick()" [disabled]="submitted" i18n>VERIFY</button>
              </div>
            </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>

    <div class="col-12" *ngIf="!complete && disable2fa">
      <h4 i18n>2FA Deactivation</h4>
      <p i18n>Enter the Two Factor Authentication token generated from your Google Authenticator app.</p>
      <div class="w-100 py-4">
        <ng-container *ngTemplateOutlet="input2fa"></ng-container>
      </div>
      <mat-error class="mb-4 d-block text-center" *ngIf="submitFailed">
        {{resultMessage|i18n}}
      </mat-error>
      <button mat-raised-button class="w-100" color="primary" (click)="verifyClick()" [disabled]="submitted" i18n>VERIFY</button>
      <div class="col-12 py-3">
        <span class="d-block disable-text" i18n>
          Lost your 2FA token? <a class="clickable" href="" (click)="$event.preventDefault(); openDisableDialog()">Click here</a> to request disabling of 2FA or
          <a target="_blank" href="{{getSupportUrl()}}">contact support here</a>.
        </span>
      </div>
    </div>

    <div class="col-12 text-center" *ngIf="complete">
      <h4 i18n>2FA Enabled</h4>
        <mat-icon color="primary" class="result-icon no-select">check_circle_outline</mat-icon>
      <p i18n>
        Two Factor Authentication (2FA) successfully enabled.
      </p>
      <button mat-raised-button color="primary" (click)="doneClick()" i18n>DONE</button>
    </div>
  </div>
</div>
</ng-template>

<ng-template #input2fa>
  <form [formGroup]="tokenData">
    <app-token-input #verifyCode [formControlInput]="tokenData.controls.verify_code" (changeOrPaste)="on2faChange($event)" ></app-token-input>
  </form>
</ng-template>
