import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

export interface DialogData {
  isTranslated?: boolean;
  okButton: boolean;
  result: string;
  reason: string;
  title: string;
  titleTranslated?: boolean;
  }

  @Component({
    selector: 'markets-market-confirmation-dialog',
    templateUrl: './market-confirmation.component.html',
    styleUrls: ['./market-confirmation.component.scss']
  })
  export class MarketConfirmationComponent {

    exchangeName: string = environment.config.EXCHANGE_NAME_L;

    constructor(
      public dialogRef: MatDialogRef<MarketConfirmationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

      close_click(): void {
        this.dialogRef.close({result: 'reject'});
      }

      ok_click(): void {
        this.dialogRef.close({result: 'accept'});
      }
  }
