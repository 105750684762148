import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MarketsService, IMarket } from '../../markets/markets.service';
import { SubscriptionLike } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Component({
  selector: 'home-market-coins',
  templateUrl: './market-coins.component.html',
  styleUrls: ['./market-coins.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketCoinsComponent implements OnInit, OnDestroy {
  @Input() darkTheme: boolean = false;
  @Input() markets: any;
  @Input() selectedMarket: any;

  coinSelected: string = '';
  exchangeSelected: string = '';

  BASE_URL: string = environment.config.BACKEND_URL;
  imageUrl: string = 'assets/images/coins/';

  private marketUpdateSub: SubscriptionLike;
  private subs: SubscriptionLike;

  constructor(
    public marketService: MarketsService,
    private sessionStorage: SessionStorageService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // if markets not linked, fetch markets
    if (!(this.markets)) {
      this.marketUpdateSub = this.marketService.marketUpdateSubject.subscribe((response) => {
        this.markets = response;

        if (this.selectedMarket) {
          this.selectedMarket = this.markets.find(obj => obj.id === this.selectedMarket.id);
        } else {
          if (this.markets.length > 0) {
            this.selectMarket(this.markets[0].id);
          }
        }

        this.cdr.detectChanges();
      });
      this.coinSelected = 'BTC';
      this.exchangeSelected = 'ZAR';
    }

    this.subs = this.sessionStorage.observe('THEME').subscribe(
      data => {
        this.darkTheme = (data === 'dark-theme');
      });
  }

  selectMarket(marketId: string) {
    const filteredMarket = this.markets.find(market => {
      return market.id === marketId;
    });
    if (filteredMarket) {
      this.selectedMarket = filteredMarket;
    }
  }

  ngOnDestroy() {
    if (this.marketUpdateSub) {
      this.marketUpdateSub.unsubscribe();
    }
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  trackByMarket(_index: number, market: any) {
    return market && market.id;
  }

}
