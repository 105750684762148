<mat-icon class="dialog-close" (click)="close_click()">close</mat-icon>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center pb-2">
            <h4 class="pb-2">{{ data.title }}</h4>

            <div class="mt-2 mb-2 text-left overflowWrap col-12" id="reasonContainer">
                <div *ngIf="data.compound">
                    Are you sure you want to compound your earnings?
                </div>
                <div *ngIf="!data.compound">
                    Are you sure you want to stop compounding your earnings?
                </div>
            </div>

            <div class="col-12 row mr-0 pr-0">
                <div class="col-6 text-left pt-2">
                    <button mat-raised-button color="accent" (click)="close_click()" i18n>CLOSE</button>
                </div>
                <div class="col-6 text-right pt-2 pr-0">
                    <button mat-raised-button color="primary" class="mr-2" (click)="ok_click()" i18n>CONFIRM</button>
                </div>
            </div>
        </div>
    </div>
</div>
