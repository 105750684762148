import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'explorerUrl'})
export class ExplorerUrlPipe implements PipeTransform {
  // Replace {Address} or {TXID} in blockchain explorer links
  transform(value: string, explorerUrl: string): string {
    // if url placeholder set and value is a wallet or transaction address
    if (!!explorerUrl) {
      // check for TXID or Address. if not set, append address to end of url
      if (explorerUrl.indexOf('{Address}') < 0 && explorerUrl.indexOf('{TXID}') < 0) {
        if (explorerUrl[explorerUrl.length - 1] === '/') {
          return explorerUrl + value;
        } else {
          return explorerUrl + '/' + value;
        }
      }
      let result = explorerUrl;
      result = result.replace(/{TXID}/g, value);
      result = result.replace(/{Address}/g, value);
      return result;
    } else {
      // if url not set, return value intact
      return value;
    }
  }
}
