import {
  Component, OnInit, OnDestroy, ViewChild,
  Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';
import { SubscriptionLike } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { MarketsService, IMarket } from '../../markets.service';
import { StoreService } from '../../../core/services/store.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SessionStorageService } from '../../../core/services/session-storage.service';

@Component({
  selector: 'markets-sidemarket',
  templateUrl: './side-market.component.html',
  styleUrls: ['./side-market.component.scss']
})
export class SideMarketComponent implements OnInit, OnDestroy {
  coinData: MatTableDataSource<any> = new MatTableDataSource([]);
  coinLabels: any = [this.i18n('Loading')];
  favorites: any = [];
  filterCoin: string = '1';
  filterControl: FormControl = new FormControl('');
  filterSearch: string = '';
  highlightTab: HTMLElement;
  @Output() marketIdChange: EventEmitter<any> = new EventEmitter();
  pairs: any = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  tableDefinition: string[] = [
    'favorites',
    'pair',
    'price',
    'change',
    'volume'
  ];
  selectedTabIndex: number = -1;
  coins: IMarket[];

  private subs: SubscriptionLike[] = [];

  translatedWords: Object = { 'favorites': this.i18n('Favorites')};

  constructor(
    public marketService: MarketsService,
    private store: StoreService,
    private i18n: I18n,
    public cdr: ChangeDetectorRef,
    private sessionStorage: SessionStorageService,
  ) { }

  ngOnInit() {
    this.getCoinData(); // Setup subscription
    this.getTradingPairs();
    this.getActiveMarket();
    const self = this;
    this.coinData.filterPredicate = (data: any, filters: string) => {
      const filterJson = JSON.parse(filters);
      if (filterJson.exchange_id !== '-1') {
        return filterJson.exchange_id === data.exchange_id
        && (data.exchange_code.toLowerCase().indexOf(filterJson.search) > -1
        || data.coin_code.toLowerCase().indexOf(filterJson.search)  > -1 || filterJson.search === '');
      } else {
        return self.isFavorite(data.id)
        && (data.exchange_code.toLowerCase().indexOf(filterJson.search)  > -1
        || data.coin_code.toLowerCase().indexOf(filterJson.search)  > -1 || filterJson.search === '');
      }
    };
    this.filter();

    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh && response.data) {
        this.favorites =
          !response.data.favorites
          || typeof response.data.favorites !== 'object' ? [] : response.data.favorites;
      }
    }));

    this.coinData.sort = this.sort;

    window.addEventListener('resize', () => { this.marketService.setExpandable(); });
    this.marketService.setExpandable();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub && sub.unsubscribe());
    window.removeEventListener('resize', () => { this.marketService.setExpandable(); });
  }

  clearFilter() {
    this.filterSearch = '';
    this.filter();
  }

  createPairList(pairs: any) {
    for (let i = 0; i < pairs.length; i++) {
      if (!this.isInPair(pairs[i].exchange_code)) {
        this.pairs.push({name: pairs[i].exchange_code, id: pairs[i].exchange_id});
      }
    }
    this.cdr.markForCheck();
  }

  filter() {
    this.coinData.filter = JSON.stringify({exchange_id: this.filterCoin, search: this.filterSearch.toLowerCase()});
  }

  getCoinData() {
    this.subs.push(this.marketService.marketUpdateSubject.pipe(
      takeWhile(() => !this.coins || !this.coins.length))
      .subscribe((response: IMarket[]) => {
        this.coins = response;
        this.setCoinLabels(this.coins);
      }));
  }

  getActiveMarket() {
    this.subs.push(this.marketService.activeMarketSubject.subscribe(() => {
      if (!!this.coins && this.coinLabels[0] === this.i18n('Loading')) {
        this.setCoinLabels(this.coins);
      }
      this.getSelectedTabIndex();
    }));
  }

  getTradingPairs() {
    this.subs.push(this.store.subscribe('marketData').subscribe(response => {
      if (!response.refresh && !!response.data) {
        this.createPairList(response.data);
        if (!this.selectedTabIndex && this.selectedTabIndex !== 0) {
          this.getSelectedTabIndex();
        }
        this.coinData.data = response.data;
        this.favorites = typeof response.favorites !== 'object' ? this.favorites : response.favorites;
        this.filter();
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }
    }));
  }

  getMarketDataFromTable(id: any) {
    for (let i = 0; i < this.coinData.data.length; i++) {
      if (this.coinData.data[i].id === id) {
        return this.coinData.data[i];
      }
    }
    return {price: 1, volume: 1};
  }

  getPair(coin: string) {
    for (let i = 0; i < this.pairs.length; i++) {
      if (this.pairs[i].name === coin) {
        return this.pairs[i];
      }
    }
    return null;
  }

  isFavorite(id: any) {
    return !id || this.favorites.length === 0 ? false : this.favorites.indexOf(id) > -1;
  }

  isInPair(coin: string) {
    for (let i = 0; i < this.pairs.length; i++) {
      if (this.pairs[i].name === coin) {
        return true;
      }
    }
    return false;
  }

  pairChange(e: any) {
    const pair = this.getPair(this.coinLabels[e.index]);
    if (pair !== null) {
      this.filterCoin = pair.id;
    } else if (!!e.tab && e.tab.textLabel === this.translatedWords['favorites']) {
      this.filterCoin = '-1';
    } else {
      this.filterCoin = '-2';
    }
    this.filter();
  }

  getSelectedTabIndex() {
    this.selectedTabIndex = -1;
    if (!!this.coinLabels && !!this.marketService.activeMarket && (this.marketService.getMarketLength() > 1)
     && this.pairs) {
      this.selectedTabIndex = this.coinLabels.indexOf(this.marketService.activeMarket.exchangeCode || 'ZAR') || 0;
      this.pairChange({index: this.selectedTabIndex});
    }
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  rowClick(id: any, event: any) {
    event.preventDefault();
    this.marketIdChange.emit(id);
    this.sessionStorage.store('selected_market', id);
    this.tabGroup.selectedIndex = this.selectedTabIndex;
  }

  setCoinLabels(response: IMarket[]) {
    this.coinLabels = [];
    this.selectedTabIndex = null;
    for (let i = 0; i < response.length; i++) {
      const filteredLabels = this.coinLabels.filter(searchLabel => searchLabel === response[i].exchangeCode);
      if (filteredLabels.length === 0) {
      this.coinLabels.push(response[i].exchangeCode);
      }
    }
    this.coinLabels.sort();
    const idx = this.coinLabels.indexOf('ZAR');
    if (idx > -1) {
      this.coinLabels.splice(idx, 1);
      this.coinLabels.unshift('ZAR');
    }
    this.coinLabels.push(this.translatedWords['favorites']);
    this.cdr.markForCheck();
  }

  toggleFavourites(id: any, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isFavorite(id)) {
      this.favorites.push(id);
      this.marketService.addFavoritePairs({favorites: this.favorites}).subscribe((response) => {
        this.store.getSettings();
      });
    } else {
      this.favorites.splice(this.favorites.indexOf(id), 1);
      this.marketService.addFavoritePairs({favorites: this.favorites}).subscribe((response) => {
        this.store.getSettings();
      });
    }
    this.filter();
  }

  trackCoinLabel(_index: number, label: string) {
    return label;
  }

  trackByCoinData(_index: number, coin: any) {
    return coin && coin.id || null;
  }

}
