import { I18nTranslationService } from './../../core/services/i18n-translation.service';
import { SettingsService } from './../../settings/settings.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { StoreService, SubscriptionLike } from './../../core/services/store.service';
import { environment } from './../../../environments/environment';
import { SessionStorageService } from './../../core/services/session-storage.service';
import { RouterHelper } from 'src/app/core/router-helper';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { QuickWizardComponent } from 'src/app/markets/order-types/quick-wizard/quick-wizard.component';

type GetLoginUrl = (router: Router, state: RouterStateSnapshot) => void;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  announcements: any[];
  getLoginUrl: GetLoginUrl = RouterHelper.getLoginUrl;
  isIn: boolean = false;
  loggedIn: boolean;
  subs: SubscriptionLike[] = [];
  unreadAnnouncements: boolean = false;
  assetsPath: string = '/assets/chainex/images/pages/home/';
  username: string;
  darkTheme: boolean = false;

  headerStats: boolean = environment.config.HEADER_STATS;

  siteName: String = environment.config.EXCHANGE_NAME_L;

  pushConnected: boolean = false;
  private interval: NodeJS.Timeout;

  constructor(
    public router: Router,
    private sessionStorage: SessionStorageService,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private store: StoreService,
    private service: SettingsService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.interval = setInterval((() => {
      this.pushConnected = this.store.pushHost._websocket_connected;
      this.cdr.detectChanges();
    }).bind(this), 2500);
    this.subs.push(this.sessionStorage.observe('LOGGED_IN').subscribe(
      (data) => {
        if (data !== '' &&
          this.sessionStorage.get('TOKEN') !== '') {

          this.filterMenu();
        }
        this.loggedIn = data === 'logged_in';

        if (this.loggedIn) {
          this.store.getAnnouncements();
        }
        this.cdr.detectChanges();
      }
    ));

    this.subs.push(this.store.subscribe('announcements').subscribe((response: any) => {
      if (!response.refresh) {
        const temp: any[] = [];
        for (let i = 0; i < response.data.length; i++) {
          if (!!response.data[i].active && response.data[i].active === '1') {
            temp.push(response.data[i]);
          }
        }
        this.announcements = temp;
        this.getUnreadAnnouncements();
        this.cdr.detectChanges();
      }
    }));

    this.subs.push(this.sessionStorage.observe('THEME')
      .subscribe(data => {
        this.darkTheme = data === 'dark-theme';
        this.cdr.detectChanges();
      }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public announcementsRead() {
    this.store.setAnnouncementsRead();
    if (this.loggedIn) {
      this.store.commitAnnouncementsRead();
      this.getUnreadAnnouncements();
    }
    this.cdr.detectChanges();
  }

  filterMenu() {
    if (this.sessionStorage.get('PROFILE_FIRST_NAME') === '') {
      this.username = this.sessionStorage.get('PROFILE_EMAIL');
    } else {
      this.username = this.sessionStorage.get('PROFILE_FIRST_NAME');
    }
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  public getUnreadAnnouncements() {
    this.unreadAnnouncements = false;
    this.announcements.forEach((item: any) => {
      if (item.read !== '1') { this.unreadAnnouncements = true; }
    });
    this.cdr.detectChanges();
  }

  hideNav() {
    this.isIn = false;
    this.cdr.detectChanges();
  }

  changeTheme(theme: string) {
    if (this.loggedIn) {
      const data: any = {
        dark_theme: theme === 'dark-theme' ? 1 : 0
      };
      this.service.updateSettings(data)
        .subscribe((response: any) => {
          if (response.response === 'success') {
            this.sessionStorage.store('THEME', theme);
          }
          if (response.response === 'success' || response.response === 'failure') {
            this.snackbar.open(this.translateService.translateResponse(response.reason), this.i18n('Close'),
              { duration: 2000 });
          }
        });
    } else {
      this.sessionStorage.store('THEME', theme);
    }
    this.cdr.detectChanges();
  }

  logout() {
    this.sessionStorage.store('LOGGED_IN', '');
    this.hideNav();
    this.cdr.detectChanges();
  }

  navlink(): string {
    return (this.loggedIn ? '/dashboard' : '/');
  }

  toggleState() {
    this.isIn = !this.isIn;
    this.cdr.detectChanges();
  }

  openDialog(type: number = 0): void {
    try {
      this.dialog.open(QuickWizardComponent, {
        width: '500px',
        maxHeight: '90vh',
        position: { top: '52px', right: '25px'},
        data: {type}
      });
    } catch (e) {
      console.log(e);
    }
  }
}
