import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar-message',
    styleUrls: ['snackbar-message.scss'],
    templateUrl: 'snackbar-message.html',
})
export class SnackbarMessageComponent {
    constructor(public snackBar: MatSnackBar, public snackRef: MatSnackBarRef<SnackbarMessageComponent>) { }

    public message: string;

    public static openSnackBar(snackBar: MatSnackBar, message: string, duration: number) {
        const componentRef: MatSnackBarRef<SnackbarMessageComponent> =
        snackBar.openFromComponent(SnackbarMessageComponent, {
            duration: duration,
        });
        componentRef.instance.message = message;
    }

    closeSnackbar() {
        this.snackRef.dismiss();
    }
}
