<div class="container-fluid">
  <div class="row" *ngIf="verificationData.kyc_attempts < 3">
    <div class="col-12">
      <ng-container #fullVerify>
        <ng-container *ngIf="requestType === VerificationRequestType.Identity"><!-- Identity Verification -->
          <ng-container *ngIf="verificationPage === 0"> <!-- Identity Documentation -->
            <div class="col-12">
              <h2 class="text-center" i18n>
                  <span class="orange-text">
                      <b>Identity Documentation</b>
                  </span>
              </h2>
            </div>
            <mat-horizontal-stepper [linear]="true" #idStepper>
              <mat-step [stepControl]="identity_form"> <!-- Identity Documentation Information Input -->
                <form [formGroup]="identity_form">
                  <div class="border-2 my-3 p-3">
                    <small class="d-block text-center" i18n>
                      On this step you will be required to provide us with a copy of either your Passport or Identity Document.
                      Please complete the following fields relating to your document before uploading it on the next step
                    </small>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <div class="row align-items-center">
                        <mat-form-field class="col my-0 mx-auto">
                          <mat-select [disableOptionCentering]="true" (openedChange)="opened($event)" i18n-placeholder required
                            placeholder="Issuing Country" formControlName="country" [(ngModel)]="location.country"
                            (selectionChange)="country_code_change()" #countrySelect>
                            <mat-option>
                              <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No countries found.'"
                              [formControl]="issuingCountryFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                              {{country.nicename}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="identity_form.controls.country.hasError('blocked')" i18n>Country Blocked
                          </mat-error>
                        </mat-form-field>
                        <div class="col-auto attention-icon" *ngIf="fieldsFailed">
                          <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('birthCountry'); else imagePassed">
                        </div>
                      </div>
                    </div>

                    <div class="col-12" *ngIf="location.country.id">
                      <div class="row align-items-center">
                        <mat-form-field class="col my-0 mx-auto">
                          <mat-select placeholder="ID Type" formControlName="id_type" [(ngModel)]="location.selectidtype"
                            (selectionChange)="country_code_change()">
                            <mat-option value="ID" i18n>
                              ID
                            </mat-option>
                            <mat-option value="Passport" i18n>
                              Passport
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="col-auto attention-icon" *ngIf="fieldsFailed">
                          <!-- placeholder -->
                        </div>
                      </div>
                    </div>

                    <div class="col-12" *ngIf="location.country.idrequired === '1'">
                      <div class="row align-items-center">
                        <mat-form-field class="col my-0 mx-auto">
                          <input matInput name="ID Number" i18n-placeholder placeholder="ID Number" formControlName="id_number" [(ngModel)]="location.id_number">
                          <mat-error *ngIf="this.identity_form.controls.id_number.hasError('Invalid ID')" i18n>The
                            ID number is not a valid ID.</mat-error>
                        </mat-form-field>
                        <div class="col-auto attention-icon" *ngIf="fieldsFailed">
                          <!-- placeholder -->
                        </div>
                      </div>
                    </div>

                    <div class="col-12"
                      *ngIf="!(location.country.idrequired === '1') && (location.selectidtype === 'ID')">
                      <div class="row align-items-center">
                        <mat-form-field class="col my-0 mx-auto">
                          <input matInput name="Document ID Number" i18n-placeholder placeholder="Document Number"
                            formControlName="document_id_number" [(ngModel)]="location.id_number">
                          <mat-error *ngIf="this.identity_form.controls.document_id_number.hasError('Invalid ID')" i18n>
                            Invalid document number.</mat-error>
                        </mat-form-field>
                        <div class="col-auto attention-icon" *ngIf="fieldsFailed">
                          <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('idNumber'); else imagePassed">
                        </div>
                      </div>
                    </div>

                    <div class="col-12"
                      *ngIf="!(location.country.idrequired === '1') && (location.selectidtype === 'Passport')">
                      <div class="row align-items-center">
                        <mat-form-field class="col my-0 mx-auto">
                          <input matInput name="Document Passport Number" i18n-placeholder placeholder="Document Number"
                            formControlName="document_passport_number" [(ngModel)]="location.passport_number">
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="col-12"
                      *ngIf="location.country.idrequired === '1' && location.selectidtype === 'Passport'">
                      <div class="row align-items-center">
                        <mat-form-field class="col my-0 mx-auto">
                          <input matInput name="Passport Number" i18n-placeholder placeholder="Passport Number"
                            formControlName="passport_number" [(ngModel)]="location.passport_number">
                        </mat-form-field>
                        <div class="col-auto attention-icon" *ngIf="fieldsFailed">
                          <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('passportNumber'); else imagePassed">
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="row align-items-center">
                        <mat-form-field class="col my-0 mx-auto">
                          <input matInput type="date" name="date_of_birth" i18n-placeholder placeholder="Date of Birth" max="{{today | date:'yyyy-MM-dd'}}"
                            formControlName="date_of_birth" [(ngModel)]="location.dateOfBirth">
                        </mat-form-field>
                        <div class="col-auto attention-icon" *ngIf="fieldsFailed">
                          <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('dateOfBirth'); else imagePassed">
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngTemplateOutlet="errorAlert"></ng-container>

                  <div class="col-12 mt-4 px-0">
                    <div class="row justify-content-around">
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button type="button" color="accent" (click)="cancel_click()"
                          i18n>CANCEL</button>
                      </div>
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button type="submit" (click)="submitIdInformation()" color="primary"
                          i18n>NEXT</button>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="identity_doc_form"> <!-- Identity Documentation Upload -->
                <div class="col-12 my-2">
                  <div class="border-2 my-3 p-3">
                    <small class="d-block text-center" i18n>
                      Please upload a copy of the front and back of your {{location.selectidtype}}.<br>Please ensure that the copies
                      are:
                      <div class="width-max-content my-3 mx-auto text-left">
                        <ul>
                          <li>Original and unedited </li>
                          <li>Not blurred or low quality</li>
                          <li>All text and photos are easy to see and read</li>
                        </ul>
                      </div>
                      If a back of document upload is not applicable to your document, you may skip the upload by selecting the
                      checkbox below it.
                    </small>
                  </div>
                </div>

                <form [formGroup]="identity_doc_form">
                  <div class="col-12 col-lg-10 mx-auto">
                    <div class="row mx-0">
                      <div class="col-12 col-lg-6 text-center">
                        <!-- ID Front -->
                        <div class="row justify-content-center align-items-baseline">
                          <small class="col-auto px-1 orange-text"><b>Front of Document</b></small>
                          <div class="my-1" *ngIf="fieldsFailed">
                            <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="!fileFrontID ||
                              (verificationData.failedFields.files && verificationData.failedFields.files.includes(fileFrontID.id)); else imagePassed">
                          </div>
                        </div>

                        <div class="col-12 p-0 border-2 upload-image-box" (dragover)="$event.preventDefault();" (drop)="fileDrop($event, 1)">
                          <input accept="image/*" name="fileFront" #fileFront hidden="true" type="file"
                            (change)="onFileChange($event, 1)" />
                          <ng-container *ngIf="!fileFrontID; else frontIdDisplay">
                            <a class="clickable w-100 h-100 d-flex flex-column align-items-center justify-content-center"  (click)="fileFront.click(); $event.preventDefault();">
                              <img class="upload-icon" src="{{assetsPath}}upload-orange.png">
                              <small>Drag or select to upload</small>
                            </a>
                          </ng-container>
                          <ng-template #frontIdDisplay>
                            <ng-container *ngIf="frontIdUrl; else loadingSpinner">
                              <div *ngIf="(fileFrontID.type && fileFrontID.type.startsWith('image/')) || (fileFrontID.s3Link && !fileFrontID.s3Link.includes('.pdf'))">
                                <a class="clickable w-100 h-100 d-flex flex-column align-items-center justify-content-center" (click)="fileView(1)">
                                  <div *ngIf="fileLoading[0]">
                                    <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
                                  </div>
                                  <img class="upload-preview" [src]="frontIdUrl" (load)="fileLoaded(0)" draggable="false">
                                  <small>Select to view</small>
                                </a>
                              </div>
                              <div *ngIf="(fileFrontID.type && !fileFrontID.type.startsWith('image/')) || (fileFrontID.s3Link && fileFrontID.s3Link.includes('.pdf'))">
                                <ng-container *ngTemplateOutlet="filePlaceholder; context: {file: fileFrontID}"></ng-container>
                              </div>
                            </ng-container>
                          </ng-template>
                        </div>
                        <button class="col-12 mt-1 text-white yellow-button" *ngIf="fileFrontID"
                          [disabled]="fileUploading" mat-raised-button (click)="fileFront.click(); $event.preventDefault();">
                          Upload New
                        </button>
                        <mat-error class="center-error" *ngIf="imagesValid.frontId === false">
                          <small class="col-12" i18n>
                            Image uploaded is too big, please upload an image which is smaller than 5mb
                          </small>
                        </mat-error>
                        <mat-error *ngIf="identity_doc_form.touched && this.identity_doc_form.controls.fileFront.hasError('required')">
                          <small class="col-12" i18n>
                            Please provide the front of your document.
                          </small>
                        </mat-error>
                        <div class="row mt-3 justify-content-center" *ngIf="fileUploading">
                          <mat-progress-spinner class="mx-auto" [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 text-center">
                        <!-- ID Back -->
                        <div class="row justify-content-center align-items-baseline">
                          <small class="col-auto px-1 orange-text"><b>Back of Document</b></small>
                          <div class="my-1" *ngIf="fieldsFailed">
                            <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update"
                              *ngIf="(verificationData.failedFields.files && verificationData.failedFields.files.includes(fileBackID.id)); else imagePassed">
                          </div>
                        </div>

                        <div [ngClass]="location.blankIdBack ? 'border-2-grey' : 'border-2'" class="col-12 p-0 upload-image-box" (dragover)="$event.preventDefault();" (drop)="fileDrop($event, 2)">
                          <input accept="image/*" name="fileBack" [disabled]="location.blankIdBack" hidden="true" type="file" #fileBack
                            (change)="onFileChange($event, 2)" />
                            <ng-container *ngIf="!fileBackID || location.blankIdBack; else backIdDisplay">
                              <a [class.clickable]="!location.blankIdBack"  class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"  (click)="fileBack.click(); $event.preventDefault();">
                                <img class="upload-icon" src="{{location.blankIdBack ? assetsPath + 'upload-grey.png' : assetsPath + 'upload-orange.png' }}">
                                <small [ngClass]="{'grey-text': location.blankIdBack}">Drag or select to upload</small>
                              </a>
                            </ng-container>
                            <ng-template #backIdDisplay>
                              <ng-container *ngIf="backIdUrl; else loadingSpinner">
                                <div *ngIf="(fileBackID.type && fileBackID.type.startsWith('image/')) || (fileBackID.s3Link && !fileBackID.s3Link.includes('.pdf'))">
                                  <a class="clickable w-100 h-100 d-flex flex-column align-items-center justify-content-center" (click)="fileView(2)">
                                    <div *ngIf="fileLoading[1]">
                                      <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
                                    </div>
                                    <img class="upload-preview" [src]="backIdUrl" (load)="fileLoaded(1)" draggable="false">
                                    <small>Select to view</small>
                                  </a>
                                </div>
                                <div *ngIf="(fileBackID.type && !fileBackID.type.startsWith('image/')) || (fileBackID.s3Link && fileBackID.s3Link.includes('.pdf'))">
                                  <ng-container *ngTemplateOutlet="filePlaceholder; context: {file: fileBackID}"></ng-container>
                                </div>
                              </ng-container>
                            </ng-template>
                        </div>
                        <button [class.clickable]="!location.blankIdBack" class="col-12 mt-1 text-white yellow-button" *ngIf="fileBackID && !location.blankIdBack"
                          [disabled]="fileUploading" mat-raised-button (click)="fileBack.click(); $event.preventDefault();">
                          Upload New
                        </button>
                        <mat-error class="center-error" *ngIf="imagesValid.backId === false">
                          <small class="col-12" i18n>
                            Image uploaded is too big, please upload an image which is smaller than 5mb
                          </small>
                        </mat-error>
                        <mat-error *ngIf="!location.blankIdBack && (identity_doc_form.touched && !fileBackID)">
                          <small class="col-12" i18n>
                          Please provide the back of your document.
                          </small>
                        </mat-error>
                        <mat-checkbox class="d-block mt-2" formControlName="id_back_blank" [(ngModel)]="location.blankIdBack" i18n>My document has no back</mat-checkbox>
                        <div class="row mt-3 justify-content-center" *ngIf="fileUploading && !location.blankIdBack">
                          <mat-progress-spinner class="mx-auto" [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-4 px-0">
                    <ng-container *ngTemplateOutlet="errorAlert"></ng-container>
                    <div class="row px-3 justify-content-between">
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button matStepperPrevious type="button" color="accent" i18n>
                          BACK
                        </button>
                      </div>
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button type="submit" [disabled]="fileUploading" (click)="submit_idDocuments()" color="primary" i18n>
                          NEXT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-step>
            </mat-horizontal-stepper>
          </ng-container>
          <ng-container *ngIf="verificationPage === 1">
            <!-- Identity Selfie -->
            <div *ngIf="manualSelfie; else livenessCheck">
              <div class="col-12">
                <h2 class="text-center" i18n>
                  <span class="orange-text">
                    <b>Photo Verification</b>
                  </span>
                </h2>
              </div>

              <div class="col-12 mb-2">
                <div class="border-2 my-3 p-3">
                  <small class="d-block text-center" i18n>
                    <span>
                      Please upload a photo ("selfie") of yourself holding the document you uploaded on the previous step and a piece
                      of paper with the words "ChainEX" and today's date.
                      Please ensure that the photo is:
                    </span>
                    <ul class="width-max-content my-3 mx-auto text-left">
                      <li>Not edited </li>
                      <li>Not blurred or low quality</li>
                      <li>Your Document is clear and readable</li>
                      <li>Your face can be seen clearly</li>
                    </ul>
                    <a class="clickable d-block" (click)="openSelfieExample()">
                      Click here for an example of a photo such as this
                    </a>
                  </small>
                </div>
              </div>

              <form [formGroup]="selfie_form">
                <div class="row justify-content-center">

                  <div class="col-12 col-lg-6 text-center">
                    <!-- Selfie Photo -->
                    <div class="row justify-content-center align-items-baseline">
                      <small class="col-auto px-1 orange-text">Selfie Photo</small>
                      <div class="my-1" *ngIf="fieldsFailed">
                        <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update"
                          *ngIf="!fileSelfiePhoto ||
                        (verificationData.failedFields.files && verificationData.failedFields.files.includes(fileSelfiePhoto.id)); else imagePassed">
                      </div>
                    </div>

                    <div class="col-12 p-0 border-2 upload-image-box" (dragover)="$event.preventDefault();" (drop)="fileDrop($event, 3)">
                      <input accept="image/*" name="fileSelfie" #fileSelfie hidden="true" type="file" (change)="onFileChange($event, 3)" />
                      <ng-container *ngIf="!fileSelfiePhoto; else selfieDisplay">
                        <a class="clickable w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                          (click)="fileSelfie.click(); $event.preventDefault();">
                          <img class="upload-icon" src="{{assetsPath}}upload-orange.png">
                          <small>Drag or select to upload</small>
                        </a>
                      </ng-container>
                      <ng-template #selfieDisplay>
                        <ng-container *ngIf="selfieUrl; else loadingSpinner">
                          <div
                            *ngIf="(fileSelfiePhoto.type && fileSelfiePhoto.type.startsWith('image/')) || (fileSelfiePhoto.s3Link && !fileSelfiePhoto.s3Link.includes('.pdf'))">
                            <a class="clickable w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                              (click)="fileView(3)">
                              <div *ngIf="fileLoading[2]">
                                <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
                              </div>
                              <img class="upload-preview" [src]="selfieUrl" (load)="fileLoaded(2)" draggable="false">
                              <small>Select to view</small>
                            </a>
                          </div>
                          <div
                            *ngIf="(fileSelfiePhoto.type && !fileSelfiePhoto.type.startsWith('image/')) || (fileSelfiePhoto.s3Link && fileSelfiePhoto.s3Link.includes('.pdf'))">
                            <ng-container *ngTemplateOutlet="filePlaceholder; context: {file: fileSelfiePhoto}"></ng-container>
                          </div>
                        </ng-container>
                        <ng-template #selfieDisplay>
                          <ng-container *ngIf="selfieUrl; else loadingSpinner">
                            <a class="clickable w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                              (click)="fileView(3)">
                              <div *ngIf="fileLoading[2]">
                                <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
                              </div>
                              <img class="upload-preview" [src]="selfieUrl" (load)="fileLoaded(2)">
                              <small>Select to view</small>
                            </a>
                          </ng-container>
                        </ng-template>
                      </ng-template>
                    </div>

                    <button class="col-12 mt-1 text-white yellow-button" *ngIf="fileSelfiePhoto" [disabled]="fileUploading"
                      mat-raised-button (click)="fileSelfie.click(); $event.preventDefault();">
                      Upload New
                    </button>
                    <mat-error class="center-error" *ngIf="imagesValid.selfie === false">
                      <small class="col-12" i18n>
                        Image uploaded is too big, please upload an image which is smaller than 5mb
                      </small>
                    </mat-error>
                    <mat-error *ngIf="selfie_form.touched && this.selfie_form.controls.fileSelfie.hasError('required')">
                      <small class="col-12" i18n>
                        Please provide a selfie photo
                      </small>
                    </mat-error>
                    <div class="row mt-3 justify-content-center" *ngIf="fileUploading">
                      <mat-progress-spinner class="mx-auto" [diameter]="20" color="accent" mode="indeterminate">
                      </mat-progress-spinner>
                    </div>
                  </div>


                  <div class="col-12 mt-4 px-0">
                    <ng-container *ngTemplateOutlet="errorAlert"></ng-container>
                    <div class="row px-3 justify-content-between">
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button (click)="back_click()" type="button" color="accent" i18n>
                          BACK
                        </button>
                      </div>
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button type="submit" [disabled]="fileUploading" (click)="submit_selfie()"
                          color="primary" i18n>
                          NEXT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <ng-template #livenessCheck>
              <div>
                <app-liveness-check (livenessChecked)="onLivenessChecked($event)" (manualSelfieSwitch)="onManualSelfieSwitch($event)"></app-liveness-check>
              </div>
              <div class="col-12 mt-4 px-0">
                <ng-container *ngTemplateOutlet="errorAlert"></ng-container>
                <div class="row px-3 justify-content-between">
                  <div class="col-12 col-md-3 mb-2">
                    <button class="w-100" mat-raised-button (click)="back_click()" type="button" color="accent" i18n>
                      BACK
                    </button>
                  </div>
                  <div class="col-12 col-md-3 mb-2">
                    <button class="w-100" mat-raised-button type="submit" (click)="submit_liveness()"
                      color="primary" [disabled]="!(verificationData.livenessChecked)"  i18n>
                      NEXT
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="requestType === VerificationRequestType.Residence"> <!-- Residence step -->
          <div class="col-12">
            <h2 class="text-center" i18n>
                <span class="orange-text">
                    <b>Residence Verification</b>
                </span>
            </h2>
          </div>
          <mat-horizontal-stepper [linear]="true" #residenceStepper>
              <mat-step [stepControl]="location_form"> <!-- Proof of Residence Address Input -->
                <div class="col-12 mb-2">
                  <div class="border-2 my-3 p-3">
                    <small class="d-block text-center" i18n>
                      <span>
                        On this step you will be required to provide us with valid Proof of Residence Documentation.
                        Please complete the following fields relating to your document before uploading it on the next step
                      </span>
                    </small>
                  </div>
                </div>

                <form [formGroup]="location_form">
                  <div class="row justify-content-center">
                    <div class="col-12">
                      <div class="row align-items-center">
                        <mat-form-field class="col my-0">
                          <mat-select [disableOptionCentering]="true" (openedChange)="opened($event)" i18n-placeholder required
                            placeholder="Country of Residence" formControlName="residenceCountry" [(ngModel)]="location.residenceCountry"
                            #countrySelect>
                            <mat-option>
                              <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No countries found.'"
                              [formControl]="residenceCountryFilterCtrl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of residenceCountries | async" [value]="country">
                              {{country.nicename}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="location_form.controls.residenceCountry.hasError('blocked')" i18n>Country Blocked
                          </mat-error>
                        </mat-form-field>
                        <div class="col-auto attention-icon" *ngIf="fieldsFailed">
                          <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('residenceCountry'); else imagePassed">
                        </div>
                      </div>
                    </div>
                    <ng-container *ngTemplateOutlet="provinceInput; context:{control: location_form.get('state')}">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="cityInput; context:{control: location_form.get('city')}">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="suburbInput; context:{control: location_form.get('suburb')}">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="zipCodeInput; context:{control: location_form.get('zip')}">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="streetNumberInput; context:{control: location_form.get('street')}">
                    </ng-container>

                    <ng-container *ngTemplateOutlet="addessButtonsErrorMessage"></ng-container>

                  </div>
                </form>
              </mat-step>
              <mat-step> <!-- Proof of Residence Upload -->
                <div class="col-12 mb-2">
                  <div class="border-2 my-3 p-3">
                    <small class="d-block text-center" i18n>
                      <span>
                        Please upload a Proof of Residence which confirms the details entered on the previous steps.
                        The document should:
                      </span>
                      <ul class="width-max-content my-3 mx-auto text-left">
                        <li>Contain your Name and Address </li>
                        <li>Contain a Date not older than 3 months</li>
                        <li>Should not be edited</li>
                        <li>Your Document is clear and readable</li>
                      </ul>
                    </small>

                    <ng-container *ngTemplateOutlet="proofOfResidenceLink"></ng-container>
                  </div>
                </div>

                <form [formGroup]="por_upload_form">
                  <div class="row justify-content-center">
                    <ng-container
                      *ngTemplateOutlet="proofOfResidenceInput; context:{control: por_upload_form.get('filePOR')}">
                    </ng-container>
                  </div>

                  <ng-container *ngTemplateOutlet="errorAlert"></ng-container>
                  <div class="row mt-3 justify-content-center" *ngIf="fileUploading">
                    <mat-progress-spinner class="mx-auto" [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
                  </div>

                  <div class="col-12 mt-4 px-0">
                    <div class="row px-3 justify-content-between">
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button matStepperPrevious type="button" color="accent" i18n>
                          BACK
                        </button>
                      </div>
                      <div class="col-12 col-md-3 mb-2">
                        <button [disabled]="fileUploading"
                          class="w-100" mat-raised-button type="submit" (click)="submit_form()" color="primary" i18n>
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-step>
          </mat-horizontal-stepper>
        </ng-container>

        <ng-container *ngIf="requestType === VerificationRequestType.Compliance">
          <div class="col-12">
            <h2 class="text-center" i18n>
                <span class="orange-text">
                    <b>Income and Use</b>
                </span>
            </h2>
          </div>
          <div class="col-12 mb-2">
            <div class="border-2 my-3 p-3">
              <small class="d-block text-center" i18n>
                <span>
                  Please complete the below questions relating to your income and intended use of your ChainEX account.
                  We are required by law to obtain these answers
                </span>
              </small>
            </div>
          </div>
          <mat-horizontal-stepper [linear]="true" #stepper>
              <mat-step [stepControl]="accountPurpose_form"> <!-- Purpose of Account Input -->
                <div *ngTemplateOutlet="purposeOfAccountTemplate"></div>
                <div *ngIf="accountPurpose_form" class="col-12 mt-4 px-0">
                  <div class="row px-3 justify-content-between">
                    <div class="col-12 col-md-3 mb-2">
                      <button class="w-100" mat-raised-button type="button" (click)="back_click()" color="accent" i18n>
                        BACK
                      </button>
                    </div>
                    <div class="col-12 col-md-3 mb-2">
                      <button class="w-100" mat-raised-button type="submit" (click)="submitAccountPurposeForm()" color="primary" i18n>
                        NEXT
                      </button>
                    </div>
                  </div>
                </div>
              </mat-step>
              <mat-step [stepControl]="sourceOfFunds_form"> <!-- Source of Funds Input -->
                <div *ngTemplateOutlet="sourceOfFundsTemplate"></div>
                <div class="col-12 mt-4 px-0">
                  <div class="row px-3 justify-content-between">
                    <div class="col-12 col-md-3 mb-2">
                      <button class="w-100" mat-raised-button matStepperPrevious type="button" color="accent" i18n>
                        BACK
                      </button>
                    </div>
                    <div class="col-12 col-md-3 mb-2">
                      <button class="w-100" mat-raised-button type="submit" (click)="submitSourceOfFundsForm()" color="primary" i18n>
                        NEXT
                      </button>
                    </div>
                  </div>
                </div>
              </mat-step>
              <mat-step [stepControl]="compliance_form"> <!-- Other Compliance Input -->
                <div *ngTemplateOutlet="complianceQuestionnaire1"></div>
                  <div class="col-12 mt-4 px-0">
                    <div class="row px-3 justify-content-between">
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button matStepperPrevious type="button" color="accent" i18n>
                          BACK
                        </button>
                      </div>
                      <div class="col-12 col-md-3 mb-2">
                        <button class="w-100" mat-raised-button type="submit" (click)="submitComplianceForm()" color="primary" i18n>
                          NEXT
                        </button>
                      </div>
                    </div>
                  </div>
              </mat-step>
          </mat-horizontal-stepper>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="verificationData.kyc_attempts >= 3">
    <div class="col-12 text-center">
      <img src="assets/{{exchangeName}}/images/pages/common/cx_failed.svg" width="90">

      <div class="mt-2 mb-2 text-left">
        <p [innerHTML]="verificationData.max_kyc_attempt_notification">
        </p>
      </div>

      <div class="col-12 text-right">
        <button mat-raised-button color="accent" (click)="cancel_click()" i18n>CLOSE</button>
      </div>
    </div>
  </div>
</div>

<ng-template #purposeOfAccountTemplate>
  <!-- Purpose of Account -->
  <form *ngIf="questionsAndOptions && accountPurpose_form" [formGroup]="accountPurpose_form">
    <ng-container *ngIf="(questionsAndOptions && accountPurpose_form?.controls); else loadingSpinner">
      <div class="row my-4">
        <h6 class="col-12 mx-0">
          <strong>{{ questionsAndOptions?.question_1.question }} (Please select all applicable
            options):</strong>
        </h6>

        <section class="col-12 d-flex flex-row flex-wrap pt-3" >
          <ng-container *ngFor="let optionControl of accountPurpose_form.controls.account_purpose.controls"
            [formGroup]="optionControl">
            <mat-checkbox class="col-12 col-md-4 pb-2" formControlName="checked" [id]="optionControl.controls.id.value"
              [(ngModel)]="accountPurpose[getKeyName(optionControl.controls.id.value)]" color="primary">
              <span class="checkbox-text" [class.text-white]="darkTheme" i18n>
                {{ optionControl.controls.textValue.value }}
              </span>
            </mat-checkbox>
          </ng-container>

          <mat-error class="col-12"
            *ngIf="!accountPurpose_form.controls.account_purpose_required.valid && accountPurpose_form.controls.account_purpose.touched">
            Please select at least one field.
          </mat-error>
        </section>
      </div>
    </ng-container>

  </form>
</ng-template>

<ng-template #sourceOfFundsTemplate>
  <!-- Source of funds -->
  <form *ngIf="questionsAndOptions && sourceOfFunds_form" [formGroup]="sourceOfFunds_form">
    <ng-container *ngIf="(questionsAndOptions && sourceOfFunds_form?.controls); else loadingSpinner">
    <div class="row">
      <h6 class="col-12 mx-0">
        <strong>{{ questionsAndOptions?.question_2.question }} (Please select all applicable
          options):</strong>
      </h6>

      <section class="col-12 d-flex flex-row flex-wrap pt-3 align-items-center">
        <ng-container *ngFor="let optionControl of sourceOfFunds_form.controls.source_of_funds.controls"
          [formGroup]="optionControl">
          <mat-checkbox class="col-12 col-md-4 pb-2" formControlName="checked" [id]="optionControl.controls.id.value"
            [(ngModel)]="sourceOfFunds[getKeyName(optionControl.controls.id.value)]" color="primary">
            <span class="checkbox-text" [class.text-white]="darkTheme" i18n>
              {{ optionControl.controls.textValue.value }}
            </span>
          </mat-checkbox>
        </ng-container>
        <mat-error class="col-12"
            *ngIf="!sourceOfFunds_form.controls.source_of_funds_required.valid && sourceOfFunds_form.controls.source_of_funds.touched">
            Please select at least one field.
        </mat-error>
      </section>

      <!-- Other source of income input -->
      <div class="col-12 col-md-6 py-0 my-0 d-flex justify-content-end"
        *ngIf="sourceOfFunds['option_' + otherSourceOfFundId]">
        <mat-form-field class="w-100 py-0 my-0">
          <input type="text" matInput formControlName="other_source_of_funds"
            [required]="sourceOfFunds['option_' + otherSourceOfFundId]"
            [(ngModel)]="complianceAnswers.other_source_of_funds" i18n-placeholder
            placeholder="Other Source of Funds">
        </mat-form-field>
      </div>
    </div>
    </ng-container>
  </form>
</ng-template>

<!-- Compliance Questionnaire Template -->
<ng-template #complianceQuestionnaire1 let-control="control">
  <form *ngIf="questionsAndOptions && compliance_form" [formGroup]="compliance_form">
    <ng-container *ngIf="(questionsAndOptions && compliance_form?.controls); else loadingSpinner">
      <!-- Net monthly income & industry -->
      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field class="w-100">
            <mat-select [disableOptionCentering]="true" formControlName="question_3"
              [(ngModel)]="complianceAnswers.question_3" i18n-placeholder
              placeholder="{{ questionsAndOptions?.question_3.question }}" #netMonthlyIncomeSelect>
              <mat-option *ngFor="let option of filteredNetMonthlyIncome" [value]="option">
                {{ option.compliance_option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
          <mat-form-field class="w-100">
            <mat-select [disableOptionCentering]="true" formControlName="question_4"
              [(ngModel)]="complianceAnswers.question_4" i18n-placeholder
              placeholder="{{ questionsAndOptions?.question_4.question }}" #industrySelect>
              <mat-option *ngFor="let option of questionsAndOptions?.question_4.options" [value]="option">
                {{ option.compliance_option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Position -->
      <div class="row" *ngIf="!hidePositionSelection">
        <div class="col-12 col-md-6">
          <mat-form-field class="w-100">
            <mat-select [disableOptionCentering]="true" formControlName="question_5"
              [(ngModel)]="complianceAnswers.question_5" required i18n-placeholder
              placeholder="{{ questionsAndOptions?.question_5.question }}" #positionSelect>
              <mat-option *ngFor="let option of filteredPositions" [value]="option">
                {{ option.compliance_option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Other position input -->
        <div class="col-12 col-md-6" *ngIf="otherPositionId == complianceAnswers.question_5.option_id">
          <mat-form-field class="w-100">
            <input type="text" matInput formControlName="other_position"
              [required]="otherPositionId == complianceAnswers.question_5.option_id"
              [(ngModel)]="complianceAnswers.other_position" i18n-placeholder placeholder="Other Position">
          </mat-form-field>
        </div>
      </div>

      <!-- Nature of business -->
      <div class="row">
        <h6 class="col-12 mx-0">
          <strong>{{ questionsAndOptions?.question_8.question }}</strong>
        </h6>

        <div class="col-12 mx-0 pb-2">
          <div class="col-12 px-0 my-0">
            <mat-form-field class="w-100">
              <input type="text" matInput formControlName="nature_of_business"
                [(ngModel)]="complianceAnswers.nature_of_business" i18n-placeholder placeholder="Nature of Business">
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Politically exposed -->
      <div class="row">
        <h6 class="col-12 mx-0">
          <strong>{{ questionsAndOptions?.question_6.question }}</strong>
        </h6>

        <div class="col-12 mx-0 pb-2">
          <mat-radio-group class="col-4 d-flex flex-column align-items" [(ngModel)]="complianceAnswers.question_6"
            formControlName="question_6" [align]="'start'">
            <mat-radio-button *ngFor="let option of questionsAndOptions?.question_6.options" [value]="option">
              <span [class.text-white]="darkTheme">
                {{ option.compliance_option }}
              </span>
            </mat-radio-button>
          </mat-radio-group>
          <mat-error class="col-12"
            *ngIf="compliance_form.controls.question_6.touched && compliance_form.controls.question_6.hasError('required')">
            Field required.
          </mat-error>

          <!-- if `YES` is selected -->
          <div class="col-12 px-0 my-0" *ngIf="politicallyExposedId == complianceAnswers.question_6.option_id">
            <mat-form-field class="w-100 py-0 my-0">
              <input type="text" matInput formControlName="politically_exposed"
                [(ngModel)]="complianceAnswers.politically_exposed"
                [required]="politicallyExposedId == complianceAnswers.question_6.option_id" i18n-placeholder
                placeholder="Please explain">
            </mat-form-field>
          </div>
        </div>
      </div>

      <ng-container *ngTemplateOutlet="errorAlert"></ng-container>
    </ng-container>
  </form>
</ng-template>

<ng-template #complianceQuestionnaire2 let-control="control">
  <div class="col-12">
    <p i18n>Congratulations! Your submission has been captured for approval and you will be notified
      with the result as soon as the process has been completed.</p>
    <p i18n>If you already have a bank account linked to your profile, your bank account will now be
      sent for automatic verification. We will notify you of the verification results via email within
      48 hours. If you do not have a linked bank account yet or wish to update your current one you
      may do so from your ZAR wallet on the balances page which will also initiate the automated
      verification process</p>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-md-3 offset-md-9 mb-2">
        <button class="w-100" mat-raised-button (click)="cancel_click(true)" color="primary" i18n>CLOSE</button>
      </div>
    </div>
  </div>
</ng-template>


<!-- Province Input Template -->
<ng-template #provinceInput let-control="control">
  <div class="col-12">
    <div class="row align-items-center">
      <mat-form-field class="col my-0">
        <input required matInput name="Province" i18n-placeholder placeholder="Province/State" [formControl]="control"
          [(ngModel)]="location.state">
      </mat-form-field>
      <div class="col-auto attention-icon" *ngIf="fieldsFailed">
        <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('province'); else imagePassed">
      </div>
    </div>
  </div>
</ng-template>

<!-- City Input Template -->
<ng-template #cityInput let-control="control">
  <div class="col-12">
    <div class="row align-items-center">
      <mat-form-field class="col my-0">
        <input required matInput name="City" i18n-placeholder placeholder="City" [formControl]="control"
          [(ngModel)]="location.city">
      </mat-form-field>
      <div class="col-auto attention-icon" *ngIf="fieldsFailed">
        <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('city'); else imagePassed">
      </div>
    </div>
  </div>
</ng-template>

<!-- suburb Input Template -->
<ng-template #suburbInput let-control="control">
  <div class="col-12">
    <div class="row align-items-center">
      <mat-form-field class="col my-0">
        <input required matInput name="Suburb" i18n-placeholder placeholder="Suburb" [formControl]="control"
          [(ngModel)]="location.suburb">
      </mat-form-field>
      <div class="col-auto attention-icon" *ngIf="fieldsFailed">
        <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('suburb'); else imagePassed">
      </div>
    </div>
  </div>
</ng-template>

<!-- Zip Code Input Template -->
<ng-template #zipCodeInput let-control="control">
  <div class="col-12">
    <div class="row align-items-center">
      <mat-form-field class="col my-0">
        <input required matInput name="ZIP Code" i18n-placeholder placeholder="Code/ZIP" [formControl]="control"
          [(ngModel)]="location.zipcode">
      </mat-form-field>
      <div class="col-auto attention-icon" *ngIf="fieldsFailed">
        <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('zipCode'); else imagePassed">
      </div>
    </div>
  </div>
</ng-template>

<!-- Street Number Input Template -->
<ng-template #streetNumberInput let-control="control">
  <div class="col-12">
    <div class="row align-items-center">
      <mat-form-field class="col my-0">
        <input required matInput name="Street" i18n-placeholder placeholder="Street Name & Number" [formControl]="control"
          [(ngModel)]="location.street_no">
      </mat-form-field>
      <div class="col-auto attention-icon" *ngIf="fieldsFailed">
        <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="verificationData.failedFields.fields && verificationData.failedFields.fields.includes('streetNumber'); else imagePassed">
      </div>
    </div>
  </div>
</ng-template>

<!-- Error Message and Buttons for address form Template -->
<ng-template #addessButtonsErrorMessage>
  <ng-container *ngTemplateOutlet="errorAlert"></ng-container>

  <div class="col-12">
    <div class="row">
      <div class="col-12 col-md-3 mb-2">
        <button class="w-100" mat-raised-button type="button" color="accent" (click)="cancel_click()"
          i18n>CANCEL</button>
      </div>
      <div class="col-12 col-md-3 offset-md-6 mb-2">
        <button class="w-100" mat-raised-button type="submit" (click)="submitResidenceInformation()" color="primary"
          i18n>NEXT</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Proof Of Residence Input -->
<ng-template #proofOfResidenceInput let-control="control">
  <div class="col-12 col-lg-10 d-flex justify-content-center">
    <div class="col-12 col-lg-6 text-center">
      <!-- Proof of Residence -->
      <div class="row justify-content-center align-items-baseline">
        <small class="col-auto px-1 orange-text">Proof of Residence</small>
        <div class="my-1" *ngIf="fieldsFailed">
          <img src="{{assetsPath}}error_circle.svg" matTooltip="Failed, please update" *ngIf="!filePORDocument ||
            (verificationData.failedFields.files && verificationData.failedFields.files.includes(filePORDocument.id)); else imagePassed">
        </div>
      </div>

      <div class="col-12 p-0 border-2 upload-image-box" (dragover)="$event.preventDefault();" (drop)="fileDrop($event, 4)">
        <input name="filePOR" #filePOR hidden="true" type="file"
          (change)="onFileChange($event, 4)" />

        <ng-container *ngIf="!filePORDocument; else porDisplay">
          <a class="clickable w-100 h-100 d-flex flex-column align-items-center justify-content-center"  (click)="filePOR.click(); $event.preventDefault();">
            <img class="upload-icon" src="{{assetsPath}}upload-orange.png">
            <small>Drag or select to upload</small>
          </a>
        </ng-container>
        <ng-template #porDisplay>
          <ng-container *ngIf="porUrl; else loadingSpinner">
            <div *ngIf="(filePORDocument.type && filePORDocument.type.startsWith('image/')) || (filePORDocument.s3Link && !filePORDocument.s3Link.includes('.pdf'))">
              <a class="clickable w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                (click)="fileView(4)">
                <div *ngIf="fileLoading[3]">
                  <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
                </div>
                <img class="upload-preview" [src]="porUrl" (load)="fileLoaded(3)" draggable="false">
                <small>Select to view</small>
              </a>
            </div>
            <div *ngIf="(filePORDocument.type && !filePORDocument.type.startsWith('image/')) || (filePORDocument.s3Link && filePORDocument.s3Link.includes('.pdf'))">
              <ng-container *ngTemplateOutlet="filePlaceholder; context: {file: filePORDocument}"></ng-container>
            </div>
          </ng-container>
        </ng-template>
      </div>
      <button class="col-12 mt-1 text-white yellow-button" *ngIf="filePORDocument"
        [disabled]="fileUploading" mat-raised-button (click)="filePOR.click(); $event.preventDefault();">
        Upload New
      </button>
      <mat-error class="center-error" *ngIf="por_upload_form.touched && por_upload_form.controls.filePOR.hasError('required')">
        <small class="col-12" i18n>
          Please provide Proof of Residence
        </small>
      </mat-error>
      <mat-error class="center-error" *ngIf="imagesValid.POR === false">
        <small class="col-12" i18n>
          Image uploaded is too big, please upload an image which is smaller than 5mb
        </small>
      </mat-error>
    </div>
  </div>
</ng-template>

<ng-template #proofOfResidenceLink>
  <small>
    <a class="d-block text-center" href="{{getSupportUrl()}}/kb/functionality/what-documents-are-accepted-as-proof-of-residence" target="_blank">
      Click here for a full list of acceptable Proof of Residence documents
    </a>
  </small>
</ng-template>

<ng-template #selfieExample>
  <div class="row justify-content-center">
    <img class="col-12 my-3 px-4" src="{{assetsPath}}Selfie-Image-Upload.png">
    <div class="col-12 border-2 p-3 text-center">
      <span i18n>
        You need to be holding your uploaded document and a piece of paper with the words "ChainEX" and today's date.
        Please ensure that all information on the document and your face is clear and readable.
      </span>
    </div>
  <button class="col-3 my-3" mat-raised-button color="primary" mat-dialog-close i18n>CLOSE</button>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div class="row justify-content-center h-100">
  <mat-progress-spinner class="m-auto" mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #imageView let-data>
  <div>
    <img class="upload-view" [src]="data.imageUrl">
  </div>
</ng-template>

<ng-template #filePlaceholder let-file="file">
  <div class="col-12">
    <img draggable="false" class="upload-icon" src="{{assetsPath}}file.svg">
    <span class="text-center" *ngIf="file && file.name">
      {{file.name}}
    </span>
    <small *ngIf="!file || !file.name">
      File Submitted
    </small>
  </div>
</ng-template>

<ng-template #errorAlert>
  <div class="col-12 mt-3" *ngIf="result_fail">
    <div class="alert alert-danger">
      <p class="mb-0 text-center">{{result_message|i18n}}</p>
    </div>
  </div>
</ng-template>

<ng-template #imagePassed>
  <img matTooltip="Passed" src="{{assetsPath}}check_circle.svg">
</ng-template>