<div class="warning-box" *ngIf="currencyInfo && currencyInfo.isFundpinEnabled === FundPinStatus.Pending && !(disabledFromTime || disabledFromSettings)">
  <div>
    <span class="d-block text-center" i18n>
      Withdrawals are disabled on your account since you have not yet confirmed your Fund PIN<br>
      Please check your email and follow the steps emailed to you
    </span>
    <br><br>
    <span class="d-block text-center" i18n>
      To resend your Fund PIN confirmation email, select the button below:
    </span>
    <br><br>
    <button mat-raised-button (click)="resendFundPin()" class="col-12 col-lg-4 d-block mx-auto mat-raised-button" color="primary" i18n>
      RESEND
    </button>
  </div>
</div>
<div class="warning-box" *ngIf="currencyInfo && currencyInfo.cryptoLevelRestricted && coinType !== 'Fiat'">
  <div>
    <span i18n>
      Please complete level 2 verification in order to withdraw from this wallet
    </span>
    <br><br>
    <button mat-raised-button routerLink="profile" class="d-block mx-auto mat-raised-button" color="primary"
            i18n>
      VERIFY NOW
    </button>
  </div>
</div>
<div class="warning-box"
  *ngIf="currencyInfo && (!currencyInfo.cryptoLevelRestricted || coinType === 'Fiat') && (!currencyInfo.is2faenabled && currencyInfo.isFundpinEnabled === FundPinStatus.Disabled)">
  <div>
    <span i18n>
      To protect your account, you are required to activate 2FA or Fund PIN security before performing a withdrawal.
    </span>
    <br><br>
    <div class="row px-3">
      <button mat-raised-button (click)="toggleFundPin()" class="col-12 col-lg-3 ml-auto mr-auto mr-lg-3 mb-3 mat-raised-button" color="primary" i18n>
        ENABLE FUND PIN
      </button>
      <button mat-raised-button (click)="toggleTwoFA()" class="col-12 col-lg-3 ml-auto ml-lg-3 mr-auto mb-3 mat-raised-button" color="primary" i18n>
        ENABLE 2FA
      </button>
    </div>
  </div>
</div>
<div class="warning-box"
  *ngIf="disabledFromSettings && !(currencyInfo && !currencyInfo.cryptoLevelRestricted && (!currencyInfo.is2faenabled && currencyInfo.isFundpinEnabled === FundPinStatus.Disabled))">
  <div>
    <span i18n>
      Please note that withdrawals are currently disabled.
    </span>
    <span class="d-block my-3">
      To enable withdrawals, please <a routerLink="settings">click here</a> to change your security settings.
    </span>
  </div>
</div>
<div class="warning-box"
  *ngIf="disabledFromTime && !(currencyInfo && !currencyInfo.cryptoLevelRestricted && (!currencyInfo.is2faenabled && currencyInfo.isFundpinEnabled === FundPinStatus.Disabled))">
  <div>
    <span class="text-center" i18n>
      Please note that withdrawals are disabled temporarily.
    </span>
    <span class="d-block text-center my-3">
      Withdrawals will be enabled again after 24 hours.
    </span>
  </div>
</div>
<div class="container-fluid mt-4 mb-4" *ngIf="isWithdrawalDisabled()">
  <div class="row">
    <div class="col-12">
      <div class="border-box warning verification_limits mt-1">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-7 pl-4">
            <p class="mb-0" i18n>
              Withdrawals for this coin have been disabled because the coin is currently listed as a (IEO) Initial
              Exchange Coins. Withdrawals will only be enabled once the coins is no longer listed as an IEO.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Crypto Withdrawals -->
<div class="container-fluid mt-4 mb-4" *ngIf="coinType !== 'Fiat' && !isWithdrawalDisabled()">
  <div class="row" [formGroup]="cryptoWithdrawal">
    <div class="col-12 mb-4">
      <div class="border-box warning verification_limits mt-1">
        <div class="row">
          <div class="col-12" *ngIf="verificationLevel > 1">
            <b>
              <ng-container i18n>Verification level</ng-container> {{verificationLevel}} <ng-container i18n>Restrictions
              </ng-container>
            </b>
            <br>
            <p *ngIf="totalCoin === undefined; else hasTotalCoinCrypto">Loading currency information..</p>
            <ng-template #hasTotalCoinCrypto [ngTemplateOutlet]="availableBalance"></ng-template>
          </div>
          <div class="col-12 col-md-6 col-lg-7" *ngIf="verificationLevel < 2">
            <b>
              <ng-container i18n>Verification level</ng-container> {{verificationLevel}} <ng-container i18n>Restrictions
              </ng-container>
            </b>
            <br>
            <p *ngIf="totalCoin === undefined; else hasTotalCoinCryptoA">Loading currency information..</p>
            <ng-template #hasTotalCoinCryptoA [ngTemplateOutlet]="availableBalance"></ng-template>
            <p class="mt-2" i18n>
              To increase your limits please verify and secure your account.
            </p>
          </div>
          <div class="col-12 col-6 col-lg-5 verifyContainer pr-4" *ngIf="verificationLevel < 2">
            <div class="w-100 text-right">
              <a mat-raised-button color="primary" routerLink="/profile" (click)="closeModal()" i18n>VERIFY YOUR
                ACCOUNT NOW</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="!allowWithdrawal">
      <div class="alert alert-danger">
        <p class="mb-0">
          <ng-container i18n>You have reached the limits of your current verification level.</ng-container>
          <ng-container i18n>Please verify your account</ng-container> <a routerLink="/profile" class="link"
            (click)="closeModal()" i18n>Here</a>
          <ng-container i18n>or contact</ng-container> <a href="mailto://support.chainex.io" i18n>support</a>.
        </p>
      </div>
    </div>

    <!-- Amount input -->
    <div class="col-12 col-md-4 mb-2" *ngIf="allowWithdrawal">
      <mat-form-field class="amountInput">
        <input title="" required type="number" matInput name="Withdrawal Amount" i18n-placeholder placeholder="Amount"
          formControlName="amount" [(ngModel)]="withdrawalAmount" (keyup)="amountChange()" (change)="amountChange()">
        <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.amount.hasError('required')" i18n>
          Withdrawal amount required.
        </mat-error>
        <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.amount.hasError('max')" i18n>
          Must be equal to or less than {{setDecimal(maxAmount)}} {{coinCode}}
        </mat-error>
        <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.amount.hasError('min')">
          <ng-container i18n>Cannot withdraw less than</ng-container> {{setDecimal(minAmount)}} {{coinCode}}.
        </mat-error>
      </mat-form-field>
      <button mat-raised-button class="ml-2" color="primary" (click)="maxClick()" i18n>MAX</button>
    </div>

    <!-- Withdrawal Fee -->
    <div class="col-12 col-md-2 WithdrawalFee">
      <mat-form-field *ngIf="allowWithdrawal">
        <input type="number" title="" disabled matInput name="Withdrawal Fee" i18n-placeholder placeholder="Fee"
          formControlName="fee" [(ngModel)]="withdrawalFee">
      </mat-form-field>
    </div>

    <!-- Net amount input -->
    <mat-form-field class="col-12 col-md-3" *ngIf="allowWithdrawal">
      <input title="" type="number" required matInput name="Withdrawal Net Amount" i18n-placeholder
        placeholder="Net Amount" formControlName="netAmount" [(ngModel)]="netAmount" (keyup)="netAmountChange()"
        (change)="netAmountChange()">
      <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.netAmount.hasError('required')">
        <ng-container i18n>Withdrawal net amount required.</ng-container>
      </mat-error>
      <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.netAmount.hasError('max')">
        <ng-container i18n>Must be equal to or less than</ng-container> {{setDecimal(maxAmount)}} {{coinCode}}
      </mat-error>
      <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.netAmount.hasError('min')">
        <ng-container i18n>Cannot withdraw less than 0</ng-container> {{coinCode}}.
      </mat-error>
    </mat-form-field>

    <!-- Fund Pin -->
    <mat-form-field class="col-12 col-md-3" [ngClass]="{'d-none': isLightningNetwork() && !lightningOverDailyLimit}" *ngIf="allowWithdrawal && currencyInfo.isFundpinEnabled === FundPinStatus.Enabled && untrustedAddress">
      <input title="" type="password" required matInput name="Fund Pin" i18n-placeholder placeholder="Fund PIN"
        formControlName="fundPin">
      <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.fundPin.hasError('required')">
        <ng-container i18n>Fund PIN is required.</ng-container>
      </mat-error>
    </mat-form-field>

    <!-- Crypto Network & Address input -->
    <div class="col-12 mx-0 px-0" [ngClass]="{'min-w-415': selectedAddress}" *ngIf="allowWithdrawal">
      <div class="row mx-0 px-0 address-flex align-items-start" [ngClass]="{'my-0': selectedAddress}">

        <!-- coin network selection -->
        <ng-container *ngTemplateOutlet="coinNetworkSelection; context: {form: cryptoWithdrawal}"></ng-container>

        <!-- address input -->
        <div class="col-12 col-xl-6">
          <div class="row mx-0 px-0 align-items-center">
            <ng-container *ngIf="!isLightningNetwork(); else lightningInvoice">
              <mat-form-field class="col px-0 mb-0 address-field" [ngClass]="{'min-w-300': selectedAddress}">
                <input
                  type="text" i18n-placeholder placeholder="Enter or select address" matInput
                  formControlName="withdrawal" (blur)="checkForTrustedAddress()" [matAutocomplete]="auto"
                  [ngClass]="{'d-none': selectedAddress}"
                >

                <div
                  *ngIf="selectedAddress" class="row max-h-40 px-0 mx-0 disabled-div"
                  [ngClass]="{'mt-less-5 min-w-300': selecteAddress}"
                >
                  <ng-container *ngTemplateOutlet="cryptoAddressSelection; context: {data: selectedAddress}">
                  </ng-container>
                </div>

                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                  (optionSelected)="checkForTrustedAddress()">
                  <mat-option class="t-15 max-h-40 min-w-300 px-0 mx-0"
                    *ngFor="let option of addressBookfilteredOptions | async" [value]="option.name">
                    <div class="row max-h-40 px-0 mx-0">
                      <ng-container *ngTemplateOutlet="cryptoAddressSelection; context: {data: option}"></ng-container>
                    </div>
                  </mat-option>
                </mat-autocomplete>

                <!-- Toggle QR scanner -->
                <button mat-button *ngIf="!selectedAddress && videoInput.found" matSuffix mat-icon-button
                  (click)="videoHasPermission()">
                  <mat-icon color="primary" i18n-matTooltip matTooltipPosition="above" matTooltip="Scan address QR code"
                    class="qr-scanner">
                    qr_code_scanner
                  </mat-icon>
                </button>
                <!-- Errors -->
                <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.withdrawal.hasError('whitespaceError')">
                  <ng-container i18n>Only one address with no spaces can be used</ng-container>
                </mat-error>
                <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.withdrawal.hasError('invalid')">
                  <ng-container i18n>Invalid wallet address, email address or mobile number</ng-container>
                </mat-error>
                <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.withdrawal.hasError('ownAddress')">
                  <ng-container i18n>You cannot withdraw to your own wallet.</ng-container>
                </mat-error>
                <mat-hint class="mb-2 orange-text" *ngIf="identifiedNewAddress">
                  <ng-container i18n>NEW ADDRESS IDENTIFIED</ng-container>
                </mat-hint>
              </mat-form-field>

              <div class="col-auto d-flex align-items-start mb-1 mx-0 px-0">
                <!-- unselect addressbook -->
                <mat-icon color="primary" *ngIf="selectedAddress"
                  (click)="unselectAddressbook()" i18n-matTooltip matTooltipPosition="above"
                  matTooltip="Clear selected Address Book">
                  close
                </mat-icon>
                <!-- Address book icon -->
                <mat-icon color="primary" class="ml-2"
                  svgIcon="address-book" i18n-matTooltip matTooltipPosition="above" matTooltip="Address Book"
                  (click)="openAddressBook()">
                </mat-icon>
                <!-- Internal transfer icon -->
                <mat-icon color="primary" class="ml-2" i18n-matTooltip
                  matTooltipPosition="above"
                  matTooltip="You can transfer funds to other ChainEX users free of charge by entering their ChainEX wallet address or registered email address or cellphone number">
                  info
                </mat-icon>
              </div>
            </ng-container>

            <ng-template #lightningInvoice>
              <mat-form-field class="col px-0 mb-0 address-field">
                <input type="text" matInput i18n-placeholder placeholder="Enter Invoice" formControlName="invoiceAddress">
                <!-- (input)="invoiceChange()" -->

                <!-- Toggle QR scanner -->
                <button mat-button *ngIf="videoInput.found" matSuffix mat-icon-button (click)="videoHasPermission()">
                  <mat-icon color="primary" i18n-matTooltip matTooltipPosition="above" matTooltip="Scan address QR code" class="qr-scanner">
                    qr_code_scanner
                  </mat-icon>
                </button>

                <!-- Errors -->
                <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.invoiceAddress.hasError('invalidInvoice')">
                  <ng-container i18n>Invalid lightning invoice</ng-container>
                </mat-error>
              </mat-form-field>

              <div class="col-auto d-flex align-items-start mb-1 mx-0 px-0">
                <mat-icon
                  color="primary" class="ml-2" i18n-matTooltip matTooltipPosition="above"
                  matTooltip="You can pay an invoice generated on ChainEX by another user free of charge"
                >
                  info
                </mat-icon>
              </div>
              <div *ngIf="invoiceMemo !== ''" class="col-12 d-flex align-items-start justify-content-center mb-1 mx-0 px-0">
                <label>
                  {{ invoiceMemo }}
                </label>
              </div>
            </ng-template>
          </div>

          <!-- Store address checkbox-->
          <div *ngIf="!isLightningNetwork()">
            <mat-checkbox formControlName="storeAddress" [disabled]="!identifiedNewAddress">
              <span i18n>
                Add to Address Book
              </span>
            </mat-checkbox>
          </div>
        </div>

        <!-- Payment ID input -->
        <div class="col-12 col-md-6 col-xl-3" *ngIf="showPaymentId()">
          <mat-form-field class="w-100 mb-0">
            <input title="" required matInput name="Payment ID" i18n-placeholder
              [placeholder]="!currencyInfo.payment_memo_label ? 'Payment ID' : currencyInfo.payment_memo_label"
              formControlName="paymentID">
            <mat-error class="mb-2" *ngIf="cryptoWithdrawal.controls.paymentID.hasError('pattern')">
              <ng-container i18n>
                invalid {{ !currencyInfo.payment_memo_label ? 'Payment ID' : currencyInfo.payment_memo_label }}.
              </ng-container>
            </mat-error>
            <mat-error *ngIf="cryptoWithdrawal.controls.paymentID.hasError('required')">
              {{currencyInfo.payment_memo_label}} is required
            </mat-error>
          </mat-form-field>
          <mat-checkbox class="w-100 flex-end" formControlName="paymentIdNotRequired" color="primary"
            *ngIf="!selectedFromBook">
            <span i18n>
              No {{currencyInfo.payment_memo_label}}
            </span>
          </mat-checkbox>
        </div>

        <!-- TFA input -->
        <div *ngIf="allowWithdrawal && untrustedAddress && currencyInfo.is2faenabled" [ngClass]="{'d-none': isLightningNetwork() && !lightningOverDailyLimit}" class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="w-100">
            <input type="tel" matInput name="Withdrawal 2FA" i18n-placeholder placeholder="2FA Token"
              formControlName="twofa" [(ngModel)]="withdrawalTwofa">
          </mat-form-field>
        </div>

        <!-- Process withdraw -->
        <div class="col-12 col-md-6 col-xl-3 ml-auto" *ngIf="allowWithdrawal">
          <button mat-raised-button color="primary" class="w-100 mt-4" (click)="processWithdrawal()" i18n>
            PROCESS WITHDRAWAL
          </button>
        </div>
      </div>
      <!-- QR scannner "camera" -->
      <div class="text-center" *ngIf="scanQR">
        <p class="orange-text" *ngIf="videoInput.hasPermission" i18n>
          Scan address QR code
        </p>

        <p class="warning" *ngIf="videoInput.hasPermission === false" i18n>
          Please allow ChainEX access to your Camera from your borwser settings to use the QR scanner
        </p>

        <zxing-scanner *ngIf="videoInput.hasPermission" [enable]="scanQR" (scanSuccess)="scanSuccessHandler($event)">
        </zxing-scanner>
      </div>
    </div>
    <!-- End Crypto Network & Address input -->
  </div>

  <!-- Custom domain prompt -->
  <div class="col-12 mt-3 mt-lg-0 py-3 autotrade-border-box" *ngIf="showDomainPrompt">
    <div class="row align-items-center mx-0">
      <mat-icon class="dialog-close" (click)="dismissDomain()">close</mat-icon>
      <div class="col-auto pr-0">
        <img class="domain-icon" src="/assets/chainex/images/pages/balances/domainicon.png">
      </div>
      <div class="col">
        <span>Get a free domain from <a href="https://unstoppabledomains.com/" target="_blank">Unstoppable Domains</a>, to link your external wallet!
        </span>
      </div>
      <div class="col-12 col-lg-auto">
        <button  mat-raised-button class="col-12 col-lg-auto mx-0 mx-lg-3 mt-3 mt-lg-0"
        color="primary" (click)="openDomainDialog()">
          GET FREE DOMAIN
        </button>
      </div>
    </div>
  </div>
</div>


<!-- Fiat Withdrawals -->
<div class="container-fluid mt-4 mb-4" *ngIf="coinType === 'Fiat' && !isWithdrawalDisabled()">
  <div class="row" [formGroup]="fiatWithdrawal" *ngIf="linkedAccount">
    <!-- Verification, Limit info -->
    <div class="col-12 mb-4">
      <div class="border-box warning verification_limits mt-1">
        <div class="row">
          <div class="col-12"><b>
              <ng-container i18n>Verification level</ng-container> {{verificationLevel}} <ng-container i18n>Restrictions
              </ng-container>
            </b></div>
          <div class="col-6">

            <p *ngIf="totalCoin === undefined; else hasTotalCoin" i18n>Loading fiat currency information..</p>
            <ng-template class="mb-0" #hasTotalCoin [ngTemplateOutlet]="availableBalance"></ng-template>

            <p class="mt-1 mb-0" i18n *ngIf="verificationLevel < 2">
              To increase your limits please verify and secure your account.
            </p>
            <p class="mt-0" i18n
              *ngIf="verificationLevel > 1 && (!linkedAccount || selectedBank?.verified === BankAddressVerified.Pending)">
              Your withdrawal limits will increase once your bank account has passed verification
            </p>
            <p *ngIf="selectedBank?.verified === BankAddressVerified.Failed" i18n>
              Bank account verification failed. To increase your withdrawal limit, please link a new bank account which
              belongs to you below
            </p>
          </div>
          <div class="col-12 col-md-6 text-right">
            <a mat-raised-button color="primary" routerLink="/profile" (click)="closeModal()" *ngIf="verificationLevel < 2" i18n>
              VERIFY YOUR ACCOUNT NOW
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="!allowWithdrawal">
      <div class="alert alert-danger">
        <p class="mb-0" [innerHTML]="getWithdrawalError()">
        </p>
      </div>
    </div>

    <!-- Amount input -->
    <div class="col-12 col-md-6 col-lg-4" *ngIf="allowWithdrawal">
      <mat-form-field class="amountInput">
        <input title="" required type="number" matInput name="Withdrawal Amount" i18n-placeholder placeholder="Amount"
          formControlName="amount" [(ngModel)]="withdrawalAmount" (keyup)="amountChange()" (change)="amountChange()">
        <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.amount.hasError('required')" i18n>
          Withdrawal amount required.
        </mat-error>
        <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.amount.hasError('min') && !fiatWithdrawal.controls.amount.hasError('max')">
          <ng-container i18n>Cannot withdraw less than</ng-container>
          {{setDecimal(minAmount)}} {{coinCode}}
        </mat-error>
        <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.amount.hasError('max')">
          <ng-container i18n>Must be equal to or less than</ng-container>
          {{setDecimal(maxAmount)}} {{coinCode}}
        </mat-error>
      </mat-form-field>
      <button mat-raised-button class="ml-2" color="primary" (click)="maxClick()" i18n>MAX</button>
    </div>

    <!-- Withdrawal fee -->
    <mat-form-field class="col-12 col-md-6 col-lg-2 WithdrawalFee" *ngIf="allowWithdrawal">
      <input title="" type="number" disabled matInput name="Withdrawal Fee" i18n-placeholder placeholder="Fee"
        formControlName="fee" [(ngModel)]="withdrawalFee">
    </mat-form-field>

    <!-- Net amount -->
    <mat-form-field class="col-12 col-md-6 col-lg-3" *ngIf="allowWithdrawal">
      <input title="" type="number" required matInput name="Withdrawal Net Amount" i18n-placeholder
        placeholder="Net Amount" formControlName="netAmount" [(ngModel)]="netAmount" (keyup)="netAmountChange()"
        (change)="netAmountChange()">
      <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.netAmount.hasError('required')" i18n>
        Withdrawal net amount required.
      </mat-error>
      <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.netAmount.hasError('max')">
        <ng-container i18n>Must be equal to or less than</ng-container> {{setDecimal(maxAmount)}} {{coinCode}}
      </mat-error>
      <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.netAmount.hasError('min')">
        <ng-container i18n>Cannot withdraw less than 0</ng-container> {{coinCode}}.
      </mat-error>
    </mat-form-field>

    <!-- Fund Pin -->
    <mat-form-field class="col-12 col-md-3" *ngIf="allowWithdrawal && currencyInfo.isFundpinEnabled === FundPinStatus.Enabled && untrustedAddress">
      <input title="" type="password" required matInput name="Fund Pin" i18n-placeholder placeholder="Fund PIN"
        formControlName="fundPin">
      <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.fundPin.hasError('required')">
        <ng-container i18n>Fund PIN is required.</ng-container>
      </mat-error>
    </mat-form-field>

    <!-- Fiat Network & Address input -->
    <div class="col-12" [ngClass]="{'min-w-335': selectedBank}" *ngIf="allowWithdrawal">
      <div class="row address-flex align-items-center" [ngClass]="{'my-0': selectedBank}">

        <!-- coin network selection -->
        <ng-container *ngTemplateOutlet="coinNetworkSelection; context: {form: fiatWithdrawal}"></ng-container>

        <!-- address input -->
        <div class="col-12 col-xl-6">
          <div class="row mr-0 align-items-center justify-content-between">
            <mat-form-field class="col address-field pr-0" [ngClass]="{'verification': verificationLevel > 1, 'my-0 min-w-270': selectedBank}">
              <input
                type="text" i18n-placeholder placeholder="Select bank account" matInput formControlName="bankSelection"
                [matAutocomplete]="auto" (input)="bankAddressBookfilter()" (blur)="bankAddressBookfilter(true)" [ngClass]="{'d-none': selectedBank}"
              >
              <div *ngIf="selectedBank" class="row px-0 mx-0 max-h-50 disabled-div" [ngClass]="{'mt-less-5 min-w-270': selectedBank}">
                <ng-container *ngTemplateOutlet="bankaccountSelection; context: {data: selectedBank}"></ng-container>
              </div>

              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="bankAddressBookfilter(true)">
                <mat-option class="t-15 max-h-50 pl-2" *ngFor="let option of bankAddressBookfilteredOptions" [value]="option.name">
                  <div class="row px-0 mx-0 max-h-50">
                    <ng-container *ngTemplateOutlet="bankaccountSelection; context: {data: option}"></ng-container>
                  </div>
                </mat-option>
              </mat-autocomplete>

              <!-- Errors -->
              <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.bankSelection.hasError('required')">
                <ng-container i18n>Bank Name / Account number required</ng-container>
              </mat-error>
              <mat-error class="mb-2" *ngIf="fiatWithdrawal.controls.bankSelection.hasError('invalidBank')">
                <ng-container i18n>Invalid Bank Entered</ng-container>
              </mat-error>
            </mat-form-field>
            <div class="col-auto d-flex align-items-start mb-1 mx-0 px-0">
              <!-- unselect bank account -->
              <mat-icon color="primary" class="ml-2 mr-2" [ngClass]="{'d-none': !selectedBank}" (click)="unselectAddressbook(true)"
                i18n-matTooltip matTooltipPosition="below" matTooltip="Clear selected Bank Account">
                close
              </mat-icon>
              <!-- Address book icon -->
              <mat-icon color="primary" [ngClass]="selectedBank ? '' : 'ml-2 mr-2'" svgIcon="address-book"
                i18n-matTooltip matTooltipPosition="below" matTooltip="Address Book"
                (click)="openBankAddressBook(false)">
              </mat-icon>
            </div>
          </div>
          <!-- Bank status icon | Verification status will show in the selected bank account -->
          <!-- <mat-icon class="noClick pos-icon" i18n-matTooltip
            matTooltip="{{selectedBank?.verified === BankAddressVerified.Pending ? translatedWords['Verification Pending']
              : selectedBank?.verified === BankAddressVerified.Success ? translatedWords['Verified'] : translatedWords['Verification Failed']}}"
            *ngIf="verificationLevel > 1 && !!selectedBank">{{selectedBank?.verified === BankAddressVerified.Pending ? 'alarm' :
            selectedBank?.verified === BankAddressVerified.Success ? 'done' : 'close'}}
          </mat-icon> -->

          <!-- Fast Clearing -->
          <div id="fast-clearing" *ngIf="!!selectedBank">
            <ng-container *ngIf="selectedBank?.bank === 'Standard Bank'; else otherBank">
              <span class="small-text" i18n>
                Fast clearing applies at no extra charge
              </span>
            </ng-container>
            <ng-template #otherBank>
              <!-- check if is fast clearing allowed -->
              <ng-container *ngIf="selectedBank?.fastClearingAvailable">
                <mat-checkbox class="w-100 flex-start" color="primary" formControlName="fastClearing" (change)="toggleFastClearing()">
                  <span i18n>
                    Fast Clearing
                  </span>
                  <mat-icon *ngIf="currencyInfo?.withdraw?.fastClearing?.fee > 0" color="primary" class="pos-icon pl-2"
                    i18n-matTooltip matTooltipPosition="right"
                    matTooltip="Your withdrawal will be cleared within 24 hours of confirmation to any bank at an increased fee of
                    R{{this.currencyInfo.withdraw.fastClearing.fee}} per withdrawal.
                    Please note that this facility is only available from {{convertUtcHourToTime(fastClearingTimes[0])}} to {{convertUtcHourToTime(fastClearingTimes[1])}} on weekdays.">
                    info
                  </mat-icon>
                </mat-checkbox>
                <mat-error class="mb-2 small-text" *ngIf="fastClearingError">
                  <ng-container i18n>Withdrawals will apply with fast clearing when the next withdrawal is processed</ng-container>
                </mat-error>
              </ng-container>
            </ng-template>
          </div>
        </div>
        <!-- TFA input -->
        <div *ngIf="allowWithdrawal && currencyInfo.is2faenabled && untrustedAddress"
          class="col-12 col-md-6 col-xl-3 align-center">
          <mat-form-field class="w-100">
            <input type="tel" matInput name="Withdrawal 2FA" i18n-placeholder placeholder="2FA Token"
              formControlName="twofa" [(ngModel)]="withdrawalTwofa">
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 col-xl-3" *ngIf="allowWithdrawal">
          <div class="h-100">
          <button mat-raised-button color="primary" class="w-100"
            (click)="processWithdrawal()" i18n>
            PROCESS WITHDRAWAL
          </button>
          </div>
        </div>
      </div>
      <!-- End Fiat Network & Address Input -->
    </div>
  </div>

  <!-- Link bank account form -->
  <div class="row" [formGroup]="bankLink" *ngIf="!linkedAccount && allowWithdrawal">
    <div class="col-12 mt-4 mb-4">
      <div class="border-box warning verification_limits mt-1">
        <div class="row">
          <div class="col-12"><b>
              <ng-container i18n>Verification level</ng-container> {{verificationLevel}} <ng-container i18n>Restrictions
              </ng-container>
            </b></div>
          <div class="col-6">
            <p *ngIf="totalCoin === undefined; else hasTotalCoinCrypto" i18n>Loading fiat currency information..</p>
            <ng-template class="mb-0" #hasTotalCoinCrypto [ngTemplateOutlet]="availableBalance"></ng-template>
          </div>
          <div class="col-12 col-md-6 text-right">
            <a mat-raised-button color="primary" routerLink="/profile" (click)="closeModal()"
              *ngIf="verificationLevel < 2" i18n>VERIFY YOUR ACCOUNT NOW</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <ng-container i18n>
        Add a bank account to your address book to perform a withdrawal
      </ng-container>
    </div>
    <div class="col-6 col-md-3">
      <button mat-raised-button color="primary" class="min-190 mt-4" (click)="openBankAddressBook(true)" i18n>
        ADD BANK ACCOUNT
      </button>
    </div>
  </div>
</div>

<ng-template #bankaccountSelection let-data="data">
  <!-- bank account with flag/s on mobile view -->
  <div class="col-12 px-1 h-100 my-auto sm-selected-bank address-option-height">
    <div class="d-flex flex-row flex-wrap w-100 my-1 justify-content-between">
      <span class="d-block show-ellipsis address-info">
        {{ data.name }}
      </span>
      <span class="orange show-ellipsis d-block address-info">
        {{ data.accountNumber }}
      </span>
    </div>
    <div class="d-flex flex-row flex-wrap w-100 mb-1 line-height-20">
      <div *ngIf="data.status !== BankAddressStatus.Untrusted" class="d-block mr-2">
        <span class="status address-info px-2 show-ellipsis" [ngClass]="data?.status === BankAddressStatus.Trusted ? 'text-trusted' : 'text-pending'">
          {{ data.status === BankAddressStatus.Trusted ? 'TRUSTED' : 'TRUSTED PENDING' }}
        </span>
      </div>
      <div class="d-block my-0 py-0">
        <span class="status address-info px-2 show-ellipsis"
          [ngClass]="data?.verified === BankAddressVerified.Success ? 'text-trusted' : (data?.verified === BankAddressVerified.Pending ? 'text-pending' : 'text-not-trusted')"
        >
          {{ data.verified === BankAddressVerified.Success ? 'VERIFIED' : (data.verified === BankAddressVerified.Pending ? 'VERIFICATION PENDING' : 'NOT VERIFIED') }}
        </span>
      </div>
    </div>
  </div>

  <!-- bank account with flag/s -->
  <div class="d-none col-12 p-0 m-0 bg-selected-bank">
    <div class="col-6 pr-0 pl-1 h-100 my-auto">
      <span class="d-block my-1 show-ellipsis address-info">
        {{ data.name }}
      </span>
      <span class="orange mb-1 show-ellipsis d-block address-info">
        {{ data.accountNumber }}
      </span>
    </div>
    <div class="col-6 pl-0 pr-1 h-100 my-auto">
      <div class="col-12 px-0 d-block">
        <div *ngIf="data.status !== BankAddressStatus.Untrusted" class="d-flex justify-content-end col-12 p-0">
          <span class="status address-info px-2 mt-1 show-ellipsis" [ngClass]="data?.status === BankAddressStatus.Trusted ? 'text-trusted' : 'text-pending'">
            {{ data.status === BankAddressStatus.Trusted ? 'TRUSTED' : 'TRUSTED PENDING' }}
          </span>
        </div>
        <div class="px-0 d-flex my-1 justify-content-end">
          <span class="status address-info px-2 show-ellipsis"
            [ngClass]="data?.verified === BankAddressVerified.Success ? 'text-trusted' : (data?.verified === BankAddressVerified.Pending ? 'text-pending' : 'text-not-trusted')"
          >
            {{ data.verified === BankAddressVerified.Success ? 'VERIFIED' : (data.verified === BankAddressVerified.Pending ? 'VERIFICATION PENDING' : 'NOT VERIFIED') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cryptoAddressSelection let-data="data">
  <!-- crypto address with flag/s for mobile view -->
  <div class="col-12 px-1 h-100 my-auto sm-selected-address address-option-height">
    <div class="d-flex flex-column w-100 mt-1 justify-content-between">
      <span class="d-block show-ellipsis address-info">
        {{ data.name }}
      </span>
      <span class="orange show-ellipsis d-block address-info">
        {{ data.address }}
      </span>
    </div>
    <div *ngIf="data.status !== AddressStatus.Untrusted" class="d-flex flex-column w-100 mb-1 line-height-20">
      <div class="d-block my-0 py-0">
        <span class="status d-block px-2 show-ellipsis"
          [ngClass]="data?.status === AddressStatus.Trusted ? 'text-trusted' : 'text-pending'"
        >
          {{ data.status === AddressStatus.Trusted ? 'TRUSTED' : 'TRUSTED PENDING' }}
        </span>
      </div>
    </div>
  </div>

  <!-- crypto address with flag/s -->
  <div class="d-none col-12 p-0 m-0 bg-selected-address">
    <div class="py-0 pl-1 h-100"
      [ngClass]="{
        'col-12': data.status === AddressStatus.Untrusted,
        'col-7 col-md-8': data.status === AddressStatus.Pending,
        'col-9': data.status === AddressStatus.Trusted
      }"
    >
      <span class="d-block show-ellipsis address-info">
        {{ data.name }}
      </span>
      <span class="orange show-ellipsis d-block address-info">
        {{ data.address }}
      </span>
    </div>
    <div *ngIf="data.status !== AddressStatus.Untrusted" class="col d-block pr-1 pl-0 h-100 my-auto">
      <div class="col-12 px-0 d-flex justify-content-end">
        <span class="status d-block px-2 show-ellipsis"
          [ngClass]="data?.status === AddressStatus.Trusted ? 'text-trusted' : 'text-pending'"
        >
          {{ data.status === AddressStatus.Trusted ? 'TRUSTED' : 'TRUSTED PENDING' }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #availableBalance>
  <!-- Daily limits -->
  <div *ngIf="coinCode === 'ZAR'; else notZarDailyBalance">
    <p *ngIf="!!selectedBank">
      <ng-container
        *ngIf="verificationLevel === 1 || (!linkedAccount || selectedBank?.verified !== BankAddressVerified.Success)"
        i18n>
        Remaining limit:
      </ng-container>
      <ng-container
        *ngIf="verificationLevel !== 1 && (linkedAccount && selectedBank?.verified === BankAddressVerified.Success)"
        i18n>
        Remaining daily limit:
      </ng-container>
      <ng-container *ngIf="selectedBank?.verified === BankAddressVerified.Success; else unVerifiedLimit">
        {{zarLimits?.verifiedRemainingLimit | decimalNumber:8:coinCode}}
        <span i18n>of</span>
        {{zarLimits?.verifiedLimit | decimalNumber:8:coinCode}}
      </ng-container>
      <ng-template #unVerifiedLimit>
        {{zarLimits?.unverifiedRemainingLimit | decimalNumber:8:coinCode}}
        <span i18n>of</span>
        {{zarLimits?.unverifiedLimit | decimalNumber:8:coinCode}}
      </ng-template>
    </p>
    <p>
      <a target="_blank" href="https://support.chainex.io/kb/general/when-will-my-zar-withdrawals-be-processed">
        What are our processing times?
      </a>
    </p>
  </div>

  <ng-template #notZarDailyBalance>
    <div *ngIf="coinCode === 'BTC'; else coinDailyBalance">
      <p>
        <ng-container i18n>Remaining limit:</ng-container> {{limitFiat}} {{coinCode}} <ng-container i18n>of</ng-container>
        {{totalFiat}} {{coinCode}}
      </p>

      <p *ngIf="isLightningNetwork() && confirmLightning">
        To disable 2FA and/or Fund Pin and email confirmation on Lightning withdrawals, <a routerLink="/settings">click here</a> to change lightning withdrawal settings.
      </p>
    </div>
    <ng-template #coinDailyBalance>
      <p>
        <ng-container i18n>Remaining limit:</ng-container> {{limitFiat | decimalNumber:8:coinCode}} {{coinCode}} ({{limitCoin |
        decimalNumber}} BTC) <ng-container i18n>of</ng-container> {{totalFiat | decimalNumber:8:coinCode}} {{coinCode}} ({{totalCoin |
        decimalNumber}} BTC)
      </p>
    </ng-template>
  </ng-template>
  <!--Monthly limits-->
  <ng-template *ngIf="limitFiatMonth != '0'">
    <p *ngIf="coinCode === 'BTC' || coinCode === 'ZAR'; else notBTCZarMonthlyBalance">
      <ng-container i18n>Remaining Monthly Limit:</ng-container> {{limitFiatMonth}} {{coinCode}} <ng-container i18n>of
      </ng-container>
      {{totalFiatMonth}} {{coinCode}}
    </p>

    <ng-template #notBTCZarMonthlyBalance>
      <p>
        <ng-container i18n>Remaining Monthly Limit:</ng-container> {{limitFiatMonth}} {{coinCode}} ({{limitCoinMonth |
        decimalNumber}} BTC) <ng-container i18n>of</ng-container> {{totalFiatMonth}} {{coinCode}} ({{totalCoinMonth |
        decimalNumber}} BTC)
      </p>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template #coinNetworkSelection let-formInput="form">
  <div class="col-12 col-xl-3" *ngIf="currencyInfo.has_coin_networks === '1'" [formGroup]="formInput">
    <div class="row mx-0 px-0 align-items-center">
      <mat-form-field class="col px-0 mx-0">
        <mat-select [disabled]="coinNetworkOptions.length <= 1" i18n-placeholder placeholder="Network"
          formControlName="coin_network" value="selectedCoinNetwork" (selectionChange)="changeNetwork($event)">
          <ng-container *ngIf="coinNetworkOptions.length > 0; else defaultNetwork">
            <mat-option
              *ngFor="let coin_network of coinNetworkOptions"
              [value]="coin_network"
              [disabled]="coin_network.active === '0'">
              {{ coin_network.network_name }}
              <ng-container *ngIf="coin_network.active === '0'">
                <span class="suspended-text ml-3 px-2">
                  SUSPENDED
                </span>
              </ng-container>
            </mat-option>
          </ng-container>
          <ng-template #defaultNetwork>
            <!-- show default network from parent component -->
            <mat-option [value]="selectedCoinNetwork">
              {{ selectedCoinNetwork.network_name }}
            </mat-option>
          </ng-template>
        </mat-select>
      </mat-form-field>

      <a class="no-decoration" href="{{getSupportUrl()}}/support/solutions/articles/what-are-coin-networks" target="_blank">
        <mat-icon color="primary" class="col-auto d-flex align-items-center ml-2 mr-0 mr-xl-2 px-0" i18n-matTooltip
          matTooltipPosition="above" matTooltip="This Coin is supported across multiple Chains. Please ensure that you select the same Chain
              here as the Chain of the Wallet to which you are withdrawing. Withdrawing to the incorrect Chain will
              result in your funds being lost. Click here for more information.">
          info
        </mat-icon>
      </a>
    </div>
  </div>
</ng-template>
