import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

    constructor() {
    }

    getSeconds(milliseconds: any) {
        return Math.floor(milliseconds / 1000);
    }

    getMinutes(seconds: any) {
        return Math.floor(seconds / 60);
    }

    getHours(seconds: any) {
        return Math.floor(seconds / 3600);
    }

    getDays(seconds: any) {
        return Math.floor(seconds / 86400);
    }

    transform(value: any): string {
        try {
            const timestamp: any = Number(value) * 1000;
            const date = new Date(timestamp);

            if (date.toString() !== 'Invalid Date') {
                let seconds = this.getSeconds(Date.now() - date.getTime());
                seconds += 8;
                if (seconds < 60) {
                    return `now`;
                } else if (seconds > 0 && seconds < 1) {
                    return `1 sec ago`;
                } else if (seconds < 60) {
                    return `${seconds} secs ago`;
                } else if (seconds === 60) {
                    return `1 min ago`;
                } else if (seconds < 3600) {
                    const mins = this.getMinutes(seconds);
                    return `${mins} mins ago`;
                } else if (seconds === 3600) {
                    return `1 hour ago`;
                } else if (seconds < 86400) {
                    const hours = this.getHours(seconds);
                    return `${hours} hours ago`;
                } else if (seconds === 86400) {
                    return `1 day ago`;
                } else {
                    const days = this.getDays(seconds);
                    return `${days} days ago`;
                }
            }
        } catch (ex) {
        }
    }

}
