<!-- Standard address book display -->
<ng-container *ngIf="viewDisplay === AddressView.Normal">
  <!-- Close dialog icon -->
  <mat-icon class="dialog-close" (click)="useAddress()">
    close
  </mat-icon>
  <!-- Main view container -->
  <div class="container-fluid dialog-over-flow mb-4">
    <!-- Header -->
    <div class="row mb-4 col-12">
      <span i18n>
        {{setting.code}} Address Book
      </span>
    </div>
    <!-- Tabs -->
    <div class="w-100">
      <div class="row space-between mb-1">
        <!-- Filter -->
        <mat-form-field class="filter ml-3">
          <input matInput (keyup)="applySavedFilter($event)" i18n-placeholder placeholder="Search addresses" #input>
        </mat-form-field>
        <!-- Add new addy -->
        <button mat-raised-button class="w-30 mr-3" color="primary" (click)="addAddressForm()" i18n>ADD NEW</button>
      </div>

      <table mat-table [dataSource]="savedAddresses" matSort>
        <!-- Bank Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <!-- Bank Column -->
        <ng-container matColumnDef="bank">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Bank </th>
          <td mat-cell *matCellDef="let element"> {{element.bank}} </td>
        </ng-container>
        <!-- Account Number Column -->
        <ng-container matColumnDef="accountNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Account Number </th>
          <td mat-cell *matCellDef="let element"> {{element.accountNumber}} </td>
        </ng-container>
        <!-- Verified Column -->
        <ng-container matColumnDef="verified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Verified </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon color="primary" class="ml-2 mt-2" matTooltipPosition="above" i18n-matTooltip
              matTooltip="{{element.verifiedLabel}}">
              {{element.verified === BankAddressVerified.Pending ? 'hourglass_empty'
              : (element.verified === BankAddressVerified.Success ? 'check_circle' : 'cancel' )}}
            </mat-icon>
          </td>
        </ng-container>
        <!-- Trusted Column -->
        <ng-container matColumnDef="trusted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header i18n> Trusted </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon color="primary" class="ml-2 mt-2" matTooltipPosition="above" i18n-matTooltip
              matTooltip="{{element.statusLabel}}">
              {{element.status === BankAddressStatus.Untrusted ? 'cancel'
              : (element.status === BankAddressStatus.Trusted ? 'check_circle' : 'hourglass_empty' )}}
            </mat-icon>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd >
          <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div class="button-container">
              <!-- Use icon -->
              <mat-icon color="primary" class="ml-2" matTooltipPosition="above" i18n-matTooltip matTooltip="Use address"
                (click)="useAddress(element.name)">
                send
              </mat-icon>
              <!-- Delete icon -->
              <mat-icon color="primary" class="ml-2" matTooltipPosition="above" i18n-matTooltip matTooltip="Remove address"
                (click)="requestRemoveAddress(element)">
                delete
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="addressDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: addressDisplayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <ng-container *ngIf="input.value; else noAddresses">
            <td class="mat-cell" colspan="4" i18n>No address matching the filter "{{input.value}}"</td>
          </ng-container>
          <ng-template #noAddresses>
            <td class="mat-cell" colspan="4" i18n>No address book entries found</td>
          </ng-template>
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</ng-container>

<!-- Add display -->
<ng-container *ngIf="viewDisplay === AddressView.Add">
  <div class="container-fluid dialog-over-flow">
    <!-- Header -->
    <div class="row mr-2 ml-2 flex-center bold t-20">
      <ng-container *ngIf="_dialogRef" i18n>
        ADD BANK ACCOUNT
      </ng-container>
    </div>
    <!-- Form -->
    <div class="row mt-4 mr-3 ml-3 column">
      <!-- Inputs -->
      <form class="modify-form" [formGroup]="addressForm" (ngSubmit)="addAddress()">
        <!-- Name -->
        <mat-form-field class="w-100">
          <input type="text" matInput formControlName="name" placeholder="Name *">
          <!-- Errors -->
          <mat-error *ngIf="form.name.hasError('required')">
            <ng-container i18n>Name is required</ng-container>
          </mat-error>
          <mat-error class="mb-2" *ngIf="form.name.hasError('nameExists')">
            <ng-container i18n>This name has already been used</ng-container>
          </mat-error>
        </mat-form-field>
        <!-- Bank -->
        <mat-form-field class="w-100">
          <mat-select required i18n-placeholder placeholder="Bank" formControlName="bank">
            <mat-option *ngFor="let bank of bankNames" [value]="bank">
              {{bank.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.bank.hasError('required')">
            <ng-container i18n>Bank is required</ng-container>
          </mat-error>
        </mat-form-field>
        <!-- Account Number -->
        <mat-form-field class="w-100">
          <input type="text" matInput formControlName="accountNumber" placeholder="Account Number *">
          <!-- Errors -->
          <mat-error *ngIf="form.accountNumber.hasError('required')">
            <ng-container i18n>Account number is required</ng-container>
          </mat-error>
          <mat-error class="mb-2" *ngIf="form.accountNumber.hasError('accountNumberExists')">
            <ng-container i18n>This account number has already been used</ng-container>
          </mat-error>
          <mat-error class="mb-2" *ngIf="form.accountNumber.hasError('invalid')">
            <ng-container i18n>Invalid Account Number</ng-container>
          </mat-error>
        </mat-form-field>
        <!-- Account Type -->
        <mat-form-field class="w-100">
          <mat-select required i18n-placeholder placeholder="Account Type " formControlName="accountType">
            <mat-option *ngFor="let type of accountTypes" [value]="type">
              {{type.name}}
            </mat-option>
          </mat-select>
          <mat-error class="mb-2" *ngIf="form.accountType.hasError('required')">
            <ng-container i18n>Account Type is required</ng-container>
          </mat-error>
          <mat-error class="mb-2" *ngIf="form.accountType.hasError('invalid')">
            <ng-container i18n>Invalid Account Type</ng-container>
          </mat-error>
        </mat-form-field>
        <!-- Branch Code -->
        <mat-form-field class="w-100">
          <input type="text" matInput formControlName="branch" placeholder="Branch Code *">
          <mat-error *ngIf="form.branch.hasError('required')">
            <ng-container i18n>Branch code is required</ng-container>
          </mat-error>
          <mat-error *ngIf="form.branch.hasError('invalid')">
            <ng-container i18n>Invalid Branch Code</ng-container>
          </mat-error>
        </mat-form-field>
        <!-- Trusted -->
        <div class="w-100 mt-4 flex-container" *ngIf="_dialogRef">
          <!-- Only show if opened as dialog i.e. not on the verification page -->
          <mat-checkbox color="primary" formControlName="trusted" i18n>
            Save as trusted account
          </mat-checkbox>
          <!-- Trusted info icon -->
          <mat-icon color="primary" class="ml-2" i18n-matTooltip matTooltipPosition="above"
            matTooltip="2FA, Fund PIN or email confirmation will not be required when withdrawing from Trusted Accounts">
            info
          </mat-icon>
        </div>
        <ng-container *ngIf="form.trusted.value">
          <!-- Password -->
          <mat-form-field class="w-100">
            <input type="password" matInput formControlName="password" placeholder="Password *">
            <mat-error *ngIf="form.password.hasError('required')">
              <ng-container i18n>Password is required</ng-container>
            </mat-error>
          </mat-form-field>
          <!-- Fund PIN -->
          <mat-form-field class="w-100" *ngIf="setting.isFundpinEnabled === FundPinStatus.Enabled">
            <input type="password" matInput formControlName="fundPin" placeholder="Fund PIN *">
            <mat-error *ngIf="form.fundPin.hasError('required')">
              <ng-container i18n>Fund PIN is required</ng-container>
            </mat-error>
          </mat-form-field>
          <!-- TFA Code -->
          <mat-form-field class="w-100" *ngIf="setting.is2faenabled">
            <input type="text" matInput formControlName="tfa" placeholder="2FA Code *">
            <mat-error *ngIf="form.tfa.hasError('required')">
              <ng-container i18n>2FA code is required</ng-container>
            </mat-error>
          </mat-form-field>
        </ng-container>
        <!-- Buttons -->
        <div *ngIf="_dialogRef; else verificationAddButtons" class="row space-evenly mt-4">
          <button mat-raised-button class="w-30 mr-3" color="accent" (click)="determineCloseView()" i18n>CANCEL</button>
          <button mat-raised-button class="w-30 mr-3" color="primary" type="submit" i18n>SAVE</button>
        </div>
        <ng-template #verificationAddButtons>
          <div class="row justify-content-between mx-0 mt-4">
            <button mat-raised-button class="col-12 col-lg-4" color="accent" (click)="verificationCancel_click()" i18n>CANCEL</button>
            <button mat-raised-button class="col-12 col-lg-auto mt-2 mt-lg-0" color="primary" type="submit" i18n>COMPLETE VERIFICATION</button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</ng-container>

<!-- Confirmation display -->
<ng-container *ngIf="viewDisplay === AddressView.Confirmation || viewDisplay === AddressView.ConfirmationTrusted">
  <div class="container-fluid dialog-over-flow col-12 flex-center">
    <!-- Header -->
    <div class="row bold t-20 column">
      <img class="mb-4 mt-2" src="assets\{{exchangeName}}\images\pages\common\cx_confirmation.svg" width="90">
      <span i18n>{{viewDisplay === AddressView.ConfirmationTrusted ? 'TRUSTED' : ''}} ACCOUNT ADDED</span>
    </div>
    <!-- Body -->
    <div class="row mt-5 mr-4 ml-4 column flex-center">
      <!-- Level 1 flavor text -->
      <ng-container *ngIf="setting.verificationLevel === 1; else level2">
        <span i18n>
          Thank you for registering your bank account, you are now entitled to withdraw a total of R15 000.
          To increase your withdrawal limit and secure your account, please complete the verification level 2 process.
          Once you have passed level 2 verification, the bank account details entered will automatically
          be sent for verification and once verified, you will receive confirmation of such via email.
        </span>
      </ng-container>
      <!-- Level 2 flavor text -->
      <ng-template #level2>
        <span i18n>
          Thank you for registering your bank account, the details received will now be verified in order
          to ensure that your withdrawals are secured. We will notify you via email of the verification process results within 48 hours.
          Please note that you are only able to process withdrawals of up to R15 000 without a verified bank account
          regardless of your ChainEX account verification level.
        </span>
      </ng-template>
    </div>
    <!-- Trusted flavor text -->
    <div class="row mt-4 mr-4 ml-4 column flex-center" *ngIf="viewDisplay === AddressView.ConfirmationTrusted">
      <span i18n>
        Since trusted accounts do not require Password, 2FA, Fund PIN or email confirmation
        when performing withdrawals, the account needs to be confirmed per email as an added
        security measure.
      </span>
      <span class="mt-4" i18n>
        An email has been sent to you from which you can confirm the trusted account.
        Please check your email inbox and confirm it.
        Please note that trusted accounts will remain untrusted for 24 hours after confirmation as an added security measure.
      </span>
      <span class="mt-4" i18n>
        If you have any queries, please contact support
        <a target="_blank" href="{{getSupportUrl()}}">
          here.
        </a>
      </span>
    </div>
    <!-- Buttons -->
    <div class="row space-evenly mt-5 mb-4">
      <button mat-raised-button class="w-30 mr-3" color="primary" (click)="determineCloseView()" i18n>CONTINUE</button>
    </div>
  </div>
</ng-container>

<!-- Confirm remove display -->
<ng-container *ngIf="viewDisplay === AddressView.ConfirmRemove">
  <div class="container-fluid dialog-over-flow col-12 flex-center">
    <!-- Header -->
    <div class="row bold flex-center t-20">
      <span i18n>Remove Bank Account</span>
    </div>
    <!-- Body -->
    <div class="row mt-4 column">
      <!-- Flavor text -->
      <div class="row mr-4 ml-4 column">
        <span i18n>
          Are you sure you would like to delete the following Bank Account Book entry?
        </span>
      </div>

      <!-- Bank details -->
      <div class="row mt-4 mr-5 ml-5 column">
        <!-- Name -->
        <div class="row w-80 space-between mobile">
          <span class="w-40 bold" i18n>Name:</span>
          <span class="w-40" i18n>{{form.name.value}}</span>
        </div>
        <!-- Bank -->
        <div class="row mt-2 w-80 space-between mobile">
          <span class="w-40 bold" i18n>Bank:</span>
          <span class="w-40" i18n>{{form.bank.value}}</span>
        </div>
        <!-- Account number -->
        <div class="row mt-2 w-80 space-between mobile">
          <span class="w-40 bold" i18n>Account Number:</span>
          <span class="w-40" i18n>{{form.accountNumber.value}}</span>
        </div>
        <!-- Trusted -->
        <div class="row mt-2 w-80 space-between mobile" *ngIf="form.trusted.value">
          <span class="w-40 bold" i18n>Trusted:</span>
          <span class="w-40" i18n>{{form.trusted.value}}</span>
        </div>
      </div>

      <!-- Buttons -->
      <div class="row space-evenly mt-3 mb-4">
        <button mat-raised-button class="w-30 mr-3" color="accent" (click)="resetView()" i18n>CANCEL</button>
        <button mat-raised-button class="w-30 mr-3" color="primary" (click)="removeAddress()" i18n>CONFIRM</button>
      </div>
    </div>
  </div>
</ng-container>
