import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, SubscriptionLike } from 'rxjs';
import { debounceTime, distinctUntilChanged, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Store } from '@ngxs/store';

import { StoreService } from 'src/app/core/services/store.service';
import { environment } from 'src/environments/environment';
import { BalancesService } from 'src/app/balances/balances.service';
import { Product } from 'src/app/core/interfaces/balances';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

import { ProductConfirmationComponent } from './product-confirmation/product-confirmation.component';
import { EarnProducts, EarnProductsState, GetEarnProducts } from './state/earn-portal.state';


@Component({
  selector: 'app-earn-portal',
  templateUrl: './earn-portal.component.html',
  styleUrls: ['./earn-portal.component.scss']
})
export class EarnPortalComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('coin') coinCode: string = '';
  // tslint:disable-next-line:no-input-rename
  @Input('type') coinType: string = 'crypto';
  // tslint:disable-next-line:no-input-rename
  @Input('earnPage') earnPage: boolean = false;

  @Input() dashboard: boolean = false;

  private _timeout$: Subject<void> = new Subject();
  imageUrl: string = '/assets/chainex/images/icons/coins/';
  supportLink: string = environment.config.SUPPORT_URL;

  products: Product[] = [];
  userProducts: Product[] = [];
  products$: Observable<EarnProducts>;

  user_products: MatTableDataSource<Product> = new MatTableDataSource([]);
  coin_products: MatTableDataSource<Product> = new MatTableDataSource([]);
  product: MatTableDataSource<Product> = new MatTableDataSource([]);

  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('user_products_table', {read: MatSort}) sort1: MatSort;
  @ViewChild('coin_products_table', {read: MatSort}) sort2: MatSort;

  userProducts_definition: any = [
    'coin_code',
    'name',
    'estimated_apy',
    'period',
    'pending_product_balance',
    'balance',
    'compound_earning',
    'action'
  ];

  products_definition: any = [
    'coin_code',
    'name',
    'estimated_apy',
    'period',
    'min_amount',
    'compound_earning',
    'action'
  ];

  product_definition: any = [
    'coin_code',
    'name',
    'estimated_apy',
    'period',
    'estimated_daily_earning',
    'estimated_annual_earning',
    'min_amount',
    'pending_product_balance',
    'balance',
    'compound_earning'
  ];

  coinBalance: any = {
    balance_available: '0',
    balance_total: '0',
    balance_held: '0',
    balance_earn: '0'
  };

  subscriptionForm: FormGroup;
  selectedProduct: Product;

  showProducts: boolean = true;
  termsAccepted: boolean = false;
  formIsDirty: boolean = true;
  warning: boolean = false;
  riskAccepted: boolean = false;
  isLoading: boolean = true;
  isSubscribing: boolean = true;
  userBalance: string = '0';
  estimated_anual: number = 0.00;
  estimated_daily: number = 0.00;
  max_amount: number = 0.00;
  min_amount: number = 0.00;
  verificationLevel: number;
  loggedIn$: Observable<boolean>;

  sub: SubscriptionLike;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private storeService: StoreService,
    private balancesService: BalancesService,
    public snackbar: MatSnackBar,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    private sessionStorage: SessionStorageService,
    private store: Store
  ) { }

  ngOnInit() {

    // TODO: Figure out coin and type filtering later on
    this.products$ = this.store.select(
      this.coinCode ?
      EarnProductsState.getCoinEarnProduct(this.coinCode) :
      EarnProductsState.getEarnProducts());

    this.products$.pipe(takeUntil(this._timeout$), distinctUntilChanged())
      .subscribe(state => {
        if (state && state.coinProducts) {
          this.products = state.coinProducts;
          if (state.userProducts) {
            this.userProducts = state.userProducts;
          }

          this.coin_products.data = this.products;
          this.user_products.data = this.userProducts;
          this.user_products.sort = this.sort1;
          this.coin_products.sort = this.sort2;
          this.user_products.paginator = this.paginator2;
          this.coin_products.paginator = this.paginator1;
        }

        this.isLoading = false;
      });
    this.subscriptionForm = this.formBuilder.group({
      amount: ['', Validators.required],
      acceptTerms: ['', Validators.requiredTrue],
      compound: [false]
    });

    this.sub = this.storeService.subscribe('update-balance').subscribe((response: any) => {
      if (response.refresh) {
        this.getBalance();

        this.getCoinProducts();
      }
    });

    this.loggedIn$ = this.sessionStorage.observe('LOGGED_IN');

    this.subscriptionForm.controls.amount.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      skipWhile(amount => amount === ''),
      tap((amount) => {
        this.formIsDirty = (!this.subscriptionForm.controls.amount.valid ||
          this.subscriptionForm.controls.amount.value <= 0);
        this.estimated_anual = (+this.selectedProduct.estimated_apy * amount) / 100;
        this.estimated_daily = (this.estimated_anual / 365);
      }),
      takeUntil(this._timeout$)
    ).subscribe();

    let level: any = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL');
      if (level === '') {
        level = 0;
      } else {
        level = Number(level);
      }

      this.verificationLevel = level;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this._timeout$.next();
    this._timeout$.complete();
  }

  getBalance() {
    if (this.coinCode) {
      this.storeService.getBalance(this.coinCode).pipe(
        distinctUntilChanged(),
        tap((data: any) => {
          if (data.response === 'success') {
            this.userBalance = data.data.balance_available;
            this.coinBalance = data.data;
            this.updateAmountValidation();
          }
        }),
        takeUntil(this._timeout$)
      ).subscribe();
    }
  }

  canAccessProduct() {
    return this.coinCode === 'USDT' && this.verificationLevel === 0;
  }

  getCoinProducts() {
    this.store.dispatch(new GetEarnProducts()).toPromise();
  }

  toggleProduct(product: any = {}, subscribe: boolean = true) {
    this.showProducts = !this.showProducts;
    if (!this.showProducts) {
      if (this.earnPage) {
        this.coinCode = product.coin_code;
        this.getBalance();
      }
      this.product.data = [product];
      this.selectedProduct = product;
      this.isSubscribing = subscribe;
      if (!subscribe) {
        this.max_amount = +this.selectedProduct.balance;
        this.min_amount = 0;
        this.subscriptionForm.controls.amount.setValue(this.selectedProduct.balance);
        this.subscriptionForm.controls.amount.setValidators([
          Validators.min(this.min_amount),
          Validators.max(this.max_amount)
        ]);
        this.subscriptionForm.controls.acceptTerms.disable();
      } else {
        this.updateAmountValidation();
        if (this.subscriptionForm.controls.acceptTerms.disabled) {
          this.subscriptionForm.controls.amount.setValue('');
          this.subscriptionForm.controls.amount.markAsUntouched();
          this.subscriptionForm.controls.acceptTerms.enable();
        }

        const user_product = this.userProducts.find(prod => prod.id === this.selectedProduct.id);
        if (!!user_product) {
          this.subscriptionForm.controls.compound.setValue(user_product.compound_earning);
        } else {
          this.subscriptionForm.controls.compound.setValue(false);
        }
      }
      this.subscriptionForm.updateValueAndValidity();
    }
  }

  getProductDefinition() {
    const hiddenCol = this.isSubscribing ?
      ['compound_earning', 'balance', 'pending_product_balance', 'earn'] :
      ['estimated_daily_earning', 'estimated_annual_earning', 'min_amount'];

    return this.product_definition.filter(definition => hiddenCol.indexOf(definition) === -1);
  }

  updateAmountValidation() {
    if (!!this.selectedProduct) {
      const maxAmount = this.selectedProduct.max_amount;
      this.min_amount = +this.selectedProduct.min_amount;
      this.max_amount = !this.isSubscribing ? +this.selectedProduct.balance :
        (maxAmount !== 'unlimited' && this.userBalance > maxAmount ? +maxAmount : +this.userBalance);
      this.subscriptionForm.controls.amount.setValidators([
        Validators.min(this.min_amount),
        Validators.max(this.max_amount)
      ]);
      this.subscriptionForm.controls.amount.updateValueAndValidity();
    }
  }

  maxAmount() {
    this.subscriptionForm.controls.amount.setValue(this.userBalance);
    this.subscriptionForm.controls.amount.markAsTouched();
  }

  toggleWarning(accept: boolean) {
    this.warning = !this.warning;
    if (accept) {
      this.riskAccepted = accept;
      this.subscribeToProduct();
    }
  }

  validateForm(subscribing: boolean) {
    this.subscriptionForm.markAllAsTouched();
    if (this.subscriptionForm.valid) {
      if (this.userProducts.findIndex(data => data.id === this.selectedProduct.id) !== -1) {
        this.riskAccepted = true;
        subscribing ? this.subscribeToProduct() : this.unsubscribeFromProduct();
      } else {
        this.toggleWarning(false);
      }
    }
  }

  subscribeToProduct() {
    if (this.riskAccepted) {
      const data = {
        productId: this.selectedProduct.id,
        coin: this.coinCode,
        amount: this.subscriptionForm.controls.amount.value,
        compound: this.subscriptionForm.controls.compound.value,
      };

      this.balancesService.subscribeToProduct(data).subscribe((resp) => {
        if (resp.response === 'success') {
          this.getCoinProducts();
          this.clearForm();
          this.showProducts = true;

          this.storeService.showToastNotification(`Subscribed to ${this.selectedProduct.name}`,
            this.translateService.translateResponse('SUBSCRIBE_SUCCESS'), 3000);
        } else {
          this.snackbar.open(
            this.translateService.translateResponse(resp.reason),
            this.i18n('Close'), {duration: 2000}
          );
        }
      });
    } else {
      this.toggleWarning(false);
    }
  }

  clearForm() {
    this.subscriptionForm.reset();
    this.formIsDirty = true;
  }

  changeCompound(product: Product, e: any) {
    const data = {
      title: 'Compond Earning - ' + this.coinCode + ' ' + product.name,
      compound: e.source.checked
    };
    const dialogRef = this.dialog.open(ProductConfirmationComponent, {
      width: '600px',
      data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!!result && result.result === 'accept') {
        const productData = {
          userproduct_id: product.userproduct_id,
          compound_earning: e.source.checked
        };
        this.balancesService.updateUserProductSettings(productData).subscribe(resp => {
          if (resp.response !== 'success') {
            e.source.checked = !e.source.checked;
          }

          this.snackbar.open(
            this.translateService.translateResponse(resp.reason),
            this.i18n('Close'), {duration: 2000}
          );
        });
      } else {
        e.source.checked = !e.source.checked;
      }
    });
  }

  unsubscribeFromProduct() {
    const data = {
      amount: this.subscriptionForm.controls.amount.value,
      productId: this.selectedProduct.id,
    };

    this.balancesService.unsubscribeFromProduct(data).subscribe((resp) => {
      if (resp.response === 'success') {
        this.getCoinProducts();
        this.clearForm();
        this.showProducts = true;

        this.storeService.showToastNotification(`Unsubscribed From ${this.selectedProduct.name}`,
        this.translateService.translateResponse('UNSUBSCRIBE_SUCCESS'), 3000);
      } else {
        this.snackbar.open(
          this.translateService.translateResponse(resp.reason),
          this.i18n('Close'), {duration: 2000}
        );
      }
    });
  }

  navlink(): string {
    return (this.earnPage ? '/profile' : '/');
  }
}
