<mat-icon class="dialog-close" (click)="close_click()">close</mat-icon>
<div class="container-fluid dialog-over-flow domains-overflow">
    <div class="row">
        <div class="col-12 pb-2">
            <h3 class="text-center">Custom Domains</h3>

            <div class="mt-2 mb-2 text-center overflowWrap">
                As a verified user, you get a <b>free</b> crypto domain from
                <a href="https://unstoppabledomains.com/" target="_blank">Unstoppable Domains</a>.
            </div>

            <div class="row mx-auto justify-content-center align-items-center">
                <mat-form-field class="col-12 mb-0">
                    <input type="text" matInput name="searchTerm" i18n-placeholder placeholder="Custom Domain Name" [(ngModel)]="searchTerm">
                </mat-form-field>
                <button mat-raised-button color="primary" class="col-auto mb-3" (click)="searchDomain()" i18n>SEARCH</button>
            </div>

            <!-- suggestions table -->
            <ng-container *ngIf="domainSuggestions && domainSuggestions.length > 0; else searchPrompt">
                <div class="mt-2 mb-2 text-center overflowWrap">
                    <span>
                        Select your new custom domain from the suggestions below:
                    </span>
                </div>

                <div class="row mx-0 table-border">
                    <div *ngFor="let domain of domainSuggestions" class="col-12">
                        <div class="row px-3 py-3 align-items-center bottom-border">
                            <div class="col"><b>{{domain.name}}</b></div>
                            <button  mat-raised-button class="col-3 col-xl-auto mx-0 px-0"
                                color="primary" (click)="requestDomainLink(domain)">
                                    GET
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #searchPrompt>
                <ng-container *ngIf="suggestionsLoading; else domainPrompt">
                    <mat-progress-spinner class="col-12 mx-auto" mode="indeterminate" color="primary"></mat-progress-spinner>
                </ng-container>

                <ng-template #domainPrompt>
                <div class="mt-2 mb-2 text-center overflowWrap">
                    <span>
                        No domain suggestions available.<br>
                        Please search for your custom domain name above.
                    </span>
                </div>
                </ng-template>


            </ng-template>
        </div>
    </div>
</div>
