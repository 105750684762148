import { Component, OnInit, ViewChild, Input, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { FormControl } from '@angular/forms';
import * as Big from 'big-js';
import { I18n } from '@ngx-translate/i18n-polyfill';

import {
    MarketConfirmationComponent,
    DialogData } from '../../markets/market-confirmation/market-confirmation.component';
    import { BalancesService } from '../balances.service';

import { StoreService } from 'src/app/core/services/store.service';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { MarketsService } from 'src/app/markets/markets.service';
import { TransactionHistoryRequest } from 'src/app/core/interfaces/balances';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-trade-history',
  templateUrl: './trade-history.component.html',
  styleUrls: ['./trade-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TradeHistoryComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:no-input-rename
  @Input('coin') coinCode: string = 'ZAR';
  // tslint:disable-next-line:no-input-rename
  @Input('type') coinType: string = 'fiat';

  showNotification: boolean = true;

  reloadHandler: any;

  subs: any = [];

  history_data: MatTableDataSource<any> = new MatTableDataSource([]);
  total_records: number;
  page_sizes: number[] = [5, 10, 20, 50];
  list_number: number = 10;
  page_handler: any = null;
  page_loading: boolean;

  data: any = {
    perPage: '10',
    pageNo: '0',
    orderBy: 'time',
    order: 'DESC',
    coin: '',
    exchange: '',
    action: '',
    dateFrom: '',
    dateTo: ''
  };

  history_definition: any = [
    'market',
    'type',
    'amount',
    'price',
    'total',
    'fee',
    'net_total',
    'time'];

  coin_options: any[] = [];

  status_options: any[] = [
    {id: '-1', name: 'ALL'},
    {id: '0', name: 'BUY'},
    {id: '1', name: 'SELL'}];

    selected_coin: any = '-1';
    selected_market: any = '-1';
    selected_date_from: any = '';
    selected_date_to: any = '';
    selected_action: any = '-1';

    coin_control: FormControl = new FormControl(['']);
    market_control: FormControl = new FormControl(['']);
    date_from_control: FormControl = new FormControl(['']);
    date_to_control: FormControl = new FormControl(['']);
    action_control: FormControl = new FormControl(['']);

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private cdRef: ChangeDetectorRef,
    private balancesService: BalancesService,
    private marketService: MarketsService,
    public dialog: MatDialog,
    private store: StoreService,
    private i18n: I18n,
    public snackbar: MatSnackBar,
    private translateService: I18nTranslationService,
  ) { }

  ngOnInit() {
    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showNotification = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
    }));
    this.subs.push(this.store.subscribe('tradeHistory').subscribe((response) => {
      let id: string;
      let pair;

      if (response.feed) {
        if (response.feed.indexOf('marketUpdates-') !== -1) {
          id = response.feed.replace('marketUpdates-', '');
          pair = this.marketService.getMarketPair(id);
          if (pair) {
            if ((this.data.coin.toString() === '-1' ||
              pair.coinId.toString() === this.data.coin.toString()) &&
              (this.data.exchange.toString() === '-1' ||
                pair.exchangeId.toString() === this.data.exchange.toString())) {
              this.loadTableData();
            }
          }
        }
      } else {
        this.loadTableData();
      }
    }));
    this.loadTableData();

    this.history_data.sort = this.sort;
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    clearInterval(this.reloadHandler);

    this.subs.forEach(sub => sub.unsubscribe());
  }

  loadTableData() {
    let sortBy = 'time';
    let direction = this.sort.direction.toUpperCase();
    if (!this.sort.active || this.sort.direction === '') {
      sortBy = 'time';
      direction = 'DESC';
    } else {
      sortBy = this.sort.active;
    }

    this.data.orderBy = sortBy;
    this.data.order = direction;
    this.data.coin = this.coinCode;
    this.data.type = '';
    this.data.exchange = '';

    this.balancesService.getMyTradeHistory(this.data).subscribe((response: any) => {
      if (response.response === 'success') {
         this.history_data.data = response.data;
         this.total_records = response.recordsTotal;
         this.cdRef.detectChanges();
      }
    });
  }

  paging_change($event: any) {
    this.data.pageNo = $event.pageIndex;
    this.data.perPage = $event.pageSize;
    if (!!this.page_handler) {
      clearTimeout(this.page_handler);
    }

    this.page_handler = setTimeout(() => {
      this.loadTableData();
    }, 1000);
  }

  cancelOrder(element: any) {
    if (this.showNotification) {
      const dialogData: DialogData = {
        title: 'Cancel Order',
        result: 'string',
        reason: this.i18n(`Are you sure you would like to cancel the following Order?'
          '<br><b>Pair</b>: {{pair}}
          '<br><b>Type</b>: {{type}}
          '<br><b>'Amount'</b>: {{amount}}
          '<br><br>`, {pair: element.pair, type: (element.type === '0' ? 'BUY' : 'SELL'), amount: element.amount}),
        okButton: true,
        isTranslated: true
      };
      const dialogRef = this.dialog.open(MarketConfirmationComponent, {
        width: '400px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.result === 'accept') {
          this.balancesService.cancelOrder({orderId: element.order_id}).subscribe((response) => {
            /* if (response.response === 'success') {
              const conf = this.dialog.open(MarketConfirmationComponent, {
                width: '400px',
                data: { title: 'Confirmation', result: 'success', reason: response.reason}
              });
            } else {
              const conf = this.dialog.open(MarketConfirmationComponent, {
                width: '400px',
                data: { title: 'Failed', result: 'failed', reason: response.reason}
              });
            }
            */
            this.snackbar.open(this.translateService.translateResponse(response.reason),
              this.i18n('Close'), {duration: 2000});
          });
        }
      });
    } else {
      this.balancesService.cancelOrder({orderId: element.order_id}).subscribe((response) => {
        if (response.response === 'success') {
        } else {
          this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), {duration: 2000});
        }
      });
    }
  }

  setFee(e: any) {
    return e.fee;
    /*
    // for maker/taker or order types maybe
    if (e.type === '0') {
      return e.buyer_fee;
    } else {
      return e.seller_fee;
    }
    */
  }

  displayTypeNiceName(e: any) {
    if (e.type === '0') {
      return 'BUY';
    } else {
      return 'SELL';
    }
  }
  setNetTotal(e: any) {
    return this.formatDecimals(e.net_total, 'net_total');
    /*
    // for maker/taker or order types maybe
    if (e.type === '0') {
      return this.formatDecimals(e.buyer_net_total, 'net_total');
    } else {
      return this.formatDecimals(e.seller_net_total, 'net_total');
    }
    */
  }

  formatDecimals(value: any, column: string) {
    let decimalSpaces: number = 8;
    if (String(this.coinType).toLowerCase() === 'fiat' &&
      ((column === 'amount') || (column === 'price') || (column === 'total') || (column === 'net_total'))) {
      decimalSpaces = 2;
    }

    if (!!value) {
      const ten = new Big(10);
      const multiplier = ten.pow(decimalSpaces);

      value = new Big(Number(value));
      value = new Big(Number(Math.floor(value.times(multiplier))));
      value = value.div(multiplier);
      value = value.toFixed(decimalSpaces);
      }
    return value;
  }

}
