import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit, OnDestroy {

  subs: SubscriptionLike;

  constructor(
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.subs = this.route.params.subscribe(params => {
      if (params['url'] != null) { // Slashes should be written as '%2F' in the url
        window.location.href = params['url']; // Google absolute url: https:%2F%2Fwww.google.com
      } else {
        if (this.router) {
          this.router.navigateByUrl('');
        }
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
