import { IMarket } from './../../markets/markets.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { throwError, Observable ,  BehaviorSubject, Observer ,  SubscriptionLike } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as Big from 'big-js';

import 'src/assets/js/autobahn'; // just imports the globals..

import { EventHubService } from './event-hub.service';
import { SessionStorageService } from './session-storage.service';
import { environment } from '../../../environments/environment';
import { I18nTranslationService } from '../../core/services/i18n-translation.service';
import { HistoryService } from '../../history/history.service';
import { I18n } from '@ngx-translate/i18n-polyfill';

export { SubscriptionLike } from 'rxjs';

declare global {
  interface Window { ab: any; }
}

const SITEURL = environment.config.APP_URL;
const BASE_URL = environment.config.BACKEND_URL;
const PUSH_URL = environment.config.PUSH_SERVER;

interface StoreSubject {
  subject: string;
  store: BehaviorSubject<any>;
  observable: Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
export class StoreService implements OnDestroy {

  pushHost: any;
  pushSession: string = '';
  pushSubscriptions: any[] = [];

  private _subs: StoreSubject[] = [];
  private verificationSub: SubscriptionLike;
  private tradeHistorySub: SubscriptionLike;

  abChecker: any;
  banChecker: any;

  pushReady: boolean = false;
  pushConnected: boolean = false;
  chatConnected: boolean = false;
  marketConnected: boolean = false;
  marketsConnected: Array<string> = [];

  fetchHandlers: any = {};

  externalChat: Window;

  dataStore: any = {
    totalSummary: {
      totalBitcoin: 0,
      totalZAR: 0,
      totalUSDT: 0,
      zarFiatType: true
    },
    chat: []
  };

  userNotifications: any = {
    '0': { isSelected: false, type: 'none' },
    '1': { isSelected: false, type: 'toast' },
    '2': { isSelected: false, type: 'desktop' }
  };

  keepData: string[] = [
    'chat',
    'announcements',
    'marketData',
  ];

  chat_username: string = '';
  chat_status: string = '';
  chat_banend: string = '';
  referral_code: string = '';

  delayedHistoryUpdate: any;

  balanceTimeout: any;

  getSessionSub: SubscriptionLike = null;

  noTrade: boolean = false;
  hastrades: boolean = false;

  constructor(
    private http: HttpClient,
    private session: SessionStorageService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private eventhub: EventHubService,
    private translateService: I18nTranslationService,
    private historyService: HistoryService,
    private snackbar: MatSnackBar,
    private i18n: I18n
  ) {

    this.eventhub.listen().subscribe((m: any) => {
      this.onEvent(m);
    });
    this.http.post(`${BASE_URL}/action/getToken`, {}); // Set token on construct.
    this.pushReady = false;
    this.connect_push();
    this.verificationSub = this.session.observe('PROFILE_VERIFICATION_LEVEL').subscribe(() => this.getBalances());

    window.addEventListener('unload', () => {
      if (!!this.externalChat) {
        this.externalChat.close();
      }
    });
  }

  ngOnDestroy() {
    if (this.abChecker) {
      clearInterval(this.abChecker);
    }
    window.removeEventListener('unload', () => {
      if (!!this.externalChat) {
        this.externalChat.close();
      }
    });
    this.verificationSub.unsubscribe();
    this.tradeHistorySub.unsubscribe();
    this.logOff();
  }

  onEvent(event: any) {
    switch (event) {
      case 'force-autologout':
        this.logOff();
      break;
    }
  }

  connect_push() {
    if (!!window.ab && (!this.pushHost || !this.pushHost._websocket_connected)) {
      try {
          this.pushHost = new window.ab.Session(PUSH_URL, () => {
              this.pushReady = this.pushHost._websocket_connected;
              this.login();
            }, () => {
              if (this.pushHost && this.pushSession) {
                this.pushHost.unsubscribe(this.pushSession);
              }
              this.pushHost = null;
              this.pushReady = false;
              this.pushConnected = false;
              this.chatConnected = false;
              this.marketConnected = false;
              this.pushSession = '';
              this.connect_push.bind(this)();
            },
            { 'skipSubprotocolCheck': true });

          this.pushHost._websocket.onerror = (error) => {
            console.error('Websocket Error');
            console.error(error);
            this.pushHost._websocket.close();
          };

          if (!this.pushReady) {
            this.getChatMessages('chat');
            this.getMarkets();
            this.getAnnouncements();
          }

      } catch (ex) {
        console.error(ex);
      }
    }
  }

  call<T>(api: string, params?: any): Observable<T> {
    if (params) {
      return this.http.post<T>(`${BASE_URL}/action/${api}`, params)
        .pipe(catchError(this.handleError));
    } else {
      return this.http.get<T>(`${BASE_URL}/action/${api}`)
        .pipe(catchError(this.handleError));
    }
  }

  private handleError(error: HttpErrorResponse) {
    return throwError('APIService: Something bad happened; please try again later');
  }

  private setGetParams(data: any) {
    let prams = '?';
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
      if (prams !== '?' && entries[i][1] !== '') {
        prams += '&';
      }
      if (entries[i][1] !== '') {
        prams += `${entries[i][0]}=${entries[i][1]}`;
      }
    }
    return prams;
  }

  logOff() {
    this.dialog.closeAll();
    this.notifySubscribers('balances', { refresh: true, data: this.dataStore['balances'] });

    const entries = Object.entries(this.dataStore);
    for (let i = 0; i < entries.length; i++) {
      if (!!this.dataStore[entries[i][0]]) {
        if ( this.keepData.indexOf(entries[i][0]) > -1) {
        } else if (entries[i][0] === 'totalSummary') {
          this.dataStore[entries[i][0]].totalBitcoin = 0;
          this.dataStore[entries[i][0]].totalZAR = 0;
          this.dataStore[entries[i][0]].totalUSDT = 0;
        } else {
          this.dataStore[entries[i][0]] = null;
        }
      }
    }
    for (let i = 0; i < this.pushSubscriptions.length; i++) {
      if (this.pushSubscriptions[i].subject !== 'marketData') {
        this.pushSubscriptions.splice(i, 1);
        i--;
      }
    }
    if (this.pushSession !== '') {
      try {
        this.pushHost.unsubscribe(this.pushSession);
        if (this.pushHost._websocket_connected) {
          this.pushHost.close();
          this.pushHost = null;
          this.pushReady = false;
        }
      } catch (ex) {
      }
    }
    this.pushSession = '';
    this.chat_username = '';
    this.chat_status = '';
    this.referral_code = '';
    this.pushConnected = false;

    if (!!this.externalChat && !this.externalChat.closed) {
      this.externalChat.close();
    }
  }

  login() {
    // we should ge this regardless of whether or not push is connected
    if (this.session.get('LOGGED_IN') === 'logged_in') {
      if (!this.dataStore['settings']) {
        this.getSettings();
      }
      if (!this.dataStore['balances']) {
        this.getBalances();
      }

    if (this.pushSession === '' && this.pushReady) {
      if (!!this.getSessionSub) {
        return;
      }
      this.getSessionSub = this.call('getPushSession').subscribe((response: any) => {

        if (this.pushReady && response.response === 'success') {
          this.pushSession = response.data;
          this.pushHost.subscribe(this.pushSession, this.processPush.bind(this));
          this.pushConnected = true;
          this.getSessionSub.unsubscribe();
          this.getSessionSub = null;
        } else {
          this.getSessionSub.unsubscribe();
          this.getSessionSub = null;
        }
      });
    }

    if (!this.chatConnected && this.pushReady) {
      this.pushHost.subscribe('chat', this.processPush.bind(this));
      this.chatConnected = true;
    }
    if (!this.marketConnected && this.pushReady) {
      this.pushHost.subscribe('sidebarStats', this.processPush.bind(this));
      this.pushHost.subscribe('topBar', this.processPush.bind(this));
      this.marketConnected = true;
    }
  }
}

  /// Subjects
  // balances
  // deposit
  // lightning-deposit
  // withdrawal
  // openOrders
  // orderHistory
  // tradeHistory

  // allStats
  // depth
  // marketData
  // marketFavorites
  // allTrade
  // chat
  // totalSummary
  // settings
  // announcements

  subscribe(subject: string) {
    this.login();

    const subs = this._subs.find(_ => _.subject === subject);

    if (subs) {
      return subs.observable;
    }

    const store = new BehaviorSubject<any>({refresh: true});
    const obs = store.asObservable();

    this._subs.push({
      subject,
      store,
      observable: obs
    });

    if (this.dataStore[subject]) {
      store.next({
        refresh: false,
        data: JSON.parse(JSON.stringify(this.dataStore[subject])),
      });
    }

    return obs;
  }

  notifySubscribers(subject: string, data: any) {
    const subs = this._subs.find(_ => _.subject === subject);

    if (subs) {
      subs.store.next(data); // TODO - Clone object - this is bad
    }
  }

  getBalances() {

    if (this.balanceTimeout) {
      clearTimeout(this.balanceTimeout);
    }

    this.balanceTimeout = setTimeout((() =>
      this.call<any>(`getBalances${this.setGetParams({
        start: 0,
        length: 100000,
        search: '',
        fiat_evaluation: '',
        zeroBalance: false,
        orderBy: 'sort_order',
        order: '',
        orderType: 'ASC'
      })}`).subscribe(response => {
      if (response.response === 'success') {
        this.dataStore['balances'] = response.data;
        this.dataStore['tfaEnabled'] = response.is2faenabled;
        this.dataStore['isFundpinEnabled'] = response.isFundpinEnabled;
        this.updateTotals();
        this.notifySubscribers('balances', { refresh: false, data: this.dataStore['balances'] });
        this.notifySubscribers('tfaEnabled', { data: +this.dataStore['tfaEnabled'] ? true : false });
      }
    })).bind(this), 100);
  }

  getBalance(code: any): Observable<any> {
    return new Observable((observer) => {
      if (!!this.dataStore['balances']) {
        for (let i = 0; i < this.dataStore['balances'].length; i++) {
          const balance = this.dataStore['balances'][i];
          if (balance.code === code ) {
            if (typeof balance.deposit !== 'undefined' ||
              typeof balance.withdraw !== 'undefined') {
              // return observable to keep calls compatible
              observer.next({ data: JSON.parse(JSON.stringify(balance)), response: 'success'});

              // cache for 1 second - avoid multiple calls :):)
              if (balance.timestamp + 1 > Date.now() / 1000) {
                observer.complete();
                return;
              }
            }
            this.call<any>(`getBalance?coin=${code}`).subscribe((response) => {
              if (response.response === 'success') {
                if (JSON.stringify(balance) !== JSON.stringify(response.data)) {
                  this.dataStore['balances'][i] = response.data;
                  this.dataStore['balances'][i].timestamp = response.timestamp;
                  // Copy the object so it doesn't get mutated
                  observer.next({ data: JSON.parse(JSON.stringify(
                    this.dataStore['balances'][i])),
                    response: 'success'
                  });
                }
                observer.complete();
              }
            });
            return;
          }
        }
      }

      observer.next({
        data: {
          account_available: 'false',
          balance_available: '0.00000000',
          balance_held: '0.00000000',
          balance_pending_deposit: '0.00000000',
          balance_pending_withdraw: '0.00000000',
          balance_total: '0.00000000',
          btc_estimated_value: '0.00000000',
          change: '0.00',
          code: '',
          coin_type: '',
          estimated_value: '0.00',
          id: '-1',
          name: '',
          sort_order: '1',
          usdt_estimated_value: '0.00000000',
          zar_estimated_value: '0.00000000',
          withdraw: {
            withdrawFee: '0.000000',
            withdrawData: {
              'totalCoin': 0,
              'limitCoin': 0,
              'totalCoinMonth': 0,
              'limitCoinMonth': 0,
              'totalCoinFiatEquivalent': '0.00000000',
              'limitCoinFiatEquivalent': '0.00000000',
              'totalCoinFiatEquivalentMonth': '0.00000000',
              'limitCoinFiatEquivalentMonth': '0.00000000'
            },
            verificationLevel: 1,
            withdrawLimits: {
              'max_withdraw': '0.00000000',
              'min_withdraw': '0.00000000'
            },
          },
          deposit: {
            coinBalance: '',
            depositAddress: 'paYtLuN5JU9iR36DUgaDzP8WAc1Ge6kSSr',
            reqConfirms: '3'
          }
        },
        response: 'failure'});

      observer.complete();
    });
  }

  updateBalance(record: any) {
    const updateTotals = false;

    if (updateTotals) {
      this.updateTotals();
    }

    for (let i = 0; i < this.dataStore['balances'].length; i++) {
      if (this.dataStore['balances'][i].code === record.code) {

        this.dataStore['balances'][i].balance_available = !record.balance_available ?
          this.dataStore['balances'][i].balance_available :
          record.balance_available;

        this.dataStore['balances'][i].balance_pending_deposit = !record.balance_pending_deposit ?
          this.dataStore['balances'][i].balance_pending_deposit :
          record.balance_pending_deposit;

        this.dataStore['balances'][i].balance_pending_withdraw = !record.balance_pending_withdraw ?
          this.dataStore['balances'][i].balance_pending_withdraw :
          record.balance_pending_withdraw;

        this.dataStore['balances'][i].balance_held = !record.balance_held ?
          this.dataStore['balances'][i].balance_held :
          record.balance_held;

        this.dataStore['balances'][i].estimated_value = !record.estimated_value ?
          this.dataStore['balances'][i].estimated_value :
          record.estimated_value;

        this.dataStore['balances'][i].oneEquivalentFiat = !record.oneEquivalentFiat ?
          this.dataStore['balances'][i].oneEquivalentFiat :
          record.oneEquivalentFiat;

        this.dataStore['balances'][i].zar_estimated_value = !record.zar_estimated_value ?
          this.dataStore['balances'][i].zar_estimated_value :
          record.zar_estimated_value;

        this.dataStore['balances'][i].oneEquivalentZAR = !record.oneEquivalentZAR ?
          this.dataStore['balances'][i].oneEquivalentZAR :
          record.oneEquivalentZAR;

        this.dataStore['balances'][i].usdt_estimated_value = !record.usdt_estimated_value ?
          this.dataStore['balances'][i].usdt_estimated_value :
          record.usdt_estimated_value;

        this.dataStore['balances'][i].oneEquivalentUSDT = !record.oneEquivalentUSDT ?
          this.dataStore['balances'][i].oneEquivalentUSDT :
          record.oneEquivalentUSDT;

        this.dataStore['balances'][i].btc_estimated_value = !record.btc_estimated_value ?
          this.dataStore['balances'][i].btc_estimated_value :
          record.btc_estimated_value;

        this.dataStore['balances'][i].balance_total = !record.balance_total ?
          this.dataStore['balances'][i].balance_total :
          record.balance_total;

        this.dataStore['balances'][i].change = !record.change ?
          this.dataStore['balances'][i].change :
          record.change;
      }
    }

    this.notifySubscribers('balances', { refresh: false, data: this.dataStore['balances'] });
  }
  updateTotals() {
    let totalBitcoin: any = new Big(0);
    let totalZAR: any = new Big(0);
    let totalUSDT: any = new Big(0);
    for (let i = 0; i < this.dataStore['balances'].length; i++) {
      totalBitcoin = totalBitcoin.plus(Number(this.dataStore['balances'][i].btc_estimated_value));
      totalZAR = totalZAR.plus(Number(this.dataStore['balances'][i].zar_estimated_value));
      totalUSDT = totalUSDT.plus(Number(this.dataStore['balances'][i].usdt_estimated_value));
    }
    this.dataStore['totalSummary'].totalBitcoin = totalBitcoin.toFixed(8);
    this.dataStore['totalSummary'].totalZAR = totalZAR.toFixed(2);
    this.dataStore['totalSummary'].totalUSDT = totalUSDT.toFixed(2);
    this.notifySubscribers('totalSummary', { refresh: false, data: this.dataStore['totalSummary'] });
  }

  createPairList(pairs: any) {
    const pairsList: any = [];
    for (let i = 0; i < pairs.length; i++) {
      const pair = {
        id: pairs[i].market_id,
        pair: pairs[i].market,
        coin_id: pairs[i].coinId,
        coin_code: pairs[i].coinCode,
        coin_name: pairs[i].coinName,
        coin_decimals: pairs[i].coin_decimals,
        ieo_coin: pairs[i].ieo_coin,
        exchange_id: pairs[i].exchangeId,
        exchange_code: pairs[i].exchangeCode,
        exchange_name: pairs[i].exchangeName,
        exchange_decimals: pairs[i].exchange_decimals,
        ieo_exchange: pairs[i].ieo_exchange,
        taker_fee: pairs[i].taker_fee,
        maker_fee: pairs[i].maker_fee,
        last_price: pairs[i].last_price,
        yesterday_price: pairs[i].yesterday_price,
        btcEstimate: pairs[i].btcEstimate,
        zarEstimate: pairs[i]?.zarEstimate,
        icon: pairs[i].icon,
        top_ask: pairs[i].top_ask,
        top_bid: pairs[i].top_bid,
        price: pairs[i].last_price,
        change: pairs[i].change,
        volume: pairs[i].volume,
        volume_amount: pairs[i].volume_amount,
        high: pairs[i].high,
        low: pairs[i].low,
        changeColor: ''
      };
      if (pairs[i].spread_price) {
        pair['spread_price'] = pairs[i].spread_price;
      }
      pairsList.push(pair);
    }
    this.dataStore['marketData'] = pairsList;
  }
  getMarkets() {
    this.call<any>('getMarkets').subscribe((response) => {
      if (response.response === 'success') {
        this.createPairList(response.data);
        this.getMarketData();
      }
    });
  }

  getMarketData() {
    if (!!this.fetchHandlers['getMarkets']) {
      clearTimeout(this.fetchHandlers['getMarkets']);
    }

    this.fetchHandlers['getMarkets'] = setTimeout(() => {
      this.fetchHandlers['getMarkets'] = null;
      this.call<any>('getMarkets').subscribe((response) => {
        if (response.response === 'success') {
          this.processMarketData(response.data, response['volume'], response['total'], response['quickTradingFee']);
        }
      });
    }, 1000);
  }
  processMarketData(marketData: any[], volume: Number, total: number, quickTradingFee: number) {
    this.dataStore['marketData']['volume'] = !!volume ? volume : 0;
    this.dataStore['marketData']['total'] = !!total ? total : 0;
    this.dataStore['marketData']['quick_trading_fee'] = !!quickTradingFee ? total : 0;
    if (!marketData) {
      return;
    }
    const entries: any = Object.entries(marketData);
    for (let i = 0; i < entries.length; i++) {
      if (!isNaN(entries[i][0])) {
        this.updatePair(entries[i][1]);
      }
    }
    /*for (let i = 0; i < marketData.length; i++) {
      this.updatePair(marketData[i]);
    }*/
    this.notifySubscribers('marketData', {
      refresh: false,
      data: this.dataStore['marketData']
    });
  }
  updatePair(market: any) {
    if (!this.dataStore['marketData']) {
      return;
    }
    for (let i = 0; i < this.dataStore['marketData'].length; i++) {
      if (this.dataStore['marketData'][i].id === market.market_id) {
        this.dataStore['marketData'][i].price = market.last_price;
        if (market.spread_price) {
          this.dataStore['marketData'][i].spread_price = market.spread_price;
        }
        this.dataStore['marketData'][i].change = market.change;
        this.dataStore['marketData'][i].volume = market['24hvol'];
        this.dataStore['marketData'][i].volume_amount = market['volume_amount'];
        this.dataStore['marketData'][i].high = market['24hhigh'];
        this.dataStore['marketData'][i].low = market['24hlow'];
        this.dataStore['marketData'][i].topAsk = market['top_ask'];
        this.dataStore['marketData'][i].topBid = market['top_bid'];
        this.dataStore['marketData'][i].yesterdayPrice = market['yesterday_price'];
        this.dataStore['marketData'][i].btcEstimate = market['btcEstimate'].toString();
        this.dataStore['marketData'][i].zarEstimate = market['zarEstimate'].toString();
        if (market.change.indexOf('+') > -1) {
          this.dataStore['marketData'][i].changeColor = 'positive';
        } else if (market.change.indexOf('-') > -1) {
          this.dataStore['marketData'][i].changeColor = 'negative';
        } else {
          this.dataStore['marketData'][i].changeColor = '';
        }

        // We have found and updated the pair.
        return;
      }
    }
  }
  pushVolUpdate(pushData: any) {
    // Market data might not yet be set
    if (!this.dataStore.marketData) {
      return;
    }

    if (!this.dataStore.marketData.volume) {
      this.dataStore.marketData.volume = {};
    }
    this.dataStore.marketData.volume['BTC'] =
      !!pushData['24hbtcvol'] ? Number(pushData['24hbtcvol']).toFixed(4) : '0.0000';
    this.dataStore.marketData.volume['USDT'] =
      !!pushData['24husdtvol'] ? Number(pushData['24husdtvol']).toFixed(4) : '0.0000';
  }
  pushPairUpdate(pushData: any) {
    if (!this.dataStore['marketData']) {
      return;
    }
    for (let i = 0; i < this.dataStore['marketData'].length; i++) {
      if (`marketUpdates-${this.dataStore['marketData'][i].id}` === pushData.feed) {
        this.dataStore['marketData'][i].price = !!pushData.price
          ? pushData.price : this.dataStore['marketData'][i].price;
        this.dataStore['marketData'][i].last_price = !!pushData.price
          ? pushData.price : this.dataStore['marketData'][i].price;
        this.dataStore['marketData'][i].change = !!pushData.change
          ? pushData.change : this.dataStore['marketData'][i].change;
        this.dataStore['marketData'][i].volume = !!pushData.volume
          ? pushData.volume : this.dataStore['marketData'][i].volume;
        this.dataStore['marketData'][i].high = !!pushData.high ? pushData.high : this.dataStore['marketData'][i].high;
        this.dataStore['marketData'][i].low = !!pushData.low ? pushData.low : this.dataStore['marketData'][i].low;
        this.dataStore['marketData'][i].zarEstimate = !!pushData.volume ?
          this.convertAmountToZar(pushData.volume, this.dataStore['marketData'][i].exchange_code) :
          this.dataStore['marketData'][i].zarEstimate;

        // We have found and updated the pair.
        return;
      }
    }
  }

  convertAmountToZar(amount: string, code: string) {
    if (code && code.toUpperCase() === 'ZAR') {
      return amount;
    }

    if (Number(amount) > 0) {
      const { lastPrice }: IMarket = this.dataStore['marketData'].find(({coin_code, exchange_code}) => {
        return coin_code === code && exchange_code?.toUpperCase() === 'ZAR';
      });

      if (lastPrice) {
        return (Number(amount) * Number(lastPrice)).toString();
      }
    }

    return '0';
  }

  getMyActiveOrders(market: string) {
    if (!this.dataStore['openOrders']) {
      this.dataStore['openOrders'] = [];
    }
    return new Observable((observer: Observer<any>) => {
      if (!!this.dataStore['openOrders'][market]) {
        observer.next(this.dataStore['openOrders'][market]);
        observer.complete();
      } else {
        const request: any = {
          'perPage': 6000,
          'pageNo': 0,
          'orderBy': 'time',
          'order': 'DESC',
          'market': market
        };
        this.call<any>(`getMyActiveOrders${this.setGetParams(request)}`).subscribe((response) => {
          if (response.response === 'success') {
            this.dataStore['openOrders'][market] = response.data;
            observer.next(this.dataStore['openOrders'][market]);
            observer.complete();
          }
        });
      }
    });
  }

  saveBalanceSettings(data: any) {
    this.getSettings();
    this.dataStore['totalSummary'].zarFiatType = data.fiat_evaluation === 'ZAR';
    this.notifySubscribers('totalSummary', {
      refresh: false,
      data: this.dataStore['totalSummary']
    });
  }

  getSettings() {
    this.call<any>('getSettings').subscribe((response) => {
      if (response.response === 'success') {
        this.updateSettings(response);
      }
    }, () => { });
  }

  updateSettings(response: any) {
    this.dataStore['settings'] = response.data;
    if (this.dataStore['settings']) {
      this.dataStore['settings'].referral_code = !response.referral_code ? '' : response.referral_code;
    }
    this.updateUserNotifications(response.data);
    this.chat_username = !response.chat_username ? '' : response.chat_username;
    this.chat_status = !response.chat_status ? '' : response.chat_status;
    this.chat_banend = !response.ban_end ? '' : response.ban_end;
    this.referral_code = !response.referral_code ? '' : response.referral_code;

    if (!this.banChecker && !!this.chat_banend) {
      this.banChecker = setTimeout(() => this.getSettings(), Number(this.chat_banend) * 1000 - Date.now());
    }

    this.notifySubscribers('settings', {
      refresh: false,
      data: this.dataStore['settings'],
      username: this.chat_username,
      status: this.chat_status
    });

    this.notifySubscribers('chat', {
      refresh: false,
      data: this.dataStore['chat'],
      username: this.chat_username,
      status: this.chat_status
    });
  }

  getAnnouncements() {
    this.call<any>('getAnnouncements').subscribe((response) => {
      if (response.response === 'success') {
        this.dataStore['announcements'] = response.data;
        this.notifySubscribers('announcements', { refresh: false, data: this.dataStore['announcements'] });
      }
    }, () => { });
  }

  setAnnouncementsRead() {
    for (let i = 0; i < this.dataStore['announcements'].length; i++) {
      this.dataStore['announcements'][i].read = '1';
    }
    this.notifySubscribers('announcements', {
      refresh: false,
      data: this.dataStore['announcements']
    });
  }
  commitAnnouncementsRead() {
    const announcements: string[] = [];
    for (let i = 0; i < this.dataStore['announcements'].length; i++) {
      announcements.push(this.dataStore['announcements'][i].id);
    }
    this.call<any>('setAnnouncementsRead', { announcements: announcements }).subscribe((response) => {
      if (response.response === 'success') { this.setAnnouncementsRead(); }
    });
  }

  updateUserNotifications(data: any) {
    this.userNotifications['0'].isSelected = false;
    this.userNotifications['1'].isSelected = false;
    this.userNotifications['2'].isSelected = false;

    if (!data || !data.notificationSettings ||
      !data.notificationSettings.style) {
      this.userNotifications['1'].isSelected = true;
    } else {
      this.userNotifications[data.notificationSettings.style].isSelected = true;
    }
  }

  getChatMessages(subject: string) {
    this.call<any>('loadChat').subscribe((response) => {
      if (response.response === 'success') {
        this.dataStore[subject] = response.data;
        if (!!response.chat_username) {
          this.chat_username = response.chat_username;
          this.chat_status = response.chat_status;
        } else {
          this.chat_username = '';
        }
        this.notifySubscribers(subject, this.dataStore[subject]);
      }
    });
  }

  updateChat(subject: string, messageData: any) {
    try {
      const message = JSON.parse(messageData);
      this.dataStore[subject].push(message);
      this.notifySubscribers('chat', {
        refresh: false,
        data: this.dataStore[subject]
      });
    } catch (ex) {
    }
  }
  deleteChat(chatData: any) {
    try {
      const chat = JSON.parse(chatData);
      for (let i = 0; i < this.dataStore['chat'].length; i++) {
        if (this.dataStore['chat'][i].messageid === chat.id) {
          this.dataStore['chat'].splice(i--, 1);
        }
      }
      this.notifySubscribers('chat', {
        refresh: false,
        data: this.dataStore['chat']
      });
    } catch (ex) {
    }
  }

  connectMarket(feed: string): void {
    if (!this.pushHost._websocket_connected) {
      setTimeout(() => this.connectMarket(feed), 200);
      return;
    }
    if (feed.indexOf('marketUpdates-') !== -1 && this.marketsConnected.indexOf(feed) === -1) {
      this.pushHost.subscribe(feed, (topic, data) => {
        this.processPush(topic, data);
      });
      this.marketsConnected.push(feed);
    }
  }

  processPush(topic: any, data: any) {
    switch (data.messageType) {
      case 'update-balance':
        if (!data.compoundSetting) {
          this.getBalances();
        }
        this.notifySubscribers('update-balance', {refresh: true});
        break;
      case 'New Deposit Received':
      case 'deposit':
        this.getBalances();
        this.notifySubscribers('deposit', { refresh: true });
        // notice.message, response.notice.title
        if (!!data.title && !!data.message) {
          this.showNotification(data.title, data.message,
            !!data.type ? data.type : data.messageType);
        }
        break;
        case 'lightning-deposit':
          this.notifySubscribers('lightning-deposit',
            { payment_request: data.payment_request, id: data.id ? data.id : null });
          if (!!data.title && !!data.message && data.feed === this.pushSession) {
            this.showNotification(data.title, data.message,
              !!data.type ? data.type : data.messageType);
          }
          break;
      case 'Deposit Cleared':
      case 'deposit-cleared':
        this.notifySubscribers('deposit', { refresh: true });
        this.getBalances();
        this.showNotification(data.title, data.message,
          !!data.type ? data.type : data.messageType);
        break;
      case 'withdraw':
        this.notifySubscribers('withdrawal', { refresh: true });  // , notice: data
        this.getBalances();
        if (!!data.title && !!data.message) {
          this.showNotification(data.title, data.message,
            !!data.type ? data.type : data.messageType);
        }
        break;
      case 'Withdraw Processed':
        this.notifySubscribers('withdrawal', { refresh: true });  // , notice: data
        this.getBalances();
        // notice.message, response.notice.title
        if (!!data.title && !!data.message) {
          this.showNotification(data.title, data.message,
            !!data.type ? data.type : data.messageType);
        }
        break;

      case 'order-created':
      case 'order':
      case 'order-cancel':
      case 'order-delete':
      case 'specific-user-trade':
      case 'trade':
      case 'pending-order-cancel':
        data.isUserSpecific = data.feed === (this.pushSession);
        this.dataStore['openOrders'] = [];
        this.notifySubscribers('daOrders', { refresh: true, ...data });
        this.notifySubscribers('openOrders', { refresh: true, ...data });
        this.notifySubscribers('orderHistory', { refresh: true, ...data });  // , notice: data
        this.notifySubscribers('tradeHistory', { refresh: true, ...data });
        if (data.title === 'New Trade') {
          this.hasFirstTrade(true);
        }

        // notice.message, response.notice.title
        if (!!data.title && !!data.message) {
          if (data.messageType === 'pending-order-cancel') {
            this.snackbar.open(this.i18n(data.message),
              this.i18n('close'), {duration: 5000});
          } else {
            this.showNotification(data.title, data.message,
              !!data.type ? data.type : data.messageType);
          }
        }
        if (data.messageType === 'trade') {
          this.pushPairUpdate(data);
          this.notifySubscribers('trade', data);
          this.notifySubscribers('marketData', {
            refresh: false,
            data: this.dataStore['marketData']
          });
        }
        break;
      case 'order-updated':
        data.isUserSpecific = data.feed === (this.pushSession);
        this.notifySubscribers('daOrderUpdate', { refresh: true, ...data });
        break;

      case 'All stats':
        this.notifySubscribers('allStats', { refresh: true });
        this.notifySubscribers('depth', { refresh: true });
        this.notifySubscribers('marketStats', { refresh: true });
        break;

      case 'Depth Chart':
        this.notifySubscribers('depth', { refresh: true });
        break;
      case 'Market Stats':
      case 'sidebar':
        this.pushPairUpdate(data);
        this.notifySubscribers('marketData', {
          refresh: false,
          data: this.dataStore['marketData']
        });
        // this.getMarketData();
        break;

      case 'topBar':
          this.pushVolUpdate(data);
          this.notifySubscribers('topBar', {
            refresh: false,
            data: this.dataStore['marketData']
          });
      break;

      case 'All Trade History':
        this.notifySubscribers('allTrade', { refresh: true });
        break;


      case 'settings-update':
        this.updateSettings(data);
        break;

      case 'chat-message':
        this.updateChat('chat', data.message);
        break;
      case 'chat-delete':
        this.deleteChat(data.message);
        break;
      case 'chat-username':
        this.chat_username = data.username;
        this.chat_status = '1';
        this.notifySubscribers('chat', {
          refresh: true,
          username: this.chat_username,
          status: this.chat_status
        });
        break;
      case 'chat-ban':
        try {
          const chatban = JSON.parse(data.message);
          this.chat_banend = chatban.ban_end;
          this.chat_status = '0';
        } catch (ex) { }
        if (!this.banChecker && !!this.chat_banend) {
          this.banChecker = setTimeout(() => this.getSettings(), Number(this.chat_banend) * 1000 - Date.now());
        }
        this.notifySubscribers('chat', {
          refresh: true,
          username: this.chat_username,
          status: this.chat_status
        });
      break;
      case 'chat-unban':
        this.chat_banend = '';
        this.chat_status = '1';
        if (!!this.banChecker) {
          clearTimeout(this.banChecker);
        }
        this.notifySubscribers('chat', {
          refresh: true,
          username: this.chat_username,
          status: this.chat_status
        });
      break;

      case 'announcement-message-add':
        try {
          const announcement = JSON.parse(data.message);
          this.dataStore['announcements'].unshift(announcement);
          this.notifySubscribers('announcements', {
            refresh: false,
            data: this.dataStore['announcements']
          });
        } catch (ex) {
        }
        break;
      case 'announcement-message-edit':
        try {
          const announcement = JSON.parse(data.message);
          let found = false;
          for (let i = 0; i < this.dataStore['announcements'].length; i++) {
            if (this.dataStore['announcements'][i].id === announcement.id) {
              this.dataStore['announcements'][i] = announcement;
              found = true;
              break;
            }
          }
          if (found === false) {
            this.dataStore['announcements'].unshift(announcement);
          }
          this.notifySubscribers('announcements', {
            refresh: false,
            data: this.dataStore['announcements']
          });
        } catch (ex) {
        }
        break;
      case 'announcement-message-delete':
        try {
          const announcement = JSON.parse(data.message);
          for (let i = 0; i < this.dataStore['announcements'].length; i++) {
            if (this.dataStore['announcements'][i].id === announcement.id) {
              this.dataStore['announcements'].splice(i--, 1);
            }
          }
          this.notifySubscribers('announcements', {
            refresh: false,
            data: this.dataStore['announcements']
          });
        } catch (ex) {
        }
        break;
      case 'apisettings-update':
        this.notifySubscribers('apiKeySettings', { refresh: true });
        break;
      default:
        // throw new Error(`Push ${JSON.stringify(data)} NOT IMPLEMENTED`);
        break;
    }
  }

  showNotification(title: any, message: any, tag: any, toast: boolean = false) {
    toast = !toast ? false : toast;
    if (!title || !message) {
      // console.log('Nothing to notify');
    } else {
      if (this.userNotifications['1'].isSelected || toast) {
        this.showToastNotification(title, message);
      } else if (this.userNotifications['2'].isSelected) {
        this.showDesktopNotification(title, message, tag);
      }
    }
  }

  // Takes an existing toastr config and adds a class for its position
  // based on the user's saved settings
  addToastPosition(existingConfig: any) {
    const location = this.session.get('NOTIFICATION_LOCATION');
    let newConfig;

    if (!!existingConfig) {
      newConfig = existingConfig;
    } else {
      newConfig = {
        positionClass: 'toast-top-right'
      };
    }

    switch (location) {
      case '1': {
        newConfig.positionClass = 'toast-top-right';
      } break;
      case '2': {
        newConfig.positionClass = 'toast-bottom-right';
      } break;
      case '3': {
        newConfig.positionClass = 'toast-top-left';
      } break;
      case '4': {
        newConfig.positionClass = 'toast-bottom-left';
      } break;
      case '5': {
        newConfig.positionClass = 'toast-top-center';
      } break;
      default: {
        newConfig.positionClass = 'toast-top-right';
      } break;
    }

    return newConfig;
  }

  showToastNotification(title: any, message: any, duration: number = 10000, position?: string) {
    // check if the current window is the pop-out chat window
    if (window.location.href !== SITEURL + '/chat') {
      this.toastr.success(message, title, (position ?
        {positionClass : position} : this.addToastPosition({enableHtml : true, timeOut: duration})));
    }
  }

  // function for creating the notification
  // ( response.notice.message, response.notice.title);
  showDesktopNotification(title: any, message: any, tag: any) {
    message = this.strip(message);
    const img = '../../../../assets/' + environment.config.EXCHANGE_NAME_L + '/favicon-96x96.png';
    // Check if the browser supports notifications
    if (!window['Notification']) {
      // console.log('This browser does not support notifications.');
    } else if (Notification['permission'] === 'granted') {

      // Check if the user is okay to get some notification
      // If it's okay, create a notification
      // @ts-ignore
      const notification = new Notification(title, { body: message, icon: img, tag: tag });

      window.navigator.vibrate(2000);
    } else if (Notification['permission'] !== 'denied') {
      // Otherwise, we need to ask the user for permission
      // Note, Chrome does not implement the permission static property
      // So we have to check for NOT 'denied' instead of 'default'
      Notification.requestPermission(function (permission: any) {

        // Whatever the user answers, we make sure Chrome stores the information
        if (!(Notification['permission'])) {
          // Notification.permission = permission;
        }

        // If the user is okay, let's create a notification
        if (permission === 'granted') {
          // @ts-ignore
          const notification = new Notification(title, { body: message, icon: img });

          window.navigator.vibrate(2000);
        }
      });
    }

    // At last, if the user already denied any notification, and you
    // want to be respectful there is no need to bother him any more.
  }

  strip(html: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  /*
  * Checks if a user has a first trade
  */
  hasFirstTrade(trade: boolean) {
    if (!this.hastrades || !trade) {
      let returnValue = false;
      this.tradeHistorySub = this.historyService.getMyTradeHistory({ 'perPage': 1 }).subscribe(data => {
        if (data.response === 'success' && trade && this.noTrade) {
          // console.log('first trade hooray!!!');
          returnValue = true;
        }
        this.noTrade = (+data.totalCount === 0); // user has no trades
        this.hastrades = (+data.totalCount > 0); // user has trades
        this.notifySubscribers('firstTrade', { firstTrade: returnValue });
      });
    }
  }

  /*
  * push message to user on first trade and log in
  */
  pushGreetingMessage(type: string) {
    if (type === 'LOGIN') {
      this.showToastNotification('Welcome!',
      this.translateService.translateResponse('FIRST_LOGIN'), 3000);
    } else if (type === 'TRADE') {
      this.showToastNotification('Congratulation!',
      this.translateService.translateResponse('FIRST_TRADE'), 3000);
    }
  }

  getProfile() {
    return new Observable((observer) => {
      this.call<any>('getUserProfile').subscribe((response) => {
        if (response.response === 'success') {
          this.dataStore['userProfile'] = response.data;
          this.dataStore['tfaEnabled'] = !!+response.data.twofa_enabled;
          observer.next({
            data: JSON.parse(JSON.stringify(this.dataStore['userProfile'])),
            response: 'success'
          });
          this.notifySubscribers('tfaEnabled', { data: this.dataStore['tfaEnabled'] });
          observer.complete();
        }
      });
      return;
    });
  }

  getQuickTradingFee() {
    return this.dataStore['marketData']['quick_trading_fee'];
  }
}
