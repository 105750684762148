import { Product } from './../core/interfaces/balances';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../core/api/api.service';
import {
  TransactionHistoryRequest,
  TransactionHistoryResponse,
} from '../core/interfaces/balances';

@Injectable({
  providedIn: 'root'
})
export class BalancesService {
  constructor(
    private apiService: ApiService,
  ) { }

  setGetParams(data: any) {
    let prams = '?';
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
      if (prams !== '?' && entries[i][1] !== '') {
        prams += '&';
      }
      if (entries[i][1] !== '') {
        prams += `${entries[i][0]}=${entries[i][1]}`;
      }
    }
    return prams;
  }

  requestDomainLink(domainName: string) {
    const data: any = {
      domainName: domainName
    };
    return this.apiService.call<any>('requestDomainLink', data);
  }

  requestNewAddress(coin: string, coin_network?: string) {
    const data: any = {
      coin: coin
    };
    if (coin_network) {
      data.coin_network = coin_network;
    }
    return this.apiService.call<any>('requestNewAddress', data);
  }

  requestInvoice(data: {memo: string; amount: string}) {
    return this.apiService.call<any>('requestInvoice', data);
  }

  getDepositHistory(data: TransactionHistoryRequest) {
    return this.apiService.call<TransactionHistoryResponse>(`getBalanceDeposits${this.setGetParams(data)}`);
  }

  getWithdrawalHistory(data: TransactionHistoryRequest) {
    return this.apiService.call<TransactionHistoryResponse>(`getBalanceWithdrawals${this.setGetParams(data)}`);
  }

  getOrders(data: any) {
    return this.apiService.call<any>(`getOrders${this.setGetParams(data)}`);
  }

  getTradeHistory(data: any) {
    return this.apiService.call<any>(`getTradeHistory${this.setGetParams(data)}`);
  }

  requestDeposit(data: any) {
    return this.apiService.call<any>('requestDeposit', data);
  }

  requestWithdrawal(data: any) {
    return this.apiService.call<any>('requestWithdrawal', data);
  }

  checkTransferAddress(data: any) {
    return this.apiService.call<any>('checkTransferAddress', data);
  }

  cancelWithdrawal(data: any) {
    return this.apiService.call<any>(`cancelWithdrawal${this.setGetParams(data)}`);
  }

  hashCancelWithdrawal(data: any) {
    return this.apiService.call<any>(`hashCancelWithdrawal${this.setGetParams(data)}`);
  }

  getBankAccountTypes() {
    return this.apiService.call<any>('getBankAccountTypes');
  }

  getBankAccounts() {
    return this.apiService.call<any>('getBankAccounts');
  }

  getBanks() {
    return this.apiService.call<any>('getBanks');
  }

  updateBalanceSetting(data: any) {
    return this.apiService.call<any>('updateBalanceSetting', data);
  }

  confirmWithdrawal(data: any) {
    return this.apiService.call<any>('confirmWithdrawal', data);
  }

  getMyActiveOrders(request: any): Observable<any> {
    return this.apiService.call<any>(`getMyActiveOrders${this.setGetParams(request)}`);
  }

  cancelOrder(request: any): Observable<any> {
    return this.apiService.call<any>('cancelOrder', request);
  }

  getMyTradeHistory(data: any) {
    return this.apiService.call<any>(`getMyTradeHistory${this.setGetParams(data)}`);
  }

  getMarketData(): Observable<any> {
    return this.apiService.call<any>('getMarkets');
  }

  getUserFee(): Observable<any> {
    return this.apiService.call<any>('getUserFee');
  }

  modifyAddressBookEntry(data: any) {
    return this.apiService.call<any>('modifyAddressBookEntry', data);
  }

  confirmVerifiedBank(data: any) {
    return this.apiService.call<any>('confirmVerifiedBank', data);
  }

  getCoinProducts() {
    return this.apiService.call<any>(`getCoinProducts`).pipe(
      map(response => {
        const coinProducts: Product[] = [];
        const userProducts: Product[] = [];
        if (response.response === 'success') {
          response.data.coinProducts.filter(data => data.id > 0).map(product => {
            coinProducts.push({
              id: product.id,
              name: product.name,
              coin_code: product.coin_code,
              token: product.token,
              estimated_apy: product.estimated_apy,
              period: product.period,
              withdrawal_fee: product.withdrawal_fee,
              min_amount: product.min_amount > 0 ? product.min_amount : 0,
              max_amount: !!product.max_amount ? product.max_amount : 'unlimited',
              compound_earning: (
                (typeof product.compound_earning === 'boolean' && product.compound_earning) ||
                typeof product.compound_earning === 'string' &&
                (product.compound_earning === '1' || product.compound_earning === 'true')
              )
            });
          });

          response.data.userProducts.filter(data => data.id > 0).map(product => {
            userProducts.push({
              id: product.id,
              userproduct_id: product.userproduct_id,
              name: product.name,
              coin_code: product.coin_code,
              token: product.token,
              estimated_apy: product.estimated_apy,
              period: product.period,
              withdrawal_fee: product.withdrawal_fee,
              min_amount: product.min_amount > 0 ? product.min_amount : 0,
              max_amount: !!product.max_amount ? product.max_amount : 'unlimited',
              balance: product.balance,
              pending_product_balance: product.pending_product_balance,
              compound_earning: (
                (typeof product.compound_earning === 'boolean' && product.compound_earning) ||
                typeof product.compound_earning === 'string' && product.compound_earning === 'true'),
              no_compound: (typeof product.no_compound === 'boolean' && product.no_compound ||
                typeof product.no_compound === 'string' && product.no_compound === 'false')
            });
          });

          const products = {
            response: 'success',
            data: {
              coinProducts,
              userProducts
            }
          };

          return products;
        }
      })
    );
  }

  subscribeToProduct(data: any) {
    return this.apiService.call<any>('subscribeToProduct', data);
  }

  unsubscribeFromProduct(data: any) {
    return this.apiService.call<any>('unsubscribeFromProduct', data);
  }

  updateUserProductSettings(data: any) {
    return this.apiService.call<any>('updateUserProductSettings', data);
  }

}
