<div class="col-12">
    <h2 class="text-center" i18n>
        <span class="orange-text">
            <b>Liveness Test</b>
        </span>
    </h2>
</div>

<img *ngIf="fileUrl" [src]="fileUrl">

<div class="col-12 mb-2">
    <div class="my-3 p-3">
        <span class="d-block text-center" i18n>
            Please follow the instructions presented below to prove that you are completing this verification attempt:
        </span>
    </div>
</div>

<div class="border-2 my-3 p-3">
    <div class="wrapping-box-container d-flex flex-column align-items-center justify-content-center">
        <div class="col-12 w-100 d-flex flex-column align-items-center justify-content-center" *ngIf="testComplete && submitSuccess; else actualTest">
            <div class="row justify-content-center">
                <small class="d-block text-center">
                    <span i18n>
                        Liveness Confirmed.<br>
                        Please select the NEXT button when ready.
                    </span>
                </small>
            </div>
        </div>
        <ng-template #actualTest>
            <div id="controls" class="controls w-100 d-flex flex-column align-items-center justify-content-center">
                <small>Click the button below to start the liveness test</small>
                <!-- Button that will begin a Liveness Check -->
                <button id="liveness-button" class="w-100 col-12 col-md-3 my-3" mat-raised-button (click)="onLivenessCheckPressed()"
                    [disabled]="!initialized" color="primary" i18n>
                    Start
                </button>
                <!-- The status container that will be updated appropriately based on current state of FaceTecSDK -->
                <p id="status">Initializing...</p>
            </div>
        </ng-template>
    </div>
    <div class="row mt-3 justify-content-center" *ngIf="testInProgress">
        <mat-progress-spinner class="m-auto" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
</div>

<div class="row mt-3 justify-content-center" *ngIf="resultUploading; else statusMessages">
    <mat-progress-spinner class="mx-auto" [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
</div>

<ng-template #statusMessages>
    <div class="col-12" *ngIf="!testComplete && !submitSuccess">
        <small class="d-block text-center">
            <span i18n>
                Having trouble? Try manual upload below:
            </span>
        </small>
        <div class="col-12 col-md-4 my-3 px-0 mx-auto">
            <button class="w-100 text-white yellow-button" mat-raised-button (click)="switchToManual()" i18n>
                UPLOAD PHOTOS
            </button>
        </div>
    </div>

    <div class="col-12" *ngIf="testComplete && !submitSuccess">
        <small class="d-block text-center">
            <span i18n>
                Liveness test passed, but was not submitted successfully.<br>
                Please click below to resubmit:
            </span>
        </small>
        <div class="col-12 col-md-4 my-3 mx-auto">
            <button class="w-100" mat-raised-button (click)="resubmit()" color="primary" i18n>
                Resubmit
            </button>
        </div>
    </div>

    <div class="col-12" *ngIf="testComplete && submitSuccess">
        <div class="row justify-content-center">
            <img class="attention-icon" matTooltip="Success" src="{{assetsPath}}check_circle.svg">
            <small class="d-block text-center">
                <span i18n>
                    Test completed successfully
                </span>
            </small>
        </div>
    </div>
</ng-template>

