import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-firework-animation',
  templateUrl: './firework-animation.component.html',
  styleUrls: ['./firework-animation.component.scss']
})
export class FireworkAnimationComponent {

  constructor(
    public dialogRef: MatDialogRef<FireworkAnimationComponent>
  ) {}

  // close dialog on click
  closeDialog() {
    this.dialogRef.close();
  }

}
