import { Injectable } from '@angular/core';
import { Subscription, Subject, NextObserver } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplashService {

  subject: Subject<boolean> = new Subject();

  constructor() {}

  stop() {
     this.subject.next(false);
  }
}
