import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BalancesService } from './../../../balances.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-confirm-withdrawal',
  templateUrl: './confirm-withdrawal.component.html',
  styleUrls: ['./confirm-withdrawal.component.scss']
})
export class ConfirmWithdrawalComponent implements OnInit {

  hash: string;

  cancel: boolean = false;
  confirm: boolean = false;
  deactivate: boolean = false;

  successful: Boolean = false;
  code: string;
  amount: number;
  address: string;
  account_number: string;

  expired: Boolean = false;
  reason: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: BalancesService,
  ) {
    this.hash = '';

    this.route.params.subscribe(params => {
      if (params['hash']) {
        this.confirm = true;
        this.hash = params['hash'];
      } else if (params['Hash']) {
        this.cancel = true;
        this.hash = params['Hash'];
      }
    });

    if (!this.hash || this.hash === '') {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.confirm ? this.confirmWithdraw() : this.cancelWithdraw();
  }

  confirmWithdraw() {
    const data = {
      hash: this.hash
    };
    this.service.confirmWithdrawal(data)
      .subscribe((response: any) => {
        if (response.response === 'success') {
          this.successful = true;
          this.expired = false;
          this.code = response.code;
          this.amount = response.amount;
          this.address = response.address;
          this.account_number = response.account_number;
        } else if (response.response === 'failure') {
          this.successful = false;
          this.expired = true;
          this.reason = response.reason;
        }
      });
  }

  cancelWithdraw() {
    const data = {
      hash: this.hash
    };
    this.service.hashCancelWithdrawal(data)
      .subscribe((response: any) => {
        if (response.response === 'success') {
          this.successful = true;
          this.expired = false;
          this.code = response.data.code;
          this.amount = response.data.amount;
          this.address = response.data.address;
        } else if (response.response === 'failure') {
          this.successful = false;
          this.expired = true;
        }
      });
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

}
