<div class="container-fuild">
  <div class="row clearfix">
    <div class="col-12 mt-2 mb-2">
      <h3 i18n>Level 0 Requirements</h3>
    </div>

    <div class="col-12 mt-2 mb-2" i18n>
      Welcome back! You are now entitled to start trading and using your wallets.<br />
      Please help us secure your account by providing your First Name and Last Name below:
    </div>
    <div class="col-12">
      <form [formGroup]="form_data">
        <div class="row">
          <mat-form-field class="col-12">
            <input required matInput i18n-placeholder placeholder="First Name" formControlName="first_name" [(ngModel)]="profile.first_name">
          </mat-form-field>

          <mat-form-field class="col-12">
            <input required matInput i18n-placeholder placeholder="Last Name" formControlName="last_name" [(ngModel)]="profile.last_name">
          </mat-form-field>

          <div class="col-12 mt-2 mb-2 country-ctrl" [class.error]="form_data.controls['country'].errors && (form_data.controls['country'].dirty || form_data.controls['country'].touched)">
            <mat-checkbox color="primary" formControlName="country" [(ngModel)]="profile.country" required>
              <ng-container i18n>I do not reside in a <a href="/terms#geographical_exclusions" target="_blank"><u>geographical
                  area</u></a> barred from using the ChainEX services</ng-container>
            </mat-checkbox>
            <small>
              <small i18n>
                Please tick the geographical area agreement
              </small>
            </small>
          </div>

          <div class="mt-2 col-12" *ngIf="result_fail">
            <div class="alert alert-danger">
              <p class="mb-0" >{{result_message|i18n}}</p>
            </div>
          </div>

          <div class="col-6 mt-2 mb-2">
            <button mat-raised-button (click)="cancel_click()" color="accent" i18n>CANCEL</button>
          </div>
          <div class="col-6 mt-2 mb-2 text-right">
            <button mat-raised-button (click)="save_click()" type="submit" color="primary" i18n>NEXT</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
