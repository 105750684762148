import { AbstractControl } from '@angular/forms';

export class FundPinValidator {
  static PinRegex: string = '\\d{4,8}'; // between 4 and 8 decimal numbers

  static MatchPin(AC: AbstractControl) {
    const password = AC.get('newFundsPin').value; // to get value in input tag
    const confirmPassword = AC.get('confirmFundsPin').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('confirmFundsPin').setErrors({ MatchPin: true });
    } else {
      // remove only the match pin error
      const errors = AC.get('confirmFundsPin').errors;
      if (errors) {
        delete errors['MatchPin'];
        if (Object.keys(errors).length <= 0) {
          AC.get('confirmFundsPin').setErrors(null);
        }
      }
    }
  }

}
