<mat-icon class="dialog-close" (click)="close_click()">close</mat-icon>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center pb-2">
            <h3>
                <ng-container i18n *ngIf="data.link"> Link Account</ng-container>
                <ng-container i18n *ngIf="!data.link"> Unlink Account</ng-container>
            </h3>

            <div class="mt-2 mb-2 text-left overflowWrap" id="reasonContainer">
                <ng-container i18n *ngIf="data.link">You are about to link the following bank account:</ng-container>
                <ng-container i18n *ngIf="!data.link">
                    To withdraw to another bank account you first need to unlink your current bank account.
                    The following bank account is currently linked:
                </ng-container><br>
                <br><b i18n>Account Number: </b> {{data.accountNumber}}
                <br><b i18n>Bank: </b> {{data.bankName}}
                <br><b i18n>Type: </b> {{data.typeName}}
                <br>
                <ng-container *ngIf="!data.link">
                    <br><b i18n>Do you wish to unlink the existing bank account and add a new account?</b><br>
                </ng-container>
                <br>
            </div>
        </div>

        <div class="col-6 text-left pt-2">
            <button mat-raised-button color="accent" (click)="close_click()" i18n>NO</button>
        </div>

        <div class="col-6 text-right pt-2">
            <button mat-raised-button color="primary" class="mr-2" (click)="ok_click()" i18n>YES</button>
        </div>
    </div>
</div>
