import { Component } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';

import { RouterHelper } from 'src/app/core/router-helper';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

type GetLoginUrl = (router: Router, state: RouterStateSnapshot) => void;

@Component({
  selector: 'app-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.scss']
})
export class WarningBoxComponent {
  getLoginUrl: GetLoginUrl = RouterHelper.getLoginUrl;

  loggedIn: boolean = false;

  constructor(sessionStorage: SessionStorageService) {
      this.loggedIn = (sessionStorage.get('LOGGED_IN') !== '' && sessionStorage.get('TOKEN') !== '');
  }

}
