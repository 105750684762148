<mat-icon class="dialog-close" (click)="dialogRef.close()">close</mat-icon>
<div class="container-fluid">
  <div class="row">
    <div class="table-heading click-thru col-12 col-xl-4">
      <ng-container>{{setting.type|i18n|uppercase}}</ng-container> {{coin}}
    </div>
    <div class="col-12 col-xl-8 text-left text-xl-right table-heading">
      <div class="clickable note-box" (click)="fillAmount()">
        <span class="no-bold" i18n>AVAILABLE : </span>
        <span *ngIf="setting.type === 'buy'"> {{balanceExchange?.balance_available | decimalNumber: decimalSpaces}} </span>
        <span *ngIf="setting.type === 'sell'"> {{balanceCoin?.balance_available | decimalNumber: amountDecimalSpaces}} </span>
        &nbsp;
        <span>{{setting.type === 'buy' ? exchange : coin}}</span>
      </div>
    </div>
  </div>
  <div [formGroup]="orderForm" class="order-form small-text">
    <mat-grid-list cols="8" rowHeight="12px">
      <mat-grid-tile class="field-label" [colspan]="8" [rowspan]="2">
        <ng-container *ngIf="this.setting.type === 'buy'; else sellCoinLabel" i18n>
          SPEND
        </ng-container>
        <ng-template #sellCoinLabel i18n>
          SELL
        </ng-template>
      </mat-grid-tile>

      <mat-grid-tile class="overflow-visible" [colspan]="5" [rowspan]="4">
        <div *ngIf="setting.type == 'sell'; else firstBuyInput" class="w-100">
          <ng-container *ngTemplateOutlet="amountInput; context:{control: orderForm.get('amount')}"></ng-container>
        </div>

        <ng-template #firstBuyInput>
          <ng-container *ngTemplateOutlet="netTotalInput; context:{control: orderForm.get('net_total')}"></ng-container>
        </ng-template>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="4" i18n>
        </mat-grid-tile>

      <mat-grid-tile [colspan]="2" [rowspan]="4" i18n>
        <div *ngIf="this.setting.type === 'sell'; else firstBuySelector" class="w-100">
          <ng-container *ngTemplateOutlet="coinInput; context:{control: orderForm.get('coin')}"></ng-container>
        </div>

        <ng-template #firstBuySelector>
          <ng-container *ngTemplateOutlet="exchangeInput; context:{control: orderForm.get('exchange')}"></ng-container>
        </ng-template>
      </mat-grid-tile>

      <mat-grid-tile class="field-label" [colspan]="8" [rowspan]="2">
        <ng-container *ngIf="this.setting.type === 'buy'; else sellLabel" i18n>
          RECEIVE (Estimated)
        </ng-container>
        <ng-template #sellLabel i18n>
          FOR (Estimated)
        </ng-template>
      </mat-grid-tile>

      <mat-grid-tile class="overflow-visible" [colspan]="5" [rowspan]="4">
        <div *ngIf="setting.type == 'sell'; else secondBuyInput" class="w-100">
          <ng-container *ngTemplateOutlet="netTotalInput; context:{control: orderForm.get('net_total')}"></ng-container>
        </div>

        <ng-template #secondBuyInput>
          <ng-container *ngTemplateOutlet="amountInput; context:{control: orderForm.get('amount')}"></ng-container>
        </ng-template>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="4" i18n>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="2" [rowspan]="4" i18n>
        <div *ngIf="this.setting.type === 'sell'; else secondBuySelector" class="w-100">
          <ng-container *ngTemplateOutlet="exchangeInput; context:{control: orderForm.get('exchange')}"></ng-container>
        </div>

        <ng-template #secondBuySelector>
          <ng-container *ngTemplateOutlet="coinInput; context:{control: orderForm.get('coin')}"></ng-container>
        </ng-template>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="8" [rowspan]="6">
        <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="order()"
          i18n>{{setting.type|uppercase}}</button>
      </mat-grid-tile>

    </mat-grid-list>
  </div>
</div>

<ng-template #amountInput let-control="control">
    <mat-form-field class="w-100">
      <input matInput name="amount" step="0.00000001" type="number" [formControl]="control"
        (change)="amountChange()" [(ngModel)]="amount" />
      <mat-error *ngIf="orderForm.controls.amount.hasError('min') && !(orderForm.controls.amount.hasError('min1') || orderForm.controls.amount.hasError('min0'))">
        <ng-container i18n>Minimum</ng-container> {{minAmount}} {{ coinCode }}
      </mat-error>
      <mat-error *ngIf="orderForm.controls.amount.hasError('min0') && !orderForm.controls.amount.hasError('max')">
        <ng-container i18n>Amount must be more than </ng-container> {{0 | decimalNumber : amountDecimalSpaces}} {{ coinCode }}
      </mat-error>
      <mat-error *ngIf="orderForm.controls.amount.hasError('min1') && !orderForm.controls.amount.hasError('max')">
        <ng-container i18n>Minimum</ng-container> {{setting.type === 'buy' ? this.minAmountBuy : this.minAmountSell | decimalNumber : amountDecimalSpaces}} {{ coinCode }}
      </mat-error>
      <mat-error *ngIf="setting.type === 'buy' && orderForm.controls.amount.hasError('max')">
        <ng-container i18n>Only {{amountAvailable | decimalNumber: amountDecimalSpaces}} {{coin}} is currently available for purchase</ng-container>
      </mat-error>
      <mat-error *ngIf="setting.type === 'sell' && orderForm.controls.amount.hasError('max')">
        <ng-container i18n>Only {{amountAvailable | decimalNumber: amountDecimalSpaces}} {{coin}} can be sold currently</ng-container>
      </mat-error>
      <mat-error *ngIf="orderForm.controls.amount.hasError('max2')">
        <ng-container i18n>Available balance: {{balanceCoin.balance_available | decimalNumber: amountDecimalSpaces}} {{coin}}</ng-container>
      </mat-error>
      <mat-error class="d-block" *ngIf="orderForm.controls.amount.hasError('priceDeviation')">
        Order could not be placed due to price fluctuations, please try again
      </mat-error>
    </mat-form-field>
</ng-template>

<ng-template #netTotalInput let-control="control">
    <mat-form-field class="w-100">
      <input matInput min="0" name="net_total" [max]="undefined" step="0.00000001" type="number" [formControl]="control"
        [(ngModel)]="netTotal" (change)="netTotalChange()" />
        <mat-error *ngIf="orderForm.controls.net_total.hasError('min')">
          <ng-container i18n>Should be higher than {{ totalMinimum | decimalNumber : decimalSpaces}}</ng-container>
      </mat-error>
      <mat-error *ngIf="orderForm.controls.net_total.hasError('min1')">
          <ng-container i18n>Should be higher than {{ totalMinimum | decimalNumber : decimalSpaces}}</ng-container>
      </mat-error>
      <mat-error *ngIf="orderForm.controls.net_total.hasError('min2')">
        <ng-container i18n>Should be higher than {{ totalMinimum | decimalNumber : decimalSpaces}}</ng-container>
      </mat-error>
      <mat-error *ngIf="orderForm.controls.net_total.hasError('max1')">
        <ng-container i18n>Available balance: {{ balanceExchange.balance_available| decimalNumber: decimalSpaces }}</ng-container>
      </mat-error>
      <mat-error *ngIf="orderForm.controls.net_total.hasError('max2')">
        <ng-container i18n>Available balance: {{ balanceCoin.balance_available | decimalNumber: amountDecimalSpaces }}</ng-container>
      </mat-error>
      <mat-error *ngIf="setting.type === 'buy' && orderForm.controls.net_total.hasError('max3') && !orderForm.controls.net_total.hasError('max1')">
        <ng-container i18n>Only {{netTotalAvailable| decimalNumber: decimalSpaces}} {{exchange}} worth of {{coin}} available for purchase</ng-container>
      </mat-error>
      <mat-error *ngIf="setting.type === 'sell' && orderForm.controls.net_total.hasError('max3')">
        <ng-container i18n>Only {{netTotalAvailable| decimalNumber: decimalSpaces}} {{exchange}} worth of {{coin}} can be sold currently</ng-container>
      </mat-error>
    </mat-form-field>
</ng-template>

<ng-template #exchangeInput let-control="control">
    <mat-form-field class="w-100">
      <mat-select name="exchange" (selectionChange)="changeMarket($event, 'exchange')" [(ngModel)]="exchange" [formControl]="control">
        <mat-option *ngFor="let exchange of exchanges" [value]="exchange">
          {{ exchange }}
        </mat-option>
      </mat-select>
    </mat-form-field>
</ng-template>

<ng-template #coinInput let-control="control" >
    <mat-form-field class="w-100">
      <mat-select name="coin" (selectionChange)="changeMarket($event, 'coin')" [(ngModel)]="coin" [formControl]="control">
        <mat-option *ngFor="let coin of coins" [value]="coin">
          {{ coin }}
        </mat-option>
      </mat-select>
    </mat-form-field>
</ng-template>
