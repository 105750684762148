import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LeaderBoardService {

  constructor(private apiService: ApiService) { }

  getLeaderBoard(competitionId: number = 1) {
    return this.apiService.call<any>(`getCompetitionData?id=${competitionId}`).pipe(
      map(_ => _.response === 'success' ? _.data : null)
    );
  }
}
