<div class="container-fluid blueprint-background">
  <div class="row mt-4 clearfix">
    <div class="offset-md-4 offset-lg-4 mt-5 mb-5 h-100 col-md-4 col-lg-4 container fix-gutter">
      <div class="row mat-elevation-z2">

        <ng-container *ngIf="confirm; else cancel"> <!-- confirming withdraw -->
          <form novalidate class="col-12" *ngIf="successful">
            <div class="row">
              <div class="col-12 heading mt-2 mb-2">
                <span class="icon mt-2">
                  <mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful"></mat-icon>
                </span>
                <span class="title">
                  <h2 i18n>Withdrawal Confirmed</h2>
                </span>
              </div>
              <div class="col-12 heading mt-2 mb-4">
                <h4 i18n>The following withdrawal has been confirmed.</h4>
              </div>

              <div class="info col-3 col-lg-3 col-md-4 mt-1 mb-1">
                <b i18n>Wallet:</b>
              </div>
              <div class="info col-9 col-lg-9 col-md-8 col mt-1 mb-1">
                {{code}}
              </div>
              <div class="info col-3 col-lg-3 col-md-4 mt-1 mb-1">
                <b i18n>Amount:</b>
              </div>
              <div class="info col-9 col-lg-9 col-md-8 col mt-1 mb-1">
                {{amount}}
              </div>
              <div class="addresslabel info col-3 col-lg-3 col-md-12 mt-1 mb-1">
                <b>{{code=='ZAR'?'Account:':'Address:'| i18n}}</b>
              </div>
              <div class="addressinfo info col-9 col-lg-9 col-md-12 mt-1 mb-1 overflowWrap" i18n>
                {{code=='ZAR'?account_number:address}}
              </div>
              <div class="info w-100"></div>

              <div class="col-12 heading mt-2 mb-2">
                <a routerLink="/balances">
                  <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE</button>
                </a>
              </div>
            </div>
          </form>

          <form novalidate class="w-100" *ngIf="expired">
            <div class="col-12 heading mt-2 mb-2">
              <span class="icon mt-2px">
                <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning"></mat-icon>
              </span>
              <span class="title">
                <h2 i18n>Confirm Withdrawal</h2>
              </span>
            </div>
            <div class="col-12 heading mt-2 mb-4" [innerHTML]="reason|i18n">
            </div>
            <div class="col-12 heading mt-2 mb-2">
              <a routerLink="/">
                <button mat-raised-button color="primary" class="w-100" i18n>HOME</button>
              </a>
            </div>
          </form>
        </ng-container>

        <ng-template #cancel> <!-- cancelling withdraw -->
          <form novalidate class="col-12" *ngIf="successful">
            <div class="row">
              <div class="col-12 heading mt-2 mb-2">
                <span class="icon mt-2">
                  <mat-icon svgIcon="verified" i18n-matTooltip matTooltip="Successful"></mat-icon>
                </span>
                <span class="title">
                  <h2 i18n>Withdrawal Canceled</h2>
                </span>
              </div>
              <div class="col-12 heading mt-2 mb-4">
                <h4 i18n>The following withdrawal has been canceled.</h4>
              </div>

              <div class="info col-3 col-lg-3 col-md-4 mt-1 mb-1">
                <b i18n>Wallet:</b>
              </div>
              <div class="info col-9 col-lg-9 col-md-8 col mt-1 mb-1">
                {{code}}
              </div>
              <div class="info col-3 col-lg-3 col-md-4 mt-1 mb-1">
                <b i18n>Amount:</b>
              </div>
              <div class="info col-9 col-lg-9 col-md-8 col mt-1 mb-1">
                {{amount}}
              </div>
              <ng-container *ngIf="code != 'ZAR'">
                <div class="addresslabel info col-3 col-lg-3 col-md-12 mt-1 mb-1">
                  <b>Address:</b>
                </div>
                <div class="addressinfo info col-9 col-lg-9 col-md-12 mt-1 mb-1 overflowWrap" i18n>
                  {{address}}
                </div>
              </ng-container>
              <div class="info w-100"></div>

              <div class="col-12 heading mt-2 mb-2">
                <a routerLink="/balances">
                  <button mat-raised-button color="primary" class="w-100" i18n>CONTINUE</button>
                </a>
              </div>
            </div>
          </form>

          <form novalidate class="w-100" *ngIf="expired">
            <div class="col-12 heading mt-2 mb-2">
              <span class="icon mt-2px">
                <mat-icon svgIcon="warning" i18n-matTooltip matTooltip="Warning"></mat-icon>
              </span>
              <span class="title">
                <h2 i18n>Cancel Withdrawal</h2>
              </span>
            </div>
            <div class="col-12 heading mt-2 mb-4">
              This withdrawal cannot be canceled since it has already been confirmed or canceled. Please check your
              unconfirmed withdrawals and try again <br /><br />
              If you are still having issues, please contact <a href="{{getSupportUrl()}}">support</a>.
            </div>
            <div class="col-12 heading mt-2 mb-2">
              <a routerLink="/">
                <button mat-raised-button color="primary" class="w-100" i18n>HOME</button>
              </a>
            </div>
          </form>
        </ng-template>

      </div>
    </div>
  </div>
</div>
