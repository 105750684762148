/*
 * https://javascript.plainenglish.io/-
 * creating-a-splash-screen-in-angular-for-loading-all-the-data-at-startup-b0b91d9d9f93
**/

import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { SplashService } from '../../services/splash.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  // The screen starts with the maximum opacity
  public opacityChange: number = 1;
  public splashTransition: string;
  // First access the splash is visible
  public showSplash: boolean = true;

  readonly ANIMATION_DURATION: number = 1;

  constructor(private splashScreenService: SplashService) { }

  ngOnInit(): void {
    // Somewhere the stop method has been invoked
    this.splashScreenService.subject.pipe(take(1)).subscribe((res: boolean) => {
      this.hideSplashAnimation();
    });
  }

  private hideSplashAnimation(): void {
    // Setting the transition
    this.splashTransition = `opacity ${this.ANIMATION_DURATION}s`;
    this.opacityChange = 0;

    setTimeout(() => {
       // After the transition is ended the showSplash will be hidden
       this.showSplash = !this.showSplash;
    }, 1005);
 }
}
