<div *ngIf="showSplash" class="app-splash-screen" [ngStyle]="{'opacity': opacityChange, 'transition': splashTransition}">

  <div class="app-splash-content">
    <h2>Loading...</h2>

    <div class="loading-bar">
      <div class="progress-bar"></div>
    </div>
  </div>

  <img class="rocket" src="assets/chainex/images/pages/splash/rocket.svg">
  <img class="bottom-bar" src="assets/chainex/images/pages/splash/bottom-bar.svg">
</div>
