import {
  Component, OnInit, OnDestroy, ViewChild,
  Output, EventEmitter, ElementRef, SecurityContext
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import { SubscriptionLike } from 'rxjs';

import { StoreService } from '../core/services/store.service';

import { environment } from '../../environments/environment';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const SITEURL = environment.config.APP_URL;

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent implements OnInit, OnDestroy {

  @Output() close: EventEmitter<null> = new EventEmitter();

  @ViewChild('formWidget') formWidget: ElementRef;
  @ViewChild('osticketForm') osticketForm: ElementRef;

  pageFooter: HTMLElement;
  bottomContainer: HTMLElement;

  parent: any;
  offset: number = 5;
  offsety: number = 25;
  right: boolean = true;
  expanded: boolean = false;
  hidden: boolean = false;

  drag: boolean = false;
  click: boolean = false;

  private subs: SubscriptionLike[] = [];

  clickAwayEvent: any;
  events: any = {};

  showSupportForm: boolean = true;
  supportURL: string = environment.config.SUPPORT_URL;
  supporUrlIframe: SafeResourceUrl;

  constructor(
    private store: StoreService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {}

  onClose() {
    this.close.emit(null);
  }

  ngOnInit() {
    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showSupportForm = +response.data.trollbox_enabled === 1;
      }
    }));

    window.addEventListener('click', this.clickAwayEvent);
    this.supporUrlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.config.SUPPORT_IFRAME);

    setTimeout(() => {
      this.setupEvents();
    }, 100);
  }

  ngOnDestroy() {
    window.removeEventListener('click', this.clickAwayEvent);
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setupEvents() {
    if (!this.formWidget || !this.formWidget.nativeElement || this.osticketForm) {
      return false;
    }
    this.pageFooter = document.getElementsByTagName('footer')[0];
    this.bottomContainer = document.getElementsByClassName('bottom-container')[0] as HTMLElement;

    this.formWidget.nativeElement.addEventListener('mousedown', (e) => {
      this.drag = true;
      document.body.classList.add('noselect');
      let node: any;
      if (e && e.toElement) {
        node = e.toElement;
      } else if (e && e.srcElement) { // Firefox
        node = e.srcElement;
      }
      if (!e.currentTarget || node.nodeName.toLowerCase() !== 'mat-icon') {
        this.click = true;
      }
    });

    return true;
  }

  SetParent(parent: any) {
    this.parent = parent;
    if (!!this.parent && !!this.parent.setChat) {
      this.parent.setChat(this);
    }
  }

  expand() {
    this.expanded = !this.expanded;
  }

  showForm() {
    if (this.hidden) {
      this.hidden = false;
      const interval = setInterval(() => this.setupEvents() && clearInterval(interval), 100);
    }
  }

  popOut() {
    window['debugChat'] = this.store.externalChat = window.open(SITEURL + '/support', 'newwindow', 'width=400,height=600');
    const interval = setInterval(() => {
      if (!this.store.externalChat || this.store.externalChat.closed) {
        clearInterval(interval);
        this.store.externalChat = null;
        this.showForm();
      }
    }, 100);
    this.expanded = true;
    this.expand();
    this.hidden = true;
  }
}
