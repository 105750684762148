import { AutoLogoutDialogComponent } from './auto-logout-dialog/auto-logout-dialog.component';
import { AuthGuard } from './core/services/auth-guard.service';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import {
  VerificationLevelZeroUpdateDialogComponent
} from './profile/verification-level-zero-update-dialog/verification-level-zero-update-dialog.component';
// import { ChatComponent } from './chat/chat.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { MetaGuard } from '@ngx-meta/core';
import { GenericComponent } from './core/responses/generic/generic.component';
import { NotFoundComponent } from './core/responses/not-found/not-found.component';
import { RedirectComponent } from './core/responses/redirect/redirect.component';

import { environment } from '../environments/environment';
import { UnavailableComponent } from './core/responses/unavailable/unavailable.component';
import { AppModule } from './app.module';
import { PreloadService } from './core/services/preload.service';
import { RequestVerificationComponent } from './request-verification/request-verification.component';

const routes: Routes = [
  {
    path: 'autologoutdialog', component: AutoLogoutDialogComponent, data: {
      title: 'Auto Logout'
    }, canActivate: [AuthGuard]
  },
  {
    path: 'verificationlevelzeroupdatedialog', component: VerificationLevelZeroUpdateDialogComponent, data: {
      title: 'Verification Level Zero Update'
    }, canActivate: [AuthGuard]
  },
  // verificationwizard
  {
    path: 'verificationwizard', component: RequestVerificationComponent, data: {
      title: 'User Verification Wizards'
    }, canActivate: [AuthGuard]
  },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), data: {preload: true} },
  { path: '', loadChildren: () =>
    import('./request-confirm/request-confirm.module').then(m => m.RequestConfirmModule), data: {preload: true}
  },
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), data: {preload: true} },
  { path: '', loadChildren: () => import('./balances/balances.module').then(m => m.BalancesModule), data: {
    preload: true}},
  { path: '', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: '', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: '', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: '', loadChildren: () => import('./markets/markets.module').then(m => m.MarketsModule), data: {
    preload: true} },
  { path: '', loadChildren: () => import('./info/info.module').then(m => m.InfoModule), data: {preload: true} },
  // { path: '', loadChildren: () => import('./leader-board/leader-board.module').then(m => m.LeaderBoardModule) },
  // { path: '', loadChildren: () => import('./promotions/pomotions.module').then(m => m.PromotionsModule) },
  { path: 'support', component: ContactSupportComponent,
    data: { meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Contact Support',
      description: 'Tomorrow\'s Trading, Today!'
    } },
   canActivate: [ MetaGuard ]},
  { path: '', loadChildren: () => import('./history/history.module').then(m => m.HistoryModule) },
  { path: 'unavailable', component: UnavailableComponent},
  { path: '', loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule) },
  { path: '403', redirectTo: 'core/responses/forbidden'},
  { path: '404', component: NotFoundComponent},
  { path: 'response/:code', component: GenericComponent},
  { path: 'redirect/:url', component: RedirectComponent},
  { path: 'redirect', component: RedirectComponent},
  { path: '**', redirectTo: '404'}
];

export default routes;
export { RouterModule };
export const routing: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadService,
  onSameUrlNavigation: 'reload',
  anchorScrolling: 'enabled'
});

