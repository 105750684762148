import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

/*Dependancy, Library and Module Imports*/
/*NB: Mind the order of NgModule imports.
Each new module must come after the BrowserModule.*/
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';

import {
  NgModule,
  TRANSLATIONS,
  LOCALE_ID,
  TRANSLATIONS_FORMAT,
  // ErrorHandler
} from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import {RECAPTCHA_LANGUAGE} from 'ng-recaptcha';
import {I18n} from '@ngx-translate/i18n-polyfill';

/*Component Imports*/
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';

import {environment} from './../environments/environment';
import {routing} from './app.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AutoLogoutDialogComponent} from './auto-logout-dialog/auto-logout-dialog.component';
import {
  VerificationLevelZeroUpdateDialogComponent
} from './profile/verification-level-zero-update-dialog/verification-level-zero-update-dialog.component';
import {MarketConfirmationComponent} from './markets/market-confirmation/market-confirmation.component';

import {MetaModule} from '@ngx-meta/core';

import {HttpClientModule} from '@angular/common/http';

import {Angulartics2Module} from 'angulartics2';
import {ConfirmDialogComponent} from './shared/confirm-dialog/confirm-dialog.component';
import { RequestProofOfResidenceComponent } from './request-proof-of-residence/request-proof-of-residence.component';

// States
import { CompetitionsState } from './leader-board/state/competition.state';
import { AddressBookStore } from './balances/address-book/state/address-book.state';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { EarnProductsState } from './balances/currency-management/earn-portal/state/earn-portal.state';
import { RequestVerificationComponent } from './request-verification/request-verification.component';
import { VerificationLocationComponent } from './profile/verification-location/verification-location.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { VerificationPhoneComponent } from './profile/verification-phone/verification-phone.component';
import { BankAddressBookComponent } from './balances/address-book/bank-address-book/bank-address-book.component';
// import {AirbrakeErrorHandler} from './airbrake-client';


declare const require;

@NgModule({
  declarations: [
    AppComponent,
    AutoLogoutDialogComponent,
    VerificationLevelZeroUpdateDialogComponent,
    MarketConfirmationComponent,
    RequestProofOfResidenceComponent,
    RequestVerificationComponent,
    VerificationLocationComponent,
    VerificationPhoneComponent,
    BankAddressBookComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    routing,
    MatListModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,

    UiSwitchModule,
    MatTableModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatProgressBarModule,
    NgxMatSelectSearchModule,
    MatProgressSpinnerModule,

    MetaModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearQueryParams: true,
        clearHash: true,
        excludedRoutes: [
          '.*\/confirm.*', // exclude all confirmation routes
          '.*\/authorise.*', // exclude all authorise routes
          '.*\/.*verify.*' // exclude all verification routes
        ],
      }
    }),
    NgxsModule.forRoot([CompetitionsState, AddressBookStore, EarnProductsState], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
    ScullyLibModule.forRoot()
  ],
  entryComponents: [
    MarketConfirmationComponent,
    ConfirmDialogComponent
  ],
  providers: [
    // {provide: ErrorHandler, useClass: AirbrakeErrorHandler},
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: environment.config.language,
    },
    // format of translations that you use, not needed if using AOT
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'},
    // locale id that you're using (default en-US) ALWAYS NEEDED
    {provide: LOCALE_ID, useValue: environment.config.language},
    // optional, defines how error will be handled
    // {provide: MISSING_TRANSLATION_STRATEGY, useValue: MissingTranslationStrategy.Error},

    {
      provide: TRANSLATIONS,
      useFactory: (locale) => {
        locale = locale || 'en'; // default to english if no locale provided
        return require(
          `raw-loader!../i18n/${environment.config.EXCHANGE_NAME_L}/ts/messages.${locale}.xlf`).default;
      },
      deps: [LOCALE_ID]
    },
    I18n
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  /*
  This call exports all data needed to authenticate and/or register a user
  */

}
