<div class="row top-stats">
    <div class="col-12 p-0">

        <div class="row pr-1">
            <ng-container *ngIf="showPairName">
              <div class="burnx-border-box bx-p-2  col-6 col-sm-6 col-md-4 col-lg-6 pb-sm-2 col-xl-4 pr-2" *ngIf="!showMarket" [ngClass]="[siteName==='chainex'?'col-xlg-2':'']">
                  <div><a class="text-decoration-none" [routerLink]="['/coins/' + marketsService.activeMarket.coinCode.toLowerCase()]"><b class="mb-15px">{{marketsService.activeMarket.marketPair}}</b></a></div>
                  <div class="stat-info">
                  <small>
                      {{marketsService.activeMarket.coinName + '/' + marketsService.activeMarket.exchangeName}}
                  </small>
                  </div>
              </div>
            </ng-container>
            <div class="burnx-border-box bx-p-2 col-6 pt-2 col-sm-6 col-md-4 col-lg-6 pt-sm-0 pb-sm-2 col-xl-4 pl-2 pr-2 text-xl-left text-xlg-center max-stat-width" [ngClass]="[showMarket?'mat-elevation-z2':'', siteName==='chainex'?'col-xlg-2':'']">
                <div class="row">
                    <div class="col d-flex pr-0">
                        <div class="center-stat">
                            <div class="icon">
                                <mat-icon class="mr-1 red-icon" svgIcon="bitcoin" placement="bottom" i18n-matTooltip matTooltip="Trade Price" triggers="hover click"></mat-icon>
                            </div>

                            <div class="stat-market min-width-97">
                                <b class="font-avenir-medium" placement="bottom" i18n-matTooltip matTooltip="{{marketsService.activeMarket.lastPrice}}" triggers="hover click">
                                    {{marketsService.activeMarket.lastPrice}}
                                </b>
                                <div class="stat-info"><small i18n>Last price</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="burnx-border-box bx-p-2 col-6 pt-2 col-sm-6 pt-sm-0 pb-sm-2 col-md-4  col-lg-6 pt-lg-2 col-xl-4 pt-xl-0 pl-2 pr-2 text-xl-left text-xlg-center max-stat-width" [ngClass]="[showMarket?'mat-elevation-z2':'', siteName==='chainex'?'col-xlg-2':'']">
                <div class="row">
                    <div class="col d-flex pr-0">
                        <div class="center-stat">
                            <div class="icon">
                                <mat-icon class="mr-1 red-icon" svgIcon="change" placement="bottom" i18n-matTooltip matTooltip="24h Change" triggers="hover click"></mat-icon>
                            </div>

                            <div class="stat-market min-width-80">
                                <b class="font-avenir-medium"
                                    [ngClass]="{'positive': marketsService.activeMarket && marketsService.activeMarket.change && marketsService.activeMarket.change[0] === '+','negative': marketsService.activeMarket && marketsService.activeMarket.change && marketsService.activeMarket.change[0] === '-'}"
                                    placement="bottom" i18n-matTooltip matTooltip="{{marketsService.activeMarket.change}}%" triggers="hover click">
                                    {{marketsService.activeMarket.change}}%
                                </b>
                                <div class="stat-info"><small i18n>24h Change</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="burnx-border-box bx-p-2 col-6 pt-2 col-sm-6 col-md-4 col-lg-6 col-xl-4 pt-xl-0 pl-2 pr-2 text-xl-left text-xlg-center max-stat-width" [ngClass]="[showMarket?'mat-elevation-z2':'', siteName==='chainex'?'col-xlg-2':'']">
                <div class="row">
                    <div class="col d-flex pr-0">
                        <div class="center-stat">
                            <div class="icon">
                                <mat-icon class="mr-1 red-icon" svgIcon="arrowup" placement="bottom" matTooltip="24h High" triggers="hover click"></mat-icon>
                            </div>
                            <div class="stat-market min-width-97">
                                <b class="font-avenir-medium" placement="bottom" triggers="hover click"
                                   i18n-matTooltip matTooltip="{{marketsService.activeMarket.high}}">
                                    {{marketsService.activeMarket.high}}
                                </b>
                                <div class="stat-info"><small i18n>24h High</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="burnx-border-box bx-p-2 col-6 col-sm-6 col-md-4 col-lg-6 pt-2 col-xl-4 pt-xl-0 pl-2 pr-2 text-xl-left text-xlg-center max-stat-width" [ngClass]="[showMarket?'mat-elevation-z2':'', siteName==='chainex'?'col-xlg-2':'']">
                <div class="row">
                    <div class="col d-flex pr-0">
                        <div class="center-stat">
                            <div class="icon">
                                <mat-icon class="mr-1 red-icon" svgIcon="arrowdown" placement="bottom" i18n-matTooltip matTooltip="24h Low" triggers="hover click"></mat-icon>
                            </div>
                            <div class="stat-market min-width-97">
                                <b class="font-avenir-medium"  placement="bottom" triggers="hover click" i18n-matTooltip
                                    matTooltip="{{marketsService.activeMarket.low}}">
                                    {{marketsService.activeMarket.low}}
                                </b>
                                <div class="stat-info"><small i18n>24h low</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="burnx-border-box bx-p-2 col-6 col-sm-6 col-md-4 col-lg-6 pt-2 col-xl-4 pt-xl-0 pl-2 text-xl-left text-xlg-right max-stat-width text-center" [ngClass]="[showMarket?'mat-elevation-z2':'', siteName==='chainex'?'col-xlg-2':'']">
                <div class="row">
                    <div class="col d-flex pr-0">
                        <div class="center-stat">
                            <div class="icon min-height-40">
                                <mat-icon svgIcon="volume" class="mr-1 red-icon twenty-four-volume" placement="bottom" i18n-matTooltip matTooltip="24h Volume" triggers="hover click"></mat-icon>
                            </div>
                            <div class="stat-market min-width-80 vertical-align-bottom text-left-align">
                                <b class="font-avenir-medium" placement="bottom" triggers="hover click" i18n-matTooltip matTooltip="{{marketsService.activeMarket.volume}}">
                                    {{marketsService.activeMarket.volume}}</b>
                                <div class="stat-info"><small i18n>24h Volume</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="burnx-border-box bx-p-2 d-none d-block-md col-6 col-sm-6 col-md-4 col-lg-6 pt-2 col-xl-4 pt-xl-0 pl-2 text-xl-left text-xlg-right max-stat-width" [ngClass]="[showMarket?'mat-elevation-z2':'', siteName==='chainex'?'col-xlg-2':'']">
                &nbsp;
            </div>
        </div>
    </div>
</div>
