import { Injectable } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable({
  providedIn: 'root'
})

export class Clipboard {

  constructor(
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar,
    private i18n: I18n
  ) {}

  public CopyToClipboard(str: string) {
    this.clipboard.copyFromContent(str);
    this.snackbar.open(this.i18n('Copied') + ': ' + str, this.i18n('Close'), {duration: 2000});
  }
}

export function generateCSV(filename: string, csvRows: any[], csvHeader: string[] = []) {
  try {
    const csvData = (csvHeader.length > 0 ? [csvHeader.join(',')] : []);
    csvRows.forEach(row => {
      csvData.push(row.join(','));
    });

    const newFile = new Blob([csvData.join('\n')], {
      type: 'application/csv;charset=utf-8'
    });

    // Create link and download csv file
    const link = document.createElement('a');
    link.setAttribute('download', filename  + '.csv');
    link.setAttribute('href', window.URL.createObjectURL(newFile));
    document.body.appendChild(link);

    // Click button and remove it
    link.click();
    document.body.removeChild(link);

    return true;
  } catch (error) {
    // handle error
    return false;
  }
}
