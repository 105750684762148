<nav class="navbar navbar-expand-xl navbar-light orange-gradient px-0 px-xl-1">
  <div class="col-12 mx-auto px-0">
    <div class="row mx-0 d-flex align-items-center">
      <div class="col-auto col-sm-auto px-0 d-flex align-items-center">
        <div class="row mx-0 px-1 w-100 d-flex justify-content-between align-items-center">
          <a class="navbar-brand col-auto px-0 mr-0" [routerLink]="[navlink()]">
            <img alt="logo" src="/assets/{{siteName}}/logo_white.svg" class="header-logo logo">
            <img alt="logo" src="{{assetsPath}}cx_logo.svg" class="header-logo d-md-block cx-logo">
          </a>
          <div class="navbar-brand col-auto px-0 mr-0 showbutton">
            <ng-container *ngTemplateOutlet="menuDropdown"></ng-container>
          </div>
        </div>
      </div>
      <div class="col-auto ml-auto h-100 px-0 px-lg-1 d-block d-xl-none">
        <div class="row mx-0 d-flex align-items-center">
            <div class="row mx-0">
              <div class="ml-auto px-0">
                <button class="announcement-menu mx-0 fill-icon" mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon class="bell" svgIcon="bell" i18n-matTooltip matTooltip="Announcements" triggers="hover click" *ngIf="!loggedIn || !unreadAnnouncements"></mat-icon>
                  <mat-icon class="bellactive" svgIcon="bellactive" i18n-matTooltip matTooltip="Announcements" triggers="hover click" *ngIf="loggedIn && unreadAnnouncements"></mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="announcements" [overlapTrigger]="false" (closed)="announcementsRead()">
                  <div *ngFor="let announcement of announcements" class="announcement pt-2 pb-2 pl-3 pr-3" [ngClass]="{'bg-grey': loggedIn && (!announcement.read || announcement.read == '0')}">
                    <div class="announcement-time">{{ announcement.time_formatted }}</div>
                    <div class="announcement-message" [innerHTML]="announcement.message"></div>
                  </div>
                </mat-menu>
                <a mat-icon-button class="clickable theme" *ngIf="!darkTheme" (click)="changeTheme('dark-theme')">
                  <img class="light-bulb" src="{{assetsPath}}light-mode/lightbulb.svg">
                </a>
                <a mat-icon-button class="clickable theme" *ngIf="darkTheme" (click)="changeTheme('light-theme')">
                  <img class="dark-light-bulb" src="{{assetsPath}}dark-mode/lightbulb.svg">
                </a>
              </div>
            </div>
            <button class="d-block px-2 ml-1 navbar-toggler mr-1" type="button" (click)="toggleState()">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
      </div>
      <div class="col-lg-auto px-0 d-xl-flex d-none align-items-center">
        <div class="row mx-0 px-1 w-100 d-flex justify-content-between align-items-center">
        <ng-container *ngIf="loggedIn">
          <div class="d-block d-xl-none">
            <button mat-raised-button color="primary" class="py-1 px-1 ml-0 profile-link">
              {{username | uppercase}}
            </button>
          </div>
        </ng-container>
        </div>
      </div>

      <div class="col-auto collapse navbar-collapse px-1 ml-auto h-100 dropdown-background header-nav" [ngClass]="{ 'show': isIn }">
        <ul class="navbar-nav my-2 ml-auto h-100">
          <li class="nav-item mr-2 col-12 col-lg-auto px-0" [ngClass]="loggedIn ? 'responsive-coins-loggedin' : 'responsive-coins'">
            <home-market-coins></home-market-coins>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/earn"
              (click)="hideNav()">
                <span i18n>Earn</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/maker"
              (click)="hideNav()">
                <span i18n>Maker Program</span>
            </a>
          </li>

          <div class="col-auto px-0 py-2 d-block d-xl-none mobile-coins-container">
            <home-market-coins></home-market-coins>
          </div>

          <ng-container *ngIf="loggedIn">
            <li class="nav-item px-0 mr-2 col-auto">
              <div class="row mx-0 align-items-center">
                <a class="clickable d-flex align-items-center nav-link col-auto"
                (click)="openDialog(0); hideNav(); $event.preventDefault()">
                  <span class="text-center" i18n>Quick Buy</span>
                </a>
              </div>
            </li>
            <li class="nav-item px-0 mr-2 col-auto">
              <div class="row mx-0">
                <a class="clickable d-flex align-items-center nav-link col-auto"
                (click)="openDialog(1); hideNav(); $event.preventDefault()">
                  <span class="text-center" i18n>Quick Sell</span>
                </a>
              </div>
            </li>
          </ng-container>

          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/markets"
              (click)="hideNav()">
                <span i18n>Markets</span>
            </a>
          </li>

          <ng-container *ngIf="loggedIn; else loggedOut">
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" routerLink="/balances" (click)="hideNav()">
                <ng-container i18n>Balances</ng-container>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" routerLink="/history" (click)="hideNav()">
                <ng-container i18n>History</ng-container>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="{{getSupportUrl()}}" target="_blank">
                <ng-container i18n>Support</ng-container>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-block d-xl-none" routerLinkActive="active" routerLink="/profile"
                (click)="hideNav()">
                  <span i18n>Profile</span>
              </a>
            </li>
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link" routerLinkActive="active" routerLink="/settings"
                (click)="hideNav()">
                  <span i18n>Settings</span>
              </a>
            </li>
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link" routerLinkActive="active" routerLink="/refer"
                (click)="hideNav()">
                  <span i18n>Referrals</span>
              </a>
            </li>
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link clickable" routerLinkActive="active" (click)="logout()">
                  <span i18n>Sign Out</span>
              </a>
            </li>
          </ng-container>

          <ng-template #loggedOut>
            <li class="nav-item">
              <a class="nav-link" href="{{getSupportUrl()}}" target="_blank">
                <ng-container i18n>Support</ng-container>
              </a>
            </li>
            <li class="nav-item d-block mr-3 d-xl-none">
              <a class="nav-link" routerLinkActive="active" routerLink="/register" (click)="hideNav()">
                <ng-container i18n>Sign Up</ng-container>
              </a>
            </li>
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link" routerLinkActive="active" [routerLink]="[getLoginUrl(router, null)]"
                (click)="hideNav()">
                <ng-container i18n>Sign In</ng-container>
              </a>
            </li>
          </ng-template>

          <li class="nav-item d-block d-lg-none">
            <span class="mat-icon-button">
              <ng-container *ngIf="pushConnected else notConnected">
                <mat-icon class="wifi-theme mx-0" placement="bottom" i18n-matTooltip matTooltip="Server Connected"
                triggers="hover click">wifi</mat-icon>

              </ng-container>
              <ng-template #notConnected>
                <mat-icon class="sync-theme mx-0" placement="bottom" i18n-matTooltip matTooltip="Connecting to server..."
                          triggers="hover click">sync</mat-icon>
              </ng-template>
            </span>
          </li>

          <li class="d-none d-lg-block nav-item">
            <span class="mat-icon-button">
              <ng-container *ngIf="pushConnected else notConnected1">
                <mat-icon class="wifi-theme" placement="bottom" i18n-matTooltip matTooltip="Server Connected"
                          triggers="hover click">wifi</mat-icon>
              </ng-container>
              <ng-template #notConnected1>
                <mat-icon class="sync-theme" placement="bottom" i18n-matTooltip matTooltip="Connecting to server..."
                          triggers="hover click">sync</mat-icon>
              </ng-template>
            </span>
          </li>
          <li class="d-none d-lg-block nav-item">
            <button class="announcement-menu mx-0 fill-icon" mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon class="bell" svgIcon="bell" i18n-matTooltip matTooltip="Announcements" triggers="hover click" *ngIf="!loggedIn || !unreadAnnouncements"></mat-icon>
              <mat-icon class="bellactive" svgIcon="bellactive" i18n-matTooltip matTooltip="Announcements" triggers="hover click" *ngIf="loggedIn && unreadAnnouncements"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="announcements" [overlapTrigger]="false" (closed)="announcementsRead()">
              <div *ngFor="let announcement of announcements" class="announcement pt-2 pb-2 pl-3 pr-3" [ngClass]="{'bg-grey': loggedIn && (!announcement.read || announcement.read == '0')}">
                <div class="announcement-time">{{ announcement.time_formatted }}</div>
                <div class="announcement-message" [innerHTML]="announcement.message"></div>
              </div>
            </mat-menu>
          </li>
          <li class="nav-item d-none d-xl-block">
            <div class="px-0 ml-0">
              <a mat-icon-button class="clickable theme" *ngIf="!darkTheme" (click)="changeTheme('dark-theme')">
                <img class="light-bulb" src="{{assetsPath}}light-mode/lightbulb.svg">
              </a>
              <a mat-icon-button class="clickable theme" *ngIf="darkTheme" (click)="changeTheme('light-theme')">
                <img class="dark-light-bulb" src="{{assetsPath}}dark-mode/lightbulb.svg">
              </a>
            </div>
          </li>

          <ng-container *ngIf="loggedIn; else loggedOut1">
            <li class="d-none d-xl-flex align-items-center">
              <div class="dropdown">
                <button mat-raised-button color="primary" class="py-1 px-2 ml-1 profile-link drop-button dropdown-toggle">
                  {{username | uppercase}}
                </button>
                <div class="dropdown-content">
                  <a routerLink="/profile" i18n>Profile</a>
                  <a routerLink="/settings" i18n>Settings</a>
                  <a routerLink="/refer" i18n>Referrals</a>
                  <a (click)="logout()" i18n>Sign Out</a>
                </div>
              </div>
            </li>
          </ng-container>

          <ng-template #loggedOut1>
            <li id="head-signup-button" class="nav-item d-none d-xl-block col-auto pl-1 mx-0">
              <a class="clickable mat-raised-button yellow-button nav-link" routerLink="/register" i18n>
                Sign Up
              </a>
            </li>
            <li id="head-signin-button" class="nav-item d-none d-xl-block col-auto px-0 ml-2 mr-0">
              <a class="my-1 d-flex align-items-center" [routerLink]="[getLoginUrl(router, null)]">
                <img src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/sign_in.svg">
                <span class="ml-1 text-center" i18n>Sign In</span>
              </a>
            </li>
          </ng-template>
          <li *ngIf="headerStats" class="d-block d-xl-none">
            <app-menu-stats (menuClose)="toggleState()" [collapsedView]="true"></app-menu-stats>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ng-template #menuDropdown>
    <div id="dropdown">
      <button class="drop-button">
        <img class="d-sm-none d-sm-inline mb-1" alt="drop-button" src="{{assetsPath}}dark-mode/dropdown_icon.png">
      </button>
      <div class="dropdownContent" [ngClass]="darkTheme ? 'dark-dropdown' : 'light-dropdown'">
        <a routerLink="/markets">
          <img src="{{assetsPath}}exchange_icon.png">
          <strong>Exchange</strong><br/>
          Start trading
        </a>

        <a routerLink="/earn">
          <img src="{{assetsPath}}earn_icon.png">
          <strong>Earn</strong><br/>
          Earn interest now
        </a>

        <a routerLink="/coins">
          <img src="{{assetsPath}}coin_icon.png">
          <strong>Coins</strong><br/>
          Our supported coins
        </a>

        <a href="https://blog.chainex.io/" target="_blank">
          <img src="{{assetsPath}}blog_icon.png">
          <strong>Blog</strong><br/>
          Latest blog articles
        </a>

        <a routerLink="/czar">
          <img src="assets/chainex/images/pages/home/czar.png">
          <strong>CZAR</strong><br/>
          ChainEX stablecoin
        </a>
      </div>
    </div>
  </ng-template>
</nav>
<app-menu-stats (menuClose)="toggleState()" *ngIf="headerStats"></app-menu-stats>
