import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MarketsService } from '../markets.service';

interface ItbParams {
  apiKey: string;
  language: string;
  options?: {         // Where are these options actually obtained from?
    loader?: string | boolean
  };
}


@Component({
  selector: 'markets-coin-analytics',
  templateUrl: './coin-analytics.component.html',
  styleUrls: ['./coin-analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoinAnalyticsComponent implements AfterViewInit, OnDestroy {

  @ViewChild('intotheblockWrapper') intotheblockWrapper: ElementRef;
  @ViewChild('intotheblockWidget') intotheblockWidget: ElementRef;

  private destroy$: Subject<void> = new Subject();
  private readonly supportedCoinsITB: string[];
  private paramsITB: ItbParams = {
    apiKey: environment.config.INTO_THE_BLOCK_KEY,
    language: environment.config.language,
    options: {
      loader: false
    }
  };

  private domWatcher$: MutationObserver = new MutationObserver((mutationsList, observer) => {
    // Only really necessary if we're winding down on the subtree modification events,
    //   hence the 'length <= 3' condition.
    if (mutationsList.length <= 3) {
      try {
        const resizeEv = window.document.createEvent('UIEvents');
        (resizeEv as any).initUIEvent('resize');
        window.dispatchEvent(resizeEv);
      } catch (err) {}
    }
  });


  private coinWatcher$: Observable<any> = this.marketService.activeMarketSubject.asObservable().pipe(
    map(data =>
      (Object.prototype.toString.call(data) === '[object Object]') &&
      (typeof data.coinCode === 'string') ?
      data.coinCode :
      ''
    ),
    distinctUntilChanged(),
    tap(coinCode => {

      let containerNode: any;

      if ((coinCode.length > 0) && this.supportedCoinsITB.includes(coinCode)) {

        /**
         * (Re-)Create the widget's container element. Done before the widget's container is cleared.
         * Done before the container content is cleared so as to help towards avoiding timing issues when the coin
         * is changed very quickly (quick-switching between coins): in such cases, the new content should be injected
         * into the DOM element as soon as possible after the previous content has been cleared. If the next coin
         * is already making its way through the pipeline, then it'll clear just before inserting itself
         * (and should then clear the previous selection correctly as well)
         *
        */
        containerNode = document.createElement('div');
        containerNode.setAttribute('data-target', 'itb-widget');
        containerNode.setAttribute('data-token-id', coinCode);
        containerNode.setAttribute('data-type', 'quick-view');
      }

      // Clear any rendered content inside of the widget's container
      //  (either to render new content or because its unsupported)
      const el = this.intotheblockWidget.nativeElement;
      while (el.lastElementChild) {
        el.removeChild(el.lastElementChild);
      }

      if (containerNode !== undefined) {
        // inject the updated container data...
        //  if the initial injected script's load method has completed execution,
        //    then run its initializtion method again to update it to the new coinCode.
        el.appendChild(containerNode);
        window['itbWidgetInit'](this.paramsITB);
      }
    }),
    takeUntil(this.destroy$)
  );


  constructor(
    private marketService: MarketsService
  ) {
    this.supportedCoinsITB = this.marketService.supportedCoinsITB;
  }


  ngAfterViewInit() {
    // Create the script node that will end up populating the container element

    // NB!!! Here be dragons: this is dangerous and should not be encouraged!!
    const scriptNode = document.createElement('script');
    scriptNode.src = environment.config.INTO_THE_BLOCK_URL;
    scriptNode.type = 'text/javascript';
    scriptNode.async = true;
    scriptNode.onload = () => {
      window['itbWidgetInit'](this.paramsITB);
      // Watch for and process coin changes
      this.coinWatcher$.subscribe();
    };
    this.intotheblockWrapper.nativeElement.appendChild(scriptNode);

    /**
     * Watch the widget container for subtree modifications:
     * The ITB script employs its own resize JS watcher/handler...
     *    resizing should be done by container element dimensions, controlled via css only.
     * The problem is that the resize handling sucks (looks to be throttled instead of debounced?),
     *    which means the rendered containers don't are not always sized correctly for the actual dimesions avaiable.
     *    Which in turn causes issues particularly with graph rendering (graphs are rendered extremely small
     *    on mobile devices for example), which fixes itself on any window resize event.
     * So here we watch for subtree modifications on our provided container for the widget in order to try to force
     *    a window resize event once the graphs etc are actually rendered.
    */
    this.domWatcher$.observe(this.intotheblockWidget.nativeElement, {childList: true, subtree: true});
  }


  ngOnDestroy() {
    if (this.domWatcher$) {
      try {
        this.domWatcher$.disconnect();
      } catch (err) {}
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

}
