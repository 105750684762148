<div class="card-container mat-elevation-z2 quick-order-card background-fill">
  <!-- COIN, AVAILABLE, NAME -->
  <div class="row">
    <div class="col-3 coin-code">
      {{ card.coin.code }}
    </div>
    <div class="col-9 balance">
      Available:&nbsp; {{ card.coin.code === 'ZAR' ? (card.available | decimalNumber: 2) : (card.available | decimalNumber: 8: card.coin.code)}}
    </div>
  </div>

  <div class="row mt-0">
    <div class="col">
      <div class="coin-name">
        {{ card.coin.name }}
      </div>
    </div>
  </div>

  <div class="row mt-1">

    <div class="col-7">
      <!-- COIN PRICE -->
      <div class="row">
        <div class="col-8 card-label" i18n>
          {{card.exchange}} Price:
        </div>
        <div class="col-4 card-value right-align">
          {{card.price ? (card.price | decimalNumber: 2) : 'NA'}}
        </div>
      </div>
      <!-- 24H change % / sell row -->
      <div class="row">
        <ng-container *ngIf="!(card.coin.code === 'ZAR' && card.exchange === 'BTC')">
          <div class="col-8 card-label">
            24HR Change:
          </div>
          <div class="col-4 card-value right-align">
            <ng-container *ngIf="card.change === 'NA'; else percentage">NA</ng-container>
            <ng-template #percentage>
              <span [ngClass]="{'positive': card.change[0] === '+', 'negative': card.change[0] === '-'}">{{ card.change }}%</span>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-template #cantPurchase let-msg="msg">
      <span class="disabled">{{ msg }}</span>
      <mat-icon matTooltipClass="verification-info-tooltip" class="verification-info" placement="left" svgIcon="verification-info"
        i18n-matTooltip matTooltip="Please verify your account to trade with this coin" triggers="hover click"></mat-icon>
    </ng-template>

    <!-- Buy Button-->
    <div class="col-5 dialog-links">
      <div class="row">
        <div class="col-12 mw-116">
          <ng-container *ngIf="['ZAR', 'USDT'].indexOf(card.coin.code) !== -1 then fiat; else notZarOrUSDT"></ng-container>
        </div>
      </div>

      <ng-template #fiat>
        <ng-container *ngIf="showVerification; else canBuy">
          <ng-template *ngTemplateOutlet="cantPurchase;context:{msg: 'BUY BITCOIN'}"></ng-template>
        </ng-container>

        <ng-template #canBuy>
          <div class="buy-bitcoin">
            <a class="card-link txt-orange" (click)="quickOrderDialog(card.coin.code, 'buy')" i18n>BUY BITCOIN</a>
          </div>
        </ng-template>
      </ng-template>

      <!-- BUY NOT FIAT -->
      <ng-template #notZarOrUSDT>
        <ng-container *ngIf="showVerification && card.coin.code === 'BTC'; else canPurchase">
          <ng-template *ngTemplateOutlet="cantPurchase;context:{msg: 'BUY'}"></ng-template>
        </ng-container>

        <ng-template #canPurchase>
          <a class="card-link txt-orange" (click)="quickOrderDialog(card.coin.code, 'buy')" i18n>BUY</a>
        </ng-template>
      </ng-template>

      <div class="row" *ngIf="['ZAR', 'USDT'].indexOf(card.coin.code) === -1">
        <div class="col-12 mw-116">
          <ng-container *ngIf="showVerification && card.coin.code === 'BTC'; else canPurchase">
            <ng-template *ngTemplateOutlet="cantPurchase;context:{msg: 'SELL'}"></ng-template>
          </ng-container>
          <ng-template #canPurchase>
            <a class="card-link txt-orange" (click)="quickOrderDialog(card.coin.code, 'sell')" i18n>SELL</a>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-1 mx-0 w-100">
    <!-- Deposit -->
    <div class="text-left px-0" [ngClass]="card.coin.code !== 'ZAR' && card.coin.has_product ? 'col-4' : 'col-6'">
      <span class="disabled card-link" *ngIf="showVerification && card.coin.code == 'USDT'; else canDeposit" i18n-matTooltip matTooltip="Please verify your account to trade with this coin" triggers="hover click">DEPOSIT</span>
      <ng-template #canDeposit>
        <a class="card-link txt-orange" (click)="openCurrencyManagement(card.coin, 0, card.coin_networks)" container="body" i18n-matTooltip matTooltip="Deposit {{card.coin.code}}" i18n>DEPOSIT</a>
      </ng-template>
    </div>
    <!-- Earn -->
    <div class="col text-center px-0" *ngIf="card.coin.code !== 'ZAR' && card.coin.has_product">
      <span class="disabled card-link" *ngIf="showVerification && card.coin.code == 'USDT'; else canEarn" i18n-matTooltip matTooltip="Please verify your account to trade with this coin" triggers="hover click">EARN</span>
      <ng-template #canEarn>
        <a class="card-link txt-orange" (click)="openCurrencyManagement(card.coin, 4)" container="body" i18n-matTooltip matTooltip="Earn {{card.coin.code}}" i18n>EARN</a>
      </ng-template>
    </div>
    <!-- Withdraw -->
    <div  class="text-right px-0" [ngClass]="card.coin.code !== 'ZAR' && card.coin.has_product ? 'col-4 mr-15' : 'col-6'">
      <span class="disabled card-link" *ngIf="showVerification && card.coin.code == 'USDT'; else canWithdraw" i18n-matTooltip matTooltip="Please verify your account to trade with this coin" triggers="hover click">WITHDRAW</span>
      <ng-template #canWithdraw>
        <a class="card-link txt-orange" (click)="openCurrencyManagement(card.coin, 1, card.coin_networks)" container="body" i18n-matTooltip matTooltip="Withdraw {{card.coin.code}}" i18n>WITHDRAW</a>
      </ng-template>
    </div>
  </div>
</div>
