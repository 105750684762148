<div class="container-fluid text-center col-12">
  <div class="row clearfix mx-auto">
    <div class="col-12 mt-2 mb-2 mx-auto">
      <h3 class="text-center" i18n>Auto Logout</h3>
    </div>

    <div class="col-12 mt-2 mb-2 text-center mx-auto">
      <ng-container i18n>You have been inactive for your specified automatic logout period and will be logged out in one minute.</ng-container>
      <br />
      <ng-container i18n>To cancel the logout select the cancel button below.</ng-container>
    </div>
    <div class="column mx-auto">
      <a class="button-width column m-2" mat-raised-button color="accent" (click)="close()" i18n>CANCEL </a>
      <a class="button-width column m-2" mat-raised-button color="primary" (click)="confirmModalLogout()" i18n>LOGOUT NOW</a>
    </div>
  </div>
</div>
