import {
  Component, OnInit, OnDestroy, ViewChild,
  Output, EventEmitter, ElementRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import { SubscriptionLike } from 'rxjs';

import { StoreService } from '../core/services/store.service';
import { MarketsService } from '../markets/markets.service';

import { environment } from '../../environments/environment';
import { EmojiPipe } from '../core/pipe/emoji.pipe';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ResultDialogComponent, IResultDialogData } from '../shared/result-dialog/result-dialog.component';
import {
  ConfirmDialogComponent,
  IConfirmDialogData,
  DialogTypes } from '../shared/confirm-dialog/confirm-dialog.component';
import { take } from 'rxjs/operators';

const SITEURL = environment.config.APP_URL;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<null> = new EventEmitter();

  @ViewChild('chatWidget') chatWidget: ElementRef;
  @ViewChild('emoticon') emoticonButton: MatIcon;
  @ViewChild('messageContainer') messageContainer: ElementRef;

  pageFooter: HTMLElement;
  bottomContainer: HTMLElement;

  parent: any;
  offset: number = 5;
  offsety: number = 5;
  right: boolean = true;
  expanded: boolean = false;
  hidden: boolean = false;

  chatMessages: any[] = [];

  chatMessage: string = '';

  chatDetails: any = {
    username: '',
    status: ''
  };

  username: string;

  usernameAvailable: boolean = false;

  requests: number = 0;

  loading: boolean = false;

  showNotification: boolean = true;

  drag: boolean = false;
  click: boolean = false;
  dragTimeout: any = null;
  clickTimeout: any = null;

  autoScroll: boolean = true;

  lastScrollOffset: number;

  private subs: SubscriptionLike[] = [];

  messageControl: FormControl = new FormControl(['', Validators.required, Validators.maxLength(140)]);
  usernameControl: FormControl = new FormControl(['', Validators.required, Validators.maxLength(40)]);

  emojiMenuOpen: boolean = false;
  clickAwayEvent: any;
  messageUpdater: any;

  emojiPipe: EmojiPipe;

  emojis: any = [':laugh:',
    ':love:',
    ':sad:',
    ':smile:',
    ':thumbsup:',
    ':wink:'];

  events: any = {};

  translatedWords: Object = {
    'Admin': this.i18n('Admin'),
    'Newbie': this.i18n('Newbie'),
    'Novice': this.i18n('Novice'),
    'Expert': this.i18n('Expert'),
    'Master': this.i18n('Master'),
    'Set Username': this.i18n('Set Username'),
    'Username Unavailable': this.i18n('Username Unavailable'),
  };

  showTrollBox: boolean = true;

  constructor(
    private store: StoreService,
    private marketService: MarketsService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private i18n: I18n
  ) { }


  onClose() {
    this.close.emit(null);
  }

  ngOnInit() {
    this.subs.push(this.store.subscribe('chat').subscribe((response) => {
      if (!response.refresh) {
        this.chatMessages = response.data;
      }
      if (!!response.username) {
        this.chatDetails.username = response.username;
      }
      if (!!response.status) {
        this.chatDetails.status = response.status;
      }
      this.emojiPipe = new EmojiPipe(this.sanitizer);
    }));

    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showNotification = !response.data.confirmations ? true : response.data.confirmations === '1';
        this.showTrollBox = +response.data.trollbox_enabled === 1;
      }
      if (!!response.username) {
        this.chatDetails.username = response.username;
      }
      if (!!response.status) {
        this.chatDetails.status = response.status;
      }
    }));

    if (!!this.store.chat_username) {
      this.chatDetails.username = this.store.chat_username;
    }

    if (!!this.store.chat_status) {
      this.chatDetails.status = this.store.chat_status;
    }

    this.clickAwayEvent = (e) => {
      if (this.emojiMenuOpen && e.target.id !== 'emojiIcon') {
        this.emojiMenuOpen = false;
      }
    };
    window.addEventListener('click', this.clickAwayEvent);
    this.messageUpdater = setInterval(() => {
      try {
        this.chatMessages = JSON.parse(JSON.stringify(this.chatMessages));
      } catch (ex) {
      }
    }, 30000);
    this.hidden = !!this.store.externalChat && !this.store.externalChat.closed;
    window.addEventListener('message', (message) => this.onMessage(message));
    if (!!window.opener) {
      window.addEventListener('unload', () => {
        window.opener.postMessage('close');
      });
    }
    const interval = setInterval(() => this.setupEvents() && clearInterval(interval), 100);
  }

  onMessage(message: MessageEvent) {
    if (message.data === 'close') {
      this.store.externalChat = null;
      this.showChat();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.onMessage);
    window.removeEventListener('message', (message) => this.onMessage(message));
    window.removeEventListener('click', this.clickAwayEvent);
    window.removeEventListener('unload', () => {
      window.opener.postMessage('close');
    });

    this.subs.forEach(sub => sub.unsubscribe());

    clearInterval(this.messageUpdater);

    if (!!this.events['mouseup']) {
      document.removeEventListener('mouseup', this.events['mouseup']);
    }

    if (!!this.events['mousemove']) {
      document.removeEventListener('mousemove', this.events['mousemove']);
    }

    if (!!this.events['resize']) {
      document.removeEventListener('resize', this.events['resize']);
    }

    if (!!this.events['scroll']) {
      this.bottomContainer.removeEventListener('scroll', this.events['scroll']);
    }
  }

  SetParent(parent: any) {
    this.parent = parent;
    if (!!this.parent && !!this.parent.setChat) {
      this.parent.setChat(this);
    }
  }

  setupEvents() {
    if (!this.messageContainer || !this.chatWidget || !this.chatWidget.nativeElement) {
      return false;
    }
    this.pageFooter = document.getElementsByTagName('footer')[0];
    this.bottomContainer = document.getElementsByClassName('bottom-container')[0] as HTMLElement;

    this.chatWidget.nativeElement.addEventListener('mousedown', (e) => {
      this.drag = true;
      document.body.classList.add('noselect');
      let node: any;
      if (e && e.toElement) {
        node = e.toElement;
      } else if (e && e.srcElement) { // Firefox
        node = e.srcElement;
      }
      if (!e.currentTarget || node.nodeName.toLowerCase() !== 'mat-icon') {
        this.click = true;
      }
      if (!!this.clickTimeout) {
        clearTimeout(this.clickTimeout);
      }
      if (!!this.dragTimeout) {
        clearTimeout(this.dragTimeout);
      }
      this.dragTimeout = setTimeout(() => { this.drag = false; document.body.classList.remove('noselect'); }, 5000);
      this.clickTimeout = setTimeout(() => { this.click = false; }, 500);
    });

    const container = this.messageContainer.nativeElement;
    if (!!container) {
      container.removeEventListener('scroll', this.events['scroll']);
      this.events['scroll'] = null;
    }
    if (!this.events['scroll']) {
      this.events['scroll'] = (e) => this.scroll();
      container.addEventListener('scroll', this.events['scroll']);
    }

    if (!this.events['mouseup']) {
      this.events['mouseup'] = (e) => this.mouseUp(e);
      document.addEventListener('mouseup', this.events['mouseup']);
    }

    if (!this.events['mousemove']) {
      this.events['mousemove'] = (e) => this.mouseMove(e);
      document.addEventListener('mousemove', this.events['mousemove']);
    }

    if (!this.events['resize']) {
      this.events['resize'] = (e) => this.resize();
      window.addEventListener('resize', this.events['resize']);
    }

    if (!this.events['body-scroll'] && !!this.parent) {
      this.events['body-scroll'] = (e) => this.bodyScroll();
      this.bottomContainer.addEventListener('scroll', this.events['body-scroll']);
      this.bodyScroll();
    }

    return true;
  }

  bodyScroll() {
    if (!this.pageFooter || !this.chatWidget) {
      this.offsety = 5;
      return;
    }
    // find scroll position from bottom: (total height - top of bottom-container) - scroll position
    // At max scroll, position from bottom will be 0
    const height = this.bottomContainer.scrollHeight - this.bottomContainer.offsetHeight -
      this.bottomContainer.scrollTop;

    // if the page footer has scrolled into view from the bottom
    if (height <= this.pageFooter.offsetHeight) {
      this.offsety = (this.pageFooter.offsetHeight - height) + 2;
    } else {
      this.offsety = 5;
    }
  }

  scroll() {
    const container = this.messageContainer.nativeElement;
    if (!!container) {
      this.autoScroll = container.scrollTop === container.scrollHeight - container.clientHeight;
      this.lastScrollOffset = container.scrollTop;
    }
  }

  resize() {
    this.fixOffset();
    this.bodyScroll();
  }

  mouseUp(e: MouseEvent) {
    this.drag = false;
    if (!!this.dragTimeout) {
      clearTimeout(this.dragTimeout);
    }
    if (this.click) {
      if (!!this.clickTimeout) {
        clearTimeout(this.clickTimeout);
      }
      this.dragTimeout = setTimeout(() => { this.drag = false; document.body.classList.remove('noselect'); }, 5000);
      this.expanded = !this.expanded;
      this.click = false;
    }
  }

  mouseMove(e: MouseEvent) {
    if (this.drag) {
      this.offset -= e.movementX;
      this.fixOffset();
      document.body.classList.remove('noselect');
      if (!!this.dragTimeout) {
        clearTimeout(this.dragTimeout);
      }
      this.dragTimeout = setTimeout(() => { this.drag = false; document.body.classList.remove('noselect'); }, 5000);
    }
  }

  fixOffset() {
    if (this.chatWidget) {
      this.offset = this.offset < 5 ? 5 : this.offset;
      const width = this.chatWidget.nativeElement.clientWidth;
      this.offset = this.offset > innerWidth - width - 20 ? innerWidth - width - 20 : this.offset;
    }
  }

  expand() {
    this.expanded = !this.expanded;
    if (this.emojiMenuOpen) {
      this.emojiMenuOpen = false;
    }
  }

  send() {
    this.messageControl.markAsTouched();
    if (this.messageControl.valid && !this.loading) {
      this.loading = true;
      this.autoScroll = true;
      this.marketService.sendChat({ message: this.chatMessage }).pipe(take(1)).subscribe(() => {
        this.loading = false; }, () => {
        this.loading = false; });
      this.chatMessage = '';
    }
  }

  toggleEmojiDrawer() {
    this.emojiMenuOpen = !this.emojiMenuOpen;
  }

  emoticonSelected(emoji: any) {
    if (this.emojiMenuOpen) {
      this.emojiMenuOpen = false;
    }
    if (this.chatMessage.length + emoji.length < 140) {
      this.chatMessage += emoji;
      try {
        document.getElementById('longInput').focus();
      } catch {
      }
      try {
        document.getElementById('usernameInput').focus();
      } catch {
      }
      try {
        document.getElementById('longInput').focus();
      } catch {
      }
    }
  }

  getTitle(message: any) {
    if (message.user_type === '1' &&
        !message.system_message) {
        return this.translatedWords['Admin'];
    }
    switch (message.chat_rank) {
      default:
      case '0':
          return this.translatedWords['Newbie'];
      case '1':
        return this.translatedWords['Novice'];
      case '2':
        return this.translatedWords['Expert'];
      case '3':
        return this.translatedWords['Master'];
      case '99':
        return this.translatedWords['Admin'];
    }
  }

  SetLocation(location: any) {
    if (!location) {
      return;
    }
    if (!!location.right) {
      this.right = location.right;
    }
    if (!!location.offset) {
      this.offset = location.offset;
    }
  }

  GetLocation() {
    const location: any = {};
    location[this.right ? 'right' : 'left'] = this.offset + 'px';
    location['bottom'] = this.offsety + 'px';
    return location;
  }

  reportMessage(message: any) {
    if (this.showNotification) {

      const transformedMessage: any = this.emojiPipe.transform(message.message);

      const dialogData: IConfirmDialogData = {
        username: message.chat_username,
        transformedMessage: transformedMessage.changingThisBreaksApplicationSecurity,
        cancelType: DialogTypes.reportMessage,
      };

      const dialogRef = ConfirmDialogComponent.openDialog(this.dialog, dialogData);

      dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
        if (result.result === 'accept') {
          this.postReport(message);
        }
      });

    } else {
      this.postReport(message);
    }
  }

  postReport(message: any) {
    const data = {
      chatID: message.messageid
    };
    this.marketService.reportMessage(data).pipe(take(1)).subscribe((response) => {
      if (response.response === 'success' && !this.showNotification) {
        // If confirmations are disabled and it was successful then do nothing
        return;
      }
      // If it failed or confirmations are enabled show the response message.
      const dialogResultData: IResultDialogData = {
        title: 'Message Report',
        result: response.response,
        reason: response.reason
      };
      ResultDialogComponent.openDialog(this.dialog, dialogResultData);
    });
  }

  writeMessage(event: any) {
    if (event.keyCode === 13) {
      this.send();
    }
  }

  checkUsername(event: any) {
    if (event.keyCode === 13) {
      this.setUsername();
    } else {
      const data = {
        username: this.username
      };
      this.requests++;
      this.marketService.checkUsernameAvailable(data).pipe(take(1)).subscribe((response) => {
        this.requests--;
        if (response.response === 'success') {
          this.usernameAvailable = response.data === 'available';
        }
      });
    }
  }

  setUsername() {
    this.usernameControl.markAsTouched();
    if (this.requests === 0 && this.usernameAvailable && this.usernameControl.valid && !this.loading) {
      const data = {
        username: this.username
      };
      this.username = '';
      this.loading = true;
      this.store.call<any>('setUsername', data).pipe(take(1)).subscribe((response) => {
        this.loading = false;
        if (response.response === 'success') {
          const dialogData: IResultDialogData = {
            title: 'Confirmation',
            result: 'success',
            reason: 'Username has been set.'
          };
          ResultDialogComponent.openDialog(this.dialog, dialogData);
        }
      });
    }
  }

  getIcon(message: any) {
    if (message.user_type === '1' &&
        !message.system_message) {
        return 'rank_99';
    }
    switch (message.chat_rank) {
      case '0':
        return 'rank_0';
      case '1':
        return 'rank_1';
      case '2':
        return 'rank_2';
      case '3':
        return 'rank_3';
      case '99':
        return 'rank_99';
    }
    return '';
  }

  showChat() {
    if (this.hidden) {
      this.hidden = false;
      const interval = setInterval(() => this.setupEvents() && clearInterval(interval), 100);
    }
  }

  popOut() {
    window['debugChat'] = this.store.externalChat = window.open(SITEURL + '/chat', 'newwindow', 'width=400,height=600');
    const interval = setInterval(() => {
      if (!this.store.externalChat || this.store.externalChat.closed) {
        clearInterval(interval);
        this.store.externalChat = null;
        this.showChat();
      }
    }, 100);
    this.expanded = true;
    this.expand();
    this.hidden = true;
  }

}
