export interface Profilesettings {
  timezone: string;
  autologout: string;
  popupStyle: string;
  popupLocation: string;
  defaultMarket: string;
  defaultCard: string;
  confirm_enabled: number;
  dark_theme: number;
  trollbox_enabled: number;
  hide_domain_prompt?: number;
}

export enum VerificationRequestType {
  Mobile = 0,
  Identity = 1,
  Residence = 2,
  Compliance = 3, // income
  Bank = 4,
  CompliancePending = 5,
  Level2Pending = 6,
  Level2Blocked = 7,
}
