import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-logout-dialog',
  templateUrl: './auto-logout-dialog.component.html',
  styleUrls: ['./auto-logout-dialog.component.scss']
})
export class AutoLogoutDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AutoLogoutDialogComponent>) { }
  logout: boolean = false;
  auto: boolean = false;

  ngOnInit() {
    this.auto = false;
    setTimeout(() => {
      this.auto = true;
      this.confirmModalLogout();
    }, 60 * 1000);
  }

  confirmModalLogout() {
    this.logout = true;
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
