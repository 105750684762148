import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { BalancesService } from 'src/app/balances/balances.service';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';

interface DomainSuggestion {
  name: string;
  price: string;
}

@Component({
  selector: 'app-domain-dialog',
  templateUrl: './domain-dialog.component.html',
  styleUrls: ['./domain-dialog.component.scss']
})
export class DomainDialogComponent implements OnInit {
  suggestionsLoading: boolean = false;
  searchTerm: string;
  domainSuggestions: DomainSuggestion[];

  constructor(
    private i18n: I18n,
    private balancesService: BalancesService,
    private translateService: I18nTranslationService,
    public dialogRef: MatDialogRef<DomainDialogComponent>,
    public snackbar: MatSnackBar,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.searchTerm) {
      this.searchTerm = data.searchTerm;
    }
  }

  ngOnInit(): void {
    this.searchDomain();
  }

  searchDomain() {
    if (this.searchTerm) {
      this.domainSuggestions = [];
      this.suggestionsLoading = true;
      const params = new HttpParams().set('search', this.searchTerm).toString();
      const url = `https://unstoppabledomains.com/api/v2/resellers/chainex/domains/suggestions/free?${params}`;

      this.http.get(url).pipe(take(1)).subscribe((response) => {
        this.suggestionsLoading = false;
        this.domainSuggestions = [];
        const responseArray = response as any[];
        responseArray.forEach(element => {
          this.domainSuggestions.push({name: element.name, price: element.price});
        });
      }, (err) => {
        this.suggestionsLoading = false;
        this.snackbar.open(
          this.i18n('Could not retrieve domain suggestions. Please try again later.'),
          this.i18n('Close'), {duration: 2000}
        );
      });
    }
  }

  requestDomainLink(domain: DomainSuggestion) {
    // check availability
    // if available, link user to unstoppable domains
    const url = `https://unstoppabledomains.com/api/v2/resellers/chainex/domains/${domain.name}`;
    this.http.get(url).pipe(take(1)).subscribe((response) => {
      if (response['availability'] && response['availability']['registered'] === false && response['availability']['protected'] === false) {
        this.balancesService.requestDomainLink(domain.name).pipe(take(1)).subscribe((result) => {
          if (result.link) {
            window.open(result.link, '_blank');
          } else {
            this.snackbar.open(
              this.translateService.translateResponse(this.i18n(result.reason)),
              this.i18n('Close'), { duration: 7500 }
            );
          }
        });
      } else {
        this.snackbar.open(
          this.i18n('This domain is not available. Please try a different domain.'),
          this.i18n('Close'), { duration: 2000 }
        );
      }
    }, (err) => {
      this.suggestionsLoading = false;
      this.snackbar.open(
        this.i18n('Could not retrieve domain suggestions. Please try again later.'),
        this.i18n('Close'), { duration: 2000 }
      );
    });
  }

  close_click(): void {
    this.dialogRef.close({result: 'reject'});
  }

}
