<a [ngClass]="darkTheme ? 'dark-text' : 'light-text'" class="no-decoration"
  [routerLink]="'/markets/' + market.marketPair">
  <div *ngIf="!showChartOnly" class="row px-2">
    <div class="stat-callout py-1 px-2" *ngIf="market.type === 'maxChange'" i18n>
      Highest Change
    </div>
    <div class="stat-callout py-1 px-2" *ngIf="market.type === 'minChange'" i18n>
      Lowest Change
    </div>
    <div class="stat-callout py-1 px-2" *ngIf="market.type === 'maxVol'" i18n>
      Highest Volume
    </div>

    <div class="col-12 px-3 d-flex align-items-center">
      <div class="col-auto pl-0 pr-2">
        <img *ngIf="imageUrl" class="imageholder" [src]="imageUrl" (error)="$event.target.src = '/assets/chainex/images/icons/coins/fallback_coin_icon.svg'" loading="lazy">
      </div>
      <div class="col-8 text-left px-0">
        <span class="market-header">{{market.marketPair}}</span>
      </div>
    </div>

    <div class="price-box row mx-0 my-1 py-1 px-3">
      <div class="col-8 px-0">
        <span class="market-text">{{market.exchangeCode}}
          {{market.lastPrice| decimalNumber: ((market.exchangeDecimals &gt; 4) ? 4 : market.exchangeDecimals)}}</span>
      </div>
      <div class="col-4 px-0 text-right" [ngClass]="strToNumber(market.change) &gt; 0 ? 'green-text':'red-text'">
        <span class="change-text">{{market.change}}%</span>
      </div>
    </div>
  </div>

  <div class="row px-2" [ngClass]="showChartOnly ? 'chart-height' : null">
    <div class="col py-0 chart-wrapper">
      <canvas baseChart [datasets]="market.chartData" [labels]="lineChartLabels" [options]="lineChartOptions"
        [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
        [ngClass]="showChartOnly ? 'limit-chart-size' : 'limit-chart-sizes'"></canvas>
        <!-- TODO: Add dates to this line graph based on ng2-charts documentation: https://valor-software.com/ng2-charts/
        <canvas baseChart [datasets]="market.chartData" [labels]="lineChartLabels" [options]="lineChartOptions"
        [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
        class="w-100 h-100 limit-chart-size"></canvas> -->
    </div>
  </div>

  <div class="row px-2"  *ngIf="!showChartOnly">
    <!-- <div class="col-9 pr-0">
      <div class="col-12 text-left px-0">
        <span class="volume-text orange-text">Volume:</span>
      </div>
      <div class="col-12 text-left px-0 volume-text">
        <span>{{market.volume}} {{market.exchangeCode}}</span>
      </div>
      <div class="col-12 text-left px-0 volume-text">
        <span>({{market.volumeAmount}} {{market.coinCode}})</span>
      </div>
    </div> -->
    <div class="col mt-auto d-flex justify-content-end">
      <div class="mat-primary border-2 time-panel">24h</div>
    </div>
  </div>
</a>
