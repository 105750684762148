export interface BankAddressBooks {
  addresses: BankAddressBook[];
  limits: BankLimits;
}
export interface BankAddressBook {
  name: string;
  bank: string;
  accountNumber: string;
  verified: number;
  trusted: boolean;
  status: number;
  statusLabel: string;
  verifiedLabel: string;
  fastClearingAvailable: boolean;
}

export interface BankLimits {
  unverifiedLimit: number;
  verifiedLimit: number;
  unverifiedRemainingLimit: number;
  verifiedRemainingLimit: number;
}

export interface AddBankAddressBook {
  coin: string;
  name: string;
  bankName: string;
  bankID: number;
  accountNumber: string;
  accountType: number;
  branchCode: string;
  trusted: boolean;
  fastClearingAvailable: boolean;
  password?: string;
  tfa?: string;
  fundPin?: string;
}

export interface RemoveBankAddress {
  coin: string;
  name: string;
  accountNumber: string;
}

export interface BankAddressBookConfirm {
  hash: string;
}

export enum BankAddressStatus {
  Untrusted = 0,
  Trusted = 2,
  Pending = 1
}

export enum BankAddressVerified {
  Pending = 0,
  Success = 1,
  Failed = 2
}

export enum AddressView {
  Normal = 0,
  Add = 1,
  Edit = 2,
  Confirmation = 3,
  ConfirmationTrusted = 4,
  ConfirmRemove = 5
}
