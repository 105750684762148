import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ApiService } from '../core/api/api.service';


interface ResponseAllCoins {
  response: 'success' | 'failure';
  data: {
    id: number,
    code: string,
    name: string,
    network_active: string,
    default_network: string;
    extra_data: string,
    network_block_explorers: string
  }[];
}


export interface CoinData {
  id: number;
  code: string;
  name: string;
  defaultNetwork: string;
  extraData: {
    information: string[],
    website: string,
    blockExplorer: string,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    redditLink: string,
    githubLink: string,
    telegramLink: string,
    youtubeLink: string
    networkBlockExplorers: any[]
  };
}


@Injectable({
    providedIn: 'root'
  })
  export class HistoryService {
    constructor(
      private apiService: ApiService
    ) { }

    setGetParams(data: any) {
      let prams = '?';
      const entries = Object.entries(data);
      for (let i = 0; i < entries.length; i++) {
        if ( prams !== '?' && entries[i][1] !== '') {
          prams += '&';
        }
        if (entries[i][1] !== '') {
          prams += `${entries[i][0]}=${entries[i][1]}`;
        }
      }
      return prams;
    }

    getDepositHistory(data: any) {
      return this.apiService.call<any>(`getMyDepositHistory${this.setGetParams(data)}`);
      /*return new Observable ((observer) => {
        observer.next(
          {
            response : 'success',
          data: [{
            coin: 'WWWW',
            amount: '5.55555555',
            status: 'COMPLETE',
            address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
            time: '2018-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2016-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2017-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2018-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2016-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2017-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2018-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2016-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2017-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2018-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2016-09-12 09:02'
        }, {
          coin: 'WWWW',
          amount: '5.55555555',
          status: 'COMPLETE',
          address: '2N4SnJ2LeSZihwjwbtUSL6SFfFi4SAriuow',
          time: '2017-09-12 09:02'
        }]
          });
      });*/
    }

    getWithdrawalHistory(data: any) {
      return this.apiService.call<any>(`getMyWithdrawalHistory${this.setGetParams(data)}`);
    }

    getMyActiveOrders(data: any) {
      return this.apiService.call<any>(`getMyActiveOrders${this.setGetParams(data)}`);
      /*return new Observable ((observer) => {
        observer.next(
          {
            response : 'success',
          data: [{
            pair: 'WWWW/MMMM',
            amount: '5.55555555',
            nettotal: '8.88888888',
            status: 'COMPLETE',
            action: 'WWWW',
            type: 'Market',
            price: 0.88888888,
            total: 0.88888888,
            fee: 0.55555555,
            placed: '2018-09-12 09:02'
        }, {
            pair: 'WWWW/MMMM',
            amount: '9.99999999',
            nettotal: '8.88888888',
            status: 'COMPLETE',
            action: 'MMMM',
            type: 'Market',
            price: 5.55555555,
            total: 8.88888888,
            fee: 9.99999999,
            placed: '5895-89-58 95:89'
        }, {
          pair: 'WWWW/MMMM',
          amount: '9.99999999',
          nettotal: '8.88888888',
          status: 'COMPLETE',
          action: 'MMMM',
          type: 'Market',
          price: 5.55555555,
          total: 8.88888888,
          fee: 9.99999999,
          placed: '5895-89-58 95:89'
        }, {
          pair: 'WWWW/MMMM',
          amount: '9.99999999',
          nettotal: '8.88888888',
          status: 'COMPLETE',
          action: 'MMMM',
          type: 'Market',
          price: 5.55555555,
          total: 8.88888888,
          fee: 9.99999999,
          placed: '5895-89-58 95:89'
        }, {
          pair: 'WWWW/MMMM',
          amount: '9.99999999',
          nettotal: '8.88888888',
          status: 'COMPLETE',
          action: 'MMMM',
          type: 'Market',
          price: 5.55555555,
          total: 8.88888888,
          fee: 9.99999999,
          placed: '5895-89-58 95:89'
        }, {
          pair: 'WWWW/MMMM',
          amount: '9.99999999',
          nettotal: '8.88888888',
          status: 'COMPLETE',
          action: 'MMMM',
          type: 'Market',
          price: 5.55555555,
          total: 8.88888888,
          fee: 9.99999999,
          placed: '5895-89-58 95:89'
        }, {
          pair: 'WWWW/MMMM',
          amount: '9.99999999',
          nettotal: '8.88888888',
          status: 'COMPLETE',
          action: 'MMMM',
          type: 'Market',
          price: 5.55555555,
          total: 8.88888888,
          fee: 9.99999999,
          placed: '5895-89-58 95:89'
        }, {
          pair: 'WWWW/MMMM',
          amount: '9.99999999',
          nettotal: '8.88888888',
          status: 'COMPLETE',
          action: 'MMMM',
          type: 'Market',
          price: 5.55555555,
          total: 8.88888888,
          fee: 9.99999999,
          placed: '5895-89-58 95:89'
        }]
          });
      });*/
    }

    getMyOrderHistory(data: any) {
      return this.apiService.call<any>(`getMyOrderHistory${this.setGetParams(data)}`);
    }

    getMyTradeHistory(data: any) {
      return this.apiService.call<any>(`getMyTradeHistory${this.setGetParams(data)}`);
    }

  getDAOrders(data: any) {
    return this.apiService.call<any>(`getDAOrders${this.setGetParams(data)}`);
  }

  cancelorder(data: any) {
    return this.apiService.call<any>(`cancelOrder${this.setGetParams(data)}`);
  }

  cancelDAOrder(data: any) {
    return this.apiService.call<any>('cancelDAOrder', data);
  }

  getMarketData() {
    return this.apiService.call<any>('getMarkets');
  }

  getCoins(data: any) {
    return this.apiService.call<any>(`getCoins${this.setGetParams(data)}`);
  }

  getAllCoins(): Observable<CoinData[]> {
    return this.apiService.call<ResponseAllCoins>(`getAllCoins`).pipe(
      map((response: ResponseAllCoins) => {
        if (response.response && (response.response === 'success')) {
          if (Array.isArray(response.data)) {
            const coins = response.data.map(dataItem => {
              let dataItemExtras: any = {};
              try {
                dataItemExtras = JSON.parse(dataItem.extra_data || '{}');
              } catch (err) {
                // nothing much to do here
              }

              let dataItemNetworkBlockExplorers: any = {};
              try {
                dataItemNetworkBlockExplorers = JSON.parse(dataItem.network_block_explorers || '{}');
              } catch (err) {
                // nothing much to do here
              }

              const extraData = {
                information: [],
                website: '',
                blockExplorer: '',
                twitterLink: '',
                facebookLink: '',
                instagramLink: '',
                redditLink: '',
                githubLink: '',
                telegramLink: '',
                youtubeLink: '',
                defaultNetwork: '',
                networkBlockExplorers: []
              };

              if (Object.prototype.toString.call(dataItemExtras) === '[object Object]') {
                extraData.information = typeof dataItemExtras.information === 'string' ?
                  dataItemExtras.information.split(/\n/g) : extraData.information;
                extraData.website = typeof dataItemExtras.website === 'string' ?
                  dataItemExtras.website : extraData.website;
                extraData.blockExplorer = typeof dataItemExtras.blockExplorer === 'string' ?
                  dataItemExtras.blockExplorer : extraData.blockExplorer;
                extraData.twitterLink = typeof dataItemExtras.twitterLink === 'string' ?
                  dataItemExtras.twitterLink : extraData.twitterLink;
                extraData.facebookLink = typeof dataItemExtras.facebookLink === 'string' ?
                  dataItemExtras.facebookLink : extraData.facebookLink;
                extraData.instagramLink = typeof dataItemExtras.instagramLink === 'string' ?
                  dataItemExtras.instagramLink : extraData.instagramLink;
                extraData.redditLink = typeof dataItemExtras.redditLink === 'string' ?
                  dataItemExtras.redditLink : extraData.redditLink;
                extraData.githubLink = typeof dataItemExtras.githubLink === 'string' ?
                  dataItemExtras.githubLink : extraData.githubLink;
                extraData.telegramLink = typeof dataItemExtras.telegramLink === 'string' ?
                  dataItemExtras.telegramLink : extraData.telegramLink;
                extraData.youtubeLink = typeof dataItemExtras.youtubeLink === 'string' ?
                  dataItemExtras.youtubeLink : extraData.youtubeLink;
              }

              const newCoin: CoinData = {
                id: +dataItem.id > 0 ? +dataItem.id : 0,
                code: typeof dataItem.code === 'string' ? dataItem.code : '',
                name: typeof dataItem.name === 'string' ? dataItem.name : '',
                defaultNetwork: typeof dataItem.default_network === 'string' ? dataItem.default_network : '',
                extraData
              };

              if (Object.prototype.toString.call(dataItemNetworkBlockExplorers) === '[object Object]') {
                Object.keys(dataItemNetworkBlockExplorers).forEach(key => {
                  if (dataItemNetworkBlockExplorers[key]) {
                    if (dataItemNetworkBlockExplorers[key].networkName && dataItemNetworkBlockExplorers[key].link) {
                      extraData.networkBlockExplorers.push(
                        {
                          networkName: dataItemNetworkBlockExplorers[key].networkName,
                          link: dataItemNetworkBlockExplorers[key].link,
                          active: dataItemNetworkBlockExplorers[key].active === 1,
                        }
                      );
                    } else {
                      // default block explorer item, will use default_network as name
                      extraData.networkBlockExplorers.push(
                        {
                          networkName: key,
                          link: dataItemNetworkBlockExplorers[key],
                          active: true
                        }
                      );
                    }
                  }
                });

                // if no active network block explorers found, return default
                if (extraData.networkBlockExplorers.length < 1) {
                  extraData.networkBlockExplorers.push(
                    {
                      networkName: 'default',
                      link: dataItemNetworkBlockExplorers['default'],
                      active: true
                    }
                  );
                }
              }

              return newCoin;
            }).filter(coin => coin.id > 0);

            return coins;
          }
        }
        return [] as CoinData[];
      }),
      catchError(() => of([] as CoinData[]))
    );
  }

    cancelOrder(request: any): Observable<any> {
      return this.apiService.call<any>('cancelOrder', request);
    }
}
