import { Observable } from 'rxjs';
import { Settings } from './../core/interfaces/settings';
import { ApiService } from '../core/api/api.service';
import { StoreService } from 'src/app/core/services/store.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private apiService: ApiService,
    private store: StoreService,
  ) { }

  setGetParams(data: any) {
    let prams = '?';
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
      if (i > 0) {
        prams += '&';
      }
      prams += `${entries[i][0]}=${entries[i][1]}`;
    }
    return prams;
  }

  sendOtpToCellPhoneNumber(data: any): Observable<any> {
    return this.apiService.call<any>('sendOtpToCellPhoneNumber', data);
  }

  verifyCellPhoneNumber(data: any): Observable<any> {
    return this.apiService.call<any>('verifyCellPhoneNumber', data);
  }

  get3DLivenessKeys() {
    return this.apiService.call('get3dLivenessKeys');
  }

  get3DSessionToken(data: any) {
    return this.apiService.call<any>('get3dSessionToken', data);
  }

  getTimezoneList() {
    return this.apiService.call('getTimezoneList');
  }

  deactivateAccount() {
    return this.apiService.call('deactivateRequest');
  }

  updateSettings(data: Settings): Observable<Settings> {
    return this.apiService.call<Settings>('updateSetting', data);
  }

  requestVerification(data?: {}) {
    return this.apiService.call<any>('requestVerification', data);
  }

  getCountries(data: any) {
    return this.apiService.call<any>(`getCountries${this.setGetParams(data)}`);
  }

  getLoginHistory(data: any) {
    return this.apiService.call<any>(`getLoginHistory${this.setGetParams(data)}`);
  }

  verifyAddress(data: any) {
    return this.apiService.call<any>('verifyAddress', data);
  }

  checkIDNumber(data: any) {
    return this.apiService.call<any>('checkIDNumber', data);
  }

  checkPassportNumber(data: any) {
    return this.apiService.call<any>('checkPassportNumber', data);
  }

  getVerificationLevel() {
    return this.apiService.call<any>('getVerificationLevel');
  }

  saveUsernames(data: any) {
    return this.apiService.call<any>('saveUsernames', data);
  }

  getAnnouncements() {
    return this.apiService.call<any>('getAnnouncements');
  }

  setAnnouncementsRead() {
    return this.apiService.call<any>('setAnnouncementsRead');
  }

  uploadKYC(data: any) {
    return this.apiService.call<any>('uploadKYC', data);
  }

  getKYCUploads() {
    return this.apiService.call<any>('getKYCUploads');
  }

  getKYCUpload(queryString: string) {
    return this.apiService.callBlob('getKYCUpload' + queryString);
  }

  getComplianceQuestions() {
    return this.apiService.call<any>('getComplianceQuestions');
  }

  saveComplianceAnswers(data: any) {
    return this.apiService.call<any>('saveComplianceAnswers', data);
  }

}
