import { Injectable } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class I18nTranslationService {

  private i18n: I18n;


  constructor(i18n: I18n) {
    this.i18n = i18n;
  }

  // map a shortened API response message to a full, translated message
  translateResponse(response: string, args?: any) {

    if (response != null) {

    // Split any concatenated messages into an array
    let translatedMessage = '';
    let currentMessage;
    const messagesArray = response.split('&');
    let count;

    // Translate all the messages in the array individually and concatenate them together again
    for (count = 0; count < messagesArray.length; count++) {
      currentMessage = messagesArray[count];

      switch (currentMessage) {
        // General Responses
        case 'GENERAL_SOMETHINGWENTWRONG': {
          translatedMessage += this.i18n('Something went wrong.');
          break;
        }
        case 'GENERAL_SOMETHINGWENTWRONGPROMPT': {
          translatedMessage += this.i18n('Something went wrong, please try again.');
          break;
        }
        case 'GENERAL_SOMETHINGWENTWRONGREFRESH': {
          translatedMessage += this.i18n('An unknown error occurred. Please refresh the page and try again.');
          break;
        }
        case 'GENERAL_NOTLOGGEDIN': {
          translatedMessage += this.i18n('Not logged in');
          break;
        }
        case 'GENERAL_NOTLOGGEDIN_UA': {
          translatedMessage += this.i18n('Not logged in');
          break;
        }
        case 'GENERAL_NOTLOGGEDIN_SESSION_EXPIRED': {
          translatedMessage += this.i18n('Your session has ended.');
          break;
        }
        case 'GENERAL_NOTLOGGEDIN_SESSION_DEACTIVATED': {
          translatedMessage += this.i18n('Your account has been deactivated successfully.');
          break;
        }
        case 'GENERAL_NOTLOGGEDIN_IP_CHANGE': {
          translatedMessage += this.i18n('You have been signed out due to IP address change');
          break;
        }
        case 'GENERAL_INCORRECTPASSWORD': {
          translatedMessage += this.i18n('The password entered is incorrect, please try again. ');
          translatedMessage +=
            this.i18n('Please note that your account will be disabled after three unsuccessful attempts.');
          break;
        }
        case 'GENERAL_NOPROFILE': {
          translatedMessage += this.i18n('No profile found, please contact support.');
          break;
        }
        case 'GENERAL_DOMAINSUNAVAILABLE': {
          translatedMessage += this.i18n('This promotion has ended. We apologise for the inconvenience.');
          break;
        }
        case 'GENERAL_DOMAINGENERATED': {
          translatedMessage += this.i18n('A domain link has successfully been generated.');
          break;
        }
        case 'buy': {
          translatedMessage += this.i18n('buy');
          break;
        }
        case 'sell': {
          translatedMessage += this.i18n('sell');
          break;
        }
        // Translatable Phrases
        case 'Confirmation': {
          translatedMessage += this.i18n('Confirmation');
          break;
        }
        case 'Failed': {
          translatedMessage += this.i18n('Failed');
          break;
        }
        case 'Price': {
          translatedMessage += this.i18n('Price');
          break;
        }
        case 'Volume': {
          translatedMessage += this.i18n('Volume');
          break;
        }
        case 'Cancel Order': {
          translatedMessage += this.i18n('Cancel Order');
          break;
        }
        case 'Cancel Withdrawal': {
          translatedMessage += this.i18n('Cancel Withdrawal');
          break;
        }
        case 'Link Account': {
          translatedMessage += this.i18n('Link Account');
          break;
        }
        case 'Unlink Account': {
          translatedMessage += this.i18n('Unlink Account');
          break;
        }
        case 'Report Message': {
          translatedMessage += this.i18n('Report Message');
          break;
        }
        case 'Failed to generate API keys': {
          translatedMessage += this.i18n('Failed to generate API keys');
          break;
        }
        case 'API generated successfully': {
          translatedMessage += this.i18n('API generated successfully');
          break;
        }
        case 'Failed to regenerate API keys': {
          translatedMessage += this.i18n('Failed to regenerate API keys');
          break;
        }
        case 'Username has been set.': {
          translatedMessage += this.i18n('Username has been set.');
          break;
        }
        case 'Success': {
          translatedMessage += this.i18n('Success');
          break;
        }
        case 'Are you sure you wish to delete this key?': {
          translatedMessage += this.i18n('Are you sure you wish to delete this key?');
          break;
        }
        case 'External service will no longer be able to access your APIs once deleted.': {
          translatedMessage += this.i18n('External services will no longer be able to access your APIs once deleted.');
          break;
        }
        case 'Allowed IPs': {
          translatedMessage += this.i18n('Allowed IPs');
          break;
        }
        case 'Permissions': {
          translatedMessage += this.i18n('Permissions');
          break;
        }
        // Login
        case 'LOGIN_LOGINBAN': {
        translatedMessage += this.i18n('You have failed too many logins and have been banned for 24hrs.') +
        ' <a href="' + environment.config.SUPPORT_URL + '">' +
        this.i18n('Please contact support here.') + '</a>';
        break;
        }
        case 'LOGIN_DEACTIVATED': {
          translatedMessage +=
          this.i18n('Your account has been deactivated, would you like to reactivate your account?');
          break;
        }
        case 'ACCOUNT_BLOCKED': {
          translatedMessage +=
          this.i18n('Your account has been blocked temporarily for security reasons. If you have any queries, ') +
          '<a href="' + environment.config.SUPPORT_URL + '">' +
         this.i18n('please contact support here.') +
         '</a>';
          break;
        }
        case 'LOGIN_ACCOUNTDISABLED': {
          translatedMessage += this.i18n(`You have entered your login details incorrectly three times.
           Your account has been blocked as a result.
           To unblock your account please follow the instructions that have been sent to you per email,
           or contact our support team.`) +
           '<a href="' + environment.config.SUPPORT_URL + '"> ' +
          this.i18n('Contact support here.') +
          '</a>';
          break;
        }
        case 'LOGIN_INVALIDLOGIN': {
          translatedMessage += this.i18n(`Invalid login details entered, please try again. Please note
            that your account will be blocked after three unsuccessful login attempts`);
          break;
        }
        case 'LOGIN_INVALID2FA': {
          translatedMessage += this.i18n(`Invalid 2FA Token entered, please try again.
           Please note that your account will be blocked after three unsuccessful login attempts.`);
          break;
        }
        case 'LOGIN_INVALIDPASSWORD': {
          translatedMessage += this.i18n(`Invalid password entered, please try again.
           Please note that your account will be blocked after three unsuccessful login attempts.`);
          break;
        }
        case 'LOGIN_DISABLELOGIN': {
          translatedMessage +=
          this.i18n(`Your account has been blocked as a result of three unsuccessful login attempts.
           Please follow the instructions that have been emailed to you to unblock your account
           or contact our support team.`) +
          ' <a href="' + environment.config.SUPPORT_URL + '">' +
          this.i18n('Contact support here.') +
          '</a>';
          break;
        }
        case 'LOGIN_REQUIRED': {
          translatedMessage += this.i18n('Invalid username / password. Please fill out all fields.');
          break;
        }
        case '2FA_FAILED': {
          translatedMessage += this.i18n('Unable to verify 2 Factor Authentication. Please try again.');
          break;
        }
        case 'EMAIL_EMAILNOTFOUND': {
          translatedMessage += this.i18n('Unable to locate an account with that email address');
          break;
        }
        case 'RESENDACTIVATION_SENT': {
          translatedMessage += this.i18n('Your activation link has been resent.');
          break;
        }
        case 'RESENDACTIVATION_ALREADYVERIFIED': {
          translatedMessage += this.i18n('Your account has already been verified.');
          break;
        }
        case 'ACTIVATEREQUEST_ON': {
          translatedMessage += this.i18n('Email sent successfully.');
          break;
        }
        case 'ACTIVATEREQUEST_WRONGPASSWORD': {
          translatedMessage += this.i18n('Password is wrong');
          break;
        }
        case 'ACTIVATEREQUEST_UNKNOWN': {
          translatedMessage += this.i18n('An unknown error occurred. Please try again.');
          break;
        }
        case 'RECAPTCHA_REQUIRED': {
          translatedMessage += this.i18n('Please tick the \"I\'m not a robot\" box');
          break;
        }
        // Register User
        case 'REGISTER_PASSWORDREQUIREMENTS': {
          translatedMessage += this
          .i18n(`Password should be at least 8 characters long and contain upper and lower case letters,
           numbers and special characters`);
          break;
        }
        case 'REGISTER_INVALIDEMAIL': {
          translatedMessage += this.i18n('Invalid email address');
          break;
        }
        case 'REGISTER_PASSWORDMISMATCH': {
          translatedMessage += this.i18n('The passwords you entered do not match. Please try again.');
          break;
        }
        case 'REGISTER_MISSINGFIELDS': {
          translatedMessage += this.i18n('One or more fields were missing. Please try again');
          break;
        }
        case 'REGISTER_ACCOUNTEXISTS': {
          translatedMessage +=
          this.i18n(`An account for the email address entered already exists. Please use a different address
           or login to your existing account. `) +
          '<a href="/login">' +
          this.i18n('Click here to login.') +
          '</a>';
          break;
        }
        case 'REGISTER_COULDNOTSENDACTIVATION': {
          translatedMessage +=
          this.i18n(`You are successfully registered, but we could not send an activation link to you.
           Please contact the support team to resend the activation email address.`) +
          ' <a href=' + environment.config.SUPPORT_URL + '}>' +
          this.i18n('Contact support here.') +
          '</a>';
          break;
        }
        case 'GENERAL_INCORRECTREFERRALCODE': {
          translatedMessage += this.i18n(`The referral code entered is not associated with an existing ChainEX user.
           Please check that the entered code is correct and try again.`);
          break;
        }
        // Verify Account
        case 'VERIFY_LINKEXPIRED': {
          translatedMessage += this.i18n('This link is expired.');
          break;
        }
        case 'VERIFY_ALREADYCONFIRMED': {
          translatedMessage += this.i18n('Your account has already been confirmed. Please login below');
          break;
        }
        case 'VERIFY_INVALIDDETAILS': {
          translatedMessage += this.i18n('Invalid details provided.');
          break;
        }
        // Unblock Account
        case 'UNBLOCK_LINKEXPIRED': {
          translatedMessage += this.i18n(`This confirmation link has already been used or its request has expired.
           Please try again or `) + '<a href=' + environment.config.SUPPORT_URL + '>' + this.i18n('contact support') +
          '</a>.';
          break;
        }
        case 'UNBLOCK_SUCCESS': {
          translatedMessage += this.i18n('The account has been unblocked.');
          break;
        }
        // Forgot password
        case 'FORGOTPASSWORD_SUCCESS': {
          translatedMessage += this.i18n('We have sent a link to reset your password to your email address.');
          break;
        }
        // Reset password
        case 'RESETPASSWORD_SAMEPASSWORD': {
          translatedMessage += this.i18n('Your new Password must not be the same as your old password.');
          break;
        }
        case 'RESETPASSWORD_INVALIDEMAIL': {
          translatedMessage += this.i18n('Please enter a valid email address');
          break;
        }
        case 'ACCOUNT_UNABLETOVERIFY': {
          translatedMessage += this.i18n('Unable to verify your account details. Please try again.');
          break;
        }
        case 'RESETPASSWORD_PASSWORDMISMATCH': {
          translatedMessage += this.i18n('Password and Confirm password do not match. Please try again. ');
          break;
        }
        case 'RESETPASSWORD_REQUIREDFIELDS': {
          translatedMessage += this.i18n('Please fill in all the required fields.');
          break;
        }
        // First login
        case 'FIRST_LOGIN': {
          translatedMessage += this.i18n('Thank you for signing up, it is a pleasure having you on board!');
          break;
        }
        // First trade
        case 'FIRST_TRADE': {
          translatedMessage += this.i18n('Well done on performing your first trade! <br />May it be the first of many more to come.');
          break;
        }
        // Transaction History
        case 'CANCELWITHDRAW_NOWITHDRAWAL': {
          translatedMessage +=
          this.i18n('The withdrawal does not exist or has already been confirmed, please try again.');
          break;
        }
        case 'CANCELWITHDRAW_INSUFFICIENTBALANCE': {
          translatedMessage += this.i18n(
            'You do not have enough pending withdrawal balance to cover this cancellation, please contact suport.');
          break;
        }
        case 'CANCELWITHDRAW_SUCCESS': {
          translatedMessage += this.i18n(
            'The pending withdrawal has successfully been cancelled and the balance made available again.');
          break;
        }
        case 'CANCELWITHDRAW_ERROR': {
          translatedMessage += this.i18n('There was an error cancelling the withdrawal, please try again.');
          break;
        }
        case 'Are you sure you would like to cancel this Order?': {
          translatedMessage += this.i18n('Are you sure you would like to cancel this Order?');
          break;
        }
        case 'CANCELORDER_NOTFOUND': {
          translatedMessage += this.i18n('Order could not be found.');
          break;
        }
        case 'CANCELORDER_SUCCESS': {
          translatedMessage += this.i18n(
          `The order has been scheduled for cancellation,
           provided it has not been matched with another order before processing.`);
          break;
        }
        case 'CANCELORDER_ERROR': {
          translatedMessage += this.i18n('There was an error in cancelling the order, please try again.');
          break;
        }
        case 'CANCELORDER_OWNERSHIPERROR': {
          translatedMessage += this.i18n('This order does not belong to you.');
          break;
        }
        // Deposit
        case 'REQUESTINVOICE_FAILED': {
          translatedMessage += this.i18n('Could not successfully generate the invoice, please try again.');
          break;
        }
        case 'REQUESTNEWADDRESS_FAILED': {
          translatedMessage += this.i18n('Could not successfully generate a deposit address, please try again.');
          break;
        }
        case 'REQUESTDEPOSIT_AMOUNTMORETHANZERO': {
          translatedMessage += this.i18n('The deposit amount must be greater than zero.');
          break;
        }
        // Withdrawal
        case 'REQUESTWITHDRAWAL_2FAREQUIRED': {
          translatedMessage += this.i18n('You must enable two factor authentication before requesting a withdrawal.');
          break;
        }
        case 'REQUESTWITHDRAWAL_SECURITYREQUIRED': {
          translatedMessage += this.i18n('You must enable two factor authentication or your Fund PIN before requesting a withdrawal.');
          break;
        }
        case 'REQUESTWITHDRAWAL_FUNDPINREQUIRED': {
          translatedMessage += this.i18n('You must input your Fund PIN before requesting a withdrawal.');
          break;
        }
        case 'REQUESTWITHDRAWAL_FUNDPINPENDING': {
          translatedMessage += this.i18n('Please confirm your new Fund PIN before requesting a withdrawal.');
          break;
        }
        case 'REQUESTWITHDRAWAL_FUNDPINERROR': {
          translatedMessage += this.i18n('Incorrect Fund PIN. Please try again.');
          break;
        }
        case 'REQUESTWITHDRAWAL_AMOUNTMORETHANZERO': {
          translatedMessage += this.i18n('The withdrawal amount must be greater than zero.');
          break;
        }
        case 'REQUESTWITHDRAWAL_WITHDRAWALSTIME': {
          translatedMessage += this.i18n('Your withdrawals are disabled temporarily. Confirm your new withdrawal settings to continue.');
          break;
        }
        case 'REQUESTWITHDRAWAL_WITHDRAWALSDISABLED': {
          translatedMessage += this.i18n('Your withdrawals have been disabled. You can enable withdrawals from your settings page.');
          break;
        }
        case 'REQUESTWITHDRAWAL_SETTINGTIME': {
          translatedMessage += this.i18n('Withdrawals from this wallet are disabled temporarily. Confirm your new withdrawal settings to continue.');
          break;
        }
        case 'REQUESTWITHDRAWAL_SETTINGDISABLED': {
          translatedMessage += this.i18n('Withdrawals from this wallet are disabled. You can enable withdrawals from your settings page.');
          break;
        }
        case 'REQUESTWITHDRAWAL_SUCCESS': {
          translatedMessage += this.i18n(
          'Your withdrawal request has now been added. Please confirm by email and we will then process it.');
          break;
        }
        case 'REQUESTWITHDRAWAL_SUCCESS_TRUSTED': {
          translatedMessage += this.i18n(
          'Your withdrawal request to a trusted account has been added and will be processed shortly.');
          break;
        }
        case 'REQUESTWITHDRAWAL_NOBANKACCOUNT': {
          translatedMessage += this.i18n('No linked bank account found.');
          break;
        }
        case 'BANKLINK_STARTSUCCESS': {
          translatedMessage += this.i18n(`Thank you for registering your bank account,
           the details received will now be verified in order to ensure that your withdrawals are secured.
           We will notify you via email of the verification processes results within 48 hours.`);
          translatedMessage += '<br /><br />';
          translatedMessage += this.i18n(`Please note that you are only able to process withdrawals
           of up to R15 000 without a verified bank account regardless of your ChainEX account verification level.
           To reach the maximum withdrawal limit your bank account needs to be successfully verified
           and your ChainEX account will need to be verified up to level 2.`);
          break;
        }
        case 'BANKLINK_ENDSUCCESS': {
          translatedMessage += this.i18n(
          `Thank you for registering your bank account, you are now entitled to withdraw a total of R15 000.
           To increase your withdrawal limit and secure your account, please complete the verification level
           2 process. Once you have passed level 2 verification your entered bank account will be automatically
           sent for verification and you will receive the results per email.`);
          break;
        }
        case 'BANKLINK_ACCOUNTUSED': {
          translatedMessage += this.i18n('Bank account already exists for another user');
          break;
        }
        case 'BANKLINK_LOADFAILED': {
          translatedMessage += this.i18n('Cannot load bank account');
          break;
        }
        case 'BANKLINK_UNLINKED': {
          translatedMessage += this.i18n('Your account has been unlinked');
          break;
        }
        // CHAT
        case 'CHAT_REPORTINVALIDID': {
          translatedMessage += this.i18n('Please provide a valid chat ID.');
          break;
        }
        case 'CHAT_REPORTSUCCESS': {
          translatedMessage += this.i18n('The message has been reported.');
          break;
        }
        case 'CHAT_REPORTFAILED_ALREADY': {
          translatedMessage += this.i18n('You have already reported this message.');
          break;
        }
        // CREATE ORDERS
        case 'CREATEORDER_PRICE_CHECK': {
          translatedMessage += this.i18n(`To ensure realistic prices on our markets, this order cannot be placed.
            Please raise the price of your order or make use of `) +
            '<a href="' + environment.config.SUPPORT_URL +
            '/kb/functionality/what-are-stop-stop-limit-orders-and-how-do-they-work" target="_blank">' +
            this.i18n('Stop Limit Orders') + '</a>' +
            this.i18n(' to schedule the placement of an order at that price.');
            break;
        }
        case 'CREATEORDER_MARKETREQUIRED': {
          translatedMessage += this.i18n('Please provide a market.');
          break;
        }
        case 'CREATEORDER_INVALIDTYPE': {
          translatedMessage += this.i18n('Invalid order type');
          break;
        }
        case 'CREATEORDER_AMOUNTMORETHANZERO': {
          translatedMessage += this.i18n('Amount has to be greater than 0.');
          break;
        }
        case 'CREATEORDER_PRICEMORETHANZERO': {
          translatedMessage += this.i18n('Price has to be greater than 0.');
          break;
        }
        case 'CREATEORDER_PRICEINCORRECTDECIMAL': {
          translatedMessage += this.i18n('The price amount must have the correct amount of decimals.');
          break;
        }
        case 'CREATEORDER_NETMORETHANZERO': {
          translatedMessage += this.i18n('Order net total must be above the minimum single trade amount.');
          break;
        }
        case 'CREATEORDER_TOTALMORETHANZERO': {
          translatedMessage += this.i18n('Order total must be above the minimum single trade amount.');
          break;
        }
        case 'CREATEORDER_NOTENOUGHBALANCE': {
          translatedMessage += this.i18n('There is not enough available balance to add this order.');
          break;
        }
        case 'CREATEORDER_QUICK': {
          translatedMessage += this.i18n('You are attempting to add too many orders in too short a time period.');
          break;
        }
        case 'CREATEORDER_TOOMANY': {
          translatedMessage += this.i18n(`You have too many orders open in this market,
            please cancel some before placing new orders.`);
          break;
        }
        case 'CREATEORDER_TOOMANYSAMEAMOUNT': {
          translatedMessage += this.i18n(`You have too many orders open at the same amount or total,
            please combine some orders.`);
          break;
        }
        case 'CREATEORDER_TOOMANYSAMEPRICE': {
          translatedMessage += this.i18n(`You have too many orders open at a similar price,
            please combine some orders.`);
          break;
        }
        case 'CREATEORDER_ERROR': {
          translatedMessage += this.i18n('There was an error adding this order, please try again.');
          break;
        }
        case 'CREATEORDER_SUCCESS': {
          translatedMessage += this.i18n('The order has successfully gone to market.');
          break;
        }
        case 'CREATEORDER_IEO': {
          translatedMessage += this.i18n('This coin is only available for initial exchange offering.');
          break;
        }
        case 'CREATEORDER_NOTENOUGH': {
          translatedMessage += this.i18n(`Amount requested not available in order book,
            please check the values entered and try again.`);
          break;
        }
        case 'CREATEORDER_PRICEDEVIATION': {
          translatedMessage += this.i18n(`We are currently unable to place market orders for the
           specified amount due to price fluctuations. Please try a different amount or make use of
           limit orders until the market’s prices stabilize.`);
          break;
        }
        // Create DA Order
        case 'CREATEDAORDER_A_SUCCESS': {
          translatedMessage += this.i18n('An Accumulation order has successfully been created.');
          break;
        }
        case 'CREATEDAORDER_D_SUCCESS': {
          translatedMessage += this.i18n('A Distribution order has successfully been created.');
          break;
        }
        case 'CREATEDAORDER_ERROR': {
          translatedMessage += this.i18n('Something went wrong trying to add an Accumulation' +
            ' / Distribution Order, please try again.');
          break;
        }
        case 'CREATEDAORDER_INVALIDPRICECHANGETYPE': {
          translatedMessage += this.i18n('The order must have a price change type of either 0 (BELOW) or 1 (ABOVE).');
          break;
        }
        case 'CREATEDAORDER_INVALIDDELAYBETWEENORDERS': {
          translatedMessage += this.i18n('The delay between order placement should be between 10 and 3600 seconds.');
          break;
        }
        case 'CREATEDAORDER_ORDERAMOUNTBELOWZERO': {
          translatedMessage += this.i18n('The order amount parameter should be greater than 0.001.');
          break;
        }
        case 'CREATEDAORDER_ORDERPRICEBELOWZERO': {
          translatedMessage += this.i18n('The order price parameter should be greater than 0.001.');
          break;
        }
        case 'CREATEDAORDER_ALREADYHASORDERFORMARKET': {
          translatedMessage += this.i18n('A user may only have one active Accumulation or' +
            'Distribution Order per market.');
          break;
        }
        case 'CANCELDAORDER_NOORDERFORMARKET': {
          translatedMessage += this.i18n('The user has no active Accumulation or ' +
            'Distribution Order for the current market.');
          break;
        }
        case 'CANCELDAORDER_INVALIDCANCELTYPE': {
          translatedMessage += this.i18n('The cancellation of an order must have reason type ' +
            'of either 0 (MANUAL), 1 (BALANCE), or 2 (ORDER_PRICE).');
          break;
        }
        case 'CANCELDAORDER_ERROR': {
          translatedMessage += this.i18n('Something went wrong trying to cancel an Accumulation' +
          ' / Distribution Order, please try again.');
          break;
        }
        case 'CANCELDAORDER_A_SUCCESS': {
          translatedMessage += this.i18n('The Accumulation order has successfully been cancelled.');
          break;
        }
        case 'CANCELDAORDER_D_SUCCESS': {
          translatedMessage += this.i18n('The Distribution order has successfully been cancelled.');
          break;
        }
        // DA Order
        case 'FETCHDAORDER_ERROR': {
          translatedMessage += this.i18n('Something went wrong trying to fetch an Accumulation' +
            ' / Distribution Orders, please try again.');
          break;
        }
        // Display orders
        case 'ORDER_LOADINGORDER': {
          translatedMessage += this.i18n('Loading Orders');
          break;
        }
        case 'ORDER_NOORDERS': {
          translatedMessage += this.i18n('No Orders');
          break;
        }
        // Reset Password
        case 'RESETPASSWORD_WRONGEMAIL': {
          translatedMessage += this.i18n(`No account exists for the specified email address,
           please check the email address entered and try again`);
          break;
        }
        // Settings
        case 'DEACTIVATE_EMAILSENT': {
          translatedMessage += this.i18n('Please confirm your deactivation by following the steps emailed to you.');
          break;
        }
        case 'DEACTIVATE_BLOCKED': {
          translatedMessage +=
          this.i18n(`Your account has been disabled as a result of three unsuccessful withdrawal attempts.
           To unblock your account please follow the instructions that have been emailed to you.`);
          break;
        }
        case 'SETTINGS_CELLPHONENOTVERIFIED': {
          translatedMessage +=
            this.i18n(`A verified cellphone number is not available for your account. Please contact support instead.`);
          break;
        }
        case 'SETTINGS_UPDATED': {
          translatedMessage += this.i18n('Your settings have been updated.');
          break;
        }
        case 'SETTINGS_UPDATEFAILED': {
          translatedMessage += this.i18n('Unable to update your settings. Please try again.');
          break;
        }
        case 'MARKETING_SETTINGS_UPDATED': {
          translatedMessage += this.i18n('Your settings have been updated.');
          break;
        }
        case 'MARKETING_SETTINGS_UPDATEFAILED': {
          translatedMessage += this.i18n('Unable to update your settings. Please try again.');
          break;
        }
        case 'WITHDRAW_SETTINGS_UPDATED': {
          translatedMessage += this.i18n('Please confirm your withdrawal settings via email');
          break;
        }
        // Two-Factor Authentication
        case '2FA_ALREADYENABLED': {
          translatedMessage += this.i18n('2 Factor Authentication is already enabled for this account');
          break;
        }
        case '2FA_NOTENABLED': {
          translatedMessage += this.i18n('2 Factor Authentication is not enabled for this account');
          break;
        }
        case '2FA_DISABLESUCCESS': {
          translatedMessage += this.i18n('2 Factor Authentication successfully disabled');
          break;
        }
        case '2FA_DISABLEFAIL': {
          translatedMessage += this.i18n('Unable to disable 2 Factor Authentication. Please try again.');
          break;
        }
        // Verify location
        case 'VERIFYADDRESS_BARREDCOUNTRY': {
          translatedMessage += this.i18n(`Unfortunately we cannot allow trading in digital currency in your country
           of birth in terms of legal advice we have received. Your account can therefore not be verified.
           We apologize for the inconvenience.`);
          break;
        }
        case 'VERIFYRESIDENCE_BARREDCOUNTRY': {
          translatedMessage += this.i18n(`Unfortunately we cannot allow trading in digital currency in your country
           of residence in terms of legal advice we have received. Your account can therefore not be verified.
           We apologize for the inconvenience.`);
          break;
        }
        case 'VERIFYADDRESS_COUNTRYREQUIRED': {
          translatedMessage += this.i18n('Please provide your Country of Birth.');
          break;
        }
        case 'VERIFYRESIDENCE_COUNTRYREQUIRED': {
          translatedMessage += this.i18n('Please provide your Country of Residence.');
          break;
        }
        case 'VERIFYADDRESS_SUCCESS': {
          translatedMessage += this.i18n('Profile successfully updated.');
          break;
        }
        case 'VERIFYADDRESS_FAILED': {
          translatedMessage += this.i18n('Could not update profile data. Please try again.');
          break;
        }
        case 'VERIFYADDRESS_ACCOUNTNONEXISTANT': {
          translatedMessage += this.i18n('This account does not exist.');
          break;
        }
        case 'VERIFYADDRESS_ALREADYVERIFIED': {
          translatedMessage += this.i18n('This account has already been verified.');
          break;
        }
        case 'VERIFYADDRESS_IDREQUIRED': {
          translatedMessage += this.i18n('Another account with the same ID Number has already been verified. Please contact support');
          break;
        }
        case 'VERIFYADDRESS_IDALREADYUSED': {
          translatedMessage += this.i18n('Another account with the same ID Number has already been verified. Please contact support');
          break;
        }
        case 'VERIFYADDRESS_DOBREQUIRED': {
          translatedMessage += this.i18n('Please provide your Date of Birth.');
          break;
        }
        case 'VERIFYADDRESS_UNKNOWNCOUNTRY': {
          translatedMessage += this.i18n('Could not resolve Country ID');
          break;
        }
        case 'VERIFYADDRESS_ATTEMPTSEXCEEDED': {
          translatedMessage += this.i18n('Number of verification attempts have been exceeded');
          break;
        }
        // file retrieval and upload
        case 'FILES_RETRIEVAL_FAILED': {
          translatedMessage += this.i18n('Could not retrieve uploaded verification documents');
          break;
        }
        case 'FILE_UPLOAD_FAILED': {
          translatedMessage += this.i18n('File was not uploaded succesfully. Please try again or contact support.');
          break;
        }
        case 'KYC_ERROR_NUM_FILES': {
          translatedMessage += this.i18n('Please provide at least one document to upload');
          break;
        }
        // phone verification
        case 'SENDOTP_SMSSENT': {
          translatedMessage += this.i18n(`Verification SMS sent, please be patient while it is being delivered
            (up to a minute) and enter the verification code received.`);
          break;
        }
        case 'SENDOTP_SMSRESENT': {
          translatedMessage +=
          this.i18n(`Verification SMS resent successfully, please be patient while it is being delivered
            (up to a minute) and enter the verification code received.`);
          break;
        }
        case 'SENDOTP_MISSINGPARAMETERS': {
          translatedMessage += this.i18n('Missing parameters');
          break;
        }
         case 'SENDOTP_ATTEMPTLIMIT': {
          translatedMessage += this.i18n(`Please note that you have entered your OTP incorrectly three times and
           have been blocked from this action as a result. Please contact support`) +
            ' <a href="' + environment.config.SUPPORT_URL + '">' +
            this.i18n('here') +
            '</a> for assistance.';
          break;
        }
        case 'SENDOTP_NUMBERUSED': {
          translatedMessage +=
          this.i18n(`The mobile number you have entered is already associated with an existing ChainEX account,
           please enter a unique number.  Please contact support should you have any queries.`) +
          ' <a target=_blank href="' + environment.config.SUPPORT_URL + '">' +
          this.i18n('Contact support here.') +
          '</a';
          break;
        }
        case 'SENDOTP_SAMENUMBER': {
          translatedMessage += this.i18n('The new cellphone number cannot be the same as the old one.');
          break;
        }
        case 'VERIFYCELL_CODEINCORRECT': {
          translatedMessage += this.i18n(`The verification code entered is incorrect.
           Please retry or resend a code using the "Resend" button.`);
          break;
        }
        case 'VERIFYCELL_CODEINCORRECT_SUPPORT': {
          translatedMessage += this.i18n(`The verification code entered is incorrect.
           Please retry or contact support.`);
          break;
        }
        case 'VERIFYCELL_CODEEXPIRED': {
          translatedMessage += this.i18n(`The OTP entered has expired, please wait for the count down above to
          reach 0 and resend an SMS using the button above and try again`);
          break;
        }
        // referral
        case 'REFERRAL_INVALIDEMAIL': {
          translatedMessage += this.i18n(`There was a problem with the email address you used.
           Please try another email.`);
          break;
        }
        case 'REFERRAL_ACCOUNTUSED': {
          translatedMessage += this.i18n('The user already has an account');
          break;
        }
        case 'REFERRAL_LIMITREACHED': {
          translatedMessage += this.i18n('Your daily referral send limit has been reached');
          break;
        }
        case 'REFERRAL_INVITATIONSENT': {
          translatedMessage += this.i18n('The invitation has been sent');
          break;
        }
        case 'NO_ID': {
          translatedMessage += this.i18n('No auto trade ID has been selected');
          break;
        }
        case 'NO_COIN': {
          translatedMessage += this.i18n('No coin has been selected');
          break;
        }
        case 'NO_MARKET': {
          translatedMessage += this.i18n('No market has been selected');
          break;
        }
        case 'NO_TYPE': {
          translatedMessage += this.i18n('No type has been selected');
          break;
        }
        case 'ADD_AUTOTRADE_FAILED': {
          translatedMessage += this.i18n('Adding auto trade failed');
          break;
        }
        case 'DELETE_AUTOTRADE_FAILED': {
          translatedMessage += this.i18n('Deleting auto trade failed');
          break;
        }
        case 'COPYRIGHT_STRING': {
          let sitename: string = '';
          if (environment.config.EXCHANGE_NAME_L === 'chainex') {
            sitename = 'ChainEX';
          } else {
            sitename = 'BurnX';
          }
          translatedMessage += this.i18n('Copyright © {{0}} {{1}}, All rights reserved.')
          .replace('{{0}}', (new Date()).getUTCFullYear().toString())
          .replace('{{1}}', sitename);
          break;
        }
        case 'NAME_ALREADY_ADDED': {
          translatedMessage += this.i18n('This name has already been used');
          break;
        }
        case 'ADDRESS_ALREADY_ADDED': {
          translatedMessage += this.i18n('This address has already been added');
          break;
        }
        case 'ADDRESS_NOT_FOUND': {
          translatedMessage += this.i18n('Unable to remove address');
          break;
        }
        case 'ADDRESS_NETWORK_INVALID': {
          translatedMessage += this.i18n('Invalid network specified for this coin. Please try again.');
          break;
        }
        case 'ADDRESS_REMOVED': {
          translatedMessage += this.i18n('Address successfully removed');
          break;
        }
        case 'ADDRESS_UPDATED': {
          translatedMessage += this.i18n('Address successfully updated');
          break;
        }
        case 'INVALID_TRUSTED_ADDRESS': {
          translatedMessage += this.i18n(`This address has already been verified, its verification request has expired
            or does not belong to the account you are logged in as, Please check your address book and try again.`);
          break;
        }
        case 'GENERATEAPIKEY_2FAREQUIRED': {
          translatedMessage += this.i18n('You must enable two factor authentication before being able to generate an Api key.');
          break;
        }
        case 'GENERATEAPIKEY_FAILED': {
          translatedMessage += this.i18n('Unable to generate an Api key. Please try again.');
          break;
        }
        case 'ACTIVATIONEMAIL_RESENT': {
          translatedMessage += 'Activation email resent successfully';
          break;
        }
        case 'FUNDPIN_INVALIDFUNDPIN': {
          translatedMessage += this.i18n('Invalid Fund PIN entered, please try again.');
          break;
        }
        case 'FUNDPIN_NOFUNDPIN': {
          translatedMessage += this.i18n('Please input a new Fund PIN.');
          break;
        }
        case 'FUNDPIN_ENABLE_FAILED': {
          translatedMessage += this.i18n('Fund PIN could not be enabled. Please try again or contact support.');
          break;
        }
        case 'FUNDPIN_DISABLE_FAILED': {
          translatedMessage += this.i18n('Fund PIN could not be disabled. Please try again or contact support.');
          break;
        }
        case 'FUNDPIN_PENDING': {
          translatedMessage += this.i18n(`Fund PIN added succesfully. Please confirm this setting from the confirmation email sent to you to enable it.`);
          break;
        }
        case 'FUNDPIN_RESENT': {
          translatedMessage += this.i18n(`Fund PIN confirmation email resent successfully`);
          break;
        }
        case 'FUNDPIN_CONFIRMED': {
          translatedMessage += this.i18n(`Your Fund PIN has now been confirmed and enabled. You will be required to enter this PIN when performing withdrawals.`);
          break;
        }
        case 'FUNDPIN_DISABLED': {
          translatedMessage += this.i18n(`Your Fund PIN has now been disabled.`);
          break;
        }
        case 'INVALID_WITHDRAW_FUNDPIN': {
          translatedMessage += this.i18n(`Invalid Fund PIN entered, please try again.
            Please note that your account will be blocked after three unsuccessful attempts.`);
          break;
        }
        case 'INVALID_2FA': {
          translatedMessage += this.i18n(`Invalid 2FA Token entered, please try again.
            Please note that your account will be blocked after three unsuccessful attempts.`);
          break;
        }
        case 'INVALID_FUNDPIN_DISABLE_DETAILS': {
          translatedMessage += this.i18n(`Invalid password or Fund PIN entered, please try again.
            Please note that your account will be blocked after three unsuccessful attempts.`);
          break;
        }
        case 'INVALID_TRUSTED_ACCOUNT_DETAILS': {
          translatedMessage += this.i18n(`Invalid password, 2FA Token and/or Fund PIN entered, please try again.
            Please note that your account will be blocked after three unsuccessful attempts..`);
          break;
        }
        case 'SUBSCRIBE_SUCCESS': {
          translatedMessage += this.i18n(`You have successfully subscribed to the product.`);
          break;
        }
        case 'UNSUBSCRIBE_SUCCESS': {
          translatedMessage += this.i18n(`You have successfully unsubscribed from the product.`);
          break;
        }
        case '': {
          translatedMessage += '';
          break;
        }
        // Default
        default: {
          // console.warn('[WARNING: Missing Translation] ' + response);
          translatedMessage += currentMessage;
          break;
        }
      }
      translatedMessage += ' ';
    }
    return translatedMessage.trim();
  } else {
    return 'undefined';
  }

}
}
