import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { StoreService } from 'src/app/core/services/store.service';
import { IMarket, MarketsService } from 'src/app/markets/markets.service';
import { CardDetail } from 'src/app/core/interfaces/quickbuysell';
import { SessionStorageService, SubscriptionLike } from 'src/app/core/services/session-storage.service';

@Component({
  selector: 'app-balance-cards',
  templateUrl: './balance-cards.component.html',
  styleUrls: ['./balance-cards.component.scss']
})
export class BalanceCardsComponent implements OnInit, OnDestroy {

  cards: CardDetail[] = [
    {
      coin:
      {
        coin_type: 'Fiat',
        code: 'ZAR',
        icon: '',
        name: 'South African Rand',
        has_product: false,
        has_coin_networks: false
      },
      total: 0, available: 0, change: 'NA', price: '', exchange: ''},
    {
      coin:
      {
        coin_type: '',
        code: 'BTC',
        icon: '',
        name: 'Bitcoin',
        has_product: false,
        has_coin_networks: false
      },
      total: 0, available: 0, change: 'NA', price: '', exchange: ''
    },
    {
      coin:
      {
        coin_type: '',
        code: 'ETH',
        icon: '',
        name: 'Ethereum',
        has_product: false,
        has_coin_networks: false
      },
      total: 0, available: 0, change: 'NA', price: '', exchange: ''
    },
    {
      coin:
      {
        coin_type: '',
        code: 'LTC',
        icon: '',
        name: 'Litecoin',
        has_product: false,
        has_coin_networks: false
      },
      total: 0, available: 0, change: 'NA', price: '', exchange: ''
    },
  ];

  subs: SubscriptionLike[] = [];

  verificationLevel: number = 0;

  constructor(
    private sessionStorage: SessionStorageService,
    private marketService: MarketsService,
    private store: StoreService
  ) { }

  ngOnInit() {
    this.verificationLevel = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL');
    if (this.verificationLevel !== undefined) {
      if (this.verificationLevel === 0) {
        this.cards[0].coin = {
          coin_type: '',
          code: 'USDT',
          icon: '',
          name: 'USDT',
          has_product: false,
          has_coin_networks: false
        };
      } else if (this.verificationLevel > 0) {
        const profile_country = this.sessionStorage.get('PROFILE_COUNTRY');
        if (profile_country === 'South Africa') {
          this.cards[0].coin = {
            coin_type: 'Fiat',
            code: 'ZAR',
            icon: '',
            name: 'South African Rand',
            has_product: false,
            has_coin_networks: false
          };
        } else {
          this.cards[0].coin = {
            coin_type: '',
            code: 'USDT',
            icon: '',
            name: 'USDT',
            has_product: false,
            has_coin_networks: false
          };
        }
      }
    }

    this.subs.push(this.marketService.marketUpdateSubject.subscribe(_ => {
      for (const card of this.cards) {
        let market: IMarket;

        if (card.coin.code === 'ZAR') {
          market = this.marketService.getMarketbyCoinPair('BTC', 'ZAR');
          card.exchange = 'BTC';
        } else {
          for (const exchange of ['ZAR', 'USDT', 'BTC']) {
            market = this.marketService.getMarketbyCoinPair(card.coin.code, exchange);

            if (market && market.coinCode === card.coin.code && market.exchangeCode === exchange) {
              card.exchange = exchange;
              break;
            }
          }
        }
        card.price = market.spreadPrice ? market.spreadPrice.toFixed(market ? market.exchangeDecimals : 8) :
          (+market.lastPrice).toFixed(market ? market.exchangeDecimals : 8);
      }
    }));

    this.subs.push(this.store.subscribe('balances').subscribe((response: any) => {
      if (!response.refresh) {
        const defaultCoin = this.sessionStorage.get('PROFILE_DEFAULT_CARD');
        if (defaultCoin) {
          const coin = response.data.find(({ code }) => code === defaultCoin.code);
          if (coin && coin.active === '1') {
            this.cards[3].coin = {
              coin_type: '',
              code: coin.code,
              icon: '',
              name: coin.name,
              has_product: false,
              has_coin_networks: false
            };
          }
        }

        for (const data of response.data) {
          for (const card of this.cards) {
            if (card.coin.code === data.code) {
              card.total = data.balance_total;
              card.available = data.balance_available;
              // change from spread price is applied here, if available
              card.change = data.change;
              card.coin.has_product = (typeof data.has_product === 'boolean' && data.has_product ||
                data.has_product === 'true');
              continue;
            }
          }
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub && sub.unsubscribe());
  }
}
