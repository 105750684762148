import { Component, OnDestroy, Optional, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SubscriptionLike } from 'rxjs';
import { take } from 'rxjs/operators';

import { MarketsService, IMarket } from 'src/app/markets/markets.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { StoreService } from 'src/app/core/services/store.service';

import {
  CurrencyManagementComponent
} from 'src/app/balances/currency-management/currency.management.component';

@Component({
  selector: 'app-quick-wizard',
  templateUrl: './quick-wizard.component.html',
  styleUrls: ['./quick-wizard.component.scss', '../order-types-component.scss']
})
export class QuickWizardComponent implements OnDestroy {

  // Wizard Step
  step: number = 0;

  selectedCoin: any = {};
  selectedExchange: string;
  coinMarkets: any = {}; // TODO: Type
  marketSubs: SubscriptionLike;
  balanceSubs: SubscriptionLike;
  balances: any = {};
  loggedIn: boolean;
  verificationLevel: number;
  profileCountry: string;
  hasBalance: boolean = false;
  hasExchangeBalance: boolean = false;
  isBuy: boolean = true;
  coinMarketsOrdered: any[] = [];
  hideZeroBalances: boolean;

  /** control for the selected coin */
  public coinCtrl: FormControl = new FormControl();

   /** control for the MatSelect filter keyword.. TODO! */
  public coinFilterCtrl: FormControl = new FormControl();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    marketService: MarketsService,
    sessionStorage: SessionStorageService,
    store: StoreService,
    private dialog: MatDialog) {

    const waiting: Promise<any>[] = [];

    this.loggedIn = (sessionStorage.get('LOGGED_IN') !== '' && sessionStorage.get('TOKEN') !== '');
    this.verificationLevel = sessionStorage.get('PROFILE_VERIFICATION_LEVEL') || 0;
    this.profileCountry = sessionStorage.get('PROFILE_COUNTRY') || '';

    waiting.push(new Promise(resolve => {
      this.marketSubs = marketService.marketUpdateSubject.pipe(take(1)).subscribe({
        next: (markets) => {
          this.populateMarkets(markets);

          resolve();
        },
        error: e => console.error('Error in marketUpdate subject', e)
      });
    }));

    if (!data) {
      this.data = {type: 0};
    } else {
      this.isBuy = data.type === 0;
    }

    waiting.push(new Promise(resolve => {
      let resolved = false;
      this.balanceSubs = store.subscribe('balances').subscribe(response => {
        if (!response.refresh) {
          this.balances = response.data;

          // Only resolve promise first time
          if (resolved) {
            this.updateBalances();
          } else {
            resolve();
            resolved = true;
          }
        }
      });
    }));

    Promise.all(waiting).then(this.updateBalances.bind(this));
  }

  private sortCoinOrder(a: any, b: any) {
    return (a.sortOrder > b.sortOrder ? 1 : -1);
  }

  updateBalances() {
    for (const code of Object.keys(this.coinMarkets)) {
      const coin = this.coinMarkets[code];
      const balance = this.balances.find(coinBalance => coinBalance.code === coin.coinCode);

      if (balance) {
        coin.balance = (+balance.balance_available).toFixed(coin.coinCode === 'ZAR' ? 2 : 8);
        this.hasBalance = this.hasBalance || +balance.balance_available > 0;
        coin.type = balance.coin_type;
        coin.icon = balance.icon;
        coin.sortOrder = balance.sort_order;
      }

      for (const exchange of coin.exchanges) {
        const exchangeBalance = this.balances.find(coinBalance => coinBalance.code === exchange.coinCode);

        if (exchangeBalance) {
          exchange.balance = (+exchangeBalance.balance_available).toFixed(exchange.coinCode === 'ZAR' ? 2 : 8);
          exchange.type = exchangeBalance.coin_type;
          exchange.icon = exchangeBalance.icon;
          exchange.sortOrder = exchangeBalance.sort_order;
          this.hasExchangeBalance = this.hasExchangeBalance || +exchangeBalance.balance_available > 0;
        }
      }
      coin.exchanges.sort(this.sortCoinOrder.bind(this));
    }

    this.coinMarketsOrdered = Object.keys(this.coinMarkets)
      .map(key => this.coinMarkets[key])
      .sort(this.sortCoinOrder.bind(this));
  }

  ngOnDestroy() {
    if (this.marketSubs) {
      this.marketSubs.unsubscribe();
    }
    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }
  }

  private populateMarkets(markets: IMarket[]) {
      for (const obj of markets) {
        const coin = this.coinMarkets[obj.coinCode];
        if (coin) {
          coin.exchanges.push({coinCode: obj.exchangeCode, coinName: obj.exchangeName});
        } else {
          this.coinMarkets[obj.coinCode] = {
            coinCode: obj.coinCode,
            coinName: obj.coinName,
            balance: 0,
            exchanges: [{coinCode: obj.exchangeCode, coinName: obj.exchangeName}]
          };
        }
        // Remove exchanges that aren't allowed...
        if (this.verificationLevel < 1 && ['ZAR', 'USDT'].indexOf(obj.exchangeCode) !== -1) {
          this.coinMarkets[obj.coinCode].exchanges.pop();
        }
      }
      if (this.marketSubs) {
        this.marketSubs.unsubscribe();
      }
  }

  selectCoin(coin: string) {
    if (this.loggedIn) {
      this.selectedCoin = this.coinMarkets[coin];
      this.step++;
    }
  }

  selectExchange(coin: string) {
    if (this.loggedIn) {
      const exchange = this.selectedCoin.exchanges.find(obj => obj.coinCode === coin);
      this.selectedExchange = exchange ? exchange.coinCode : '';
      if (this.selectedExchange) {
        this.step++;
      }
    }
  }

  openCurrencyManagement(event: Event, coin: any) {
    event.stopPropagation();

    this.dialog.open(CurrencyManagementComponent, {
      width: '1200px',
      data: {
        code: coin.coinCode,
        type: coin.type,
        icon: coin.icon,
        mode: 0
      }
    });
  }

  trackByCoin(_index: number, coin: any) {
    return coin && coin.coinCode;
  }
}
