import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { Observable } from 'rxjs';

import { UserRegistration } from '../core/interfaces/auth';
import { User } from '../core/interfaces/user';
import { Auth2FA } from '../core/interfaces/auth-2fa';
import { AuthIP } from './../core/interfaces/auth-ip';
import { StoreService } from '../core/services/store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn: boolean;

  constructor(
    private apiService: ApiService,
    private store: StoreService
  ) {}

  /**
   * @param {User} user
   * @returns {Observable<User>}
   * @memberof AuthService
   */
  login(user: User): Observable<User> {
    return this.apiService.call<User>('authenticateUser', user);
  }

  /**
   * @param {string} recaptcha
   * @param {string} email
   * @memberof AuthService
   */
  forgotPassword(recaptcha: string, email: string) {
    return this.apiService.call('forgotPassword', {email, 'g-recaptcha-response': recaptcha});
  }

  /**
   * @param passwordOne
   * @param passwordTwo
   * @param hash
   */
  resetPassword(passwordOne: string, passwordTwo: string, hash: string) {
    return this.apiService.call('verifyForgot', {
      password: passwordOne,
      password2: passwordTwo,
      verify: hash
    });
  }

  /**
   *
   * @param twofa
   * @param action
   */
  verify2fa(data: Auth2FA): Observable<Auth2FA> {
    return this.apiService.call<Auth2FA>('authenticate2fa', data);
  }

  /**
   *
   * @param hash
   * @param action
   */
  authorizeIP(data: AuthIP): Observable<Auth2FA> {
    return this.apiService.call<Auth2FA>('authorizeIP', data);
  }

  /**
   * @param {Registration} registration
   * @returns {Observable<Registration>}
   * @memberof AuthService
   */
  registerUser(registration: UserRegistration): Observable<UserRegistration> {
    return this.apiService.call<UserRegistration>('registerUser', registration);
  }

  /**
   * @param {string} hash
   * @returns
   * @memberof AuthService
   */
  verifyEmail(data: {verify: string, 'g-recaptcha-response': string}) {
    return this.apiService.call<UserRegistration>('verifyEmail', data);
  }

  /**
   * @param {string} hash
   * @returns
   * @memberof AuthService
   */
  unblockUser(hash: string) {
    return this.apiService.call<UserRegistration>('unblockConfirm', {hash});
  }

  updateUserAffiliate(selectedAffiliates: object) {
    return this.apiService.call('updateUserAffiliate', selectedAffiliates);
  }

  checkReferrerExists(checkReferrer: any) {
    return this.apiService.call('checkReferrerExists', checkReferrer);
  }
  /**
   * @param {string} email
   * @returns
   * @memberof AuthService
   */

  resendActivationEmail(email: string) {
    return this.apiService.call<UserRegistration>('resendVerificationLink', {email});
  }

  /**
   * @param {string} closed
   * @param {string} email
   * @param {string} password
   * @returns
   * @memberof AuthService
   */
  sendReactivationEmail(closed: string, email: string, password: string) {
    return this.apiService.call<UserRegistration>('activateRequest', {closed, email, password});
  }

  /**
   * @param {string} hash
   * @returns
   * @memberof AuthService
   */
  activateConfirm(hash: string) {
    return this.apiService.call<UserRegistration>('activateConfirm', {hash});
  }

  logout() {
    this.store.logOff();
    return this.apiService.call('logoutUser');
  }

  autoLogout(lastPage: string) {
    this.store.logOff();
    return this.apiService.call('logoutUser', { lastPage });
  }
  /*Affiliate API Calls*/
  getAffiliates() {
    return this.apiService.call('getAffiliates');
  }

  getUserAffiliates() {
    return this.apiService.call('getUserAffiliates');
  }

  addUserAffiliates(selectedAffiliates: object) {
    return this.apiService.call('addUserAffiliate', selectedAffiliates);
  }

}
