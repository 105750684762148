import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

export interface IResultDialogData {
    title: string;
    result: string;
    reason: string;
  }

  @Component({
    selector: 'app-result-dialog-dialog',
    templateUrl: './result-dialog.component.html',
    styleUrls: ['./result-dialog.component.scss']
  })
  export class ResultDialogComponent {

    exchangeName: string = environment.config.EXCHANGE_NAME_L;

    public static openDialog(dialog: MatDialog, dialogData: IResultDialogData, width?: string) {
      dialog.open(ResultDialogComponent, {
        width: !width ? width : '650px',
        data: dialogData
      });
    }

    constructor(
      public dialogRef: MatDialogRef<ResultDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IResultDialogData) {
        data.title = !!this.data.title ? data.title : '';
        data.reason = !!this.data.reason ? data.reason : '';
        data.result = !!this.data.result ? this.data.result : '';
    }

    close_click(): void {
      this.dialogRef.close({result: 'accept'});
    }
  }
