<mat-table [dataSource]="historyData" multiTemplateDataRows class="w-100 col-12" matSort (matSortChange)="reload_table()">
  <ng-container matColumnDef="pair" *ngIf="history_mode === 2">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Market</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Market"> {{element.pair}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Action</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Action" i18n> <mat-icon [ngClass]="[element.type === '0' ? 'buy' : 'sell']">lens</mat-icon> {{element.type === '0' ? 'BUY' : 'SELL'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Type</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Type"> {{element.type}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Amount</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Amount"> {{element.amount | decimalNumber: element.coin_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="fee">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fee</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Fee"> {{element.fee | decimalNumber: (getExchangeCode(element.pair, element) === 'ZAR' ? element.coin_decimals : element.exchange_decimals)}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="netTotal">
    <mat-header-cell *matHeaderCellDef i18n>Net Total</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Amount"> {{(element.amount - element.fee) | decimalNumber: element.coin_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="price">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Price</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Price"> {{element.price | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Total</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Total"> {{element.total | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="net_total">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Net Total</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Net Total"> {{element.net_total | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="confirmation">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Confirmation</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Confirmation"> {{element.confirms}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="address">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Receiving</mat-header-cell>
    <mat-cell *matCellDef="let element" class="center-vertical" data-label="Receiving" (dblclick)="coin_code !== 'ZAR' ? copyToClipboard(element.address) : ''">
      <div class="mobile-view-container row w-100 mx-0">
        <mat-icon class="copy-icon w-auto h-auto d-none d-lg-flex" *ngIf="coin_code !== 'ZAR'" color="primary" ngxClipboard (click)="copyToClipboard(element.address)" i18n-matTooltip matTooltip="Copy Address">file_copy</mat-icon>
        <a class="mobile-view col pr-2" *ngIf="element.address_explorer_url && element.canLink && element.code !== 'ZAR'" target="_blank" href="{{element.address | explorerUrl: element.address_explorer_url}}">
          {{element.address}}
        </a>
        <span class="mobile-view col pr-3" *ngIf="!(element.address_explorer_url && element.canLink && element.code !== 'ZAR')">{{element.address}}</span>
        <mat-icon class="copy-icon w-auto h-auto d-flex d-lg-none" *ngIf="coin_code !== 'ZAR'" color="primary" ngxClipboard (click)="copyToClipboard(element.address)" i18n-matTooltip matTooltip="Copy Address">file_copy</mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="network_name">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Network</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Network">
        {{element.network_name}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef i18n>Status</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Status">
      {{!element.status ? element.pending : element.status}}
      <mat-icon
        color="primary"
        *ngIf="element.status === 'DELAYED'"
        data-container="body"
        i18n-matTooltip matTooltip="For security reasons, your withdrawal has been referred for manual review">
        info
      </mat-icon>
    </mat-cell>
  </ng-container>


  <ng-container matColumnDef="time">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Time</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Time"> {{element.time_formatted}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let element">
      <div *ngIf="coin_type !== 'Fiat' && coin_type !== 'Cryptonote'">
          <span class="expand-row">
            <b class="expand-col w-140px" i18n>Transaction ID :</b>
            <a class="expand-col" *ngIf="element.txid_explorer_url && element.canLinkTXID && !isLightning && element.code !== 'ZAR'; else plainTextTransaction" target="_blank" href="{{element.txid | explorerUrl: element.txid_explorer_url}}">
              {{element.txid}}
            </a>
            <ng-template #plainTextTransaction>
              <span class="expand-col">{{element.txid}}</span>
            </ng-template>
            <mat-icon *ngIf="!!element.txid" class="copy-icon" color="primary" ngxClipboard (click)="copyToClipboard(element.txid)" i18n-matTooltip matTooltip="Copy TXID">file_copy</mat-icon>

            <ng-container *ngIf="history_mode === 1">
              <span class="expand-col" *ngIf="((!!element.status && element.status === 'UNSUCCESSFUL') || (!!element.pending && element.pending === 'UNSUCCESSFUL')) && !element.txid" i18n>
                For security reasons your withdrawal has been referred for manual review. Please contact support if you have any questions, <a href="{{getSupportUrl() }}" target="_blank">here.</a>
              </span>
              <span class="expand-col" *ngIf="((!!element.status && element.status === 'UNCONFIRMED') || (!!element.pending && element.pending === 'UNCONFIRMED')) && !element.txid" i18n>
                Withdrawal requested, please confirm this withdrawal using the withdrawal confirmation email which has been sent to you.
              </span>
              <span class="expand-col" *ngIf="((!!element.status && element.status === 'CONFIRMED') || (!!element.pending && element.pending === 'CONFIRMED')) && !element.txid" i18n>
                Withdrawal has been confirmed via email and will be processed soon.
              </span>
            </ng-container>
          </span>
          <br>
          <br>
          <span class="expand-row">
            <b class="expand-col w-140px" i18n>{{history_mode === 0 ? 'Deposit': 'Withdrawal'}} Address:</b>
            <a class="expand-col" *ngIf="element.address_explorer_url && element.canLink && element.code !== 'ZAR'; else plainTextAddress" target="_blank" href="{{element.address | explorerUrl: element.address_explorer_url}}">
              {{element.address}}
            </a>
            <ng-template #plainTextAddress>
              <span class="expand-col">{{element.address}}</span>
            </ng-template>
            <mat-icon class="copy-icon" color="primary" ngxClipboard (click)="copyToClipboard(element.address)" i18n-matTooltip matTooltip="Copy Address">file_copy</mat-icon>
          </span>
          <br>
          <br>
          <span *ngIf="!!element.payment_id">
            <b class="expand-col w-140px" i18n>{{history_mode === 0 ? 'Deposit': 'Withdrawal'}} {{element.network_name === 'Lightning Network' ? 'Description: ' : memo_label}}</b>
            <span> {{element.payment_id}}</span>
            <mat-icon class="copy-icon" color="primary" ngxClipboard (click)="copyToClipboard(element.payment_id)" i18n-matTooltip matTooltip="Copy Payment ID">file_copy</mat-icon>
          </span>
      </div>
      <div *ngIf="coin_type === 'Cryptonote'">
        <span class="expand-row">
          <b class="expand-col w-140px" i18n>Transaction ID :</b>
          <a class="expand-col" *ngIf="element.txid_explorer_url && element.canLinkTXID && element.code !== 'ZAR'; else plainTextTransaction" target="_blank" href="{{element.txid | explorerUrl: element.txid_explorer_url}}">
            {{element.txid}}
          </a>
          <ng-template #plainTextTransaction>
            <span> {{element.txid}}</span>
          </ng-template>
          <ng-container *ngIf="history_mode === 1; else otherHistory">
            <span class="expand-col" *ngIf="((!!element.status && element.status === 'UNCONFIRMED') || (!!element.pending && element.pending === 'UNCONFIRMED')) && !element.txid; else withdrawal" i18n>
              Withdrawal requested, please confirm this withdrawal using the withdrawal confirmation email which has been sent to you.
            </span>
            <ng-template #withdrawal>
              <span class="expand-col" *ngIf="((!!element.status && element.status === 'CONFIRMED') || (!!element.pending && element.pending === 'CONFIRMED')) && !element.txid; else confirmed" i18n>
                Withdrawal has been confirmed via email and will be processed soon.
              </span>
            </ng-template>
            <ng-template #confirmed>
              <mat-icon class="copy-icon" color="primary" ngxClipboard (click)="copyToClipboard(element.txid)" i18n-matTooltip matTooltip="Copy Transaction ID">file_copy</mat-icon>
            </ng-template>
          </ng-container>
          <ng-template #otherHistory>
            <mat-icon class="copy-icon" color="primary" ngxClipboard (click)="copyToClipboard(element.txid)" i18n-matTooltip matTooltip="Copy Transaction ID">file_copy</mat-icon>
          </ng-template>
        </span>
        <br>
        <br>
        <span class="expand-row">
          <b class="expand-col w-140px" i18n>{{history_mode === 0 ? 'Deposit': 'Withdrawal'}} Address:</b>
            <a class="expand-col" *ngIf="element.address_explorer_url && element.canLink && element.code !== 'ZAR'; else plainTextAddress"  target="_blank" href="{{element.address | explorerUrl: element.address_explorer_url}}">
            {{element.address}}
          </a>
          <ng-template #plainTextAddress>
            <span> {{element.address}}</span>
          </ng-template>
          <mat-icon class="copy-icon" color="primary" ngxClipboard (click)="copyToClipboard(element.address)" i18n-matTooltip matTooltip="Copy Address">file_copy</mat-icon>
        </span>
        <br>
        <br>
        <span *ngIf="!!element.payment_id">
          <b class="expand-col w-140px" i18n>{{history_mode === 0 ? 'Deposit': 'Withdrawal'}} {{memo_label}}:</b>
          <span> {{element.payment_id}}</span>
          <mat-icon class="copy-icon" color="primary" ngxClipboard (click)="copyToClipboard(element.payment_id)" i18n-matTooltip matTooltip="Copy Payment ID">file_copy</mat-icon>
        </span>
        <br>
        <br>
    </div>
      <div *ngIf="coin_type === 'Fiat'">
        <span *ngIf="!!element.txid"> <b i18n>Reference ID:</b> {{element.txid}}</span>
        <span class="col-6" *ngIf="!element.status && !element.txid" i18n>Withdrawal requested, please confirm this withdrawal using the withdrawal confirmation email which has been sent to you.</span>
        <ng-container *ngIf="history_mode === 1">
          <span class="col-6" *ngIf="((!!element.status && element.status === 'UNCONFIRMED') || (!!element.pending && element.pending === 'UNCONFIRMED')) && !element.txid" i18n>Withdrawal requested, please confirm this withdrawal using the withdrawal confirmation email which has been sent to you.</span>
          <span class="col-6" *ngIf="((!!element.status && element.status === 'CONFIRMED') || (!!element.pending && element.pending === 'CONFIRMED')) && !element.txid" i18n>Withdrawal has been confirmed via email and will be processed soon.</span>
        </ng-container>
        <br>
        <br>
        <span> <b i18n>{{history_mode === 0 ? 'Deposit': 'Withdrawal'}} Address:</b>  {{element.address}}</span>
        <br>
        <br>
        <!--Account Number: {{element.account_number}}<br>
        Branch: {{element.branch}}<br>
        Type: {{element.type}}<br>-->
        <ng-container i18n><b>Amount:</b></ng-container> {{element.amount}}<br>
        <!--Reference ID: {{element.reference_id}}-->
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action" stickyEnd>
    <mat-header-cell *matHeaderCellDef i18n>Action</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon color="primary" (click)="expand(element)" *ngIf="history_mode !== 2" data-container="body" i18n-matTooltip matTooltip="{{this.tooltipMessage}}"> {{ expandedElement !== null && element.time_formatted === expandedElement.time_formatted ? "remove" : "add"}}</mat-icon>
      <mat-icon color="primary" (click)="cancel_Withdrawal(element)" *ngIf="element.status === 'UNCONFIRMED' && history_mode === 1" data-container="body" i18n-matTooltip matTooltip="Cancel">close</mat-icon>
      <mat-icon color="primary" (click)="cancelOrder(element)" *ngIf="history_mode === 2" data-container="body" i18n-matTooltip matTooltip="Cancel">clear</mat-icon>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="history_definition; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: history_definition;"></mat-row>
  <mat-row  *matRowDef="let row; columns: ['expandedDetail'];" [@detailExpand]="expandedElement !== null && row.time_formatted === expandedElement.time_formatted ? 'expanded' : 'collapsed'"></mat-row>
</mat-table>
<div class="w-100 text-center" *ngIf="!historyData || historyData.filteredData.length === 0" i18n>No records found</div>
<mat-paginator i18n-itemsPerPageLabel itemsPerPageLabel="Items per page:" [length]="totalRecords" [pageSize]="listNumber"
[pageSizeOptions]="pageSizes" (page)="paging_change($event)" [ngClass]="[pageLoading ? 'disable-control' : '']">
</mat-paginator>
