import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Disable2FA } from './../../core/interfaces/disable-2fa';
import { Enable2FA } from './../../core/interfaces/enable-2fa';
import { SettingsService } from '../settings.service';
import { environment } from 'src/environments/environment';
import { SessionStorageService, SubscriptionLike } from 'src/app/core/services/session-storage.service';
import { StoreService } from 'src/app/core/services/store.service';
import { TokenInputComponent } from 'src/app/shared/token-input/token-input.component';
import { DisableTwofaFallbackComponent } from 'src/app/shared/disable-twofa-fallback/disable-twofa-fallback.component';

export enum InformationScreen {
  apiKeyGeneration = 1
}
export interface TwoFaDialogData {
  disable: any;
  informationScreen: InformationScreen;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent implements OnInit, OnDestroy {
  @ViewChild('verifyCode') verifyCodeInput: TokenInputComponent;

  darkTheme: boolean = false;
  private subs: SubscriptionLike[] = [];
  tokenData: FormGroup;
  disableData: FormGroup;
  enableData: FormGroup;
  resultMessage: string;
  submitted: boolean = false;
  submitSucceeded: boolean;
  submitFailed: boolean;
  twoFactorEnabled: boolean;

  qrCode: string = '';
  twoFactorSecret: string;
  twoFactorCode: string;

  disable2fa: boolean;

  complete: boolean;

  siteNameN: String = environment.config.EXCHANGE_NAME;
  siteName: String = environment.config.EXCHANGE_NAME_L;

  email: string = '';

  // Used to reference enum on html
  InformationScreen: typeof InformationScreen = InformationScreen;
  showTfaSteps: boolean = true;

  constructor(
    private fb: FormBuilder,
    private service: SettingsService,
    private store: StoreService,
    private sessionStorage: SessionStorageService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TwoFactorComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: TwoFaDialogData
  ) {
      this.complete = false;
      if (dialogData.informationScreen) {
        this.showTfaSteps = false;
      }

      this.disable2fa = dialogData.disable ? dialogData.disable : false;
    }

  ngOnInit() {
    this.submitted = false;
    this.twoFactorEnabled = false;
    this.qrCode = '';
    this.twoFactorSecret = '';

    this.email = this.sessionStorage.get('PROFILE_EMAIL');

    this.subs.push(this.sessionStorage.observe('THEME').subscribe(
      data => {
        this.darkTheme = (data === 'dark-theme');
      } ));

    this.fetch2FA();
    this.setupForms();
    this.resetErrors();

    if (this.verifyCodeInput) {
      setTimeout(this.verifyCodeInput.focus, 100);
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setupForms() {
    this.tokenData = this.fb.group({
      verify_code: new FormControl({
        value: '',
        disabled: false
      })
    });
    this.enableData = this.fb.group({
    });
    this.disableData = this.fb.group({
    });
  }

  fetch2FA() {
    this.service.fetch2FAStatus()
    .subscribe((response: any) => {
      if (response.response === 'success') {
        this.qrCode = 'otpauth://totp/' + encodeURIComponent(this.siteNameN + ' (' + this.email + ')')
          + '?secret=' + response.secret;
        this.twoFactorSecret = response.secret;
        this.twoFactorEnabled = false;
      } else if (response.response === 'failure' &&
        response.reason === '2FA_ALREADYENABLED') {
        this.twoFactorEnabled = true;
        this.startDisable();
      }
    });
  }

  on2faChange(value: string) {
    if (value.length === 6) {
      this.twoFactorCode = value;
      this.tokenData.controls.verify_code.setValue(value);
      this.verifyClick();
    }
  }

  startDisable() {
    this.disable2fa = true;
  }

  resetErrors() {
    this.resultMessage = '';
    this.submitFailed = false;
    this.submitSucceeded = false;
  }

  cancelClick() {
    this.dialogRef.close({result: 'failure'});
  }

  doneClick() {
    this.dialogRef.close({result: 'success'});
  }

  codeChange() {
    this.submitFailed = false;
  }

  nextClick() {
    if (this.verifyCodeInput) {
      setTimeout(this.verifyCodeInput.focus, 100);
    }
  }

  verifyClick() {
    if (!this.tokenData.controls.verify_code.disabled) {
      // replace unicode U+2000 (white space) and regular spaces
      this.twoFactorCode = this.twoFactorCode.replace(/ | /g, '');
      this.resetErrors();
      this.tokenData.controls.verify_code.disable();
      this.submitted = true;
      if (this.disable2fa) {
        const data: Disable2FA = {
          verify: this.twoFactorCode
        };
        this.service.disable2FA(data)
          .subscribe((response: any) => {
            if (response.response === 'success') {
              this.twoFactorEnabled = false;
              this.fetch2FA();
              this.submitSucceeded = true;
              this.resultMessage = response.reason;
              this.dialogRef.close({ result: 'success' });
            } else if (response.response === 'failure') {
              this.submitted = false;
              this.tokenData.controls.verify_code.enable();
              this.verifyFail(response);
            }
          });
      } else {
        const data: Enable2FA = {
          secret: this.twoFactorSecret,
          verify: this.twoFactorCode
        };
        this.service.enable2FA(data).subscribe((response: any) => {
          this.tokenData.controls.verify_code.enable();
          if (response.response === 'success') {
            this.complete = true;
            this.disable2fa = true;
          } else {
            this.verifyFail(response);
          }
        });
      }
    }
    // Call this to update tfa status with linked listeners
    // TODO: To be implemented more appropriately with https://cxprojects.atlassian.net/browse/CP-3247
    this.store.getProfile().subscribe();
  }

  verifyFail(response: any) {
    this.resultMessage = response.reason;
    this.submitFailed = true;
    this.twoFactorCode = '';
    this.tokenData.controls.verify_code.setValue('');
    if (this.verifyCodeInput) {
      setTimeout(this.verifyCodeInput.focus, 100);
    }
  }

  openDisableDialog() {
    const disableDialogRef = this.dialog.open(DisableTwofaFallbackComponent, {
      width: '600px',
      data: { startDialogue: true }
    });
    disableDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

}
