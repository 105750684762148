import { Component, OnInit, Input, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionLike } from 'rxjs';

import { TransactionHistory, TransactionHistoryRequest } from '../../core/interfaces/balances';
import { BalancesService } from '../balances.service';

import { StoreService } from '../../core/services/store.service';
import { environment } from './../../../environments/environment';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ResultDialogComponent, IResultDialogData } from 'src/app/shared/result-dialog/result-dialog.component';
import {
  IConfirmDialogData,
  ConfirmDialogComponent,
  DialogTypes,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { Clipboard } from 'src/app/core/services/util.service';
import { UtilHelper } from 'src/app/core/helpers/util-helper';
import { GetFiatAddressBook } from '../address-book/state/address-book.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionHistoryComponent implements OnInit, OnDestroy {
  public isAddress: Function = UtilHelper.isAddress;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // tslint:disable-next-line:no-input-rename
  @Input('coin') coin_code: string = 'ZAR';
  // tslint:disable-next-line:no-input-rename
  @Input('type') coin_type: string = 'fiat';
  // tslint:disable-next-line:no-input-rename
  @Input('mode') history_mode: number = 0;

  parent: any;

  history_definition: string[] = [
    'amount',
    'fee',
    'confirmation',
    'status',
    'time',
    'action'
  ];

  formatList: string[] = [
    'usdt',
    'zar'
  ];

  historyData: MatTableDataSource<TransactionHistory> = new MatTableDataSource([]);
  totalRecords: number;
  pageSizes: number[] = [5, 10, 20, 50];
  listNumber: number = 10;
  pageLoading: boolean;

  reloadHandler: any;

  data: TransactionHistoryRequest = {
    coin_id: this.coin_code,
    start: 0,
    length: 10,
    orderBy: '',
    orderType: ''
  };

  tooltipMessage: string = 'Expand';

  showNotification: boolean = true;

  private subs: SubscriptionLike[] = [];

  depositHistorySub: SubscriptionLike = null;
  withdrawalHistorySub: SubscriptionLike = null;
  activeOrdersSub: SubscriptionLike = null;
  cancelWithdrawalSub: SubscriptionLike = null;
  cancelOrderSub: SubscriptionLike = null;

  reloadInterval: number = 1000;

  expandedElement: TransactionHistory = null;

  initialLoad: boolean = true;
  memo_label: string = 'Payment Id';

  ngOnDestroy() {
    clearTimeout(this.reloadHandler);

    this.subs.forEach(sub => sub.unsubscribe());

    if (!!this.depositHistorySub) {
      this.depositHistorySub.unsubscribe();
    }
    if (!!this.withdrawalHistorySub) {
      this.withdrawalHistorySub.unsubscribe();
    }
    if (!!this.activeOrdersSub) {
      this.activeOrdersSub.unsubscribe();
    }
    if (!!this.cancelWithdrawalSub) {
      this.cancelWithdrawalSub.unsubscribe();
    }
    if (!!this.cancelOrderSub) {
      this.cancelOrderSub.unsubscribe();
    }
  }

  constructor(
    public balanceService: BalancesService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private store: StoreService,
    private i18n: I18n,
    public snackbar: MatSnackBar,
    private translateService: I18nTranslationService,
    public clipboard: Clipboard,
    private _addressStore: Store,
  ) {
  }

  ngOnInit() {
    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showNotification = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
    }));
    if (this.history_mode === 0) { // Deposits
      if (this.coin_type.toLowerCase() !== 'fiat') {
        this.history_definition = [
          'amount',
          'address',
          'network_name',
          'confirmation',
          'status',
          'time',
          'action'
        ];
      } else if (this.coin_type.toLowerCase() === 'fiat') {
        this.history_definition = [
          'amount',
          'address',
          'confirmation',
          'status',
          'time',
          'action'
        ];
      }
      this.subs.push(this.store.subscribe('deposit').subscribe((response) => {
        if ( response.refresh ) {
          this.reloadTableInterval();
        }
      }));
    } else if (this.history_mode === 1) { // Withdrawal

      if (this.coin_type.toLowerCase() !== 'fiat') {
        this.history_definition = [
          'amount',
          'fee',
          'netTotal',
          'address',
          'network_name',
          'status',
          'time',
          'action'
        ];
      } else if (this.coin_type.toLowerCase() === 'fiat') {
        this.history_definition = [
          'amount',
          'fee',
          'netTotal',
          'address',
          'status',
          'time',
          'action'
        ];
      }
      this.subs.push(this.store.subscribe('withdrawal').subscribe((response) => {
        if ( response.refresh ) {
          this.reloadTableInterval();
        }
      }));
    } else if (this.history_mode === 2) { // Open Orders
      this.history_definition = [
        // 'actions',
        'pair',
        'type',
        'amount',
        'price',
        // 'coin1',
        // 'coin2',
        'total',
        'fee',
        // 'status',
        'time',
        'action',
      ];
      this.subs.push(this.store.subscribe('openOrders').subscribe((response) => {
        if ((response.refresh && Object.keys(response).length === 1) ||
          ((response.refresh && response.market.search(this.coin_code) > -1 && response.market.isUserSpecific)
          || this.initialLoad)) {
          this.reloadTableInterval(false);
        }
      }));
    }

    this.historyData.sort = this.sort;
    this.cdRef.detectChanges();
  }

  reload_table() {
    let sortBy = 'time';
    let direction = this.sort.direction.toUpperCase();
    if (!this.sort.active || this.sort.direction === '') {
      sortBy = 'time';
      direction = 'DESC';
    } else {
      sortBy = this.sort.active;
    }

    this.data.orderBy = sortBy;
    this.data.orderType = direction;

    this.data.coin_id = this.coin_code;
    if (this.history_mode === 0) {
      if (!!this.depositHistorySub) {
        this.depositHistorySub.unsubscribe();
      }
      this.depositHistorySub = this.balanceService.getDepositHistory(this.data)
      .subscribe((th: any) => {
        if (th.response === 'success') {
          this.historyData.data = this.processAddresses(th.data);
          this.totalRecords = th.recordsFiltered;
        }
      });
    } else if (this.history_mode === 1) {
      if (!!this.withdrawalHistorySub) {
        this.withdrawalHistorySub.unsubscribe();
      }
      this.withdrawalHistorySub = this.balanceService.getWithdrawalHistory(this.data)
      .subscribe((th: any) => {
        if (th.response === 'success') {
          this.memo_label = th.payment_memo_label;
          this.historyData.data = this.processAddresses(th.data);
          this.totalRecords = th.recordsFiltered;
        }
      });
    } else if (this.history_mode === 2) {
      if (!!this.activeOrdersSub) {
        this.activeOrdersSub.unsubscribe();
      }

      const activeOrderRequest = {
        coin: this.data.coin_id,
        perPage: this.data.length,
        pageNo: this.data.start / this.data.length,
        orderBy: sortBy,
        order: direction
      };

      this.activeOrdersSub = this.balanceService.getMyActiveOrders(activeOrderRequest)
      .subscribe((th: any) => {
        if (th.response === 'success') {
          this.historyData.data = th.data;
          this.totalRecords = th.recordsFiltered;
        }
      });
    }
    clearTimeout(this.reloadHandler);
    this.reloadHandler = null;
  }

  paging_change($event: any) {
    this.data.start = $event.pageIndex * $event.pageSize;
    this.data.length = $event.pageSize;
    if (!this.reloadHandler) {
      this.reloadHandler = setTimeout(() => {
        this.reload_table();
      }, this.reloadInterval);
    }
  }

  cancel_Withdrawal(element: any) {
    if (this.showNotification) {
      const dialogData: IConfirmDialogData = {
        amount: element.amount,
        address: element.address,
        cancelType: DialogTypes.cancelWithdrawal,
      };
      const dialogRef = ConfirmDialogComponent.openDialog(this.dialog, dialogData);
      dialogRef.afterClosed().subscribe((result) => {
        if (result.result === 'accept') {
          if (!!this.cancelWithdrawalSub) {
            this.cancelWithdrawalSub.unsubscribe();
          }
          this.cancelWithdrawalSub = this.balanceService.cancelWithdrawal({withdrawalId: element.id})
          .subscribe((response) => {
          /**
           * call the address book again, to update the remaining limits
           * this is not the correct way to be doing this, but with the standing of the api
           * and zar now having "dynamic" limits based on address, verification and bank account being
           * verified or not this was the quick "hacky" solution
           */
            if (this.coin_code === 'ZAR') {
              this._addressStore.dispatch(new GetFiatAddressBook(this.coin_code));
            }
            /*let resultDialogData: IResultDialogData = null;
            if (response.response === 'success') {
              resultDialogData = {
                title: 'Confirmation',
                result: 'success',
                reason: response.reason
              };
              this.store.updateBalance(response.balanceData);
              this.reload_table();
            } else {
              resultDialogData = {
                title: 'Failed',
                result: 'failed',
                reason: response.reason
              };
            }
            ResultDialogComponent.openDialog(this.dialog, resultDialogData, '400px');*/
            this.snackbar.open(this.translateService.translateResponse(response.reason),
              this.i18n('Close'), {duration: 2000});
          });
        }
      });
    } else {
      if (!!this.cancelWithdrawalSub) {
        this.cancelWithdrawalSub.unsubscribe();
      }
      this.cancelWithdrawalSub = this.balanceService.cancelWithdrawal({withdrawalId: element.id})
      .subscribe((response) => {
        if (response.response === 'success') {
          /**
           * call the address book again, to update the remaining limits
           * this is not the correct way to be doing this, but with the standing of the api
           * and zar now having "dynamic" limits based on address, verification and bank account being
           * verified or not this was the quick "hacky" solution
           */
          if (this.coin_code === 'ZAR') {
            this._addressStore.dispatch(new GetFiatAddressBook(this.coin_code));
          }

          this.store.updateBalance(response.balanceData);
          this.reloadTableInterval();
        } else {
          const resultDialogData: IResultDialogData = {
            title: 'Failed',
            result: 'failed',
            reason: response.reason
          };
          ResultDialogComponent.openDialog(this.dialog, resultDialogData, '400px');
        }
      });
    }
  }

  expand(element: any) {
    if (this.expandedElement !== null && element.time_formatted === this.expandedElement.time_formatted) {
      this.expandedElement = null;
      this.tooltipMessage = 'Expand';
    } else {
      this.expandedElement = element;
      this.tooltipMessage = 'Collapse';
    }
  }

  cancelOrder(element: any) {
    if (this.showNotification) {
      const dialogData: IConfirmDialogData = {
        pair: element.pair,
        type: (element.type === '0' ? 'BUY' : 'SELL'),
        amount: element.amount,
        cancelType: DialogTypes.cancelOrder,
      };
      const dialogRef = ConfirmDialogComponent.openDialog(this.dialog, dialogData, '400px');
      dialogRef.afterClosed().subscribe((result) => {
        if (result.result === 'accept') {
          if (!!this.cancelOrderSub) {
            this.cancelOrderSub.unsubscribe();
          }
          this.cancelOrderSub = this.balanceService.cancelOrder({orderId: element.order_id})
          .subscribe((response) => {
            if (response.response === 'success') {
              this.reloadTableInterval();
            }

            /*let resultDialogData: IResultDialogData = null;
            if (response.response === 'success') {
              resultDialogData = {
                title: 'Confirmation',
                result: 'success',
                reason: response.reason
              };
            } else {
              resultDialogData = {
                title: 'Failed',
                result: 'failed',
                reason: response.reason
              };
            }
            ResultDialogComponent.openDialog(this.dialog, resultDialogData, '400px');
            */
           this.snackbar.open(this.translateService.translateResponse(response.reason),
              this.i18n('Close'), {duration: 2000});
          });
        }
      });
    } else {
      if (!!this.cancelOrderSub) {
        this.cancelOrderSub.unsubscribe();
      }
      this.cancelOrderSub = this.balanceService.cancelOrder({orderId: element.order_id})
      .subscribe((response) => {
        if (response.response === 'success') {
          this.reloadTableInterval();
        } else {
          /*const resultDialogData: IResultDialogData = {
            title: 'Failed',
            result: 'failed',
            reason: response.reason
          };
          ResultDialogComponent.openDialog(this.dialog, resultDialogData, '400px');*/
          this.snackbar.open(this.translateService.translateResponse(response.reason),
              this.i18n('Close'), {duration: 2000});
        }
      });
    }
  }

  getExchangeCode(pairName: string, pair: any) {
    if (pairName) {
    const exchangeCode = pairName.substr(pairName.indexOf('/') + 1);
    return exchangeCode;
    } else {
      return '';
    }
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  // Check every address to see if an explorer can be linked
  processAddresses(data: any) {
    const newData = [];
    if (data) {
      data.forEach(item => {
        if (item.network_name === 'Lightning Network') {
          item.canLinkTXID = false;
          item.canLink = false;
        } else {
          if (item.txid) {
            item.canLinkTXID = this.isAddress(item.txid);
          }
          if (item.address) {
            item.canLink = this.isAddress(item.address);
          }
        }
        newData.push(item);
      });
    }
    return newData;
  }

  copyToClipboard(str: string) {
    this.clipboard.CopyToClipboard(str);
  }

  reloadTableInterval(initalLoad?: boolean) {
    if (!this.reloadHandler) {
      this.reloadHandler = setTimeout(() => {
        if (initalLoad) {
          this.initialLoad = initalLoad;
        }
        this.reload_table();
      }, this.reloadInterval);
    }
  }
}
