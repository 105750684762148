import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss']
})
export class UnavailableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }
}
