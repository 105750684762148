<div _ngcontent-c6 class="container-fluid wrapper p-0">
  <div class="row mx-3">
    <div class="col-12 col-lg-6 align-self-center flex-grow-1">
      <br />
      <br />
      <div class="row">
      </div>
      <br>
      <div class="row">
        <div class="col">
          <h1 class="main-text">
            404 - Not Found
          </h1>
          <h2>
            The requested URL or resource could not be found on the server
          </h2>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>
