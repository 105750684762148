<ng-template *ngIf="exchangeName === 'chainex'" [ngTemplateOutlet]="chainex"></ng-template>
<ng-template *ngIf="exchangeName === 'burnx'" [ngTemplateOutlet]="burnx"></ng-template>

<ng-template #chainex>
  <div *ngIf="collapsedView">
  <div class="border col-12"></div>
  <div class="row mx-0 d-block d-xl-none clickable" *ngFor="let price of coinPrices">
    <a class="no-decoration" [routerLink]="'markets/'+price.coinCode+'/'+price.exchangeCode" (click)="openMarket(price)">
      <div class="col-12 px-1">
        <div class="row mx-0 py-2">
          <div class="col-6 px-0 text-left">
            <small class="orange-text">{{price.marketPair}} :</small>
          </div>
          <div class="col-6 px-0 text-right">
            <small class=""> {{+(price.lastPrice)| decimalNumber: +(price.exchangeDecimals)}}</small>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="row mx-0 d-block" *ngFor="let vol of coinVolumes">
    <div class="col-12 px-1">
      <div class="row mx-0 py-2">
        <div class="col-6 px-0 text-left">
          <small class="orange-text" i18n>24HR {{vol.coinCode}} VOL :</small>
        </div>
        <div class="col-6 px-0 text-right">
          <small class=""> {{vol.volume}}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row mx-0 d-block clickable" *ngFor="let stats of dynamicStats">
    <a class="no-decoration" [routerLink]="'markets/'+stats.coinCode+'/'+stats.exchangeCode" (click)="openMarket(stats)">
      <div class="col-12 px-1">
        <div class="row mx-0 py-2">
          <div class="col-6 px-0 text-left">
            <small class="orange-text">{{stats.text}} :</small>
          </div>
          <div class="col-6 px-0 text-right">
            <ng-container *ngIf="stats.type == 'maxVol'; else changeVal">
              <small class="">{{stats.marketPair}} {{stats.volume}}</small>
            </ng-container>
            <ng-template #changeVal>
              <small class=""> {{stats.change}} % {{stats.coinCode}}</small>
            </ng-template>
          </div>
        </div>
      </div>
    </a>
  </div>
  </div>

  <div class="container-fluid d-block top-bar-stats background-grey" *ngIf="!collapsedView">
    <div class="row d-flex justify-content-around">
      <div class="col-auto px-1 text-center clickable"  *ngFor="let price of coinPrices">
        <a class="no-decoration" [routerLink]="'markets/'+price.coinCode+'/'+price.exchangeCode" (click)="openMarket(price)">
          <small class="white-text">{{price.marketPair}} :</small> <small class="txt-bold white-text">
            {{+(price.lastPrice) | decimalNumber: +(price.exchangeDecimals)}}</small>
        </a>
      </div>
      <div class="col-auto px-1 d-none d-sm-block text-center" *ngFor="let vol of coinVolumes">
        <small class="white-text" i18n>24HR {{vol.coinCode}} VOL :</small> <small class="txt-bold white-text">
          {{vol.volume}}</small>
      </div>
      <div class="col-auto px-1 d-none d-xl-block text-center clickable background-grey" *ngFor="let stats of dynamicStats">
        <a class="no-decoration" [routerLink]="'markets/'+stats.coinCode+'/'+stats.exchangeCode" (click)="openMarket(stats)">
          <small class="white-text">{{stats.text}} :</small>
          <ng-container *ngIf="stats.type == 'maxVol'; else changeVal">
            <small class="white-text"> {{stats.marketPair}} {{stats.volume}}</small>
          </ng-container>
          <ng-template #changeVal>
            <small class="white-text">  {{stats.coinCode}}/{{stats.exchangeCode}} {{stats.change}} %</small>
          </ng-template>
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #burnx>

  <div class="top-bar-stats font-avenir-book">
    <div class="row ">
      <div class="col-6 background-grey text-center clickable" *ngFor="let price of coinPrices">
        <a class="no-decoration" [routerLink]="'markets/'+price.coinCode+'/'+price.exchangeCode" (click)="openMarket(price)">
          <small class="">{{price.marketPair}} :</small> <small class="txt-bold">
            {{price.lastPrice | decimalNumber: 8: price.exchangeCode}}</small>
        </a>
      </div>
      <div class="col-6 background-grey text-center">
          <small class="" i18n>BurnX 24 VOL:</small> <small class="txt-bold"> {{totalVolume}} BTC</small>
      </div>
      <div class="col background-grey grey-panel text-center p-1 clickable" *ngFor="let i of [0,1,2]">
        <a class="no-decoration" [routerLink]="'markets/'+rawStats[i]?.coinCode+'/'+rawStats[i]?.exchangeCode" (click)="openMarket(rawStats[i])">
          <small class="">{{rawStats[i]?.coinName}} :</small>
          <small class=""> {{rawStats[i]?.volume}} {{rawStats[i]?.change}}%</small>
        </a>
      </div>
    </div>
  </div>

</ng-template>
