<mat-expansion-panel [disabled]="!marketService.expandable" [expanded]="marketService.expanded" class="lefttop fullheight">
  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
    <div class="table-heading markets-heading pl-2 font-avenir-medium" i18n>MARKETS</div>
  </mat-expansion-panel-header>

  <mat-tab-group class="market" (selectedTabChange)="pairChange($event)" [selectedIndex]="selectedTabIndex">
    <mat-tab label="{{coin}}" *ngFor="let coin of coinLabels;trackBy: trackCoinLabel">
    </mat-tab>
  </mat-tab-group>

  <mat-form-field class="w-100 no-margin">
    <input matInput [formControl]="filterControl" i18n-placeholder placeholder="Search" name="filter" [(ngModel)]="filterSearch"
      (keyup)="filter()">
    <button mat-button *ngIf="filterSearch" matSuffix mat-icon-button (click)="clearFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="restrict-table-height">
    <mat-table matSort matSortActive="volume" matSortDirection="desc" [dataSource]="coinData" class="marketTable fullheight no-x-overflow" [trackBy]="trackByCoinData">
      <ng-container matColumnDef="favorites">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="clickable " color="{{isFavorite(element.id) ? 'primary' : 'accent'}}" (click)="toggleFavourites(element.id, $event)">{{isFavorite(element.id)
            ? 'star' : 'star_border'}}</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pair">
        <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Coin</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="rowClick(element.id, $event)">
          <a class="market-link-coin side-bar-market-link" (click)="rowClick(element.id, $event)" [href]="'/markets/' + element.pair">
            {{element.coin_code}}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Price</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="rowClick(element.id, $event)">
          <a class="market-link side-bar-market-link" (click)="rowClick(element.id, $event)" [href]="'/markets/' + element.pair">
            {{element.price | decimalNumber:+(element.exchange_decimals)}}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="change">
        <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>24HR</mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column-change-percentage" (click)="rowClick(element.id, $event)"
          [ngClass]="{'positive': element.change && element.change[0] === '+','negative': element.change && element.change[0] === '-'}">
          <a class="market-link side-bar-market-link" (click)="rowClick(element.id, $event)" [href]="'/markets/' + element.pair">
            {{element.change}}%
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="volume">
        <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" i18n>Vol.</mat-header-cell>
        <mat-cell *matCellDef="let element" matTooltip="{{element.volume}} {{element.exchange_code}} ({{element.volume_amount}} {{element.coin_code}})">
          <a class="market-link side-bar-market-link" (click)="rowClick(element.id, $event)" [routerLink]="'/markets/' + element.coin_code + '/' + element.exchange_code">
            {{element.volume}}
          </a>
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: tableDefinition;" routerLink="/markets/{{row.coin_code}}/{{row.exchange_code}}"
        routerLinkActive="active" [ngClass]="{active: marketService.activeMarket.coinCode === row.coin_code && marketService.activeMarket.exchangeCode === row.exchange_code}"></mat-row>


      <mat-header-row *matHeaderRowDef="tableDefinition; sticky: true" class="clickable"></mat-header-row>
    </mat-table>

  </div>
</mat-expansion-panel>
