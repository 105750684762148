import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SessionStorageService, SubscriptionLike } from 'src/app/core/services/session-storage.service';
import { SettingsService } from 'src/app/settings/settings.service';

@Component({
  selector: 'shared-disable-twofa-fallback',
  templateUrl: './disable-twofa-fallback.component.html',
  styleUrls: ['./disable-twofa-fallback.component.scss']
})
export class DisableTwofaFallbackComponent implements OnInit, OnDestroy {
  public dialogRef: MatDialogRef<DisableTwofaFallbackComponent>;
  private subs: SubscriptionLike[] = [];
  email: string;
  dialogHeader: boolean = false;
  emailSent: boolean = false;
  submitFailed: boolean = false;
  resultMessage: string = '';
  recaptcha: string;
  enableRecaptch: boolean = true;
  darkTheme: boolean;
  missingRecaptcha: boolean;
  formData: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private service: SettingsService,
    @Optional() _dialogRef: MatDialogRef<DisableTwofaFallbackComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: boolean
  ) {
    if (_dialogRef) {
      this.dialogHeader = true;
      this.dialogRef = _dialogRef;
    }
  }

  public resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
    this.missingRecaptcha = false;
  }

  ngOnInit() {
    this.recaptcha = '';
    this.resetErrors();

    this.subs.push(this.sessionStorage.observe('THEME').subscribe(
      (data) => {
        this.darkTheme = (data === 'dark-theme');
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => !!sub && sub.unsubscribe());
  }

  resetErrors() {
    this.missingRecaptcha = false;
  }

  validateForm() {
    let valid = true;
    if (this.recaptcha === '') {
      this.missingRecaptcha = true;
      valid = false;
    }
    return valid;
  }

  confirmDisable() {
    if (this.validateForm()) {
      if (!this.email) {
        this.email = this.sessionStorage.get('PROFILE_EMAIL');
      }
      if (this.email) {
        const data = {
          email: this.email,
          type: 2,
          'g-recaptcha-response': this.recaptcha
        };

        this.service.disableSettingFallback(data).pipe(take(1)).subscribe((response) => {
          if (response.response === 'success') {
            this.emailSent = true;
          } else if (response.response === 'failure') {
            this.submitFailed = true;
            this.resultMessage = response.reason;
          }
        }, (err) => {
          this.submitFailed = true;
          this.resultMessage = 'Something went wrong. Please try again or contact support.';
        });

      } else {
        this.submitFailed = true;
        this.resultMessage = 'Email address not available. Please contact support.';
      }
    }
  }

  continueClick() {
    if (this.dialogRef) {
      this.dialogRef.close(true);
    } else {
      this.router.navigate(['/profile']);
    }
  }

  cancelClick() {
    if (this.dialogRef) {
      this.dialogRef.close(true);
    } else {
      this.router.navigate(['/login']);
    }
  }

}
