<mat-icon class="dialog-close" (click)="close_click()">close</mat-icon>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center pb-2">
            <h3>{{data.title | i18n}}</h3>

            <mat-icon *ngIf="data.result==='success'" color="primary" class="result-icon pb-4 pt-4 no-select">check_circle_outline</mat-icon>
            <mat-icon *ngIf="data.result==='failed'" color="primary" class="result-icon pb-4 pt-4 no-select">cancel</mat-icon>

            <div class="mt-2 mb-2 text-left overflowWrap" id="reasonContainer" [innerHTML]="data.reason|i18n">
            </div>
        </div>

        <div class="col-12 text-center pt-2">
            <button mat-raised-button color="accent" (click)="close_click()" i18n>CLOSE</button>
        </div>
    </div>
</div>
