<h2 i18n>Quick {{ isBuy ? 'Buy' : 'Sell' | i18n}}</h2>

<ng-container *ngIf="step === 0 && coinMarketsOrdered.length">
  <ng-container *ngIf="(isBuy && hasExchangeBalance) || hasBalance; else noBalance">
    <p>
      <ng-container>
        <div class="flex-container">
          <ng-container *ngIf="isBuy; else sell" i18n>
            What would you like to buy?
          </ng-container>
          <ng-template #sell i18n>
            What would you like to sell?
          </ng-template>
          <div>
            <mat-checkbox [(ngModel)]="hideZeroBalances" i18n>Hide Zero Balances</mat-checkbox>
          </div>
        </div>
      </ng-container>
    </p>

    <div *ngFor="let coin of coinMarketsOrdered; trackBy: trackByCoin"
      (click)="!isBuy && +coin.balance === 0 ? openCurrencyManagement($event, coin) : selectCoin(coin.coinCode)">
      <div class="row exchange-box" *ngIf="hideZeroBalances ? coin.balance > 0 : coin.balance >= 0">
        <div class="coin-box">{{coin.coinCode | i18n}}</div>
        <div class="available-box notMobile"><small><ng-container i18n>Available Balance: </ng-container> {{coin.balance}}</small></div>
        <div class="available-box mobile"><small>{{coin.balance}}</small></div>
        <div class="deposit-box">
          <a class="txt-orange" (click)="openCurrencyManagement($event, coin)">Deposit</a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noBalance>
    <p>
      <ng-container *ngIf="isBuy; else sell" i18n>
        Unfortunately you don&apos;t have any coins or currency to buy something with.
      </ng-container>

      <ng-template #sell i18n>
        Unfortunately you do not have any coins available to sell.
      </ng-template>

      Select any of the coins below to perform a deposit of the selected coin:
    </p>

    <div class="row exchange-box" *ngFor="let coin of coinMarketsOrdered; trackBy: trackByCoin" (click)="openCurrencyManagement($event, coin)">
      <div class="col-12" [class.col-3]="data.type === 1">{{coin.coinName | i18n}} <small>({{coin.coinCode | i18n}})</small></div>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="step === 1">
  <p>
    <ng-container *ngIf="isBuy; else selling" i18n>
      You are buying {{ selectedCoin.coinName | i18n }},
    </ng-container>

    <ng-template #selling i18n>
      You are selling {{ selectedCoin.coinName | i18n }},
    </ng-template>

    <a href="" (click)="$event.preventDefault(); step = step - 1;" i18n> Click here to choose a different coin.</a>
  </p>

  <ng-container *ngIf="isBuy; else receive">
    <p i18n>With what would you like to pay?</p>
  </ng-container>

  <ng-template #receive>
    <p i18n>What would you like to receive?</p>
  </ng-template>

  <div class="row exchange-box" *ngFor="let exchange of selectedCoin.exchanges; trackBy: trackByCoin"
    (click)="isBuy && +exchange.balance === 0 ? openCurrencyManagement($event, exchange) : selectExchange(exchange.coinCode)">
    <div class="coin-box" >{{exchange.coinCode | i18n}}</div>
    <div class="available-box notMobile"><small><ng-container i18n>Available Balance: </ng-container> {{exchange.balance}}</small></div>
    <div class="available-box mobile" [class.available-box-full]="data.type === 1">
      <small>
        <ng-container *ngIf="data.type === 1" i18n>Available Balance: </ng-container>
        {{exchange.balance}}
      </small>
    </div>

    <div class="deposit-box" *ngIf="isBuy">
      <a class="txt-orange" (click)="openCurrencyManagement($event, exchange)">Deposit</a>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="step === 2">

  <app-quick-order-dialog [setting]="{type: isBuy ? 'buy' : 'sell', exchange: selectedExchange, coin: selectedCoin.coinCode}">
  </app-quick-order-dialog>
</ng-container>

<app-warning-box></app-warning-box>
