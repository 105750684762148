import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-generic-response',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss']
})
export class GenericComponent implements OnInit {
  private i18n: I18n;
  responseCodeHeader: string;
  responseMessage: string;

  constructor( i18n: I18n,
    private route: ActivatedRoute,
    ) {
    this.i18n = i18n;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['code'] != null) {
        switch (params['code']) {
        case '404': {
          this.responseCodeHeader = this.i18n('404 - Not found');
          this.responseMessage = this.i18n('The requested URL or resource could not be found on the server.');
          break;
        }
        case 'ipbanned': {
          this.responseCodeHeader = this.i18n('ACCESS DENIED');
          this.responseMessage = this.i18n('Your IP has been blocked.');
          break;
        }
        case '403': {
          this.responseCodeHeader = this.i18n('403 - Forbidden');
          this.responseMessage = this.i18n('You do not have permission to access this URL or resource.');
          break;
        }
        default : {
          this.responseCodeHeader = this.i18n('Something went wrong');
          this.responseMessage =
          this.i18n('Our servers were not able to process your request. We apologise for the inconvenience.');
        }
        }
      } else {
        this.responseCodeHeader = this.i18n('Something went wrong');
        this.responseMessage =
        this.i18n('Our servers were not able to process your request. We apologise for the inconvenience.');
      }
    });

  }
/*
  private printURL() {
     // Need to investigate how to get previous url
    this.responseMessage += '\n\r Your requested URL: ' + this.route.snapshot.url;
  }
*/
}
