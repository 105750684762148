import { RouterHelper } from './../router-helper';
import { SessionStorageService } from './session-storage.service';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '../interfaces/auth';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    private sessionStorage: SessionStorageService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const auth = (this.sessionStorage.get('LOGGED_IN') === 'logged_in' &&
      this.sessionStorage.get('TOKEN') !== '');

    if (!auth) {
      RouterHelper.goToLogin(this.router, state);
    }
    return auth;
  }

}

@Injectable({
  providedIn: 'root'
})
export class AuthRerouteGuard implements CanActivate {

  constructor(
    public router: Router,
    private sessionStorage: SessionStorageService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const auth = (this.sessionStorage.get('LOGGED_IN') === 'logged_in' &&
        this.sessionStorage.get('TOKEN') !== '');

      const level = !!this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL')
        ? Number(this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL'))
        : 0;
      if (auth && level >= 1) {
        this.router.navigate(['/markets']);
      } else if (auth) {
        this.router.navigate(['/profile']);
      }
      return !auth;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  constructor(private sessionStorage: SessionStorageService, public router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const auth = (this.sessionStorage.get('LOGGED_IN') === 'logged_in' &&
      this.sessionStorage.get('TOKEN') !== '');
    if (auth) {
      // route not allowed, already logged in
      if (next.url[0].path === 'forgot') {         // if user followed a forgot password link
        this.router.navigate(['/settings/reset']); // open change password dialogue
      } else {                                     // else return to landing
        this.router.navigate(['/']);
      }
    }
    return !auth;
  }
}
