import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CardDetail, CoinSimple, QuickDialogSetting } from 'src/app/core/interfaces/quickbuysell';

import { QuickOrderDialogComponent } from 'src/app/markets/order-types/quick-order-dialog/quick-order-dialog.component';
import { CurrencyManagementComponent } from 'src/app/balances/currency-management/currency.management.component';

import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Component({
  selector: 'app-balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: ['./balance-card.component.scss']
})
export class BalanceCardComponent {
  @Input() card: CardDetail;
  @Input() showVerification: boolean;

  constructor(
    public dialog: MatDialog,
    private sessionStorage: SessionStorageService
  ) {}

  openCurrencyManagement(coin: CoinSimple, mode: number, coin_networks: any) {
    this.dialog.open(CurrencyManagementComponent, {
      width: '1200px',
      data: {
        code: coin.code,
        type: coin.coin_type,
        icon: coin.icon,
        mode,
        has_product: coin.has_product,
        has_coin_networks: coin.has_coin_networks,
        coin_networks: coin_networks
      }
    });
  }

  quickOrderDialog(code: string, type: string) {
    const isExchangeCoin = ['ZAR', 'USDT'].indexOf(code) !== -1;
    const zaUser = this.sessionStorage.get('PROFILE_COUNTRY') === 'South Africa';

    const data: QuickDialogSetting = {
      coin: isExchangeCoin ? 'BTC' : code,
      exchange: code === 'BTC' ? (zaUser ? 'ZAR' : 'USDT') : (isExchangeCoin ? code : 'BTC'),
      type
    };
    const dialogRef = this.dialog.open(QuickOrderDialogComponent, {
      width: '550px',
      data: data
    });
  }
}
