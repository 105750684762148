import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SubscriptionLike } from 'rxjs';
import { FundPin } from 'src/app/core/interfaces/fund-pin';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { FundPinValidator } from 'src/app/core/validators/fund-pin.validator';
import { SettingsService } from '../settings.service';
import { take } from 'rxjs/operators';

export interface FundPinDialog {
  enable: any;
}

@Component({
  selector: 'app-fund-pin',
  templateUrl: './fund-pin.component.html',
  styleUrls: ['./fund-pin.component.scss']
})
export class FundPinComponent implements OnInit {
  darkTheme: boolean = false;
  defaultDialog: boolean = true;
  disableFallbackUnconfirmed: boolean = true;
  private subs: SubscriptionLike[] = [];
  disableData: FormGroup;
  enableData: FormGroup;
  resultMessage: string;
  submitFailed: boolean;

  enableFundPin: boolean;
  recaptcha: string;
  missingRecaptcha: boolean;
  formData: FormGroup;

  constructor(private fb: FormBuilder,
    private service: SettingsService,
    private sessionStorage: SessionStorageService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<FundPinComponent>,
    public snackbar: MatSnackBar,
    private translateService: I18nTranslationService,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data: FundPinDialog
  ) {
    if (data !== undefined && typeof (data.enable) === 'boolean') {
      this.enableFundPin = data.enable;
    } else {
      this.enableFundPin = true;
    }
  }

  public resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
    this.missingRecaptcha = false;
  }

  ngOnInit(): void {

    if (this.enableFundPin) {
      this.setupEnableForm();
    } else {
      this.setupDisableForm();
    }

    this.recaptcha = '';
    this.resetErrors();

    this.subs.push(this.sessionStorage.observe('THEME').subscribe(
      data => {
        this.darkTheme = (data === 'dark-theme');
      }));
  }

  setupEnableForm() {
    this.enableData = this.fb.group({
      accountPassword: new FormControl('', Validators.required),
      newFundsPin: new FormControl('',
      [Validators.required, Validators.minLength(4), Validators.maxLength(8),
        Validators.pattern(FundPinValidator.PinRegex)]),
      confirmFundsPin: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(8)])
    }, {
      validator: FundPinValidator.MatchPin
    });
  }

  setupDisableForm() {
    this.disableData = this.fb.group({
      accountPassword: new FormControl('', Validators.required),
      fundsPin: new FormControl('', [Validators.required])
    }, { });
  }

  onSubmit() {
    if (this.validateForm()) {
      this.resetErrors();
      if (this.enableFundPin) {
        const data: FundPin = {
          password: this.enableData.controls.accountPassword.value,
          fundpin: this.enableData.controls.newFundsPin.value
        };

        this.service.enableFundPin(data)
          .subscribe((response: any) => {
            if (response.response === 'success') {
              this.resultMessage = response.reason;
              this.snackbar.open(this.translateService.translateResponse(response.reason),
                this.i18n('Close'), { duration: 8000 });
              this.dialogRef.close();
            } else {
              this.submitFailed = true;
              this.resultMessage = response.reason;
              this.enableData.reset();
            }
          });
      } else {
        const data: FundPin = {
          password: this.disableData.controls.accountPassword.value,
          fundpin: this.disableData.controls.fundsPin.value
        };
        this.service.disableFundPin(data)
          .subscribe((response: any) => {
            if (response.response === 'success') {
              this.resultMessage = response.reason;
              this.dialogRef.close();
            } else {
              this.submitFailed = true;
              this.resultMessage = response.reason;
              this.disableData.reset();
            }
          });
      }
    }
  }

  resetErrors() {
    this.submitFailed = false;
    this.resultMessage = '';
    this.missingRecaptcha = false;
  }

  resetForm() {
    this.resetErrors();
    if (this.enableData) {
      this.enableData.reset();
    }
    if (this.disableData) {
      this.disableData.reset();
    }
  }

  validateForm(validateRecaptcha: boolean = false) {
    let valid = true;
    if (validateRecaptcha) {
      if (this.recaptcha === '') {
        this.missingRecaptcha = true;
        valid = false;
      }
    } else {
      let controls;
      if (this.disableData) {
        controls = this.disableData.controls;
      } else {
        controls = this.enableData.controls;
      }
      for (const name in controls) {
        if (controls[name].invalid) {
          controls[name].markAsDirty();
          valid = false;
        }
      }
    }
    return valid;
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  onDisableFallback() {
    if (this.validateForm(true)) {
      const email = this.sessionStorage.get('PROFILE_EMAIL');
      if (email && email !== '') {
        const data = {
          email: email,
          type: 3,
          'g-recaptcha-response': this.recaptcha
        };

        this.service.disableSettingFallback(data).pipe(take(1)).subscribe((response) => {
          if (response.response === 'success') {
            this.disableFallbackUnconfirmed = false;
          } else if (response.response === 'failure') {
            this.submitFailed = true;
            this.resultMessage = response.reason;
          }
        }, (err) => {
          this.submitFailed = true;
          console.error(err);
          this.resultMessage = 'Something went wrong. Please try again or contact support.';
        });
      } else {
        this.submitFailed = true;
        this.resultMessage = 'Email address not available. Please contact support.';
      }
      this.disableFallbackUnconfirmed = false;
    }
  }

}
