import { Component, OnInit, Inject, ChangeDetectorRef, Optional, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuickDialogSetting } from 'src/app/core/interfaces/quickbuysell';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as Big from 'big-js';
import { MarketsService, IMarket } from 'src/app/markets/markets.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { StoreService } from 'src/app/core/services/store.service';
import { orderType, executionType } from 'src/app/markets/order-types/order-types-shared';
import {
  DialogData,
  MarketConfirmationComponent
} from 'src/app/markets/market-confirmation/market-confirmation.component';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { UtilHelper } from 'src/app/core/helpers/util-helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quick-order-dialog',
  templateUrl: './quick-order-dialog.component.html',
  styleUrls: ['./quick-order-dialog.component.scss']
})
export class QuickOrderDialogComponent implements OnInit {
  public valueChange: Function = UtilHelper.bigValueToFixed;

  @Input() setting: QuickDialogSetting = {
    type: '',
    coin: '',
    exchange: ''
  };
  orderForm: FormGroup;

  coin: string = '';
  exchange: string = '';
  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  amount: number | Big = new Big(0).toFixed(8);
  price: number | Big = new Big(0).toFixed(8);
  fee: number | Big = new Big(0).toFixed(8);
  total: number | Big = new Big(0).toFixed(8);
  netTotal: number | Big = new Big(0).toFixed(8);
  priceMarket: number | Big = new Big(0).toFixed(8);
  minAmountBuy: number | Big = new Big(0).toFixed(8);
  minAmountSell: number | Big = new Big(0).toFixed(8);

  priceDeviationError: boolean = false;

  amountAvailable: number | Big = new Big(0).toFixed(8);
  totalAvailable: number | Big = new Big(0).toFixed(8);
  netTotalAvailable: number | Big = new Big(0).toFixed(8);
  quickOrder: boolean = true;

  feeType: string = 'takerFee';

  decimalSpaces: number = 8;
  amountDecimalSpaces: number = 8;
  totalMinimum: number = 0.0001;
  netTotalMinimum: number = 0.0001;

  minAmount: number | Big = new Big(0).toFixed(8);
  feePercentage: number | Big = new Big(0).toFixed(8);
  quickTradingFee: number | Big = new Big(0).toFixed(8);
  firstPrice: number | Big = new Big(0).toFixed(8);
  executionType: string = '';

  coins: string[] = [];
  exchanges: string[] = [];
  markets: {
    coin: string,
    exchange: string,
    id: string
  }[] = [];
  activeMarket: IMarket;

  balanceCoin: any = {
    balance_available: 0.00000000, code: ''
  };
  balanceExchange: any = {
    balance_available: 0.00000000, code: ''
  };

  storeSubscriptions: any = [];

  orderBookArray: any = [];

  showConfirmation: boolean = true;
  netTotalChanged: boolean = false;

  verificationLevel: number = 0;
  profileCountry: string = '';
  balance: any;

  private sellAmountAvailable: number | Big = new Big(0).toFixed(8);
  private buyAmountAvailable: number | Big = new Big(0).toFixed(8);

  constructor(private sessionStorage: SessionStorageService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private CDRef: ChangeDetectorRef,
    private store: StoreService,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    public marketService: MarketsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<QuickOrderDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: QuickDialogSetting) {
    if (data) {
      this.setting = data;
    }
    this.orderForm = this.formBuilder.group({
      coin: ['', Validators.required],
      exchange: ['', Validators.required],
      amount: ['', Validators.required],
      total: ['', Validators.required],
      net_total: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.verificationLevel = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL') || 0;
    this.profileCountry = this.sessionStorage.get('PROFILE_COUNTRY') || '';

    this.coin = this.setting.coin;
    this.exchange = this.setting.exchange;

    const waiting: Promise<any>[] = [];

    waiting.push(this.marketService.getMarketData()
    .toPromise().then((response: IMarket[]) => {
      for (const currentMarket of response) {
        if (this.coins.indexOf(currentMarket.coinCode) === -1) {
          this.coins.push(currentMarket.coinCode);
        }

        if (this.exchanges.indexOf(currentMarket.exchangeCode) === -1) {
          if (['ZAR', 'USDT'].indexOf(currentMarket.exchangeCode) !== -1 && this.verificationLevel === 0) {
            continue;
          }
        }

        if (this.markets.filter(obj => obj.coin === currentMarket.coinCode
          && obj.exchange === currentMarket.exchangeCode)) {
          this.markets.push({
            coin: currentMarket.coinCode,
            exchange: currentMarket.exchangeCode,
            id: currentMarket.id
          });
        }
      }

      this.quickTradingFee = this.store.getQuickTradingFee();
      this.reloadExchanges(this.coin);
      this.exchanges.sort();
      this.coins.sort();
      this.setDefaultExchange(this.verificationLevel, this.profileCountry);
    }));

    Promise.all(waiting).then( () => {
      this.setActiveMarket();
      this.setupFormValidation();
      this.amount = 0;
      this.amountChange();
      this.orderForm.markAsPristine();
      this.orderForm.markAsUntouched();
    });
  }

  resetFormValidation() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).setValidators(null);
      this.orderForm.get(key).updateValueAndValidity();
    });
    this.orderForm.markAsPristine();
    this.orderForm.markAsUntouched();
  }

  selectMinAmount() {
    this.processOrderBook();
    if (this.exchangeName === 'burnx') {
      if (this.setting.type === 'sell') {
        this.amountAvailable = this.sellAmountAvailable;
        this.minAmount = this.minAmountBuy;
      } else {
        this.amountAvailable = this.buyAmountAvailable;
        this.minAmount = this.minAmountSell;
      }
    }
  }

  setupFormValidation() {
    this.resetFormValidation();
    const maximumValue = (this.setting.type === 'buy' ? false :
      (!!this.balance ? this.balance?.balance_available : this.amountAvailable));
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, null, +this.price, this.calcFeeToUse('takerFee'), this.activeMarket);

    const amountValidator = () => (control) => {
      if (this.setting.type === 'sell') {
        if (+this.amount > +this.balanceCoin.balance_available) {
          return { 'max2': true };
        }
      }
      if (+this.amount > +this.amountAvailable) {
        return { max: true, actual: control.value };
      }
      if (+this.amount <= 0) {
        return {'min': true };
      }
      if (this.priceDeviationError) {
        return { priceDeviation: true };
      }
      return null;
    };

    const netTotalValidator = () => (control) => {
      if (this.setting.type === 'buy') {
        if (+this.netTotal > +this.netTotalAvailable) {
          return { 'max3': { 'max': this.netTotalAvailable, 'actual': this.netTotal } };
        }
        if (+this.totalMinimum > +this.netTotal && +(this.amount) !== 0) {
          return { 'min1': { 'min': this.totalMinimum, 'actual': this.amount } };
        }
      } else {
        if (+this.netTotal > +this.netTotalAvailable) {
          return { 'max3': { 'max': this.netTotalAvailable, 'actual': this.netTotal } };
        }
        if (+this.totalMinimum > +this.total && +(this.amount) !== 0) {
          return { 'min2': { 'min': this.totalMinimum, 'actual': this.amount } };
        }
      }
      if (+this.netTotal <= 0) {
        return {'min': true };
      }
      return null;
    };

    const balanceValidator = () => (control) => {
      if (this.setting.type === 'buy') {
        if (+this.netTotal > +this.balanceExchange.balance_available) {
          return { 'max1': { 'max': this.netTotal, 'actual': this.balanceExchange.balance_available } };
        }
      }
      return null;
    };

    const amountCheck = () => (control) => {
      this.selectMinAmount();
      const minAmount = this.setting.type === 'buy' ? this.minAmountBuy : this.minAmountSell;
      if (+minAmount === 0) {
        if (+minAmount >= +this.amount) {
          return { min0: true };
        }
      } else {
        if (+minAmount > +this.amount) {
          return { min1: true };
        }
      }
      if (this.priceDeviationError) {
        return { priceDeviation: true };
      }
      return null;
    };

    this.orderForm.controls.coin.setValidators(null);
    this.orderForm.controls.coin.updateValueAndValidity();
    this.orderForm.controls.coin.setValidators([Validators.required]);
    this.orderForm.controls.coin.updateValueAndValidity();

    this.orderForm.controls.exchange.setValidators(null);
    this.orderForm.controls.exchange.updateValueAndValidity();
    this.orderForm.controls.exchange.setValidators([Validators.required]);
    this.orderForm.controls.exchange.updateValueAndValidity();

    this.orderForm.controls.amount.setValidators(null);
    this.orderForm.controls.amount.updateValueAndValidity();
    this.orderForm.controls.amount.setValidators(
      [Validators.required, amountValidator(), amountCheck(), Validators.min(this.minAmount)]
      );
    this.orderForm.controls.amount.updateValueAndValidity();

    this.orderForm.controls.net_total.setValidators(null);
    this.orderForm.controls.net_total.updateValueAndValidity();
    this.orderForm.controls.net_total.setValidators([Validators.required, netTotalValidator(), balanceValidator()]);
    this.orderForm.controls.net_total.updateValueAndValidity();

    this.orderForm.markAsPristine();
  }

  marketExist(coin: string, exchange: string) {
    const count: number = this.markets.filter(obj => obj.coin === coin && obj.exchange === exchange).length;
    return count === 1 ? true : false;
  }

  changeMarket(event: any, type: string) {
    if (type === 'coin') {
      this.reloadExchanges(event.value);
      if (!this.marketExist(this.coin, this.exchange)) {
        this.exchange = this.exchanges[0];
      }
    } else {
      this.reloadCoins(event.value);
      if (!this.marketExist(this.coin, this.exchange)) {
        this.coin = this.coin[0];
      }
    }
    this.setActiveMarket();
    this.setupFormValidation();
  }

  setDefaultExchange(verificationLevel: number, profileCountry: string) {
    if (!verificationLevel || verificationLevel < 1) {
      this.exchange = 'BTC';
    } else {
      if (profileCountry === 'South Africa') {
        this.exchange = this.exchange || 'ZAR';
      } else {
        this.exchange = this.exchange || 'USDT';
      }
    }
    if (!this.marketExist(this.coin, this.exchange)) {
      this.exchange = this.exchange || 'BTC';
    }
  }

  setActiveMarket() {
    if (this.coin !== '' && this.exchange !== '') {
      const market = this.markets.filter(obj => obj.coin === this.coin && obj.exchange === this.exchange);
      if (market.length > 0) {
        this.activeMarket = this.marketService.getMarketByID(market[0].id);
        if (this.activeMarket) {
          this.totalMinimum = ['ZAR', 'USDT'].indexOf(this.activeMarket.exchangeCode) === -1 ? 0.0001 : 1;
          this.netTotalMinimum = ['ZAR', 'USDT'].indexOf(this.activeMarket.exchangeCode) === -1 ? 0.0001 : 1;
          this.amountDecimalSpaces = +this.activeMarket.coinDecimals;
          this.decimalSpaces = +this.activeMarket.exchangeDecimals;
          this.amountChange();
          this.netTotalChange();
          this.loadOrderBook();

          if (this.activeMarket.id !== '-1') {
            this.store.subscribe('balances').subscribe(response => {
              if (!response.refresh) {
                const filteredResponseCoin = response.data.filter(responseBalance =>
                  responseBalance.code === (this.coin))[0];
                const filteredResponseExchange = response.data.filter(responseBalance =>
                  responseBalance.code === (this.exchange))[0];
                if (filteredResponseCoin !== undefined) {
                  this.balanceCoin = filteredResponseCoin;
                }
                if (filteredResponseExchange !== undefined) {
                  this.balanceExchange = filteredResponseExchange;
                }
                if (!this.CDRef['destroyed']) {
                  this.CDRef.detectChanges();
                }
              }
            });
          }
        } else {
          console.error('Could not find active market id:', market[0].id);
        }
      } else {
        console.error('No markets were set');
      }
    }
  }

  reloadExchanges(coin: string) {
    this.exchanges = this.markets.filter(obj => obj.coin === coin).map(function (obj: any) {
      return obj.exchange;
    });
    this.exchanges.sort();
  }

  reloadCoins(exchange: string) {
    this.coins = this.markets.filter(obj => obj.exchange === exchange).map(function (obj: any) {
      return obj.coin;
    });
    this.coins.sort();
  }

  fillAmount() {
    if (this.setting.type === 'buy') {
      this.netTotal = this.balanceExchange.balance_available;
      this.netTotalChange();
    } else {
      this.amount = this.balanceCoin.balance_available;
      this.amountChange();
    }
  }

  calcFeeToUse(type: string = 'makerFee') {
    let toUse = +this.activeMarket[type];
    if (this.marketService[type] < toUse) {
      toUse = +this.marketService[type];
    }
    return toUse;
  }

  amountChange() {
    this.amount = this.valueChange(this.amount, this.amountDecimalSpaces);
    if (!this.netTotalChanged) {
      if (!!this.orderBookArray && this.orderBookArray.length > 0) {
        this.processOrderBookForAmount(this.orderBookArray);
      }
      const maximumValue = (this.setting.type === 'buy' ? false :
      (!!this.balance ? this.balance?.balance_available : this.amountAvailable));
      this.minAmount = this.marketService.getMinAmount(
        this.minAmount, maximumValue, null, +this.price, this.calcFeeToUse('takerFee'), this.activeMarket);
      this.orderForm.controls.amount.markAsTouched();
      this.orderForm.controls.net_total.markAsTouched();
    }
  }

  processOrderBook(checkDeviationOnly: boolean = false) {
    if (this.exchangeName === 'chainex') {
      if (!!this.orderBookArray && this.orderBookArray.length > 0) {
        this.calculateMinAmountNeeded(this.orderBookArray, this.setting.type);
        this.processOrderBookForAmount(this.orderBookArray, checkDeviationOnly);
      }
    }
  }

  netTotalChange() {
    this.netTotalChanged = true;
    this.netTotal = this.valueChange(this.netTotal, this.decimalSpaces);
    if (!!this.orderBookArray && this.orderBookArray.length > 0) {
      this.processOrderBookForNetTotal(this.orderBookArray);
    }
    this.netTotalChanged = false;
    const maximumValue = (this.setting.type === 'buy' ? false :
      (!!this.balance ? this.balance?.balance_available : this.amountAvailable));
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, null, +this.price, this.calcFeeToUse('takerFee'), this.activeMarket);
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.net_total.markAsTouched();
  }

  calculateOrderNetTotal(price: number, amount: number, fee: number, total: number, type: string = 'partial') {
    if (type === 'full') {
      if (['ZAR', 'USDT'].indexOf(this.activeMarket.exchangeCode) === -1) {
        const currentFee = new Big(1)[this.setting.type === 'buy' ? 'plus' : 'minus'](fee);
        return new Big(amount).times(price).times(currentFee);
      } else {
        const currentFee  = new Big(+amount).times(new Big(fee));
        const feeCalc = new Big(amount)[this.setting.type === 'buy' ? 'plus' : 'minus'](currentFee).times(price);
        return feeCalc;
      }
    } else {
      if (['ZAR', 'USDT'].indexOf(this.activeMarket.exchangeCode) === -1) {
        const currentFee  = new Big(+price).times(+amount).times(new Big(fee));
        return new Big(+total)[this.setting.type === 'buy' ? 'plus' : 'minus'](currentFee);
      } else {
        const currentFee  = new Big(+amount).times(new Big(fee));
        const feeCalc = new Big(amount)[this.setting.type === 'buy' ? 'plus' : 'minus'](currentFee).times(price);
        return feeCalc;
      }
    }
  }

  processOrderBookForNetTotal(orderBook: any = []) {
    try {
      let feeToUse = (+this.activeMarket[this.feeType] && +this.activeMarket[this.feeType] > this.quickTradingFee ?
        +this.activeMarket[this.feeType] : this.quickTradingFee);
      if (this.marketService[this.feeType] < feeToUse) {
        feeToUse = +this.marketService[this.feeType];
      }
      let netTotalToOffer: number = this.netTotal;
      let currentNetTotal: number | Big = new Big(0).toFixed(8);
      let amountCalc: number | Big = new Big(0).toFixed(8);
      let totalCalc: number | Big = new Big(0).toFixed(8);
      let totalPrice: number | Big = new Big(0).toFixed(8);
      this.amount = 0;
      const weightCalcData: {
        'amount': number,
        'price': number
      }[] = [];

      for (let i = 0; i < orderBook.length; i++) {
        if (+orderBook[i].amount > 0) {
          currentNetTotal = this.calculateOrderNetTotal(orderBook[i].price, orderBook[i].amount,
            feeToUse, orderBook[i].orderTotal, 'partial');
          let orderTake: number | Big = new Big(0).toFixed(8);
          if (netTotalToOffer - currentNetTotal > 0) {
            orderTake = +orderBook[i].amount;
            totalCalc = new Big(totalCalc).plus(orderBook[i].orderTotal);
            netTotalToOffer = netTotalToOffer - currentNetTotal;
          } else {
            let currentAmount: number | Big = new Big(0).toFixed(8);
            if (['ZAR', 'USDT'].indexOf(this.activeMarket.exchangeCode) === -1) {
              const currentFee = new Big(1)[this.setting.type === 'buy' ? 'plus' : 'minus'](feeToUse);
              const currentTotal = new Big(+netTotalToOffer).div(currentFee);
              totalCalc = new Big(totalCalc).plus(currentTotal);
              currentAmount = currentTotal.div(+orderBook[i].price);
            } else {
              const currentFee = new Big(1)[this.setting.type === 'buy' ? 'plus' : 'minus'](feeToUse);
              currentAmount = new Big(netTotalToOffer).div(currentFee.times(orderBook[i].price));
              totalCalc = new Big(currentAmount).times(orderBook[i].price);
            }
            orderTake = currentAmount;
            netTotalToOffer = 0;
          }
          weightCalcData.push({
            'amount': new Big(orderTake),
            'price': new Big(orderBook[i].price)
          });

          amountCalc = new Big(amountCalc).plus(orderTake);
          if (netTotalToOffer <= 0) {
            break;
          }
        }
      }
      weightCalcData.forEach(element => {
        const weight = new Big(element.amount).div(amountCalc);
        const thisPrice = new Big(weight).times(element.price);
        totalPrice = new Big(totalPrice).add(thisPrice);
      });
      this.amount = this.valueChange(amountCalc, this.amountDecimalSpaces);
      this.priceMarket = totalPrice;
      this.priceMarket = this.valueChange(this.priceMarket, this.decimalSpaces);
      this.price = this.valueChange(this.priceMarket, this.decimalSpaces);
      this.total = this.valueChange(totalCalc, this.decimalSpaces);


    } catch (ex) {
      console.error(ex);
    }
  }

  processOrderBookForAmount(orderBook: any = [], checkDeviationOnly: boolean = false) {
    if (!this.activeMarket || +this.amount === 0) {
      return;
    }

    this.priceDeviationError = false;
    try {
      let feeToUse = +this.activeMarket[this.feeType];
      if (this.activeMarket[this.feeType] < feeToUse) {
        feeToUse = +this.activeMarket[this.feeType];
      }
      let amountToOffer: number = this.amount;
      let totalCalc: number | Big = new Big(0).toFixed(8);
      let totalPrice: number | Big = new Big(0).toFixed(8);
      let totalNetTotal: number | Big = new Big(0).toFixed(8);
      let orderNetTotal: number = 0;
      let sumTotal: number = 0;
      let sumAmount: number = 0;
      if (!checkDeviationOnly) {
        this.priceMarket = new Big(0).toFixed(8);
      }

      for (let i = 0; i < orderBook.length; i++) {
        if (+orderBook[i].amount > 0) {
          amountToOffer = amountToOffer - orderBook[i].amount;
          let orderTake: number | Big = new Big(0).toFixed(8);
          let weight: number | Big = new Big(0).toFixed(8);
          if (amountToOffer > 0) {
            orderTake = new Big(+orderBook[i].amount).times(+orderBook[i].price);
            orderNetTotal = this.calculateOrderNetTotal(orderBook[i].price, orderBook[i].amount,
              feeToUse, orderBook[i].orderTotal, 'partial');
            totalCalc = new Big(totalCalc).plus(orderTake);
            weight = new Big(+orderBook[i].amount).div(this.amount);
            sumAmount += orderBook[i].amount;
          } else {
            orderTake = (new Big(+orderBook[i].amount)
              .plus(+amountToOffer))
              .times(+orderBook[i].price);
            orderNetTotal = this.calculateOrderNetTotal(orderBook[i].price,
              new Big(orderBook[i].amount).plus(+amountToOffer),
              feeToUse, orderTake, 'full');
            if (orderNetTotal < +this.netTotalMinimum) {
              // amount rounding error occurred, round up again
              const newDigits = this.activeMarket.coinDecimals - 1;
              const newAmount = new Big(orderBook[i].amount).plus(+amountToOffer).round(newDigits, 3); // round up
              orderNetTotal = this.calculateOrderNetTotal(orderBook[i].price,
                new Big(newAmount),
                feeToUse, orderTake, 'full');
            }
            totalCalc = new Big(totalCalc).plus(orderTake);
            weight = new Big(+orderBook[i].amount)
              .plus(+amountToOffer).div(this.amount);
            sumAmount += +orderBook[i].amount + +amountToOffer;
          }
          sumTotal += +orderTake;
          const thisPrice: number | Big = new Big(+orderBook[i].price).times(weight);
          totalPrice = new Big(totalPrice).plus(thisPrice);
          totalNetTotal = new Big(totalNetTotal).plus(orderNetTotal);
          if ((sumTotal / sumAmount) / orderBook[i].price > 1.05 ||
            (sumTotal / sumAmount) / orderBook[i].price < 0.95) {
              this.priceDeviationError = true;
          }
          if (amountToOffer <= 0) {
            break;
          }
        }
      }

      // get first order's price
      // on the sell order book, this will be the first available price in the array
      this.firstPrice = 0;
      if (this.setting.type === 'buy') {
        let i = 0;
        while (i < orderBook.length && (this.firstPrice === 0)) {
          if (orderBook[i].price > 0) {
            this.firstPrice = orderBook[i].price;
          }
          i++;
        }
      } else {
        this.firstPrice = orderBook[0].price;
      }
      if (sumTotal && sumAmount && (this.firstPrice === 0)) {
        if ((sumTotal / sumAmount) / this.firstPrice > 1.05 ||
          (sumTotal / sumAmount) / this.firstPrice < 0.95) {
          this.priceDeviationError = true;
        }
      }

      if (!checkDeviationOnly) {
        this.priceMarket = this.valueChange(totalPrice, this.decimalSpaces);
        this.price = this.valueChange(this.priceMarket, this.decimalSpaces);
        this.total = this.valueChange(totalCalc, this.decimalSpaces);
        this.netTotal = this.valueChange(totalNetTotal, this.decimalSpaces);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  loadOrderBook() {
    this.orderBookArray = [];

    if (this.activeMarket.id === '-1') {
      return;
    }

    const data = {
      'perPage': 50,
      'pageNo': 0,
      'orderBy': 'price',
      'market': this.activeMarket.id,
      'decimals': 8,
      'type': this.setting.type === 'buy' ? 1 : 0 // for buying we want the sell order book, and vice versa
    };

    this.marketService.getOrderDepth(data).subscribe((response) => {
      if (response.response === 'success') {
        const cleanOrders = (book, type) => {
          for (let i = 0; i < book.length; i++) {
            try {
              const order = book[i];
              order.price = Number(order.price);
              order.amount = Number(order.amount);
              order.orderTotal = Number(order.orderTotal);
              if (order.total === 0) {
                book.splice(i--, 1);
              }
            } catch (ex) { }
          }
          if (type === 'sell') {
            book.sort((a, b) => a.price < b.price ? -1 : 1);
          } else if (type === 'buy') {
            book.sort((a, b) => a.price > b.price ? -1 : 1);
          }
        };
        let feeToUse = +this.activeMarket[this.feeType];

        if (this.marketService[this.feeType] < feeToUse) {
          feeToUse = +this.marketService[this.feeType];
        }
        this.amountAvailable = 0;
        if (response.buy.length > 0 && this.setting.type === 'sell') {
          cleanOrders(response.buy, 'buy');
          this.orderBookArray = response.buy;
          this.amountAvailable = response.buy_total_amount;
          this.totalAvailable = response.buy_total;
          this.netTotalAvailable = new Big(this.totalAvailable).times(new Big(1).minus(feeToUse));
        }
        if (response.sell.length > 0 && this.setting.type === 'buy') {
          cleanOrders(response.sell, 'sell');
          this.orderBookArray = response.sell;
          this.amountAvailable = response.sell_total_amount;
          this.totalAvailable = response.sell_total;
          this.netTotalAvailable = new Big(this.totalAvailable).times(new Big(1).plus(feeToUse));
        }
      }
    });
  }

  order() {
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.amount.updateValueAndValidity();
    this.orderForm.controls.net_total.markAsTouched();
    this.orderForm.controls.net_total.updateValueAndValidity();

    this.orderForm.markAsTouched();

    if (this.orderForm.valid) {

      if (this.showConfirmation) {
        const order = this.setting.type.charAt(0).toUpperCase() + this.setting.type.slice(1);
        const execution = 'Quick';
        let formattedReason = this.i18n(`Are you sure you would like to place this {{type}} order?<br /><br />
                                        <b>Your order will be executed against orders currently in the market's orderbook
                                        </b>, estimated values are as follows:
                                        <br /><br /><b>Market: </b>{{pair}}
                                        <br /><b>Amount: </b>{{amount}} {{coincode}}
                                        <br /><b>Estimated Price: </b>{{price}} {{exchangecode}}
                                        <br /><b>Net Total: </b>MARKET
                                        {{exchangecode}} <br><br>
                                        Please ensure that the above amounts are correct before confirming.
                                        We will be unable to refund/cancel any live order that has been matched.<br /><br />
                                        <b>Are you sure you would like to place this order at the following estimated price?</b><br /><br />
                                        {{format}}<b>Estimated Price: </b>{{price}} {{exchangecode}}{{endFormat}}`, {
        pair: this.activeMarket.marketPair,
        amount: this.amount,
        coincode: this.activeMarket.coinCode,
        price: this.priceMarket,
        exchangecode: this.activeMarket.exchangeCode,
        type: order
        });
        formattedReason = formattedReason.replace('{{format}}', '<div class="border-2 py-2 mb-3 text-center">');
        formattedReason = formattedReason.replace('{{endFormat}}', '</div>');
        const dialogData: DialogData = {
          title: this.i18n(`{{execution}} {{order}} {{coincode}}`,
            { execution, order, coincode: this.activeMarket.coinCode }),
          result: 'string',
          reason: formattedReason,
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          maxHeight: '100vh',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            // check price deviation again after confirmed
            if (!!this.orderBookArray && this.orderBookArray.length > 0) {
              this.processOrderBookForAmount(this.orderBookArray, true);
            }
            if (this.priceDeviationError) {
              this.dialog.open(MarketConfirmationComponent, {
                width: '500px',
                data: {
                  title: 'Failed',
                  result: 'failed',
                  reason: `We are currently unable to place market orders for the specified amount due to price fluctuations.
                          Please try a different amount or make use of limit orders until the market’s prices stabilize.`
                }
              });
            } else {
              this.placeOrder();
            }
          }
        });
      } else {
        this.placeOrder();
      }
    }
  }

  calculateMinAmountNeeded(orderBook: any = [], type: string = '') {
    try {
      let feeToUse = +this.activeMarket[this.feeType];
      if (this.marketService[this.feeType] < feeToUse) {
        feeToUse = +this.marketService[this.feeType];
      }
      let netTotalToOffer: number = this.netTotalMinimum;
      let currentNetTotal: number | Big = new Big(0).toFixed(8);
      let amountCalc: number | Big = new Big(0).toFixed(8);

      for (let i = 0; i < orderBook.length; i++) {
        if (+orderBook[i].amount > 0) {
          currentNetTotal = this.calculateOrderNetTotal(orderBook[i].price, orderBook[i].amount,
            feeToUse, orderBook[i].orderTotal, 'partial');
          let orderTake: number | Big = new Big(0).toFixed(8);
          if (netTotalToOffer - currentNetTotal > 0) {
            orderTake = +orderBook[i].amount;
            netTotalToOffer = netTotalToOffer - currentNetTotal;
          } else {
            let currentAmount: number | Big = new Big(0).toFixed(8);
            if (['ZAR', 'USDT'].indexOf(this.activeMarket.exchangeCode) === -1) {
              const currentFee = new Big(1)[this.setting.type === 'buy' ? 'plus' : 'minus'](feeToUse);
              const currentTotal = new Big(+netTotalToOffer).div(currentFee);
              currentAmount = currentTotal.div(+orderBook[i].price);
            } else {
              const currentFee = new Big(1)[this.setting.type === 'buy' ? 'plus' : 'minus'](feeToUse);
              currentAmount = new Big(netTotalToOffer).div(currentFee.times(orderBook[i].price));
            }
            orderTake = currentAmount;
            netTotalToOffer = 0;
          }
          amountCalc = new Big(amountCalc).plus(orderTake, 8);
          if (netTotalToOffer <= 0) {
            break;
          }
        }
      }
      if (type === 'buy') {
        this.minAmountBuy = this.valueChange(amountCalc, this.amountDecimalSpaces);
      } else if (type === 'sell') {
        this.minAmountSell = this.valueChange(amountCalc, this.amountDecimalSpaces);
      }
    } catch (ex) {
    }
  }

  placeOrder() {
    const data: any = {
      amount: this.amount,
      price: this.firstPrice,
      net_total: this.netTotal,
      market: this.activeMarket.id,
      type: orderType[this.setting.type.toLowerCase()],
      action: executionType.market,
      quick_order: this.quickOrder
    };
    this.marketService.createOrder(data).subscribe((response) => {

      if (this.showConfirmation) {

        // checking for specific error messages
        if (response.reason === 'CREATEORDER_NOTENOUGHBALANCE' || response.response === 'success') {
          this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), { duration: 2000 });
        } else {
          this.dialog.open(MarketConfirmationComponent, {
            width: '500px',
            data: {
              title: response.response === 'success' ? 'Success' : 'Failed',
              result: response.response === 'success' ? 'success' : 'failed',
              reason: response.reason
            }
          });
        }
      } else if (response.response !== 'success') {
        this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), { duration: 5000 });
      }

      if (response.response === 'success') {
        this.store.updateBalance({
          code: data.type === 0 ?
            this.activeMarket.exchangeCode : this.activeMarket.coinCode,
          balance_available: response.balance_available
        });

      this.amount = (0).toFixed(this.amountDecimalSpaces);
      this.netTotal = (0).toFixed(this.decimalSpaces);
      this.orderForm.controls.amount.markAsUntouched();
      this.orderForm.controls.net_total.markAsUntouched();
      }
    });
  }

}
