import { Pipe, PipeTransform } from '@angular/core';
import * as Big from 'big-js';

interface CoinException {
  code: string;
  decimals: number;
}

@Pipe({name: 'decimalNumber'})
export class DecimalNumberPipe implements PipeTransform {

  static coinExceptions: Array<CoinException> = [
    { code: 'zar', decimals: 2 },
    { code: 'usdt', decimals: 8 },
  ];

  transform(value: any, decimal: any, coin_code: string): string {
    let converted;
    if (typeof value === 'string') {
      converted = Number(value);
      if (isNaN(converted)) {
        return value;
      } else {
        value = converted;
      }
    }
    if (typeof decimal === 'string') {
      converted = Number(decimal);
      if (isNaN(converted)) {
        decimal = 8;
      } else {
        decimal = converted;
      }
    } // adding this in so that the pipe doesn't break on strings

    let decimalSpaces: number = !!decimal ? decimal : 8;

    coin_code = !!coin_code ? coin_code.toLowerCase() : '';


    const exception: CoinException = DecimalNumberPipe.coinExceptions.find(ce => ce.code === coin_code);

    if (!!exception) {
      decimalSpaces = exception.decimals;
    }

    let result: string = (0).toFixed(decimalSpaces);
    if (!!value) {
      const ten = new Big(10);
      const multiplier = ten.pow(decimalSpaces);

      let valueT = new Big(Number(value));
      valueT = new Big(Number(Math.floor(valueT.times(multiplier))));
      valueT = valueT.div(multiplier);
      result = valueT.toFixed(decimalSpaces);
    }
    return result;
  }
}
