import { Component, OnDestroy, OnInit } from '@angular/core';
import { MetaService } from '@ngx-meta/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AddressView } from '../core/interfaces/bankAddressbook';
import { VerificationRequestType } from '../core/interfaces/profilesettings';
import { EventHubService } from '../core/services/event-hub.service';
import { SessionStorageService, SubscriptionLike } from '../core/services/session-storage.service';
import { StoreService } from '../core/services/store.service';

@Component({
  selector: 'app-request-verification',
  templateUrl: './request-verification.component.html',
  styleUrls: ['./request-verification.component.scss']
})
export class RequestVerificationComponent implements OnInit, OnDestroy {

  // ENUM reference
  AddressView: typeof AddressView = AddressView;
  VerificationRequestType: typeof VerificationRequestType = VerificationRequestType;
  verificationData: any;

  assetsPath: string = '/assets/chainex/images/pages/verification/';
  exchangeName: string = environment.config.EXCHANGE_NAME_L;
  requestType: VerificationRequestType = VerificationRequestType.Mobile; // default to mobile

  darkTheme: boolean = false;
  complianceAnswered: true;
  verificationStarted: boolean = false;
  verificationFailed: boolean = false;

  profile: any;

  sub: SubscriptionLike;

  constructor(
    private sessionStorage: SessionStorageService,
    private store: StoreService,
    private eventhub: EventHubService,
    private readonly meta: MetaService) {
  }

  ngOnInit(): void {
    // set page title after redirecting from event service
    this.meta.setTitle(environment.config.EXCHANGE_NAME + ` - Verification`);

    this.sub = this.sessionStorage.observe('THEME').subscribe((data) => {
      this.darkTheme = data === 'dark-theme';
    });
    const verificationTypeRequired = this.sessionStorage.get('requestType');
    this.requestType = verificationTypeRequired ? verificationTypeRequired : VerificationRequestType.Mobile;

    this.store.getProfile().pipe(take(1)).subscribe((response: any) => {
      if (response.data.verification_type_required &&
        response.data.verification_type_required !== this.sessionStorage.get('verificationTypeRequired')) {
        // verification type required has changed and needs to be updated
        this.eventhub.emit(response.data.verification_type_required);
        this.sessionStorage.store('verificationTypeRequired', response.data.verification_type_required);
        const newType = this.sessionStorage.get('requestType');
        this.requestType = newType ? newType : VerificationRequestType.Mobile;
      }

      this.profile = response.data;
      this.verificationData = {
        firstName: this.profile.first_name,
        lastName: this.profile.last_name,
        cellphone_attempt_count: Number(this.profile.cellphone_attempt_count),
        max_cellphone_attempt_notification: this.profile.max_cellphone_attempt_notification,
        profile: this.profile,
        code: 'ZAR',
        failedFields: this.profile.failed_fields ? (JSON.parse(this.profile.failed_fields)) : [],
        livenessChecked: this.profile.livenessChecked ? this.profile.livenessChecked === '1' : false
      };
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  openForm() {
    this.verificationStarted = true;
  }

  onComplianceAnswered() {
    this.complianceAnswered = true;
  }

  onVerificationCancel() {
    this.verificationStarted = false;
  }

  onShowToastNotification(message: string) {
    this.store.showToastNotification('Verification', message, 3000, 'toast-bottom-center');
  }

  onVerificationSet(verificationType: VerificationRequestType) {
    this.requestType = verificationType;
    if (this.requestType > VerificationRequestType.Bank) {
      this.verificationStarted = false; // Verification complete
    }
  }

  onVerificationNext() {
    // verification steps:
    // Mobile
    // Identity
    // Residence
    // Income (Compliance)
    // Bank

    this.requestType++;
    if (this.requestType > VerificationRequestType.Bank) {
      this.verificationStarted = false; // Verification complete
    }
  }

  onVerificationPrevious() {
    this.requestType--;
  }

  logout() {
    this.sessionStorage.store('LOGGED_IN', '');
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

}
