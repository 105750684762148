<div class="wrapper d-flex">
  <div class="col mt-auto mb-auto">
    <h1 class="main-text">
      404 - Not Found
    </h1>
    <h2>
      The requested URL or resource could not be found on the server
    </h2>
  </div>
</div>
