// Angular
import { Component, OnInit, OnDestroy, Inject, AfterViewInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild } from '@angular/core';
// Components
import { SnackbarMessageComponent } from 'src/app/shared/snackbar-message/snackbar-message';
// Interfaces
import { BankAddressBook, BankAddressStatus, AddBankAddressBook,
  BankAddressVerified, RemoveBankAddress, BankAddressBooks, AddressView
} from 'src/app/core/interfaces/bankAddressbook';
// Libraries
import { merge, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map,
  mergeMap,
  skipWhile, take, takeUntil, tap
} from 'rxjs/operators';
// Services
import { BalancesService } from 'src/app/balances/balances.service';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { AddressBookService } from '../address-book.service';
// Store
import { AddressBookStore, GetFiatAddressBook, AddFiatAddressBookItem,
  RemoveFiatAddressBookItem
} from '../state/address-book.state';
import { Store } from '@ngxs/store';
// Local
import { environment } from 'src/environments/environment';
import { FundPinStatus } from 'src/app/core/interfaces/fund-pin';

export interface BankAddressBookSettings {
  code: string;
  verificationLevel: number;
  addressCheck: boolean;
  is2faenabled?: boolean;
  isFundpinEnabled?: string;
}

export interface BankAddressBookCloseData {
  name: string;
  deleted: boolean;
}

@Component({
  selector: 'app-bank-address-book',
  templateUrl: './bank-address-book.component.html',
  styleUrls: ['./bank-address-book.component.scss']
})
export class BankAddressBookComponent implements OnInit, OnDestroy, AfterViewInit {
  private _timeout$: Subject<void> = new Subject();

  @Output() verificationNext: EventEmitter<any> = new EventEmitter();
  @Output() verificationCancel: EventEmitter<any> = new EventEmitter();
  @Input() viewDisplay: number = AddressView.Normal;
  @Input() setting: BankAddressBookSettings = {
    code: '',
    verificationLevel: 1,
    addressCheck: false
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('unsavedSort') unsavedSort: MatSort;
  // Allows for the paginators to be destroyed then rebinded after being created again
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.savedAddresses.paginator = paginator;
    }
  }

  addressForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    bank: new FormControl('', Validators.required),
    accountNumber: new FormControl('', Validators.required),
    accountType: new FormControl('', Validators.required),
    branch: new FormControl('', Validators.required),
    trusted: new FormControl(''),
    password: new FormControl(''),
    tfa: new FormControl(''),
    fundPin: new FormControl('')
  });

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  bankNames: Array<string> = [];
  accountTypes: Array<string> = [];

  // table definitions
  addressDisplayedColumns: string[] = ['name', 'bank', 'accountNumber', 'verified', 'trusted', 'actions'];
  savedAddresses: MatTableDataSource<BankAddressBook> = new MatTableDataSource([]);

  // Used to reference enum on html
  AddressView: typeof AddressView = AddressView;
  BankAddressStatus: typeof BankAddressStatus = BankAddressStatus;
  BankAddressVerified: typeof BankAddressVerified = BankAddressVerified;
  FundPinStatus: typeof FundPinStatus = FundPinStatus;

  bankDeleted: boolean = false;

  constructor(
    private _balancesService: BalancesService,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: BankAddressBookSettings,
    @Optional() private _dialogRef: MatDialogRef<BankAddressBookComponent>,
    private _snackbar: MatSnackBar,
    private _addressStore: Store,
    private _addressBookService: AddressBookService,
    private _translateService: I18nTranslationService,
  ) {
    if (_data) {
      this.setting = _data;
    }
  }

  ngOnInit(): void {
    if (this.setting.addressCheck === true) {
      this.viewDisplay = AddressView.Add;
    }

    merge(
      this._addressStore.dispatch(new GetFiatAddressBook(this.setting.code)).pipe(
        mergeMap(() => this._addressStore.select(AddressBookStore.GetFiatAddressBook(this.setting.code))),
        distinctUntilChanged(),
        tap((value) => {
          const val: BankAddressBooks = value;
            if (!!val && val.addresses) {
              this.savedAddresses.data = val.addresses;
            }
        }),
        takeUntil(this._timeout$)
      ),

      this.form.name.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        skipWhile(value => value === '' || value === null),
        tap((value) => {
          const idx = this.savedAddresses.data
          .findIndex((a: BankAddressBook) => a.name.toLowerCase() === value.toLowerCase());
          if (idx > -1) {
            this.form.name.setErrors({'nameExists': true});
          }
        }),
        takeUntil(this._timeout$)
      ),

      this.form.accountNumber.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        skipWhile(value => value === ''),
        tap((value) => {
          const idx = this.savedAddresses.data
          .findIndex((a: BankAddressBook) => a.accountNumber.toLowerCase() === value.toLowerCase());
          if (idx > -1) {
            this.form.accountNumber.setErrors({'accountNumberExists': true});
          }
        }),
        takeUntil(this._timeout$)
      ),

      this.form.trusted.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap((value) => {
          if (value) {
            this.form.password.setValidators([Validators.required]);
            if (this.setting.is2faenabled) {
              this.form.tfa.setValidators([Validators.required]);
            }
            if (this.setting.isFundpinEnabled === FundPinStatus.Enabled) {
              this.form.fundPin.setValidators([Validators.required]);
            }
          } else {
            this.form.password.setValue('');
            this.form.tfa.setValue('');
            this.form.fundPin.setValue('');
            this.form.password.clearValidators();
            this.form.tfa.clearValidators();
            this.form.fundPin.clearValidators();
          }
          this.form.password.updateValueAndValidity();
          this.form.tfa.updateValueAndValidity();
          this.form.fundPin.updateValueAndValidity();
        }),
        takeUntil(this._timeout$)
      )
    ).subscribe();

    this.getBanks();
    this.getBankAccountTypes();
  }

  ngAfterViewInit() {
    this.savedAddresses.sort = this.sort;
  }

  ngOnDestroy() {
    this._timeout$.next();
    this._timeout$.complete();
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  get form() {
    return this.addressForm.controls;
  }

  getBanks() {
    this._balancesService.getBanks().pipe(take(1)).subscribe(response => {
      if (response.response === 'success') {
        this.bankNames = response.data;
      }
    });
  }

  getBankAccountTypes () {
    this._balancesService.getBankAccountTypes().pipe(take(1)).subscribe(response => {
      if (response.response === 'success') {
        this.accountTypes = response.data;
      }
    });
  }

  applySavedFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.savedAddresses.filter = filterValue.trim().toLowerCase();
  }

  useAddress(name?: string): void {
    const data: BankAddressBookCloseData = {
      name: name ? name : '',
      deleted: this.bankDeleted
    };
    this._dialogRef.close(data);
  }

  addAddressForm(): void {
    this.viewDisplay = AddressView.Add;
  }

  addAddress() {
    if (this.addressForm.status !== 'VALID') {
      return false;
    }

    this.addressForm.markAsUntouched();

    const data: AddBankAddressBook = {
      coin: this.setting.code,
      name: this.form.name.value.trim(),
      bankName: this.form.bank.value.name,
      bankID: this.form.bank.value.id,
      accountNumber: this.form.accountNumber.value.trim(),
      accountType: this.form.accountType.value.id,
      branchCode: this.form.branch.value.trim(),
      trusted: this.form.trusted.value,
      fastClearingAvailable: (+this.form.bank.value.fast_clearing_available === 1)
    };
    if (this.form.trusted.value) {
      data.password = this.form.password.value;
      if (this.form.fundPin.value !== '') {
        data.fundPin = this.form.fundPin.value;
      }
      if (this.form.tfa.value !== '') {
        data.tfa = this.form.tfa.value;
      }
    }
    this._addressStore.dispatch(new AddFiatAddressBookItem(data)).pipe(take(1), catchError((error) => {
      if (error.cdvStatus) {
        this.handleCdvStatusError(error.cdvStatus);
      } else {
        this.form.password.setValue('');
        this.form.tfa.setValue('');
        this.form.fundPin.setValue('');
        this.showSnackBar(error.reason);
      }
      throw error;
    })).subscribe(() => {
      if (this.addressForm.valid) {
        if (this.form.trusted.value === true) {
          this.viewDisplay = AddressView.ConfirmationTrusted;
        } else {
          this.viewDisplay = AddressView.Confirmation;
        }
        if (!this._dialogRef && this.verificationNext) { // if not dialog i.e. viewed from verification page
          this.showSnackBar('Bank details succesfully added');
          this.verificationNext.emit();
        }
      }
    });
  }

  verificationCancel_click() {
    this.verificationCancel.emit();
  }

  handleCdvStatusError(status: string) {
    switch (status) {
      case 'INVALID_ACCOUNT_TOO_LONG':
        this.form.accountNumber.setErrors({invalid: true});
        break;
      case 'INVALID_ACCOUNT_NUMBER':
        this.form.accountNumber.setErrors({invalid: true});
        break;
      case 'INVALID_BRANCH_NUMBER':
        this.form.branch.setErrors({invalid: true});
        break;
      case 'INVALID_BRANCH':
        this.form.branch.setErrors({invalid: true});
        break;
      case 'INVALID_ACCOUNT_TYPE':
        this.form.accountType.setErrors({invalid: true});
        break;
    }
  }

  requestRemoveAddress(bankAddress: BankAddressBook): void {
    this.addressForm.reset({
      name: bankAddress.name,
      bank: bankAddress.bank,
      accountNumber: bankAddress.accountNumber,
      trusted: bankAddress.statusLabel,
    });

    this.viewDisplay = AddressView.ConfirmRemove;
  }

  removeAddress(): void {
    const data: RemoveBankAddress = {
      coin: this.setting.code,
      name: this.form.name.value,
      accountNumber: this.form.accountNumber.value
    };

    this._addressBookService.bankAccountUnlink(data).pipe(take(1)).subscribe((response) => {
      this.showSnackBar(response.reason);
      if (response.response === 'success') {
        this._addressStore.dispatch(new RemoveFiatAddressBookItem(data.coin, data.accountNumber));
        this.bankDeleted = true;
        this.resetView();
      }
    });
  }

  determineCloseView() {
    if (this.setting.addressCheck) {
      this.useAddress();
    } else {
      this.resetView();
    }
  }

  resetView(): void {
    this.addressForm.reset({
      name: '',
      bank: '',
      accountNumber: '',
      accountType: '',
      branch: '',
      trusted: false,
      password: '',
      tfa: ''
    });
    this.form.password.clearValidators();
    this.form.tfa.clearValidators();
    this.form.password.updateValueAndValidity();
    this.form.tfa.updateValueAndValidity();
    this.viewDisplay = AddressView.Normal;
  }

  showSnackBar(message: string): void {
    SnackbarMessageComponent.openSnackBar(this._snackbar,
      this._translateService.translateResponse(message),
      2000
    );
  }
}
