import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { SessionStorageService } from '../services/session-storage.service';
import { DateHelper } from '../util/date-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';

const BASE_URL = environment.config.BACKEND_URL;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  counter: number = 0;
  constructor(private http: HttpClient,
    private sessionStorage: SessionStorageService,
    public snackbar: MatSnackBar,
    private i18n: I18n
  ) { }

  call<T>(api: string, params?: any, useDefaultPath?: boolean): Observable<T> {
    const url = `${BASE_URL}/${useDefaultPath ? '' : 'action'}/${api}`;
    const req: Observable<T> = params ? this.http.post<T>(url, params) : this.http.get<T>(url);
    return req.pipe(
      tap((data: any) => {
        // @TODO: Don't call the sessionStorage each and every time a request is made...
        //        This should be set correctly somewhere and updated as needed.
        //        local / session storage requests are not cheap, even if they appear to be quick.
        // if user not logged in or does not have a timezone set
        const userTimeZone = this.sessionStorage.get('LOGGED_TIMEZONE');
        const isTimeFormatEndpoint = !!data && !!data.data && data.data.length > 0 && !!data.data[0].time_formatted;
        if (!userTimeZone && (data).response === 'success' && isTimeFormatEndpoint) {
          data.data = DateHelper.updateTimeFormatted((data as any).data);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.counter++;
        if (this.counter === 100) {
          this.counter = 0;
          this.snackbar.open(this.i18n('Connection to ChainEX API servers failed'), this.i18n('Close'), {duration: 0});
        }
        return throwError(this.i18n('APIService offline or no network'));
      })
    );
  }

  // Create HTTP request for fetching files as Blob objects
  callBlob(api: string, params?: any, useDefaultPath?: boolean): Observable<any> {
    const url = `${BASE_URL}/${useDefaultPath ? '' : 'action'}/${api}`;
    const req: Observable<any> = params ?
      this.http.post(url, params, {responseType: 'blob'}) : this.http.get(url, {responseType: 'blob'});
    return req;
  }
}
