import {EventHubService} from './event-hub.service';
import {SessionStorageService, SubscriptionLike} from './session-storage.service';
import {Injectable, OnDestroy} from '@angular/core';
import {ApiService} from '../api/api.service';

const STORE_KEY = 'lastAction';
const CHECK_INTERVAL = 5000; // in ms

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService implements OnDestroy {
  private lastBackendAction: number = 0;
  public logoff: boolean = false;

  started: boolean = false;
  timer: any;

  get lastAction() {
    return Number(this.sessionStorage.get(STORE_KEY));
  }

  set lastAction(value: any) {
    this.sessionStorage.store(STORE_KEY, value);
  }

  private subs: any = [];

  constructor(
    private apiService: ApiService,
    private sessionStorage: SessionStorageService,
    private eventhub: EventHubService) {

    this.subs.push(this.sessionStorage.observe('LOGGED_IN').subscribe(
      (data) => {
        if (data === 'logged_in') {
          this.fetchAutoLogoutTime();
        } else {
          this.eventhub.emit('close-autologout-modal');
          this.destroyInterval();
        }
      }));

    this.subs.push(this.eventhub.listen().subscribe((m: any) => {
      this.onEvent(m);
    }));

    this.initListener();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => !!sub && sub.unsubscribe());
    document.body.removeEventListener('click', () => this.reset(true));
  }

  startIfNotStarted() {
    if (!this.started) {
      if (this.sessionStorage.get('LOGGED_IN') === 'logged_in') {
        this.check(true);
        this.initInterval();
      }
    }
  }

  onEvent(event: any) {
    switch (event) {
      case 'set-auto-logout-time':
        this.fetchAutoLogoutTime();
        break;
      case 'reset-logout-time':
        this.reset();
        break;
    }
  }

  fetchAutoLogoutTime(pageClicked?: boolean) {
    // refresh time on api every 5 minutes
    // skip check if page was not not clicked (system event)
    if (this.sessionStorage.get('LOGGED_IN') === 'logged_in') {
      if (Date.now() > this.lastBackendAction + (300 * 1000) || !pageClicked) {
        this.apiService.call('getUserProfile')
          .subscribe((response: any) => {
            if (response.response === 'success') {
              this.secondsUntilAutoLogout = Number(response.data.autologout);
              this.lastBackendAction = Date.now();
            }
          });
      }
    }
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset(true));
  }

  get secondsUntilAutoLogout(): any {
    const defaultAutoLogoutTime = 2700;
    if (this.sessionStorage.get('AUTOLOGOUT_TIME') !== '') {
      return this.sessionStorage.get('AUTOLOGOUT_TIME');
    }
    return defaultAutoLogoutTime;
  }

  set secondsUntilAutoLogout(seconds: any) {
    this.sessionStorage.store('AUTOLOGOUT_TIME', Number(seconds));
  }

  reset(pageClicked?: boolean) {
      this.fetchAutoLogoutTime(pageClicked); // Reset last_active on API
      this.lastAction = Date.now();
  }

  initInterval() {
    if (this.timer) {
      this.destroyInterval();
    }
    this.timer = setInterval(() => {
      this.check(false);
    }, CHECK_INTERVAL);
    this.started = true;
  }

  destroyInterval() {
    clearInterval(this.timer);
    this.started = false;
  }

  check(force_logout: boolean) {
    if (this.sessionStorage.get('LOGGED_IN') === 'logged_in') {
      const now = Date.now();
      const timeleft = this.lastAction + Number(this.secondsUntilAutoLogout - 60) * 1000;
      const diff = timeleft - now;
      const isTimeout = diff < 0;

      if (isTimeout && this.sessionStorage.get('LOGGED_IN') === 'logged_in') {
        if (force_logout) {
          this.eventhub.emit('force-autologout');
        } else {
          this.logoff = true;
          this.eventhub.emit('show-autologout-modal');
        }
      }
    }
  }
}
