import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';

@Pipe({
    name: 'emoji'
})
export class EmojiPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    icons: any = {
        'laugh': 'assets/' + environment.config.EXCHANGE_NAME_L + '/images/chat/emoticon_laugh.svg',
        'love': 'assets/' + environment.config.EXCHANGE_NAME_L + '/images/chat/emoticon_love.svg',
        'sad': 'assets/' + environment.config.EXCHANGE_NAME_L + '/images/chat/emoticon_sad.svg',
        'smile': 'assets/' + environment.config.EXCHANGE_NAME_L + '/images/chat/emoticon_smiley.svg',
        'thumbsup': 'assets/' + environment.config.EXCHANGE_NAME_L + '/images/chat/emoticon_thumbsup.svg',
        'wink': 'assets/' + environment.config.EXCHANGE_NAME_L + '/images/chat/emoticon_wink.svg'
    };

    emojis: string = 'laugh|love|sad|smile|thumbsup|wink';

    regex: any = new RegExp(`:(${this.emojis}):`, 'g');

    getHTML(nativeElement: any) {
        if (!nativeElement) {
            return '';
        }
        const temp = document.createElement('div');
        temp.appendChild(nativeElement);
        return temp.innerHTML;
    }

    transform(value: any): SafeHtml {
        const self = this;
        value = value.replace(this.regex, function (match: string, emoji: string) {
            const icon = document.createElement('span');
            const img = document.createElement('img');
            icon.classList.add('emoji-container');
            img.classList.add('emoji');
            img.src = self.icons[emoji];
            icon.appendChild(img);
            return self.getHTML(icon);
        });
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
