<div [ngClass]="darkTheme ? 'dark-box':'light-box'" class="d-flex justify-content-start justify-content-lg-end" *ngIf="selectedMarket">
  <button type="button" class="display-price-colour btn px-1 col-auto py-0 dropdown-toggle d-flex align-items-center" data-toggle="dropdown" data-boundary="window" data-display="static">
    <img class="image-container d-block mr-2" [src]="imageUrl + selectedMarket.coinCode.toLowerCase() + '.png'" (error)="$event.target.src = imageUrl + 'fallback_coin_icon.png'">
      <div class="row display-price-size mx-0">
        <span class="d-inline px-0">{{selectedMarket.coinCode}}/{{selectedMarket.exchangeCode}}&nbsp;</span>
        <span class="d-block col-auto px-0">{{selectedMarket.lastPrice | decimalNumber: selectedMarket.exchangeDecimals}}</span>
      </div>
  </button>

  <div class="dropdown-menu">
    <div *ngFor="let market of markets" [trackBy]="trackByMarket">
    <button class="dropdown-item d-flex align-items-center pl-1 pl-lg-3 pr-2" type="button" (click)="selectMarket(market.id)">
      <img class="mr-2 image-container" [src]="imageUrl + market.coinCode.toLowerCase() + '.png'" (error)="$event.target.src = imageUrl + 'fallback_coin_icon.png'" loading="lazy">
      <span class="mr-2">{{market.coinName}}</span>
      <span class="mr-2"><b>{{market.exchangeCode}}</b></span>
      <span>{{market.lastPrice | decimalNumber: market.exchangeDecimals}}</span>
    </button>
  </div>
  </div>
</div>
