export interface AddressBook {
  name: string;
  address: string;
  coin_network?: string;
  network_name?: string;
  paymentId?: string;
  trusted: boolean;
  status: number;
  statusLabel: string;
}

export interface ModifyAddressBook {
  code: string;
  name: string;
  address: string;
  coin_network?: string;
  network_name?: string;
  action: string;
  paymentId?: string;
  trusted?: boolean;
  password?: string;
  tfa?: string;
  fundPin?: string;
}

export interface AddressBookConfirm {
  hash: string;
  action: string;
}

export interface UnsavedAddresses {
  code: string;
  coin_network?: string;
  network_name?: string;
  lastWithdrawal: string;
  address: string;
  paymentId?: string;
}

export enum AddressStatus {
  Untrusted = 0,
  Trusted = 2,
  Pending = 1
}
