import { Config } from 'src/assets/Config';
import { FaceTecSDK } from 'src/assets/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { LivenessCheckProcessor } from './processors/LivenessCheckProcessor';
import { FaceTecAppUtilities } from './utilities/FaceTecAppUtilities';
import { FaceTecSessionResult, FaceTecIDScanResult } from 'src/assets/core-sdk/FaceTecSDK.js/FaceTecPublicApi';
import { LivenessCheckComponent } from 'src/app/shared/liveness-check/liveness-check.component';

export const FaceTecController = (function() {
  let parentComponentRef: LivenessCheckComponent;
  let latestSessionResult: FaceTecSessionResult | null = null;
  let latestIDScanResult: FaceTecIDScanResult | null = null;
  let latestProcessor: LivenessCheckProcessor;
  let deviceKeyRetrieved;

  function loadComplete(
      parentComponent: LivenessCheckComponent,
      productionKey: string,
      deviceKey: string,
      publicEncryptionKey: string
    ) {
    parentComponentRef = parentComponent;
    deviceKeyRetrieved = deviceKey;

    // Set a the directory path for other FaceTec Browser SDK Resources.
    FaceTecSDK.setResourceDirectory('/assets/core-sdk/FaceTecSDK.js/resources');

    // Set the directory path for required FaceTec Browser SDK images.
    FaceTecSDK.setImagesDirectory('/assets/core-sdk/FaceTec_images');

    FaceTecSDK.setCustomization(Config.retrieveConfigurationWizardCustomization(FaceTecSDK));

    // FaceTecSDK.initializeInDevelopmentMode(Config.DeviceKeyIdentifier,Config.PublicFaceScanEncryptionKey,
    //  function(initializedSuccessfully: boolean) { // Uncomment this for debugging
    FaceTecSDK.initializeInProductionMode(productionKey, deviceKey, publicEncryptionKey,
      function (initializedSuccessfully: boolean) {

        if (initializedSuccessfully) {
          parentComponentRef.onTestInit();
          FaceTecAppUtilities.enableControlButtons();
        }
        FaceTecAppUtilities.displayStatus(FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));
        FaceTecAppUtilities.formatUIForDevice();
      });
  }

  // Initiate a 3D Liveness Check.
  function onLivenessCheckPressed(sessionToken: string, xsrfToken: string) {
    FaceTecAppUtilities.fadeOutMainUIAndPrepareForSession();

    // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.
    latestProcessor =
      new LivenessCheckProcessor(sessionToken as string, deviceKeyRetrieved, xsrfToken, FaceTecController as any);
  }

  // Show the final result and transition back into the main interface.
  function onComplete(sessionResult: FaceTecSessionResult, idScanResult: FaceTecIDScanResult) {
    latestSessionResult = sessionResult;
    latestIDScanResult = idScanResult;
    FaceTecAppUtilities.showMainUI();
    parentComponentRef.onTestEnd();

    if (!latestProcessor.isSuccess()) {
      // Show early exit message to screen.  If this occurs, please check logs.
      FaceTecAppUtilities.displayStatus('Session was not successful, please try again.');
      return;
    }

    // Show successful message to screen
    FaceTecAppUtilities.displayStatus('Success');
    parentComponentRef.onComplete(latestProcessor.isSuccess(), sessionResult);
  }

  // Can be used to get session tokens in debug mode
  function getSessionToken(sessionTokenCallback: (sessionToken?: string) => void) {
    const XHR = new XMLHttpRequest();
    XHR.open('GET', Config.BaseURL + '/session-token-v9');
    XHR.setRequestHeader('X-Device-Key', deviceKeyRetrieved);
    XHR.setRequestHeader('X-User-Agent', FaceTecSDK.createFaceTecAPIUserAgentString(''));
    XHR.onreadystatechange = function() {
      if (this.readyState === XMLHttpRequest.DONE) {
        let sessionToken = '';
        try {
          // Attempt to get the sessionToken from the response object.
          sessionToken = JSON.parse(this.responseText).sessionToken;
          // Something went wrong in parsing the response. Return an error.
          if (typeof sessionToken !== 'string') {
            onServerSessionTokenError();
            return;
          }
        } catch {
          // Something went wrong in parsing the response. Return an error.
          onServerSessionTokenError();
          return;
        }
        sessionTokenCallback(sessionToken);
      }
    };

    XHR.onerror = function() {
      onServerSessionTokenError();
    };
    XHR.send();
  }

  function onServerSessionTokenError() {
    FaceTecAppUtilities.handleErrorGettingServerSessionToken();
  }

  function getLatestSessionResult(): FaceTecSessionResult {
    return latestSessionResult;
  }

  return {
    loadComplete,
    onLivenessCheckPressed,
    onComplete,
    getLatestSessionResult,
  };

})();
